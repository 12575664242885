import { Tooltip } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

type TipProps = {
  titleLocale: string;
  children?: React.ReactNode;
};

export default function Tip({ titleLocale, children }: TipProps) {
  const { t } = useLocales();

  return (
    <Tooltip title={<>{t(titleLocale)}</>}>
      <span>{children}</span>
    </Tooltip>
  );
}
