import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Dialog, TextField, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import useNotifications from '../../../../hooks/useNotifications';
import { createFolder } from '../../../../services/dlxDriveService';
import { setDriveListIdState } from '../../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveCreateFolderDialogProps = {
  isCreateFolderDialogOpen: boolean;
  setIsCreateFolderDialogOpen: (value: boolean) => void;
};

const DLXDriveCreateFolderDialog: React.FC<DLXDriveCreateFolderDialogProps> = ({
  isCreateFolderDialogOpen,
  setIsCreateFolderDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading, selectedFolder } = useSelector((state) => state.dlxDrive);
  const [folderName, setFolderName] = useState<string>('');
  const [folderPrefix, setFolderPrefix] = useState<string>('');
  const { enqueueSuccess, enqueueError } = useNotifications();
  const maxLengthPrefix = 50;
  const maxLengthName = 100;

  const handleSave = async () => {
    try {
      await createFolder({
        parentFolderId: selectedFolder.id,
        name: folderName,
        prefix: folderPrefix,
      });

      enqueueSuccess('dlxDrive.notifications.folderCreated');
      setFolderName('');
      setFolderPrefix('');
    } catch (error) {
      enqueueError(error.message);
    } finally {
      setDriveListIdState();
      handleClose();
    }
  };

  const handleClose = () => {
    setIsCreateFolderDialogOpen(false);
  };

  return (
    <Dialog open={isCreateFolderDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.createNewFolder')}</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          id="new-folder-name"
          label={t('dlxDrive.dialogs.folderName')}
          variant="outlined"
          size="small"
          fullWidth
          value={folderName}
          onChange={(e) => setFolderName(e.target.value.replaceAll('/', ''))}
          inputProps={{ maxLength: maxLengthName }}
          helperText={folderName.length === maxLengthName ? `${t('dlxDrive.dialogs.maxFolderNameLengthReached', { maxLengthName })}` : ''}
        />

        <TextField
          id="new-folder-prefix"
          label={t('dlxDrive.dialogs.folderPrefix')}
          variant="outlined"
          size="small"
          fullWidth
          value={folderPrefix}
          onChange={(e) => setFolderPrefix(e.target.value)}
          inputProps={{ maxLength: maxLengthPrefix }}
          helperText={folderPrefix.length === maxLengthPrefix ? `${t('dlxDrive.dialogs.maxFolderPrefixLengthReached', { maxLengthPrefix })}` : ''}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={folderName.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleSave}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveCreateFolderDialog;
