import { useNavigate } from 'react-router';

import { Box, Card, useTheme, CardHeader, Typography, CardContent } from '@mui/material';

import { PATH_STANDARD } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';

import { getStatusString } from 'src/utils/documentUtils';

import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';

import SvgIconStyle from 'src/components/SvgIconStyle';

// ----------------------------------------------------------------------

type DashboardB2CDocumentStatusShortcutProps = {
  status: DocumentStatuses;
  count: number;
};

export default function DashboardB2CDocumentStatusShortcut({ status, count }: DashboardB2CDocumentStatusShortcutProps) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    navigate(PATH_STANDARD.documents, { state: { status } });
  };

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <Card>
          <CardHeader
            sx={{ color: theme.palette.text.secondary, textTransform: 'uppercase', padding: '16px 16px 16px' }}
            titleTypographyProps={{ variant: 'subtitle2' }}
            title={
              <Box>
                <SvgIconStyle
                  src="/icons/dashboard_quickaction.svg"
                  sx={{ width: 20, height: 20, verticalAlign: 'sub', mr: 1, color: theme.palette.primary.dark }}
                />
                {t(getStatusString(status))}
              </Box>
            }
          />
          <CardContent sx={{ padding: '0px 16px 16px', pb: '16px !important' }}>
            <Typography variant='h4'>{count}</Typography>
          </CardContent>
        </Card>
      </Box>
  );
}
