import { Link as RouterLink } from 'react-router-dom';

import { Box, List, Avatar, Divider, useTheme, Typography, ListItemText, ListSubheader, ListItemAvatar, ListItemButton } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { fToNow } from 'src/utils/formatTime';

import { useSelector } from 'src/redux/store';
import { SystemNotification } from 'src/@types/system-notifications/types';
import { markNotificationSeen, clearSeenNotifications, markAllNotificationsSeen } from 'src/redux/slices/systemNotifications';

import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

export default function NotificationsList() {
  const { t } = useLocales();

  const { items } = useSelector((state) => state.systemNotifications);

  const unseen = items.filter((x) => !x.isSeen);
  const totalUnseen = unseen.length;
  const seen = items.filter((x) => x.isSeen);
  const totalSeen = seen.length;

  const markAllAsSeen = () => {
    markAllNotificationsSeen();
  };

  const clearAll = () => {
    clearSeenNotifications();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='subtitle1'>{t('layout.notifications.notifications')}</Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {t('layout.notifications.youHaveUnread', { total: totalUnseen })}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Scrollbar sx={{ maxHeight: { xs: '200px', sm: '310px' }, overflowX: 'hidden' }}>
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{
                py: 1,
                px: 2.5,
                typography: 'overline',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Box>{t('layout.notifications.new')}</Box>
              {totalUnseen > 0 && (
                <Box onClick={markAllAsSeen} sx={{ cursor: 'pointer', pl: 4 }}>
                  <Typography variant='subtitle2' sx={{ textDecoration: 'underline', textTransform: 'none' }}>
                    {t('layout.notifications.markAllAsSeen')}
                  </Typography>
                </Box>
              )}
            </ListSubheader>
          }
        >
          {unseen.map((notification, index) => (
            <NotificationItem key={index} notification={notification} />
          ))}
        </List>
      </Scrollbar>

      <Divider />

      <Scrollbar sx={{ maxHeight: { xs: '200px', sm: '170px' }, overflowX: 'hidden' }}>
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{ py: 1, px: 2.5, typography: 'overline', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}
            >
              <Box>{t('layout.notifications.alreadySeen')}</Box>
              {totalSeen > 0 && (
                <Box onClick={clearAll} sx={{ cursor: 'pointer' }}>
                  <Typography variant='subtitle2' sx={{ textDecoration: 'underline', textTransform: 'none' }}>
                    {t('layout.notifications.clearAll')}
                  </Typography>
                </Box>
              )}
            </ListSubheader>
          }
        >
          {seen.map((notification, index) => (
            <NotificationItem key={index} notification={notification} />
          ))}
        </List>
      </Scrollbar>
      <Divider />

      <Box sx={{ p: 1 }}>
        {/* <Button fullWidth disableRipple component={RouterLink} to='#'>
          View All
        </Button> */}
      </Box>
    </Box>
  );
}

function NotificationItem({ notification }: { notification: SystemNotification }) {
  const { t, currentLang } = useLocales();
  const theme = useTheme();

  const isUnseen = !notification.isSeen;

  function NotificationIcon() {
    if (isUnseen) {
      return <img alt={t(notification.localeObject.key, notification.localeObject.options)} src="/icons/notifications/icon_active_system_notification.svg" />;
    } 
      return (
        <img alt={t(notification.localeObject.key, notification.localeObject.options)} src="/icons/notifications/icon_inactive_system_notification.svg" />
      );
    
  }

  return (
    <ListItemButton
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        markNotificationSeen(notification);
        e.preventDefault();
      }}
      to='#'
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(isUnseen && {
          bgcolor: theme.palette.primary.lighter,
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <NotificationIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant='subtitle2'>{t(notification.localeObject.key, notification.localeObject.options)}</Typography>}
        secondary={
          <Typography
            variant='caption'
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon='eva:clock-outline' sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.dateTime, currentLang.dateFns)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
