import { PDFDocument } from 'pdf-lib';

import { FormBlankPageOrientation } from 'src/@types/forms/enums/FormBlankPageOrientation';

import { PageFormatProps, PageOrientationProps } from './generatePageProperties';

const generateBlankPDF = async (generatePageFormat: PageFormatProps, generatePageOrientation: PageOrientationProps, generatePageCount: number) => {
  const blankPDF = await PDFDocument.create();
  const pageSize1 = generatePageFormat.size[0];
  const pageSize2 = generatePageFormat.size[1];

  for (let i = 0; i < generatePageCount; i++) {
    if (generatePageOrientation.orientation === FormBlankPageOrientation.portrait) {
      blankPDF.addPage([pageSize1, pageSize2]);
    } else {
      blankPDF.addPage([pageSize2, pageSize1]);
    }
  }

  const pdfBytes = await blankPDF.save();

  return pdfBytes;
};

export default generateBlankPDF;
