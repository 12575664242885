import INK_COLOR from './inkColor';

// -------------- --------------------------------


const writeNameOnTheCanvas = (name: string, fontHeight: number) => {
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');

  if(!name) return null;
 
  if (tempCtx) {
    tempCtx.font = `italic ${fontHeight}px serif`;
    const textWidth = tempCtx.measureText(name).width + 10; // in cursive visible last letter
    // final canvas

    const canvas = document.createElement('canvas');
    canvas.width = textWidth;
    canvas.height = fontHeight + 2; 

    const ctx = canvas.getContext('2d');

    if(ctx){

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = `italic ${fontHeight}px serif`;
      ctx.lineCap = 'round';
      ctx.fillStyle = INK_COLOR;
      ctx.textBaseline = 'bottom';
      ctx.fontKerning = 'normal';
      ctx.imageSmoothingQuality = 'high';
      ctx.fillText(name, 0, fontHeight, textWidth); // 0 -> 10
    }
    return canvas;
  }

  return null;
};

export default writeNameOnTheCanvas;
