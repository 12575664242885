import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

// ----------------------------------------------------------------

const CalendarMobileSkeletonLayout: React.FC = () => {
  const events: number[] = new Array(3).fill(0);

  return (
    <>
      {events.map((_, i) => (
        <Box
          key={`calendar-desktop-skeleton-${i}`}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            mb: 1,
          }}
        >
          <Typography variant="h5" sx={{ minWidth: '112px', mr: 1 }}>
            <Skeleton />
          </Typography>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Skeleton variant="circular" width={14} height={14} />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h5">
                <Skeleton />
              </Typography>

              <Typography variant="caption">
                <Skeleton />
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}

      <Box
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Skeleton variant="circular" width={14} height={14} />

          <Typography variant="caption" sx={{ width: '100%' }}>
            <Skeleton />
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Skeleton variant="circular" width={14} height={14} />

          <Typography variant="caption" sx={{ width: '100%' }}>
            <Skeleton />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CalendarMobileSkeletonLayout;
