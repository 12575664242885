export class Tag {
  id: number;

  name: string;

  color: string;

  workflow?: string;

  parseJson(json: any): Tag | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Tag = new Tag();

    model.id = json.id;
    model.name = json.name;
    model.color = json.color;

    return model;
  }
}
