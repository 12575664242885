import axios from 'axios';
import { useEffect } from 'react';

import useLocales from 'src/hooks/useLocales';

import { AUTH0_API } from 'src/config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: AUTH0_API.domain,
});

export const useIdentityAxiosInterceptors = () => {
  const { currentLang } = useLocales();

  const errorInterceptor = async (error: any) => Promise.reject((error.response && error.response.data) || 'Something went wrong');

  const requestInterceptor = (config: any) => {
    config.headers['Accept-Language'] = currentLang.value;
    return config;
  };

  useEffect(() => {
    const refreshInterceptor = axiosInstance.interceptors.response.use((response) => response, errorInterceptor);
    const authInterceptor = axiosInstance.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

    return () => {
      axiosInstance.interceptors.response.eject(refreshInterceptor);
      axiosInstance.interceptors.request.eject(authInterceptor);
    };
  }, [currentLang]);

  return axiosInstance;
};

export default axiosInstance;
