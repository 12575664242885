import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  Stack,
  Table,
  Button,
  Divider,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  CardHeader,
  IconButton,
  Pagination,
  Typography,
  InputAdornment,
  TableContainer,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { useSelector } from 'src/redux/store';
import { Department } from 'src/@types/departments/departments';
import { UsersFilterState } from 'src/@types/addressBook/types';
import { getAddressBookUsers } from 'src/redux/slices/addressBook';
import { AddressBookFilter } from 'src/@types/addressBook/addressBookFilter';
import { DepartmentsFilter } from 'src/@types/departments/departmentsFilter';
import { getDepartments, deleteDepartment } from 'src/redux/slices/departments';
import { useConfirmationModalContext } from 'src/contexts/ConfirmationModalContext';

import Tip from 'src/components/Tip';
import Mobile from 'src/components/Mobile';
import Desktop from 'src/components/Desktop';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

import Item3dotDialog from 'src/sections/shared/item3dotDialog';
import AdminPanelBackButton from 'src/sections/admin/AdminPanelBackButton';

import DepartmentsDialog from './DepartmentsDialog';

//-----------------------------------------------------------

type DepartmentFilterState = {
  page: number;
  pageSize: number;
  searchQuery: string;
};

export default function Departments() {
  const { t } = useLocales();
  const theme = useTheme();
  const { departments } = useSelector((state) => state.departments);
  const debounceReloadDepartment = useCallback(debounce(reloadDepartment, 500), []);
  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 10,
    searchQuery: '',
  } as DepartmentFilterState);
  const [searchButtonPressed, setSearchButtonPressed] = useState(false);
  const [open, setOpen] = useState(false);
  const [editDepartment, setEditDepartment] = useState<Department | undefined>(undefined);
  const debounceReloadUsers = useCallback(debounce(reloadUsers, 500), []);
  const isLight = theme.palette.mode === 'light';

  function reloadUsers(filterState: UsersFilterState) {
    const usersFilter = new AddressBookFilter();
    usersFilter.limit = filterState.pageSize;
    usersFilter.offset = filterState.page - 1;
    usersFilter.searchQuery = filterState.searchQuery !== '' ? filterState.searchQuery : null;

    usersFilter.limit = undefined;
    getAddressBookUsers(usersFilter);
   
  }

  useEffect(() => {
    const tempFilter: UsersFilterState = { 
     page: 1,
     pageSize: 10,
     searchQuery: ''
   };
 
    debounceReloadUsers(tempFilter);
   }, []);

  function pageCount() {
    return Math.ceil((departments?.total || 0) / filter.pageSize);
  }

  const handlePageChange = (event: any, value: number) => {
    setFilter({ ...filter, page: value });
  };

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchQuery: event.target.value, page: 1 });
  };

  function reloadDepartment(filterState: DepartmentFilterState) {
    const departmentFilter = new DepartmentsFilter();
    departmentFilter.limit = filterState.pageSize;
    departmentFilter.offset = filterState.page - 1;
    departmentFilter.searchQuery = filterState.searchQuery !== '' ? filterState.searchQuery : null;
    getDepartments(departmentFilter);
  }

  const onCreate = () => {
    setOpen(true);
  };

  const onEdit = (department: Department) => {
    setEditDepartment(department);
    setOpen(true);
  };

  const onRemove = () => {
    reloadDepartment(filter);
  };

  const closeDepartmentsDialog = (update?: boolean) => {
    setEditDepartment(undefined);
    setOpen(false);

    if (update) {
      reloadDepartment(filter);
    }
  };

  useEffect(() => {
    debounceReloadDepartment(filter);
  }, [filter]);

  function TableHeader() {
    return (
      <>
        <Mobile>
        <AdminPanelBackButton />
          <Stack px={0} py={2} spacing={2} direction="row" justifyContent="space-between">
            <CardHeader title={t('departments.departments')} sx={{ p: 0 }} />
            <Stack spacing={2} direction="row">
              <Button
                sx={{ marginLeft: 2 }}
                variant="outlined"
                color="secondary"
                onClick={() => setSearchButtonPressed(!searchButtonPressed)}
                id="BtNvIP"
              >
                <SearchIcon />
              </Button>
            </Stack>
          </Stack>
          {searchButtonPressed && (
            <Stack px={0} pb={2}>
              <TextField
                fullWidth
                size="small"
                label={t('shared.fields.search')}
                value={filter.searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Stack>
          )}
          <Stack px={0} pb={2}>
            <Button variant="contained" onClick={onCreate} id="tPIL0Y">
              {t('departments.buttons.createDepartment')}
            </Button>
          </Stack>
        </Mobile>
        <Desktop>
         <AdminPanelBackButton />
          <Stack px={1} direction="row" justifyContent="space-between">
            <CardHeader title={t('departments.departments')} sx={{ p: 0 }} />
            <Stack px={1} py={2} direction="row" justifyContent="space-between">
            <Box sx={{ p: 0 }} />
            <Stack spacing={2} direction="row">
              <TextField
                size="small"
                sx={{ width: '200px' }}
                label={t('shared.fields.search')}
                value={filter.searchQuery}
                onChange={handleSearchQueryChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="contained" onClick={onCreate} id="tPIL0Y">
                {t('departments.buttons.createDepartment')}
              </Button>
            </Stack>
             </Stack>
          </Stack>
        </Desktop>
      </>
    );
  }

  const tableHeaderStyle = {
    backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
    color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
    py: 2,
  };

  return (
    <Box sx={{ mx: 2.5}}>
      {TableHeader()}
      {open && (
        <DepartmentsDialog
          open={open}
          department={editDepartment}
          handleClose={closeDepartmentsDialog}
        />
      )}

      <Desktop>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {[t('departments.table.department'), t('departments.table.manager')].map(
                    (item) => (
                      <TableCell key={item} sx={tableHeaderStyle}>
                        {item}
                      </TableCell>
                    )
                  )}
                  <TableCell sx={tableHeaderStyle} />
                </TableRow>
              </TableHead>
              <TableBody>
                {departments?.items.map((item) => (
                  <DepartmentsItem
                    key={item.id}
                    department={item}
                    onRemove={onRemove}
                    handleOpenDepartmentsDialog={() => onEdit(item)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Desktop>
      <Mobile>
        <Stack direction="column">
          {departments?.items.map((item) => (
            <DepartmentsItem
              key={item.id}
              department={item}
              onRemove={onRemove}
              handleOpenDepartmentsDialog={() => onEdit(item)}
            />
          ))}
        </Stack>
      </Mobile>

      <Divider />

      <Box sx={{ p: 2 }}>
        <Pagination
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}
          count={pageCount()}
          onChange={handlePageChange}
          page={filter.page}
        />
      </Box>
    </Box>
  );
}

type DepartmentsItemProps = {
  department: Department;
  onRemove: () => void;
  handleOpenDepartmentsDialog: () => void;
};

function DepartmentsItem({
  department,
  onRemove,
  handleOpenDepartmentsDialog,
}: DepartmentsItemProps) {
  const { t } = useLocales();
  const theme = useTheme();
  const { users } = useSelector((state) => state.addressBook);
  const { enqueueSuccess, enqueueError } = useNotifications();
  const modalContext = useConfirmationModalContext();
  const isLight = theme.palette.mode === 'light';

  const rowStyle = {
    cursor: 'pointer',
    boxShadow: isLight ?  '2px 3px 4px #CCCCCC' : 'none',
  };

  const getManager = () => {
    const manager = users?.items.find((i) => i.id === department.managerUserId);
    return manager?.email;
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const result = await modalContext.showConfirmation(
      t('components.confirmationModal.confirmation'),
      t('departments.areYouSureWantToRemovePermanently')
    );

    if (result) {
      await deleteDepartment(department.id);
      enqueueSuccess('departments.snackbar.deleteDepartment');
      onRemove();
    }
  };

  return (
    <>
      <Desktop>
        <TableRow sx={rowStyle} className="activeHover">
          <TableCell sx={{ maxWidth: '200px', overflow: 'hidden' }}>{department.name}</TableCell>
          <TableCell>{getManager()}</TableCell>
          <TableCell align="right" sx={{ minWidth: '180px' }}>
            <Tip titleLocale="departments.buttons.editDepartment">
              <IconButton
                size="large"
                onClick={() => {
                  handleOpenDepartmentsDialog();
                }}
                id="3f6OqN"
              >
                <Iconify icon="eva:edit-fill" width={20} height={20} />
              </IconButton>
            </Tip>
            <Tip titleLocale="shared.buttons.delete">
              <IconButton
                size="large"
                onClick={(e) => {
                  handleRemove(e);
                }}
                id="UN0Smk"
              >
                <Box sx={{ height: '20px', width: '20px' }}>
                  <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
                </Box>
              </IconButton>
            </Tip>
          </TableCell>
        </TableRow>
      </Desktop>

      <Mobile>
        <Card
          sx={{
            gap: 1,
            px: 2,
            py: 2,
            mb: 1,
            border: '1px solid #fff',
            display: 'flex',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Box sx={{ pb: 1 }}>
              <Typography
                variant="h5"
                sx={{
                  flex: 1,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'wrap',
                  fontWeight: 'bold',
                  // color: theme.palette.grey[700],
                }}
              >
                {department.name}
              </Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary, pb: 1, display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('departments.table.manager')}:
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.text.primary}
                sx={{
                  maxWidth: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {getManager()}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Item3dotDialog
              handleEdit={() => {
                handleOpenDepartmentsDialog();
              }}
              handleRemove={(e: any) => handleRemove(e)}
              isLoading={false}
            />
          </Box>
        </Card>
      </Mobile>
    </>
  );
}
