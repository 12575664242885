import { useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Stack, Button, Dialog, useTheme, Typography, IconButton, DialogContent, DialogActions } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { Tag } from 'src/@types/tags/tag';
import { useSelector } from 'src/redux/store';
import tagService from 'src/services/tagService';
import { TagsFilter } from 'src/@types/tags/tagsFilter';

import Iconify from 'src/components/Iconify';
import TagLabel from 'src/components/TagLabel';
import Scrollbar from 'src/components/Scrollbar';
import LoadingSpinner from 'src/components/LoadingSpinner';

// ----------------------------------------------------------------------

type EditTagsDialogProps = {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  open: boolean;
  handleClose: () => void;
};

export default function EditTagsDialog({ tags, open, handleClose, setTags }: EditTagsDialogProps) {
  const { t } = useLocales();
  const theme = useTheme();
  const { isLoading } = useSelector((state) => state.document);
  const [tagsList, setTagsList] = useState<Tag[]>([]);
  const [thisItemTags, setThisItemTags] = useState<Tag[]>([]);
  const { enqueueSuccess, enqueueError } = useNotifications();

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    if (tags) {
      setThisItemTags(tags);
    }
  }, []);

  const reloadData = async () => {
    const filter = new TagsFilter();
    const data = await tagService.getPagedTags(filter);
    setTagsList(data.items);
  };

  const handleSave = () => {
    try {
      setTags(thisItemTags);
      enqueueSuccess('documents.snackbar.tagsSet');
      handleClose();
    } catch (error) {
      enqueueError(error);
    }
  };

  const onClose = () => {
    handleClose();
  };

  const addTag = (tag: Tag) => {
    setThisItemTags([...thisItemTags, tag]);
  };

  const removeTag = (id: number) => {
    setThisItemTags(thisItemTags.filter((x) => x.id !== id));
  };

  const availableTags = (): Tag[] =>
    tagsList?.filter((r) => {
      if (thisItemTags && thisItemTags.length > 0) {
        return !thisItemTags.map((x) => x.id).includes(r.id);
      }
      return [];
    }) || [];

  const itemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '44px',
    alignItems: 'center',
    pl: 2,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 1,
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogContent>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <>
            <Typography variant='subtitle1' sx={{ mb: 1 }}>
              {t('documents.currentTags')}
            </Typography>
            <Scrollbar autoHide={false} sx={{ maxHeight: '150px', mb: 2, pr: 2 }}>
              <Stack direction='column' spacing={1}>
                {thisItemTags &&
                  thisItemTags.length > 0 &&
                  thisItemTags.map((tag, index) => (
                    <Box sx={itemStyle} key={index}>
                    <TagLabel color={tag.color}>{tag.name}</TagLabel>
                      <Box>
                        <IconButton size='large' onClick={() => removeTag(tag.id)} id="6wxby1a6">
                          <Iconify color={theme.palette.error.main} icon="eva:close-fill" width={20} height={20} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Stack>
            </Scrollbar>

            <Typography variant='subtitle1' sx={{ mb: 1 }}>
              {t('documents.availableTags')}
            </Typography>
            <Scrollbar autoHide={false} sx={{ maxHeight: '150px', mb: 2, pr: 2 }}>
              <Stack direction='column' spacing={1}>
                {availableTags().length > 0 &&
                  availableTags().map((tag, index) => (
                    <Box sx={itemStyle} key={index}>
                                              <TagLabel color={tag.color}>{tag.name}</TagLabel>

                      <Box>
                        <IconButton size='large' onClick={() => addTag(tag)} id="sqhaw15q">
                          <Iconify color={theme.palette.success.main} icon="fluent:add-24-filled" width={20} height={20} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Stack>
            </Scrollbar>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined' color='inherit' id="mz5nnxqa">
          {t('shared.buttons.cancel')}
        </Button>
        <LoadingButton loading={isLoading} onClick={() => handleSave()} type='button' variant='contained' id="yd0sown5">
          {t('shared.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
