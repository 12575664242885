import { Box, Grid, CircularProgress } from '@mui/material';

export default function LoadingSpinner() {
  return (
    <Box width='100%' height='100%'>
      <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center'>
        <CircularProgress size={80} />
      </Grid>
    </Box>
  );
}
