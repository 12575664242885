import { createSlice } from '@reduxjs/toolkit';

import { getBase64FromFile } from 'src/utils/fileUtils';

import { HOST_API } from 'src/config';
import { Template } from 'src/@types/templates/template';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { TemplatesFilter } from 'src/@types/templates/templatesFilter';
import { TemplateState, TemplateUpload } from 'src/@types/templates/types';

import { dispatch } from '../store';
// utils
import axios from '../../utils/httpClients/axios';

// ----------------------------------------------------------------------

const initialState: TemplateState = {
  isLoading: false,
  error: null,
  templates: null,
  file: null,
  selectedId: null,
  template: null,
};

const slice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.templates = initialState.templates;
      state.file = initialState.file;
      state.selectedId = initialState.selectedId;
      state.template = initialState.template;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.templates = action.payload;
    },

    getTemplateSuccess(state, action) {
      state.isLoading = false;
      state.selectedId = action.payload.id;
      state.template = action.payload;
    },

    getFileSuccess(state, action) {
      state.isLoading = false;
      const fileName = state.template?.name || state.templates?.items.find((x) => x.id === state.selectedId)?.name || '';
      const file = new File(action.payload, fileName);
      state.file = file;
    },

    fileUploaded(state, action) {
      state.file = action.payload;
    },

    fileCleared(state) {
      state.file = null;
      state.isLoading = false;
    },

    getTemplatesError(state, action) {
      state.isLoading = false;
      state.templates = initialState.templates;
      state.error = action.payload;
    },

    getFileError(state, action) {
      state.isLoading = false;
      state.file = null;
      state.error = action.payload;
    },

    templateSelected(state, action) {
      state.selectedId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getPagedTemplates(filter: TemplatesFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<PagedResponse<Template>>(`${HOST_API  }documentTemplates`, { params: { ...filter } });

    const data = {
      total: response.data.total,
      items: response.data.items.map((item) => new Template().parseJson(item)),
    };

    dispatch(slice.actions.getTemplatesSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getTemplatesError(error));
  }
}

export function uploadTemplateFile(file: File) {
  dispatch(slice.actions.fileUploaded(file));
}

export function clearTemplateFile() {
  dispatch(slice.actions.fileCleared());
}

export function clearTemplatesState() {
  dispatch(slice.actions.clearState());
}

export async function getTemplate(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<Template>(`${HOST_API  }documentTemplates/${id}`);
    dispatch(slice.actions.getTemplateSuccess(new Template().parseJson(response.data)));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function getTemplateFile(id: number | null) {
  if (id) {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${HOST_API  }documentTemplates/${id}/file`, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      dispatch(slice.actions.getFileSuccess([blob]));
    } catch (error) {
      dispatch(slice.actions.getFileError(error));
    }
  } else {
    dispatch(slice.actions.getFileError('templates.errors.templateNotFound'));
  }
}

export async function createTemplate(template: TemplateUpload) {
  try {
    const request = {
      name: template.name,
      file: await getBase64FromFile(template.file),
    };
    await axios.post(`${HOST_API  }documentTemplates`, request);
    dispatch(slice.actions.fileCleared());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function removeTemplate(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.delete(`${HOST_API  }documentTemplates/${id}`);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}
