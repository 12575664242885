import { ReactNode } from 'react';
import { Navigate } from 'react-router';

import { getSession } from 'src/utils/auth/jwt';

// ----------------------------------------------------------------------

type RoleGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

export default function RoleGuard({ accessibleRoles, children }: RoleGuardProp) {
  const { user } = getSession();

  if (!accessibleRoles.some((a) => user?.roles.includes(a))) {
    return <Navigate to='/404' replace />;
  }

  return <>{children}</>;
}
