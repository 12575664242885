import React, { useState, useEffect } from 'react';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Select, MenuItem, useTheme, InputLabel, FormControl, FormHelperText } from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-------------------------------------------------------

type DropdownElementProps = {
  el: ElementProps;
  scaledDimensions: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    fontSize: number;
  };
  elementContentValue?: string[] | undefined;
  handleDropdownChange: (value: string, el: ElementProps) => void;
  handleTextAlign: (alignEnum: number) => string;
  pageNumberScale: number;
};

const DropdownElement: React.FC<DropdownElementProps> = ({
  el,
  scaledDimensions,
  elementContentValue,
  handleDropdownChange,
  handleTextAlign,
  pageNumberScale,
}) => {
  const { t } = useLocales();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue[0]);
    }
  }, []);

  return (
    <FormControl
      variant="standard"
      fullWidth
      error={elementErrorsList.length > 0}
      sx={{ backgroundColor: theme.palette.info.lighter, borderRadius: 1, 
        // px: 1 
      }}
    >
      <InputLabel size="small" sx={{ px: 1, pb: 1 }} id={el.id}>
        {el.label}
      </InputLabel>
      <Select
        IconComponent={ExpandMoreRoundedIcon}
        key={el.id}
        value={elementContentValue ? elementContentValue[0] : ''}
        labelId={`${el.id}`}
        id={`select-${el.id}`}
        size="small"
        sx={{
          color: el.color,
          fontSize: `${scaledDimensions.fontSize}px`,
          textAlign: handleTextAlign(el.textAlignment),
          fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
          fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
          textDecoration: el.textDecoration === 1 ? 'underline' : '',
          fontFamily: `${el.font}`,
        }}
      >
        {el.options !== undefined &&
          el.options.map((option, index) => (
            <MenuItem
              key={`${option}-${index}}`}
              value={option}
              onClick={() => {
                handleDropdownChange(option, el);
                setValue(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
      </Select>
      {elementErrorsList.length > 0 && (
        <FormHelperText>
          {elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DropdownElement;
