import { createSlice } from '@reduxjs/toolkit';

import { getFileType } from 'src/utils/getFileFormat';
import { getContainerFile } from 'src/utils/fileUtils';

import { FileViewState } from 'src/@types/fileView/types';
import { FileExtensions } from 'src/@types/fileView/enums/fileExtensions';

// utils
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: FileViewState = {
  isLoading: false,
  file: null,
};

const slice = createSlice({
  name: 'fileView',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.file = initialState.file;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = true;
    },

    fileUploaded(state, action) {
      state.file = action.payload;
    },

    fileCleared(state) {
      state.file = null;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

export async function uploadFileView(file: File) {
  let fileView: File | null = null;

  switch (getFileType(file.name)) {
    case FileExtensions.Adoc:
    case FileExtensions.Asic:
    case FileExtensions.Bdoc:
    case FileExtensions.Edoc:
      fileView = await getContainerFile(file);
      break;
    case FileExtensions.Pdf:
    default:
      fileView = file;
      break;
  }

  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.fileUploaded(fileView));
}

export function finishUploading() {
  dispatch(slice.actions.finishLoading());
}

export function clearFileView() {
  dispatch(slice.actions.fileCleared());
}
