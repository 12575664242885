import { Box, BoxProps } from '@mui/material';

import { getFileStringType } from 'src/utils/fileUtils';

import { useSelector } from 'src/redux/store';

import { DASHBOARD_HEADER_DESKTOP } from '../config';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { settings } = useSelector((state) => state.company);

  const src = () => {
    if (settings?.logoImage) {
      const logoType = getFileStringType(settings.logoImage) ?? 'image/jpg';
      return `data:${logoType};base64,${settings.logoImage}`;
    }

    return '/logo/logo_default.png';
  };

  const logo = (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', ...sx }}>
      <img
        style={{
          objectFit: 'contain',
          maxHeight: `${DASHBOARD_HEADER_DESKTOP - 44}px`,
        }}
        src={src()}
        alt="Logo"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href="https://adoc.lt/">
      {logo}
    </a>
  );
}
