import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import CalendarDesktopEventBar from './CalendarDesktopEventBar';
import CalendarDesktopEventTooltip from './CalendarDesktopEventTooltip';
import {
  CalendarDayProps,
  CalendarUserProps,
  CalendarEventProps,
} from '../../@types/calendar/types';
import {
  CALENDAR_CELL_WIDTH,
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_BORDER_COLOR,
  CALENDAR_CELL_BORDER_STYLE,
  CALENDAR_CELL_BORDER_WIDTH,
  CALENDAR_HOLIDAY_CELL_BACKGROUND,
  CALENDAR_WEEKEND_CELL_BACKGROUND,
  CALENDAR_WORKDAY_CELL_BACKGROUND,
} from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopCellProps = {
  day: CalendarDayProps;
  user: CalendarUserProps;
};

const CalendarDesktopEventCell: React.FC<CalendarDesktopCellProps> = ({ day, user }) => {
  const [gridTemplate, setGridTemplate] = useState<string>('');
  const [dayEvents, setDayEvents] = useState<CalendarEventProps[]>([]);

  const { isHoliday, isWeekend, dateId, date } = day;
  const { events } = user;

  const { concurrentCount } = events[dateId][0];

  const gridTemplateRows = `repeat(${concurrentCount}, 1fr)`;

  const getGridTemplate = () => {
    let newTemplate = '';

    for (let i = 0; i < concurrentCount; i++) {
      newTemplate += `"concurrentIndex${i + 1}" `;
    }

    setGridTemplate(newTemplate.trim());
  };

  const cellStyle = {
    width: `${CALENDAR_CELL_WIDTH}px`,
    maxWidth: `${CALENDAR_CELL_WIDTH}px`,
    minWidth: `${CALENDAR_CELL_WIDTH}px`,
    height: `${CALENDAR_CELL_HEIGHT}px`,
    backgroundColor: isHoliday
      ? CALENDAR_HOLIDAY_CELL_BACKGROUND
      : isWeekend
        ? CALENDAR_WEEKEND_CELL_BACKGROUND
        : CALENDAR_WORKDAY_CELL_BACKGROUND,
    borderColor: CALENDAR_CELL_BORDER_COLOR,
    borderWidth: `${CALENDAR_CELL_BORDER_WIDTH}px`,
    borderStyle: CALENDAR_CELL_BORDER_STYLE,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: '1fr',
    gridTemplateRows,
    gridTemplateAreas: gridTemplate,
    gap: 0.8,
    py: 0.8,
  };

  useEffect(() => {
    setDayEvents(events[dateId]);
  }, [events]);

  useEffect(() => {
    getGridTemplate();
  }, []);

  return (
    <CalendarDesktopEventTooltip events={dayEvents} date={date} user={user}>
      <Box sx={{ ...cellStyle }}>
        {dayEvents.length > 0 &&
          dayEvents.map((dayEvent, index) => (
            <Box
              key={`dayEvent-${index}`}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent:
                  dayEvent.start && dayEvent.end
                    ? 'center'
                    : dayEvent.start
                      ? 'right'
                      : dayEvent.end
                        ? 'left'
                        : 'center',
                gridArea: `concurrentIndex${dayEvent.concurrentIndex}`,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width:
                    dayEvent.start && dayEvent.end
                      ? `calc(100% / 2)`
                      : dayEvent.start || dayEvent.end
                        ? `calc(100% / 4 * 3)`
                        : '100%',
                  height: '100%',
                  maxHeight: `${CALENDAR_CELL_WIDTH / 2}px`,
                }}
              >
                <CalendarDesktopEventBar event={dayEvent} />
              </Box>
            </Box>
          ))}
      </Box>
    </CalendarDesktopEventTooltip>
  );
};

export default CalendarDesktopEventCell;
