import { ReactNode } from 'react';
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom';

import { PATH_AUTH } from 'src/routes/paths';

import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (isInitialized && !isAuthenticated) {
    setTimeout(() => {
      navigate({
        pathname: PATH_AUTH.login,
        search: createSearchParams({
          redirect: pathname,
        }).toString(),
      });
    });
    return null;
  }

  return <>{children}</>;
}
