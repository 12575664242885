// hooks
import { useState, useEffect } from 'react';

import { FileType, dataUrlToFile } from 'src/utils/fileUtils';

import { useSelector } from 'src/redux/store';

import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();
  const { userImage } = useSelector((state) => state.user);
  const [imgFile, setFile] = useState<string|undefined>(undefined);

  const getProfileData = async () =>{
    if (userImage) {
      const fileType: FileType = userImage.extension === 'png' ? 'image/png' : 'image/jpg';
      const imgFile = await dataUrlToFile(userImage.image, `${userImage.name}.${userImage.extension}`, fileType);
      setFile(URL.createObjectURL(imgFile));
    }
  }

  useEffect(() => {
    getProfileData();
  }, [userImage]);

  return (
    <Avatar src={imgFile} alt={user?.email} {...other}>
      {user?.email ? createAvatar(user?.email).name : ''}
    </Avatar>
  );
}
