import React, { useState, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import { urlParamsProps } from '../../@types/calendar/types';

// ----------------------------------------------------------------

type CalendarSearchProps = {
  urlParams: urlParamsProps;
  setSearchParams: (params: urlParamsProps) => void;
};

const CalendarSearch: React.FC<CalendarSearchProps> = ({ urlParams, setSearchParams }) => {
  const { t } = useLocales();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const isMobile = useResponsive('down', 'sm');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const newURLParams = { ...urlParams };

    if (searchQuery === '') {
      delete newURLParams.search;
    } else {
      newURLParams.search = searchQuery;
    }

    setSearchParams({ ...newURLParams });
  }, [searchQuery]);

  return (
    <TextField
      id="calendar-search"
      value={searchQuery}
      onChange={handleSearchChange}
      size="small"
      placeholder={`${t('calendar.search')}...`}
      type="search"
      sx={{ width: isMobile ? '100%' : '40%', maxWidth: isMobile ? 'none' : '400px' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CalendarSearch;
