import React from 'react';

import { Box, Skeleton } from '@mui/material';

import { SKELETON_BGCOLOR, SKELETON_ANIMATION } from '../../utils/constants/dlxDriveConstants';

const DLXDriveSkeletonBreadcrumbs = () => {
  const columnSkeletons: string[] = new Array(3).fill('breadcrumbs-skeleton');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      {columnSkeletons.map((s, i) => (
        <>
          <Skeleton
            sx={{
              width: '15%',
              backgroundColor: SKELETON_BGCOLOR,
              borderRadius: '4px',
              fontSize: '16px',
            }}
            animation={SKELETON_ANIMATION}
            variant="text"
          />

          <Skeleton
            sx={{ backgroundColor: SKELETON_BGCOLOR, mx: 1 }}
            variant="circular"
            width={4}
            height={4}
          />
        </>
      ))}

      <Skeleton
        sx={{
          width: '15%',
          backgroundColor: SKELETON_BGCOLOR,
          borderRadius: '4px',
          fontSize: '16px',
        }}
        animation={SKELETON_ANIMATION}
        variant="text"
      />
    </Box>
  );
};

export default DLXDriveSkeletonBreadcrumbs;
