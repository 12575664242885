// NEW FORM BUILDER COMPONENT

import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';

import {
  Box,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import useAppSettings from '../../../hooks/useAppSettings';
import useNotifications from '../../../hooks/useNotifications';
import { createType, updateType } from '../../../services/formService';
import { ModuleTypes } from '../../../@types/module/enums/moduleTypes';
import { TypeProps, ModuleProps } from '../create-form/FormsCreateNewForm';
import { DocumentModuleTypes } from '../../../@types/shared/enums/documentModuleTypes';

// ----------------------------------------------------------------------

type TypeAddEditDialogProps = {
  onClose: () => void;
  onSave: () => void;
  formId: number;
  isTypeAddEditDialogOpen: boolean;
  selectedType?: TypeProps;
};

const TypeAddEditDialog: React.FC<TypeAddEditDialogProps> = ({
  onClose,
  onSave,
  formId,
  isTypeAddEditDialogOpen,
  selectedType,
}) => {
  const { t } = useLocales();
  const modulesData = useAppSettings();
  const { enqueueSuccess } = useNotifications();
  const [availableModules, setAvailableModules] = useState<ModuleProps[]>([]);

  const validationSchema = Yup.object().shape({
    nameLT: Yup.string(),
    nameRU: Yup.string(),
    nameEN: Yup.string(),
    nameLV: Yup.string(),
    nameEE: Yup.string(),
    moduleType: Yup.object().required(t('shared.errors.moduleRequired')),
  });

  const formik = useFormik({
    initialValues: {
      nameLT: '',
      nameRU: '',
      nameEN: '',
      nameLV: '',
      nameEE: '',
      moduleType: availableModules[0],
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const model: TypeProps = {
        nameLT: values.nameLT,
        nameRU: values.nameRU,
        nameEN: values.nameEN,
        nameLV: values.nameLV,
        nameEE: values.nameEE,
        moduleType: values.moduleType.formModuleType,
      };

      if (values.nameLT === '') {
        model.nameLT = 'Nėra vertimo';
      }

      if (values.nameRU === '') {
        model.nameRU = 'Нет перевода';
      }

      if (values.nameEN === '') {
        model.nameEN = 'No translation';
      }

      if (values.nameLV === '') {
        model.nameLV = 'Nav tulkojuma';
      }

      if (values.nameEE === '') {
        model.nameEE = 'Tõlge puudub';
      }

      try {
        if (selectedType && selectedType.id) {
          await updateType(selectedType.id, model);
          enqueueSuccess('forms.snackbar.typeUpdated');
        } else {
          await createType(model);
          enqueueSuccess('forms.snackbar.typeCreated');
        }
      } catch (error) {
        setErrors(error);
      } finally {
        onSave();
        formik.resetForm();
        setSubmitting(false);
      }
    },
  });

  const getAvailableModules = () => {
    const modules: ModuleProps[] = [];

    if (modulesData.modules.includes(ModuleTypes.HR)) {
      modules.push({
        formModuleType: DocumentModuleTypes.HR,
        moduleTitle: t('formTemplates.modules.hr'),
      });
    }

    if (modulesData.modules.includes(ModuleTypes.Accounting)) {
      modules.push({
        formModuleType: DocumentModuleTypes.Accounting,
        moduleTitle: t('formTemplates.modules.accounting'),
      });
    }

    setAvailableModules(modules);
  };

  useEffect(() => {
    if (selectedType && availableModules.length > 0) {
      formik.setValues({
        nameLT: selectedType.nameLT ? selectedType.nameLT : 'Nėra vertimo',
        nameRU: selectedType.nameRU ? selectedType.nameRU : 'Нет перевода',
        nameEN: selectedType.nameEN ? selectedType.nameEN : 'No translation',
        nameLV: selectedType.nameLV ? selectedType.nameLV : 'Nav tulkojuma',
        nameEE: selectedType.nameEE ? selectedType.nameEE : 'Tõlge puudub',
        moduleType:
          availableModules.find((module) => module.formModuleType === selectedType.moduleType) ||
          availableModules[0],
      });
    } else {
      formik.setValues({
        nameLT: '',
        nameRU: '',
        nameEN: '',
        nameLV: '',
        nameEE: '',
        moduleType: availableModules[0],
      });
    }
  }, [selectedType, availableModules]);

  useEffect(() => {
    getAvailableModules();
  }, []);

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={isTypeAddEditDialogOpen}>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogTitle>{t('forms.typeNameInDifferentLanguages')}</DialogTitle>

          <DialogContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                variant="outlined"
                label="EN"
                id="type-en-translation"
                {...formik.getFieldProps('nameEN')}
                error={Boolean(formik.touched.nameEN && formik.errors.nameEN)}
                helperText={formik.touched.nameEN && formik.errors.nameEN}
                size="small"
              />

              <TextField
                variant="outlined"
                label="LT"
                id="type-lt-translation"
                {...formik.getFieldProps('nameLT')}
                error={Boolean(formik.touched.nameLT && formik.errors.nameLT)}
                helperText={formik.touched.nameLT && formik.errors.nameLT}
                size="small"
              />

              <TextField
                variant="outlined"
                label="LV"
                id="type-lv-translation"
                {...formik.getFieldProps('nameLV')}
                error={Boolean(formik.touched.nameLV && formik.errors.nameLV)}
                helperText={formik.touched.nameLV && formik.errors.nameLV}
                size="small"
              />

              <TextField
                variant="outlined"
                label="EE"
                id="type-ee-translation"
                {...formik.getFieldProps('nameEE')}
                error={Boolean(formik.touched.nameEE && formik.errors.nameEE)}
                helperText={formik.touched.nameEE && formik.errors.nameEE}
                size="small"
              />

              <TextField
                variant="outlined"
                label="RU"
                id="type-ru-translation"
                {...formik.getFieldProps('nameRU')}
                error={Boolean(formik.touched.nameRU && formik.errors.nameRU)}
                helperText={formik.touched.nameRU && formik.errors.nameRU}
                size="small"
              />
            </Box>

            <Autocomplete
              id="dialog-select-module"
              options={availableModules}
              getOptionLabel={(option: ModuleProps) => option.moduleTitle}
              onChange={(event, value) => {
                formik.setFieldValue('moduleType', value);
              }}
              value={formik.values.moduleType}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  {...formik.getFieldProps('moduleType')}
                  {...params}
                  error={Boolean(formik.touched.moduleType && formik.errors.moduleType)}
                  helperText={
                    formik.errors.moduleType ? t('shared.errors.moduleRequired') : undefined
                  }
                  label={t('formTemplates.fields.module')}
                />
              )}
              size="small"
              sx={{ mb: 1 }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} type="button" variant="outlined" id="mz5nnxqa">
              {t('shared.buttons.close')}
            </Button>

            <Button type="submit" variant="contained" id="yd0sown5">
              {t('shared.buttons.save')}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default TypeAddEditDialog;
