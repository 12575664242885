import React, { useState, useEffect } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  List,
  Checkbox,
  ListItem,
  Accordion,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { DLXDriveFormTemplateListType } from '../../../@types/dlx-drive/types';

type DLXDriveFormTemplatesAccordionItemProps = {
  item: DLXDriveFormTemplateListType;
  expanded: string | false;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  checked: number[];
  setChecked: (value: number[]) => void;
  handleToggle: (value: number) => () => void;
};

const DLXDriveFormTemplatesAccordionItem: React.FC<DLXDriveFormTemplatesAccordionItemProps> = ({
  item,
  expanded,
  handleChange,
  checked,
  setChecked,
  handleToggle,
}) => {
  const { t } = useLocales();
  const [totalSelectedForms, setTotalSelectedForms] = useState<number>(0);

  const countSelectedForms = (total: any, item: any) => {
    if (checked.indexOf(item.id) !== -1) {
      return total + 1;
    }

    return total;
  };

  useEffect(() => {
    if (checked.length === 0) {
      setTotalSelectedForms(0);
    } else {
      const newTotalSelectedForms = item.forms.reduce(countSelectedForms, 0);

      setTotalSelectedForms(newTotalSelectedForms);
    }
  }, [checked]);

  return (
    <Accordion
      expanded={expanded === item.name}
      onChange={handleChange(item.name)}
      sx={{ boxShadow: 'none' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '75%', flexShrink: 0 }}>{item.name}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('shared.fields.selectedOf', { totalSelectedForms, totalItems: item.forms.length })}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <List>
          {item.forms.map((form, index) => (
            <ListItem key={form.id} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(form.id)} dense id={`Vnve14_${form.id}`}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(form.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={form.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default DLXDriveFormTemplatesAccordionItem;
