import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';

import { Pagination } from '@mui/material';

import { PATH_DLX_DRIVE } from '../../routes/paths';

// ----------------------------------------------------------------

type DLXDrivePaginationProps = {
  total: number;
  limit: number;
  offset: number;
  id: string;
};

const DLXDrivePagination: React.FC<DLXDrivePaginationProps> = ({ total, limit, offset, id }) => {
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    navigate(`${PATH_DLX_DRIVE.root}/${id}/${value}`);
  };

  const count = Math.ceil(total / limit);

  return <Pagination count={count !== 0 ? count : 1} page={offset + 1} onChange={handleChange} id='nya3ieyn' />;
};

export default DLXDrivePagination;
