import axios from 'src/utils/httpClients/axios';

import { AUTH0_API } from 'src/config';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { DepartmentsFilter } from 'src/@types/departments/departmentsFilter';
import { Department, UpdateDepartment } from 'src/@types/departments/departments';

const baseUrl = `${AUTH0_API.domain}api/departments`;

async function getPagedDepartments(filter: DepartmentsFilter) {
  const response = await axios.get<PagedResponse<Department>>(baseUrl, { params: { ...filter } });

  return {
    total: response.data.total,
    items: response.data.items,
  };
}

async function createDepartment(model: UpdateDepartment) {
  await axios.post(baseUrl, model);
}

async function updateDepartment(model: UpdateDepartment, id: string) {
  await axios.put(`${baseUrl}/${id}`, model);
}

async function deleteDepartment(id: string) {
  await axios.delete(`${baseUrl}/${id}`);
}

async function getDepartmentUsers(departmentIds: number[]) {
  const data = { departmentIds };
  const response = await axios.post(`${baseUrl}/department-users`, data);

  return response.data;
}

const aspDepartmentsService = {
  getPagedDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  getDepartmentUsers,
};

export default aspDepartmentsService;
