import React, { useState, useEffect, ChangeEvent } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, TextField, IconButton, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

// ---------------------------

type AddEmailersProps = {
  emailers: string[];
  setEmailers: (emailers: string[]) => void;
};

const AddEmailers: React.FC<AddEmailersProps> = ({ emailers, setEmailers }) => {
  const { t } = useLocales();
  const [emailerValue, setEmailerValue] = useState<string>('');
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);

  useEffect(() => {
    const isEmailers = emailers.length > 0;
    setIsChecked(isEmailers);
  }, [emailers]);

  const handleEmailerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailerValue(event.target.value);
  };

  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleAddEmailers = () => {
    if (validateEmail(emailerValue)) {
      setEmailers([...emailers, emailerValue]);
      setEmailerValue('');
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (validateEmail(emailerValue)) {
      setError(false);
    }
  }, [emailerValue]);

  const handleDeleteEmailer = (index: number) => {
    const newEmailers = [...emailers];

    newEmailers.splice(index, 1);
    setEmailers(newEmailers);
  };

  useEffect(() => {
    if (!isChecked) {
      setEmailers([]);
    }
  }, [isChecked]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2">{t('forms.emailReceivers')}</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
        <TextField
          size="small"
          fullWidth
          label={`${t('formTemplates.fields.enterEmail')}`}
          value={emailerValue}
          onChange={handleEmailerChange}
          error={error}
          helperText={error && t('forms.validationErrorMessage.invalidEmailFormat')}
          id="9xr10ya6"
        />
        <IconButton sx={{ mb: error ? 3 : 0 }} onClick={() => handleAddEmailers()} id="il586p4q">
          <AddIcon color={emailerValue === '' || error ? 'disabled' : 'primary'} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1,
          width: '100%',
          maxHeight: '160px',
          overflow: 'auto',
        }}
      >
        {emailers.length > 0 &&
          emailers.map((eml, i) => (
            <Box
              key={`emailer-${eml}`}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography noWrap>{eml}</Typography>

              <IconButton onClick={() => handleDeleteEmailer(i)} id="3fwtim5o">
                <ClearIcon fontSize="small" color="error" />
              </IconButton>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default AddEmailers;
