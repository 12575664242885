const lastTabHeartbeatTime = 'lastTabHeartbeatTime';

export class TabHeartbeatTimer {
  onTimeout: VoidFunction;

  interval: NodeJS.Timer | undefined;

  constructor(onTimeout: VoidFunction) {
    this.onTimeout = onTimeout;

    const lastHeartbeat = parseInt(localStorage.getItem(lastTabHeartbeatTime) || '0', 10);
    if (lastHeartbeat && lastHeartbeat + 300 * 1000 < Date.now()) {
      onTimeout();
    }

    this.startInterval();
  }

  startInterval = () => {
    localStorage.setItem(lastTabHeartbeatTime, Date.now().toString());

    this.interval = setInterval(() => {
      localStorage.setItem(lastTabHeartbeatTime, Date.now().toString());
    }, 1000);
  };

  cleanUp = () => {
    localStorage.removeItem(lastTabHeartbeatTime);
    // @ts-ignore
    clearInterval(this.interval);
  };
}
