import React, { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Switch,
  Accordion,
  FormGroup,
  Typography,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import FormApproversList from './FormApproversList';
import { DraggableApproverProps } from '../../../@types/forms/types';
import { FormApprovalType } from '../../../@types/forms/enums/FormApprovalType';

// ----------------------------------------------------------------------

type FormApproversProps = {
  isDocLogixIntegration: boolean;
  setIsDocLogixIntegration: (isDocLogixIntegration: boolean) => void;
  items: DraggableApproverProps[];
  handleItemChange: (item: DraggableApproverProps) => void;
  onDragEnd: (dropResult: DropResult) => void;
};

const FormApprovers: React.FC<FormApproversProps> = ({
  isDocLogixIntegration,
  setIsDocLogixIntegration,
  items,
  handleItemChange,
  onDragEnd,
}) => {
  const { t } = useLocales();
  const [isApproversExpanded, setIsApproversExpanded] = useState(false);

  const DLX_APPROVERS: DraggableApproverProps[] = [
    {
      id: 'doclogix-approval',
      approvalType: FormApprovalType.DocLogixApproval,
      approvalName: t('formTemplates.fields.approvalInDoclogix'),
      checked: true,
      order: 1,
      signerEmails: [],
    },
    {
      id: 'manager-approval-doclogix',
      approvalType: FormApprovalType.ManagerApproval,
      approvalName: t('formTemplates.fields.managerApproval'),
      checked: true,
      order: 2,
      signerEmails: [],
    },
  ];

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocLogixIntegration(event.target.checked);
  };

  return (
    <Accordion id="forms-approvers-accordion" sx={{ p: 3 }} expanded={isApproversExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ px: 3, py: isApproversExpanded ? 3 : 2 }}
        onClick={() => setIsApproversExpanded(!isApproversExpanded)}
        id="A8LquA"
      >
        <Typography variant="h6">{t('forms.approvers')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '100%', py: 3, pl: 3, pr: 2, pt: 0 }}>
        <FormGroup
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                id="doclogix-integration-switch"
                checked={isDocLogixIntegration}
                onChange={handleSwitchChange}
              />
            }
            label={t('forms.doclogixIntegration')}
            labelPlacement="start"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              gap: 1,
            }}
          />
        </FormGroup>

        {isDocLogixIntegration && (
          <FormApproversList
            isDocLogixIntegration={isDocLogixIntegration}
            items={DLX_APPROVERS}
            handleItemChange={handleItemChange}
            onDragEnd={onDragEnd}
          />
        )}

        {!isDocLogixIntegration && (
          <>
            <FormApproversList
              isDocLogixIntegration={isDocLogixIntegration}
              items={items}
              handleItemChange={handleItemChange}
              onDragEnd={onDragEnd}
            />

            <Typography variant="caption">{t('formTemplates.checkDragDropReorderList')}</Typography>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FormApprovers;
