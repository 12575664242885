import { createSlice } from '@reduxjs/toolkit';

import { CompanyState, CompanyUpdate } from 'src/@types/company/types';
import aspCompanyService from 'src/services/asp-identity/aspCompanyService';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: CompanyState = {
  isLoading: false,
  company: null,
  settings: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.company = initialState.company;
      state.settings = initialState.settings;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
      state.settings = {
        logoImage: action.payload.logoImage,
        timestampImage: action.payload.timestampImage,
        code: action.payload.code,
        address: action.payload.address,
        completedDocumentLifetime: action.payload.completedDocumentLifetime,
        timestampPagePosition: action.payload.timestampPagePosition,
        timestampPosition: action.payload.timestampPosition,
        defaultExpirationTime: action.payload.defaultExpirationTime,
        emailSenderName: action.payload.emailSenderName,
        defaultEmailLocale: action.payload.defaultEmailLocale,
        locales: action.payload.locales,
      };
    },

    getSettingsSuccess(state, action) {
      state.isLoading = false;
      state.settings = action.payload;
    },

    updateCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = {
        name: state.company?.name as string,
        code: action.payload.code,
        address: action.payload.address,
        logoImage: action.payload.logoImage,
        timestampImage: action.payload.timestampImage,
        completedDocumentLifetime: action.payload.completedDocumentLifetime,
        timestampPagePosition: action.payload.timestampPagePosition,
        timestampPosition: action.payload.timestampPosition,
        defaultExpirationTime: action.payload.defaultExpirationTime,
        emailSenderName: action.payload.emailSenderName,
        defaultEmailLocale: action.payload.defaultEmailLocale,
        locales: action.payload.locales,
      };
      state.settings = {
        logoImage: action.payload.logoImage,
        timestampImage: action.payload.timestampImage,
        code: action.payload.code,
        address: action.payload.address,
        completedDocumentLifetime: action.payload.completedDocumentLifetime,
        timestampPagePosition: action.payload.timestampPagePosition,
        timestampPosition: action.payload.timestampPosition,
        defaultExpirationTime: action.payload.defaultExpirationTime,
        emailSenderName: action.payload.emailSenderName,
        defaultEmailLocale: action.payload.defaultEmailLocale,
        locales: action.payload.locales,
      };
    },
  },
});

// Reducer
export default slice.reducer;

export async function getCompanySettings() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspCompanyService.getCompanySettings();

    dispatch(slice.actions.getSettingsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.stopLoading());
    throw error;
  }
}

export async function getCompany() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspCompanyService.getCompany();
    
    dispatch(slice.actions.getCompanySuccess(data));
  } catch (error) {
    dispatch(slice.actions.stopLoading());
    throw error;
  }
}

export async function updateCompany(request: CompanyUpdate) {
  dispatch(slice.actions.startLoading());
  try {
    await aspCompanyService.updateCompany(request);
    dispatch(slice.actions.updateCompanySuccess(request));
  } catch (error) {
    dispatch(slice.actions.stopLoading());
    throw error;
  }
}
