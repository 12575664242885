import axios from 'src/utils/httpClients/axios';
import { getBase64FromFile } from 'src/utils/fileUtils';

import { TEMP_FILE_API } from 'src/config';

// ----------------------------------------------------------------------

const baseUrl = `${TEMP_FILE_API  }temp-files`;

async function getFile(id: number): Promise<File> {
  const response = await axios.get(`${baseUrl  }/${id}`, { responseType: 'blob' });
  const blob = new Blob([response.data]);

  return new File([blob], 'temp-preview');
}

async function createFile(file: File, extension: string): Promise<string> {
  const model = {
    bytes: await getBase64FromFile(file),
    extension,
  };

  const response = await axios.post<string>(baseUrl, model);

  return response.data;
}

const tempFileService = {
  getFile,
  createFile,
};

export default tempFileService;
