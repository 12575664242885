import { useState, ReactNode, useEffect, createContext } from 'react';
import { useLocation, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

import { useAxiosInterceptors } from 'src/utils/httpClients/axios';
import { useIdentityAxiosInterceptors } from 'src/utils/httpClients/identityAxios';

import { useSelector } from 'src/redux/store';
import { TenantAuth } from 'src/@types/company/types';
import { getCompanySettings } from 'src/redux/slices/company';
import aspCompanyService from 'src/services/asp-identity/aspCompanyService';
import { clearNotificationsState } from 'src/redux/slices/systemNotifications';
import { getUserProfileImage, getFreeSignaturesCount } from 'src/redux/slices/user';

import LoadingScreen from 'src/components/LoadingScreen';

import { PATH_AUTH } from '../routes/paths';
// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const initialState: TenantAuth = {
  modules: [],
  isCompany: true,
};

const AppSettingsContext = createContext(initialState);

function AppSettingsProvider({ children }: Props) {
  useAxiosInterceptors();
  useIdentityAxiosInterceptors();

  const { user } = useAuth();
  const { settings } = useSelector((state) => state.company);
  const [modulesData, setModulesData] = useState<TenantAuth>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchTenantSettings();
  }, []);

  const fetchTenantSettings = async () => {
    if (!modulesData) {
      const data = await aspCompanyService.getTenantAuthSettings();
      setModulesData(data);
    }
  };

  const fixUrl = (pathname: string) => pathname.replace(/\/+/g, '/');

  useEffect(() => {
    const isClickActionLink = searchParams.get('upn');

    const isResetPasswordLink = pathname.indexOf('reset-password') > -1;
    const isConfirmEmailLink = pathname.indexOf('confirm-email') > -1;
    const isEparaksts = pathname.indexOf('eparaksts') > -1;
    const isEparakstsError = pathname.indexOf('eparaksts?error') > -1

    if (modulesData && !isResetPasswordLink && !isConfirmEmailLink && !isClickActionLink && (!isEparaksts || isEparakstsError)) {
      setTimeout(() => {
        navigate({
          pathname: PATH_AUTH.login,
          search: createSearchParams({
            redirect: fixUrl(pathname),
          }).toString(),
        });
      });
    }
  }, [modulesData]);

  useEffect(() => {
    if (!settings) {
      if (user?.isTenant) {
        getCompanySettings();
      }
    }
    if (modulesData && !modulesData.isCompany) {
      getFreeSignaturesCount();
    }
    if (user) {
      getUserProfileImage();
    }
    if (!user) {
      clearNotificationsState();
    }
  }, [user, modulesData]);

  return (
    <>
      {!!modulesData && (
        <AppSettingsContext.Provider
          value={{
            ...(modulesData || initialState),
          }}
        >
          {children}
        </AppSettingsContext.Provider>
      )}
      {!modulesData && <LoadingScreen />}
    </>
  );
}

export { AppSettingsContext, AppSettingsProvider };
