import React from 'react';

import { Box, Menu, MenuItem, useTheme, IconButton, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import Iconify from 'src/components/Iconify';

type item3dotDialogProps = {
  handleEdit?: (e: any) => void;
  handleRemove: (e: any) => Promise<void>;
  isLoading: boolean;
  handleDuplicate?: (e: any) => void;
};

export default function Item3dotDialog({
  handleEdit,
  handleRemove,
  isLoading,
  handleDuplicate,
}: item3dotDialogProps) {
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Iconify icon="eva:more-vertical-outline" width={20} height={20} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: 1,
            width: '160px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
            borderRadius: '12px'
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
     { handleEdit &&  <MenuItem onClick={(e) => handleEdit(e)} sx={{ px: 1, py: 0 }}>
          <IconButton disabled={isLoading} size="small" onClick={(e) => handleEdit(e)} id="sx4khp0w">
            <Iconify icon="eva:edit-fill" width={20} height={20} />
          </IconButton>
          <Typography sx={{ pl: 0.5 }}>{t('shared.buttons.edit')}</Typography>
        </MenuItem>}

     {  handleDuplicate &&  <MenuItem onClick={handleDuplicate} sx={{ px: 1, py: 0 }}>
        <IconButton disabled={isLoading} size="small" id="ll03e6v5">
          <Box sx={{ height: '20px', width: '20px' }}>
            <img src="/icons/duplicate_form.svg" width={20} height={20} alt="delete" />
          </Box>
        </IconButton>
        <Typography sx={{ pl: 0.5 }}>{t('formTemplates.duplicate')}</Typography>
      </MenuItem>}

        <MenuItem onClick={handleRemove} sx={{ px: 1, py: 0 }}>
          <IconButton disabled={isLoading} size="small" id="ll03e6v5">
            <Box sx={{ height: '20px', width: '20px' }}>
              <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
            </Box>
          </IconButton>
          <Typography sx={{ pl: 0.5 }}>{t('shared.buttons.delete')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
