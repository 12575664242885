// NEW FORM BUILDER COMPONENT

import React from 'react';

import { Container } from '@mui/material';

import { PATH_ADMIN } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';

import Page from 'src/components/Page';
import BackButton from 'src/components/BackButton';

import FormsEditForm from 'src/sections/forms/edit-form/FormsEditForm';

const EditForm = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('formTemplates.editFormTemplate')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <BackButton paths={[PATH_ADMIN.forms]} />
        <FormsEditForm titleLocale='formTemplates.editFormTemplate' />
      </Container>
    </Page>
  );
};

export default EditForm;
