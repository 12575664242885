import React from 'react';
import { useNavigate } from 'react-router';

import { Box, Card, useTheme, Typography } from '@mui/material';

import { PATH_STANDARD } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { getFileType } from 'src/utils/getFileFormat';

import SvgIconStyle from 'src/components/SvgIconStyle';
import { UploadSingleFile } from 'src/components/upload';

import { AVAILABLE_EXTENSIONS } from 'src/sections/dlx-drive/settings/DLXDriveDocumentActionsUploadDocument';

// --------------------------------------------------------

const UploadDocumentBtn: React.FC = () => {
  const { t } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }

    navigate(PATH_STANDARD.composeDocument, {
      state: !acceptedFiles.length ? null : acceptedFiles,
    });
  };

  const docTypeValidator = (file: File) => {
    const extension = getFileType(file.name);
    if (!AVAILABLE_EXTENSIONS.includes(extension)) {
      return {
        code: 'file-invalid-type',
        message: 'components.uploadSingleFile.fileMustBe',
        parameters: { data: AVAILABLE_EXTENSIONS.join(', ') },
      };
    }

    return null;
  };

  return (
    <Card
      sx={{
        width: isMobile ? '100%' : '360px',
        border: `1px solid ${theme.palette.secondary.lighter}` ,
        px: 3,
        py: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        position: 'relative',
        mb: 1,
      }}
      id="088n1yp9"
    >
      <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="subtitle1">{t('documents.uploadYourDoc')}</Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          <Typography
            variant="inherit"
            component="span"
            color={theme.palette.primary.main}
            sx={{ textDecoration: 'underline' }}
          >
            {t('documents.choose')}
          </Typography>
          {t('documents.docOrDrag')}
        </Typography>
      </Box>

      <Box>
        <SvgIconStyle
          src="/icons/dlx-drive/upload_document.svg"
          sx={{
            width: { xs: 44, sm: 54 },
            height: { xs: 44, sm: 54 },
            display: 'inherit',
            opacity: '0.5',
            color: theme.palette.grey[600]
          }}
        />
      </Box>

      <UploadSingleFile
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0,
        }}
        noText
        accept={AVAILABLE_EXTENSIONS.map((x) => `.${x}`).join(', ')}
        onDrop={handleDrop}
        validator={docTypeValidator}
      />
    </Card>
  );
};

export default UploadDocumentBtn;
