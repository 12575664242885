export enum FileExtensions {
  Pdf = 'pdf',
  Adoc = 'adoc',
  Asic = 'asice',
  Bdoc = 'bdoc',
  Edoc = 'edoc',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Png = 'png',
  Jpeg = 'jpeg',
  Jpg = 'jpg',
  Gif = 'gif',
  Tiff = 'tiff',
  Bmp = 'bmp',
};

export const Containers = [
  FileExtensions.Adoc,
  FileExtensions.Asic,
  FileExtensions.Bdoc,
  FileExtensions.Edoc,
];