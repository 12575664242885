import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { HOST_API } from 'src/config';
import { DocumentSigner } from 'src/@types/documents/documentSigner';
import { DocumentSignerSetRequest } from 'src/@types/documents/types';

// ----------------------------------------------------------------------

const baseUrl = `${HOST_API  }documents`;

export async function getSigners(id: number) {
  const response = await axios.get<DocumentSigner[]>(`${baseUrl  }/${id}/signers`);

  return mapJsonArray(response.data, DocumentSigner);
}

export async function getMailGetters(id: number) {
  const response = await axios.get<DocumentSigner[]>(`${baseUrl  }/${id}/mailGetters`);
  return mapJsonArray(response.data, DocumentSigner);
}

export async function saveSigners(request: DocumentSignerSetRequest) {
  await axios.put(`${baseUrl  }/${request.documentId}/signers/set`, request);
}

const documentSignerService = {
  getSigners,
  getMailGetters,
  saveSigners,
};

export default documentSignerService;
