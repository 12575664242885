import { toLocal } from 'src/utils/dateTimeUtils';

export class DocumentInitSign {
  isBlocked: boolean;

  blockedTimeTo: Date | null;

  file: string;

  parseJson(json: any): DocumentInitSign | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: DocumentInitSign = new DocumentInitSign();

    model.isBlocked = json.isBlocked;
    model.blockedTimeTo = json.blockedTimeTo ? toLocal(json.blockedTimeTo) : null;
    model.file = json.file;

    return model;
  }
}
