import { useEffect } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';

import { Box, Stack } from '@mui/material';

import useFormTranslate from 'src/hooks/useFormTranslate';

import { DocumentSignatureType } from 'src/@types/documents/enums/documentSignatureTypes';

// ----------------------------------------------------------------------

type InitialValues = {
  personalCode: string;
};

type DocumentEParakstsSignProps = {
  submitTrigger: any;
  submitValues: (value: any) => void;
};

export default function DocumentEParakstsSign({
  submitTrigger,
  submitValues,
}: DocumentEParakstsSignProps) {
  useEffect(() => {
    if (submitTrigger) {
      submitForm();
    }
  }, [submitTrigger]);

  const formik = useFormik<InitialValues>({
    initialValues: { personalCode: '' },

    onSubmit: (values) => {
      if (isValid) {
        submitValues({ ...values, signatureType: DocumentSignatureType.EParaksts });
        resetForm();
      }
    },
  });

  const { submitForm, resetForm, isValid } = formik;

  useFormTranslate(formik);

  return (
    <Box sx={{ mb: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Stack spacing={3} id='d52rn8is'>
            <input type="submit" hidden />
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  );
}
