import React, { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import useNotifications from '../../../hooks/useNotifications';
import { deleteItems } from '../../../services/dlxDriveService';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../../utils/constants/dlxDriveConstants';
import {
  removeItemState,
  setDriveListIdState,
  clearCheckedItemsState,
} from '../../../redux/slices/dlxDrive';

type DLXDriveBulkDeleteDialogProps = {
  isBulkDeleteDialogOpen: boolean;
  setIsBulkDeleteDialogOpen: (value: boolean) => void;
};

// ----------------------------------------------------------------

const DLXDriveBulkDeleteDialog: React.FC<DLXDriveBulkDeleteDialogProps> = ({
  isBulkDeleteDialogOpen,
  setIsBulkDeleteDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading, checkedItems } = useSelector((state) => state.dlxDrive);
  const { enqueueSuccess } = useNotifications();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: t('dlxDrive.dataGrid.columnId'),
    },
    {
      field: 'name',
      headerName: t('dlxDrive.dataGrid.columnName'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('dlxDrive.dataGrid.columnType'),
    },
    {
      field: 'remove',
      headerName: t('dlxDrive.dataGrid.columnRemove'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const { id, name, type } = params.row;

          const itemToRemove = {
            id,
            name,
            isFolder: type === t('dlxDrive.types.folder'),
            actions: [],
          };

          removeItemState(itemToRemove);
        };

        return (
          <IconButton onClick={onClick} id="P1Xzwe">
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              width={FOLDER_ACTIONS_MENU_ICON_SIZE}
              height={FOLDER_ACTIONS_MENU_ICON_SIZE}
              alt={t('delete')}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = checkedItems.map((item) => ({
    id: item.id,
    name: item.name,
    type: item.isFolder
      ? t('dlxDrive.dataGrid.columnTypeFolder')
      : t('dlxDrive.dataGrid.columnTypeDocument'),
  }));

  const handleClose = () => {
    setIsBulkDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      const model = {
        documentIds: checkedItems.filter((item) => !item.isFolder).map((item) => item.id),
        folderIds: checkedItems.filter((item) => item.isFolder).map((item) => item.id),
      };

      await deleteItems(model);

      clearCheckedItemsState();

      enqueueSuccess('dlxDrive.notifications.bulkDeletedSuccessfully');
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsBulkDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setIsBulkDeleteDialogOpen(false);
    }
  }, [checkedItems]);

  return (
    <Dialog open={isBulkDeleteDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.bulkDocumentDeleting')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t('dlxDrive.dialogs.bulkDocumentDeletingInfoText')}</DialogContentText>

        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={50}
          sx={{ width: '100%' }}
          disableColumnFilter
          disableColumnMenu
          disableMultipleColumnsSorting
          disableChildrenSorting
          localeText={{ footerTotalRows: t('dlxDrive.dataGrid.footerTotalRows') }}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={checkedItems.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleDelete}
          id="dxW0kd"
        >
          {`${t('shared.buttons.delete')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveBulkDeleteDialog;
