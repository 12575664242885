import React, { useRef, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import useResponsive from '../../hooks/useResponsive';
import { FolderActions } from '../../@types/dlx-drive/enums/FolderActions';
import DLXDriveFolderActionsMenu from './settings/DLXDriveFolderActionsMenu';
import DLXDriveCreateFolderDialog from './dialogs/folders/DLXDriveCreateFolderDialog';

// ----------------------------------------------------------------

type DLXDriveFolderActionsProps = {
  isRootFolder: boolean;
};

const DLXDriveFolderActions: React.FC<DLXDriveFolderActionsProps> = ({ isRootFolder }) => {
  const { t } = useLocales();
  const { selectedFolder } = useSelector((state) => state.dlxDrive);
  const [isMenuPopoverOpen, setIsMenuPopoverOpen] = useState<boolean>(false);
  const [isCreateFolderDialogOpen, setIsCreateFolderDialogOpen] = useState<boolean>(false);
  const isMobile = useResponsive('only', 'xs');

  const settingsRef = useRef<HTMLElement>(null);

  const handleOpen = () => {
    setIsMenuPopoverOpen(true);
  };

  const handleCreateFolderClick = () => {
    setIsCreateFolderDialogOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {selectedFolder.actions.includes(FolderActions.FolderEdit) && (
          <Box>
            <Button variant="text" startIcon={<AddRoundedIcon />} onClick={handleCreateFolderClick} id="xK98fG">
              <Typography variant="button" noWrap>
                {t('dlxDrive.buttons.createNewFolder')}
              </Typography>
            </Button>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
          ref={settingsRef}
          onClick={handleOpen}
        >
          {
            // ---------------------
            //   For LARGE screens
            // ---------------------

            !isMobile && (
              <Button variant="contained" startIcon={<SettingsRoundedIcon />} id="bFk4Y3">
                <Typography variant="button" noWrap>
                  {t('dlxDrive.buttons.folderSettings')}
                </Typography>
              </Button>
            )
          }

          {
            // ---------------------
            //   For SMALL screens
            // ---------------------

            isMobile && <SettingsRoundedIcon color="primary" />
          }
        </Box>
      </Box>

      <DLXDriveCreateFolderDialog
        isCreateFolderDialogOpen={isCreateFolderDialogOpen}
        setIsCreateFolderDialogOpen={setIsCreateFolderDialogOpen}
      />

      <DLXDriveFolderActionsMenu
        isMenuPopoverOpen={isMenuPopoverOpen}
        setIsMenuPopoverOpen={setIsMenuPopoverOpen}
        anchorEl={settingsRef}
        folderColor={selectedFolder.color}
        folderIcon={selectedFolder.icon}
      />
    </>
  );
};

export default DLXDriveFolderActions;
