import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { AUTH0_API } from 'src/config';
import { Role } from 'src/@types/rolePermissions/role';
import { Permission } from 'src/@types/rolePermissions/permission';
import { RoleWithPermissions, CompanyRolePermissions } from 'src/@types/rolePermissions/types';

const baseUrl = `${AUTH0_API.domain  }api`;

async function getRoles(): Promise<Role[]> {
  const response = await axios.get<Role[]>(`${baseUrl  }/roles`);
  const data = mapJsonArray(response.data, Role);
  return data;
}

async function getPermissions(): Promise<Permission[]> {
  const response = await axios.get<Permission[]>(`${baseUrl  }/permissions`);
  const data = mapJsonArray(response.data, Permission);
  return data;
}

async function getRolePermissions(): Promise<CompanyRolePermissions> {
  const response = await axios.get<CompanyRolePermissions>(`${baseUrl  }/role-permissions`);
  return response.data;
}

async function setRolePermissions(rolePermissions: RoleWithPermissions[]) {
  await axios.put(`${baseUrl  }/role-permissions`, { rolePermissions });
}

const aspRolePermissionService = {
  getRoles,
  getPermissions,
  getRolePermissions,
  setRolePermissions,
};

export default aspRolePermissionService;
