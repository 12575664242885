import { t } from 'i18next';

import MassApprovalFailureReasons from '../@types/documents/enums/MassApprovalFailureReasons';

// ----------------------------------------------------------------------

const getMassApprovalFailureReason = (reason: MassApprovalFailureReasons) => {
  let massApprovalFailureReason = '';

  switch (reason) {
    case MassApprovalFailureReasons.NoProblem:
      massApprovalFailureReason = t('documents.massApproving.errors.noProblem');
      break;

    case MassApprovalFailureReasons.UserNotAdded:
      massApprovalFailureReason = t('documents.massApproving.errors.userNotAdded');
      break;

    case MassApprovalFailureReasons.UserAddedAsSigner:
      massApprovalFailureReason = t('documents.massApproving.errors.userAddedAsSigner');
      break;

    case MassApprovalFailureReasons.UserAddedAsAcknowledger:
      massApprovalFailureReason = t('documents.massApproving.errors.userAddedAsAcknowledger');
      break;

    case MassApprovalFailureReasons.UserAlreadyApproved:
      massApprovalFailureReason = t('documents.massApproving.errors.userAlreadyApproved');
      break;

    case MassApprovalFailureReasons.AnotherUserTurn:
      massApprovalFailureReason = t('documents.massApproving.errors.anotherUserTurn');
      break;

    case MassApprovalFailureReasons.DocumentIsBlocked:
      massApprovalFailureReason = t('documents.massApproving.errors.documentIsBlocked');
      break;

    default:
      massApprovalFailureReason = t('documents.massApproving.errors.undeterminedReason');
  }

  return massApprovalFailureReason;
};

export default getMassApprovalFailureReason;
