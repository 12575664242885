import { FileError, useDropzone, DropzoneOptions } from 'react-dropzone';

import { alpha, Theme, styled } from '@mui/material/styles';
import { Box, Paper, SxProps, useTheme, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import SvgIconStyle from 'src/components/SvgIconStyle';

import { fData } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}));

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface LocalizedFileError extends FileError {
  parameters?: any;
}

interface Props extends DropzoneOptions {
  error?: boolean;
  files?: (File | string)[];
  showRejections?: boolean;
  isCompact?: boolean;
  sx?: SxProps<Theme>;
  isRowDirection?: boolean;
}

export default function UploadMultiFile({
  error,
  showRejections,
  files = [],
  isCompact,
  sx,
  isRowDirection,
  ...other
}: Props) {
  const { t } = useLocales();
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
  });
  const theme = useTheme();

  const ShowErrors = ({ errors }: { errors: LocalizedFileError[] }) => {
    // Show last error of each type
    const filteredErrors: LocalizedFileError[] = [];
    errors.forEach((e) => {
      const index = filteredErrors.findIndex((fe) => fe.code === e.code);
      if (index < 0) {
        filteredErrors.push(e);
      } else {
        filteredErrors[0] = e;
      }
    });

    return (
      <>
        {filteredErrors.map((e) => (
          <Typography key={e.code} variant="caption" component="p">
            - {t(e.message, e.parameters)}
          </Typography>
        ))}
      </>
    );
  };

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            <ShowErrors errors={errors} />
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
        id="3MK3fy"
      >
        <input {...getInputProps()} />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isRowDirection ? 'row' : 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: 2,
            px: 2,
          }}
        >
          <Box sx={{ p: 1, textAlign: 'center' }}>
            <Typography gutterBottom variant={isCompact ? 'subtitle1' : 'h5'}>
              {t('components.uploadSingleFile.selectFiles')}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('components.uploadSingleFile.dropFilesHere')}&nbsp;
              <Typography
                variant="body2"
                component="span"
                sx={{ color: 'primary.main', textDecoration: 'underline' }}
              >
                {t('components.uploadSingleFile.browse')}
              </Typography>
              &nbsp;{t('components.uploadSingleFile.thoroughYourMachine')}
            </Typography>

            <Typography variant={isCompact ? 'subtitle1' : 'h5'}>{other.accept}</Typography>
          </Box>

          <Box sx={{pb: isCompact ? 1 : 2 }}>
            <SvgIconStyle
              src="/icons/dlx-drive/upload_document.svg"
              sx={{
                width: 70 ,
                height: 70 ,
                display: 'inherit',
                opacity: 0.5,
                color: theme.palette.grey[500]
              }}
              id="2qz2y6ud"
            />
          </Box>

        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && showRejections && <ShowRejectionItems />}
    </Box>
  );
}
