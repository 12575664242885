import SignaturePad from 'react-signature-pad-wrapper';
import React, { useRef, useState, useEffect, MutableRefObject } from 'react';

import {
  Box,
  Stack,
  Button,
  Dialog,
  useTheme,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { setErrorList } from 'src/redux/slices/formBuilder';
import validateIsRequired from 'src/@types/forms/utils/validateIsRequired';
import { ElementProps, ErrorsListProps, ElementValidationProps } from 'src/@types/forms/types';

import INK_COLOR from './signature-utils/inkColor';
import cropSignatureCanvas from './signature-utils/cropSignatureCanvas';

// ------------------------------------------------------------------------------

type CroppedCanvas = {
  image: string;
  width: number;
  height: number;
};

type SignatureElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isFormFillerPage: boolean;
  elementConetentValue?: string[] | undefined;
  handleSignatureChange?: (el: ElementProps, imgString: string) => void;
  size?: { width: number; height: number } | undefined;
};

const SignatureElement: React.FC<SignatureElementProps> = ({
  el,
  pageNumberScale,
  isFormFillerPage,
  elementConetentValue,
  handleSignatureChange,
  size,
}) => {
  const { t } = useLocales();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const theme = useTheme();
  const canvas = useRef() as MutableRefObject<any>;

  const [isEmptySignature, setIsEmptySignature] = useState(true);
  const [signature, setSignature] = useState<CroppedCanvas | undefined>();
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    setIsEmptySignature(true);
  }, [isDialogOpen]);

  const handleSubmit = () => {
    const untrimmedCanvas = canvas.current.signaturePad.canvas as HTMLCanvasElement;
    const croppedSignature = cropSignatureCanvas(untrimmedCanvas);

    const newCanvas: CroppedCanvas = {
      image: croppedSignature.toDataURL(),
      width: croppedSignature.width,
      height: croppedSignature.height,
    };

    setSignature(newCanvas);

    if (handleSignatureChange !== undefined) {
      handleSignatureChange(el, newCanvas.image);
    }

    setIsDialogOpen(false);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleClear = () => {
    canvas.current.clear();
    setIsEmptySignature(true);
  };

  const handleEndStroke = () => {
    setIsEmptySignature(canvas.current.isEmpty());
  };

  useEffect(() => {
    if (!isEmptySignature && canvas.current && canvas.current.signaturePad) {
      canvas.current.signaturePad.addEventListener('endStroke', handleEndStroke);
    }

    const data: ElementValidationProps = {
      el,
      errorMessage: '',
      value: signature?.image,
      elementErrorsList,
    };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }
    setElementErrorsList(newErrorList);
  }, [isEmptySignature]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  return (
    <>
      {!isFormFillerPage && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: el.height * pageNumberScale,
            backgroundColor: 'rgba(255,255,255,0.5)',
            border: '0.5px solid rgba(99, 115, 129, 0.75)',
            lineHeight: `${el.fontSize * pageNumberScale * 1.2}px`,
            fontSize: `${el.fontSize * pageNumberScale}px`,
          }}
          id={`${el.id}-is-not-form-filler-page`}
        >
          {el.label}
        </Box>
      )}

      {isFormFillerPage && (
        <Stack sx={{ height: size ? `${size.height}px` : '100px' }}>
          <Box
            sx={{
              width: size ? `${size.width}px` : '200px',
              height: size ? `${size.height}px` : '200px',
              border:
                elementErrorsList.length > 0
                  ? `1px dashed ${theme.palette.error.main}`
                  : `1px dashed ${theme.palette.grey[400]}`,
              p: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.info.lighter,
              lineHeight: `${el.fontSize * pageNumberScale * 1.2}px`,
              fontSize: `${el.fontSize * pageNumberScale}px`,
            }}
            onClick={() => setIsDialogOpen(true)}
            id={`${el.id}-is-form-filler-page`}
          >
            {elementConetentValue ? (
              <img
                src={`${elementConetentValue}`}
                alt="hand-sign"
                width={size ? `${size.width}px` : '200px'}
                height={size ? `${size.height}px` : '200px'}
                style={{
                  objectFit: 'scale-down',
                  width: size ? `${size.width}px` : '200px',
                  height: size ? `${size.height}px` : '200px',
                }}
              />
            ) : (
              <Typography
                color={elementErrorsList.length > 0 ? 'error' : theme.palette.grey[500]}
                sx={{
                  lineHeight: `${el.fontSize * pageNumberScale}px`,
                  fontSize: `${el.fontSize * pageNumberScale}px`,
                  whiteSpace: 'break-spaces'
                }}
              >
                {t('forms.addSignatureToSign')}
              </Typography>
            )}
          </Box>
          {elementErrorsList.length > 0 && (
            <Typography variant="caption" color="error" sx={{ pt: 1 }}>
              {t('forms.validationErrorMessage.fieldRequired')}
            </Typography>
          )}
        </Stack>
      )}

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{t('forms.addSignatureToSign')}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            {t('forms.drawSignatureClickSubmit')}
          </DialogContentText>

          <Box
            sx={{ border: `1px solid ${theme.palette.grey[300]}` }}
            onClick={() => setIsEmptySignature(false)}
            id="Ze3bR0"
          >
            <SignaturePad
              ref={canvas}
              options={{
                minWidth: 1,
                maxWidth: 4,
                penColor: el.color ? el.color : INK_COLOR,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              gap: 3,
            }}
          >
           

            <Button variant="outlined" onClick={handleClose} id="mz5nnxqa">
              {t('shared.buttons.cancel')}
            </Button>
            <Button disabled={isEmptySignature} variant="text" onClick={handleClear} id="udwb54bi">
            {t('shared.buttons.clear')}
          </Button>
          </Box>

          <Button
              disabled={isEmptySignature}
              variant="contained"
              onClick={handleSubmit}
              id="a5bdmyvs"
            >
              {t('shared.buttons.submit')}
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignatureElement;
