import React from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useAuth from '../../../../hooks/useAuth';
import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import useNotifications from '../../../../hooks/useNotifications';
import { bulkApprove } from '../../../../services/dlxDriveService';
import { DLXDriveBulkApproveModelType } from '../../../../@types/dlx-drive/types';
import { addStopLoadingState, setDriveListIdState } from '../../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveSingleApproveDialogProps = {
  itemId: number;
  itemName: string;
  isSingleApproveDialogOpen: boolean;
  setIsSingleApproveDialogOpen: (value: boolean) => void;
};

const DLXDriveSingleApproveDialog: React.FC<DLXDriveSingleApproveDialogProps> = ({
  itemId,
  itemName,
  isSingleApproveDialogOpen,
  setIsSingleApproveDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const { user } = useAuth();
  const { enqueueSuccess } = useNotifications();

  const handleClose = () => {
    setIsSingleApproveDialogOpen(false);
  };

  const handleApprove = async () => {
    let model: DLXDriveBulkApproveModelType = {
      displayText: user?.email || '',
      documentIds: [itemId],
      timestamp: true,
    };

    try {
      await bulkApprove(model);

      enqueueSuccess('dlxDrive.notifications.approvedSuccessfully', {
        item: 'Document',
        itemName,
      });
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSingleApproveDialogOpen(false);
      addStopLoadingState();
    }
  };

  return (
    <Dialog open={isSingleApproveDialogOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.approveSingleDocument', { itemName })}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('dlxDrive.dialogs.approveSingleInfoText', {
            item: 'document',
            itemName,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton loading={isLoading} variant="contained" onClick={handleApprove} id="yd0sown5">
          {`${t('shared.buttons.approve')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveSingleApproveDialog;
