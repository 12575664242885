import { useState, ReactNode, useEffect, createContext } from 'react';

// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export type MenuDrawerContextProps = {
  isOpen?: boolean;
  isLargeScreen?: boolean;
  isOpenClick: boolean;
  onToggleMenu: (value?: boolean) => void;
  onClose: VoidFunction;
};

const initialState: MenuDrawerContextProps = {
  isOpenClick: false,
  onToggleMenu: (value?: boolean) => {},
  onClose: () => {},
};

const MenuDrawerContext = createContext(initialState);

type MenuDrawerProviderProps = {
  children: ReactNode;
};

function MenuDrawerProvider({ children }: MenuDrawerProviderProps) {
  const isLargeScreen = useResponsive('up', 'lg');

  const [openMenuClick, setOpenMenuClick] = useState(false);

  useEffect(() => {
    setOpenMenuClick(!!isLargeScreen);
  }, [isLargeScreen]);

  const handleToggleMenu = (value?: boolean) => {
    if (isLargeScreen) {
      return;
    }

    setOpenMenuClick(value !== undefined ? value : !openMenuClick);
  };

  const handleClose = () => {
    if (isLargeScreen) {
      return;
    }

    setOpenMenuClick(false);
  };

  return (
    <MenuDrawerContext.Provider
      value={{
        isOpen: openMenuClick,
        isLargeScreen,
        isOpenClick: openMenuClick,
        onToggleMenu: handleToggleMenu,
        onClose: handleClose,
      }}
    >
      {children}
    </MenuDrawerContext.Provider>
  );
}

export { MenuDrawerContext, MenuDrawerProvider };
