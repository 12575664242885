import React, { useState, useEffect } from 'react';

import ElementLabel from './ElementLabel';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { Profile } from '../../../@types/users/profile';
import { ElementProps } from '../../../@types/forms/types';
import useNotifications from '../../../hooks/useNotifications';
import { setErrorList } from '../../../redux/slices/formBuilder';
import { UserDataType } from '../../../@types/forms/enums/UserDataType';
import { FormElementError } from '../../../@types/forms/enums/FormElementError';
import { AddressBookFilter } from '../../../@types/addressBook/addressBookFilter';
import aspAddressBookService from '../../../services/asp-identity/aspAddressBookService';

type UserDataElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isFormFillerPage: boolean;
  handleProfileOptionLabelChange?: (el: ElementProps, label: string) => void;
};

const UserDataElement: React.FC<UserDataElementProps> = ({
  el,
  pageNumberScale,
  isFormFillerPage,
  handleProfileOptionLabelChange,
}) => {
  const { t } = useLocales();
  const { user } = useAuth();
  const { enqueueError } = useNotifications();
  const [label, setLabel] = useState<string>('');
  const [profile, setProfile] = useState<Profile | null>(null);

  const fetchUserData = async () => {
    const userEmail = user?.email;

    if (userEmail) {
      try {
        setErrorList({
          elementID: el.id,
          errors: [
            {
              elementID: el.id,
              elementName: `UserDataElement-${el.id}`,
              errorCode: FormElementError.NoData,
              errorMessage: 'Waiting for data...',
            },
          ],
        });

        const usersFilter = new AddressBookFilter();
        usersFilter.limit = 1;
        usersFilter.offset = 0;
        usersFilter.searchQuery = userEmail;

        const response = await aspAddressBookService.getPagedUsers(usersFilter);
        setProfile(response.items[0]);
      } catch (e) {
        enqueueError(e);
      } finally {
        setErrorList({ elementID: el.id, errors: [] });
      }
    }
  };

  const getJobTitles = () => {
    const jobTitles = profile?.jobTitles;

    if (jobTitles) {
      return jobTitles.map((jobTitle) => jobTitle.name).join(', ');
    }

    return ' ';
  };

  const setProfileOptionLabel = () => {
    const option = el.userDataOption;

    switch (option) {
      case UserDataType.Name:
        setLabel(`${profile?.firstName} ${profile?.lastName}`);
        break;

      case UserDataType.Email:
        setLabel(`${profile?.email}`);
        break;

      case UserDataType.Phone:
        setLabel(`${profile?.phoneNumber}`);
        break;

      case UserDataType.Company:
        setLabel(`${profile?.companyName}`);
        break;

      case UserDataType.Job:
        setLabel(getJobTitles());
        break;

      case UserDataType.Address:
        setLabel(`${profile?.address}, ${profile?.city}, ${profile?.zipCode} ${profile?.country}`);
        break;

      case UserDataType.ManagersName:
        setLabel(
          `${profile?.userDoclogixManager.firstName} ${profile?.userDoclogixManager.lastName}`
        );
        break;

      case UserDataType.ManagersEmail:
        setLabel(`${profile?.userDoclogixManager.email}`);
        break;

      default:
        setLabel(' ');
    }
  };

  const setOptionLabel = () => {
    const option = el.userDataOption;

    switch (option) {
      case UserDataType.Name:
        setLabel(`${t('forms.elements.settings.nameSurname')}`);
        break;

      case UserDataType.Email:
        setLabel(`${t('forms.elements.settings.emailAddress')}`);
        break;

      case UserDataType.Phone:
        setLabel(`${t('forms.elements.settings.phoneNumber')}`);
        break;

      case UserDataType.Company:
        setLabel(`${t('forms.elements.settings.company')}`);
        break;

      case UserDataType.Job:
        setLabel(`${t('forms.elements.settings.jobTitle')}`);
        break;

      case UserDataType.Address:
        setLabel(`${t('forms.elements.settings.address')}`);
        break;

      case UserDataType.ManagersName:
        setLabel(`${t('forms.elements.settings.managersName')}`);
        break;

      case UserDataType.ManagersEmail:
        setLabel(`${t('forms.elements.settings.managersEmail')}`);
        break;

      default:
        setLabel(`${t('forms.elements.settings.notSelected')}`);
    }
  };

  useEffect(() => {
    if (isFormFillerPage) {
      fetchUserData();
    }

    if (!isFormFillerPage) {
      setOptionLabel();
    }
  }, [el.userDataOption]);

  useEffect(() => {
    if (isFormFillerPage) {
      setProfileOptionLabel();
    }
  }, [profile]);

  useEffect(() => {
    if (handleProfileOptionLabelChange && isFormFillerPage) {
      handleProfileOptionLabelChange(el, label);
    }
  }, [label]);

  return <ElementLabel el={el} label={label} pageNumberScale={pageNumberScale} isStatic />;
};

export default UserDataElement;
