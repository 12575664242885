import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { HOST_API } from 'src/config';
import { IdName } from 'src/@types/shared/idNameModel';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { FormTemplate } from 'src/@types/form-templates/form-template';
import { FormTemplatesFilter } from 'src/@types/form-templates/formTemplatesFilter';
import { FormTemplateCreate, FormTemplateUpdate } from 'src/@types/form-templates/types';

// ----------------------------------------------------------------------

const baseUrl = `${HOST_API  }form-templates`;

async function getPagedFormTemplates(filter: FormTemplatesFilter): Promise<PagedResponse<FormTemplate>> {
  const response = await axios.get<PagedResponse<FormTemplate>>(baseUrl, { params: { ...filter } });

  const data = {
    total: response.data.total,
    items: mapJsonArray(response.data.items, FormTemplate),
  };

  return data;
}

async function getFormTemplateItems() {
  const response = await axios.get<IdName[]>(`${baseUrl  }/items`);
  return response.data;
}

async function getFormTemplate(id: number) {
  const response = await axios.get<FormTemplate>(`${baseUrl  }/${id}`);
  return new FormTemplate().parseJson(response.data);
}

async function createFormTemplate(model: FormTemplateCreate) {
  await axios.post(baseUrl, { ...model });
}

async function editFormTemplate(model: FormTemplateUpdate) {
  await axios.put(`${baseUrl  }/${model.id}`, { ...model });
}

async function deleteFormTemplate(id: number) {
  await axios.delete(`${baseUrl  }/${id}`);
}

const formTemplateService = {
  getPagedFormTemplates,
  getFormTemplateItems,
  getFormTemplate,
  createFormTemplate,
  editFormTemplate,
  deleteFormTemplate,
};

export default formTemplateService;
