export enum FormElementType {
  InputField = 0,
  Dropdown = 1,
  Checkbox = 2,
  RadioButton = 3,
  Date = 4,
  DateRange = 5,
  MultiLineInput = 6,
  Image = 7,
  CustomList = 8,
  AddressBook = 9,
  StaticText = 10,
  Hyperlink = 11,
  Signature = 12,
  User = 13,
  RegistrationNumber = 14,
}
