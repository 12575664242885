import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from "src/utils/mapUtils";

import { AUTH0_API } from "src/config";
import { UserSignatureBalance } from "src/@types/users/userSignatureBalance";

const userBalancesBaseUrl = `${AUTH0_API.domain   }api/SignatureBalance`;

export async function getPagedUserBalances(emails: string[]): Promise<UserSignatureBalance[]> {
    const response = await axios.post<UserSignatureBalance>(`${userBalancesBaseUrl  }/admin/balances`, emails);
    return mapJsonArray(response.data, UserSignatureBalance)
}
  
export async function getUserFreeBalance() {
    const response = await axios.get<number>(`${userBalancesBaseUrl  }/balance`);
    return response.data;
}

const aspSignatureBalanceService = {
    getPagedUserBalances,
    getUserFreeBalance
};

export default aspSignatureBalanceService;