import React, { useState, useEffect } from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import useLocales from '../../hooks/useLocales';
import { urlParamsProps } from '../../@types/calendar/types';
import { SystemLocales } from '../../locales/forms/SystemLocales';
import { CalendarDateRangeType } from '../../@types/calendar/enums/CalendarDateRangeType';

// ----------------------------------------------------------------

type CalendarDateFilterProps = {
  urlParams: urlParamsProps;
  setSearchParams: (params: urlParamsProps) => void;
};

const CalendarDesktopDateFilter: React.FC<CalendarDateFilterProps> = ({
  urlParams,
  setSearchParams,
}) => {
  const { t, currentLang } = useLocales();
  const [dateTitle, setDateTitle] = useState<string>('');
  const [nextDate, setNextDate] = useState<string>('');
  const [prevDate, setPrevDate] = useState<string>('');

  const handleMonthChange = (date: string) => {
    const newURLParams = { ...urlParams };

    if (newURLParams.range === `${CalendarDateRangeType.FourWeeks}`) {
      newURLParams.range = `${CalendarDateRangeType.CurrentMonth}`;

      setSearchParams({ ...newURLParams });
    } else {
      newURLParams.date = date;
      newURLParams.range = `${CalendarDateRangeType.CurrentMonth}`;

      setSearchParams({ ...newURLParams });
    }
  };

  useEffect(() => {
    if (urlParams.date && urlParams.range) {
      const { date, range } = urlParams;

      if (parseInt(range, 10) === CalendarDateRangeType.FourWeeks) {
        setDateTitle(t('calendar.next4Weeks'));
      } else {
        // Selected date
        const selectedDate = new Date(date);
        const year = selectedDate.getFullYear();
        const localizedMonth = selectedDate.toLocaleString(currentLang.value, { month: 'long' });

        setDateTitle(`${localizedMonth.charAt(0).toUpperCase() + localizedMonth.slice(1)} ${year}`);

        // Next date
        const newNextDate = new Date(
          selectedDate.setMonth(selectedDate.getMonth() + 1, 1)
        ).toLocaleDateString(SystemLocales.lt);

        setNextDate(newNextDate);

        // Prev date
        const newPrevDate = new Date(
          selectedDate.setMonth(selectedDate.getMonth() - 2, 1)
        ).toLocaleDateString(SystemLocales.lt);

        setPrevDate(newPrevDate);
      }
    }
  }, [urlParams, currentLang]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
      <IconButton id="calendar-next-month" size="small" onClick={() => handleMonthChange(prevDate)}>
        <NavigateBeforeIcon />
      </IconButton>

      <Typography variant="subtitle1">{dateTitle}</Typography>

      <IconButton id="calendar-prev-month" size="small" onClick={() => handleMonthChange(nextDate)}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default CalendarDesktopDateFilter;
