import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import useNotifications from '../../../hooks/useNotifications';
import { bulkApprove } from '../../../services/dlxDriveService';
import { DLXDriveSelectedItem } from '../../../@types/dlx-drive/types';
import { DocumentStatuses } from '../../../@types/documents/enums/documentStatuses';
import getMassApprovalFailureReason from '../../../utils/getMassApprovalFailureReason';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../../utils/constants/dlxDriveConstants';
import { setDriveListIdState, clearCheckedItemsState } from '../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveBulkApproveDialogProps = {
  isBulkApproveDialogOpen: boolean;
  setIsBulkApproveDialogOpen: (value: boolean) => void;
};

const DLXDriveBulkApproveDialog: React.FC<DLXDriveBulkApproveDialogProps> = ({
  isBulkApproveDialogOpen,
  setIsBulkApproveDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading, checkedItems } = useSelector((state) => state.dlxDrive);
  const [itemsToApprove, setItemsToApprove] = useState<DLXDriveSelectedItem[]>([]);
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { user } = useAuth();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: t('dlxDrive.dataGrid.columnId'),
    },
    {
      field: 'name',
      headerName: t('dlxDrive.dataGrid.columnName'),
      flex: 1,
    },
    {
      field: 'remove',
      headerName: t('dlxDrive.dataGrid.columnRemove'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const newItemsToApprove = itemsToApprove.filter((item) => item.id !== params.row.id);

          setItemsToApprove(newItemsToApprove);
        };

        return (
          <IconButton onClick={onClick} id="wG0ovZ">
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              width={FOLDER_ACTIONS_MENU_ICON_SIZE}
              height={FOLDER_ACTIONS_MENU_ICON_SIZE}
              alt={t('delete')}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = itemsToApprove.map((item) => ({
    id: item.id,
    name: item.name,
  }));

  const handleClose = () => {
    setIsBulkApproveDialogOpen(false);
  };

  const handleApprove = async () => {
    try {
      const model = {
        displayText: user ? user.email : '',
        documentIds: itemsToApprove.map((item) => item.id),
        timestamp: true,
      };

      const response = await bulkApprove(model);
      const { totalCount, successfullyApproved, errors } = response;

      clearCheckedItemsState();

      if (errors.length > 0) {
        errors.forEach((error) => {
          const documentId = error.url.split('/').slice(-1);
          const reason = getMassApprovalFailureReason(error.reason);

          enqueueError('dlxDrive.errors.bulkApproveError', {
            documentId,
            reason,
          });
        });
      }

      enqueueSuccess('dlxDrive.notifications.bulkApprovedSuccessfully', {
        successfullyApproved,
        totalCount,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDriveListIdState();
      setIsBulkApproveDialogOpen(false);
    }
  };

  useEffect(() => {
    if (isBulkApproveDialogOpen) {
      const newItemsToApprove = checkedItems.filter(
        (item) => item.status && item.status === DocumentStatuses.WaitingForMeApprove
      );

      setItemsToApprove(newItemsToApprove);
    }
  }, [isBulkApproveDialogOpen, checkedItems]);

  useEffect(() => {
    if (itemsToApprove.length === 0) {
      setIsBulkApproveDialogOpen(false);
    }
  }, [itemsToApprove]);

  return (
    <Dialog open={isBulkApproveDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('documents.bulkDocumentApproving')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t('documents.massApproving.messages.dialogMessage')}</DialogContentText>

        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={50}
          sx={{ width: '100%', mt: 2 }}
          disableColumnFilter
          disableColumnMenu
          disableMultipleColumnsSorting
          disableChildrenSorting
          localeText={{ footerTotalRows: t('dlxDrive.dataGrid.footerTotalRows') }}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={itemsToApprove.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleApprove}
          id="yd0sown5"
        >
          {`${t('shared.buttons.approve')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveBulkApproveDialog;
