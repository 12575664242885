import { createSlice } from '@reduxjs/toolkit';

import { SignaturePosition } from 'src/@types/documents/types';

import { dispatch } from '../store';

const initialState: SignaturePosition = {
  documentId: undefined,
  x: undefined,
  y: undefined,
  page: 0,
};

const slice = createSlice({
  name: 'signaturePosition',
  initialState,
  reducers: {
    clearState(state) {
      state.documentId = initialState.documentId;
      state.x = initialState.x;
      state.y = initialState.y;
      state.page = initialState.page;
    },

    setSignPositioning(state, action) {
      state.documentId = action.payload.documentId;
      state.x = action.payload.x;
      state.y = action.payload.y;
      state.page = action.payload.page;
    },
  },
});

export default slice.reducer;

export function clearSignPosition() {
  dispatch(slice.actions.clearState());
}

export function setSignPosition(documentId: number, x: number, y: number, page: number) {
  dispatch(slice.actions.setSignPositioning({ documentId, x, y, page }));
}
