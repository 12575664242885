import { toLocal } from 'src/utils/dateTimeUtils';

import { DocumentSignatureType } from './enums/documentSignatureTypes';

export class DocumentSigner {
  id: number;

  email: string;

  signingOrder: number | null;

  documentId: number;

  signatureRequestedByEmail: string;

  signed: Date | null;

  rejected: Date | null;

  customPrivateMessage: string | null;

  signatureType?: DocumentSignatureType | null;

  departmentId: number | null;

  checked?: boolean = false;

  disabled?: boolean = false;

  name?: string | null;

  firstName: string = '';

  lastName: string = '';

  comment: string | null;

  parseJson(json: any): DocumentSigner | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: DocumentSigner = new DocumentSigner();

    model.id = json.id;
    model.email = json.email;
    model.signingOrder = json.signingOrder;
    model.documentId = json.documentId;
    model.signatureRequestedByEmail = json.signatureRequestedByEmail;
    model.signed = json.signed ? toLocal(json.signed) : null;
    model.rejected = json.rejected ? toLocal(json.rejected) : null;
    model.customPrivateMessage = json.customPrivateMessage;
    model.signatureType = json.signatureType;
    model.departmentId = json.departmentId;
    model.comment = json.comment;

    return model;
  }
}
