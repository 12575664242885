import { createSlice } from '@reduxjs/toolkit';

import { User } from 'src/@types/users/user';
import { UserState } from 'src/@types/users/types';
import { Role } from 'src/@types/rolePermissions/role';
import { UsersFilter } from 'src/@types/users/usersFilter';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { UserProfileImage } from 'src/@types/users/userProfileImage';
import aspUserService from 'src/services/asp-identity/aspUserService';
import { UserSignatureBalance } from 'src/@types/users/userSignatureBalance';
import aspSignatureBalanceService from 'src/services/asp-identity/aspSignatureBalanceService';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: UserState = {
  isLoading: false,
  error: null,
  users: null,
  selectedId: null,
  user: null,
  userBalances: [],
  freeSignatures: null,
  userImage: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.users = initialState.users;
      state.selectedId = initialState.selectedId;
      state.user = initialState.user;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getFreeSignatures(state, action) {
      state.isLoading = false;
      state.freeSignatures = action.payload;
    },

    getUsersBalanceSuccess(state, action) {
      state.isLoading = false;
      state.userBalances = action.payload;
    },

    getUsersError(state, action) {
      state.isLoading = false;
      state.users = initialState.users;
      state.error = action.payload;
    },

    userSelected(state, action) {
      state.selectedId = action.payload;
    },

    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
      state.selectedId = action.payload.id;
    },

    getUserProfileImage(state, action) {
      state.isLoading = false;
      state.userImage = action.payload;
    },

    usersDeactivatedChanged(state, action) {
      state.users = {
        items: state.users?.items.map((u) => (u.id === action.payload.id ? { ...u, isDeactivated: action.payload.isDeactivated } : u)),
        total: state.users?.total,
      } as PagedResponse<User>;
    },

    setUserRoles(state, action) {
      state.isLoading = false;
      state.users = {
        items: state.users?.items.map((u) => (u.id === action.payload.id ? { ...u, roles: action.payload.roles } : u)),
        total: state.users?.total,
      } as PagedResponse<User>;
    },

    setUserRolesBulk(state, action) {
      state.isLoading = false;
      state.users = {
        items: state.users?.items.map((u) => ((action.payload.ids as string[]).includes(u.id) ? { ...u, roles: action.payload.roles } : u)),
        total: state.users?.total,
      } as PagedResponse<User>;
    },

    reduceSignatureCount(state) {
      state.freeSignatures = state.freeSignatures ? state.freeSignatures - 1 : 0;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getUsers(filter: UsersFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspUserService.getPagedUsers(filter);
    const emails: string[] = data.items.map((x: User) => x.email);
    let userBalances: UserSignatureBalance[] = [];
    if (emails.length > 0) {
      userBalances = await aspSignatureBalanceService.getPagedUserBalances(emails);
    }

    const userData = {
      items: data.items,
      total: data.total,
    };
    dispatch(slice.actions.getUsersBalanceSuccess(userBalances));
    dispatch(slice.actions.getUsersSuccess(userData));
  } catch (error) {
    dispatch(slice.actions.getUsersError(error));
  }
}

export async function reduceFreeSignaturesCount() {
  dispatch(slice.actions.reduceSignatureCount());
}

export async function getFreeSignaturesCount() {
  try {
    const data = await aspSignatureBalanceService.getUserFreeBalance();
    dispatch(slice.actions.getFreeSignatures(data));
  } catch (error) {
    dispatch(slice.actions.getUsersError(error));
  }
}

export async function getUserProfileImage() {
  try {
    const data = await aspUserService.getProfileImage();
    dispatch(slice.actions.getUserProfileImage(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function setUserProfileImage(data: UserProfileImage | null) {
  dispatch(slice.actions.getUserProfileImage(data));
}

export async function setUserIsDeactivated(id: string, isDeactivated: boolean) {
  try {
    await aspUserService.setActivation(id, isDeactivated);
    dispatch(slice.actions.usersDeactivatedChanged({ id, isDeactivated }));
  } catch (error) {
    throw error;
  }
}

export async function setUserRoles(id: string, roles: Role[]) {
  try {
    dispatch(slice.actions.startLoading());
    await aspUserService.setUserRoles(
      id,
      roles.map((x) => x.id)
    );
    dispatch(slice.actions.setUserRoles({ id, roles }));
  } catch (error) {
    throw error;
  }
}

export async function setUserRolesBulk(ids: string[], roles: Role[]) {
  try {
    dispatch(slice.actions.startLoading());
    await aspUserService.setUserRolesBulk(
      ids,
      roles.map((x) => x.id)
    );
    dispatch(slice.actions.setUserRolesBulk({ ids, roles }));
  } catch (error) {
    throw error;
  }
}

export async function deleteUser(id: string) {
  try {
    dispatch(slice.actions.startLoading());
    await aspUserService.deleteUser(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    throw error;
  }
}

export async function deleteUsersBulk(ids: string[]) {
  try {
    dispatch(slice.actions.startLoading());
    await aspUserService.deleteUsersBulk(ids);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    throw error;
  }
}
