import { Grid, Container } from '@mui/material';

import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import CalendarComponent from '../../sections/calendar/CalendarComponent';

// ----------------------------------------------------------------

const Calendar = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('calendar.calendar')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <CalendarComponent />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Calendar;
