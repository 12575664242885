import { SxProps, FormHelperText, FormHelperTextProps } from '@mui/material';

interface Props extends FormHelperTextProps {
  isError: boolean;
  message: string | false | undefined;
  sx?: SxProps;
}

export default function ErrorForInput({ isError, message, sx }: Props) {
  if (isError) {
    return <FormHelperText children={message} sx={{ color: '#FF4842', paddingX: '14px', ...sx }} />;
  }
  return <> </>;
}
