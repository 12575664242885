import { SettingsValueProps } from './components/settings/type';

// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.VITE_APP_HOST_API_KEY || '';
export const TEMP_FILE_API =
  import.meta.env.VITE_APP_TEMP_FILE_API_KEY || import.meta.env.VITE_APP_HOST_API_KEY || '';
export const DS_API = import.meta.env.VITE_APP_DS_API_KEY || '';
export const AUTO_LOGOUT_MINUTES = parseInt(
  import.meta.env.VITE_APP_AUTO_LOGOUT_MINUTES || '0',
  10
);
export const DS_API_AUTH_KEY = import.meta.env.VITE_APP_DS_API_AUTH_KEY || '';
export const AUTH0_API = {
  clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_APP_AUTH0_DOMAIN,
};
export const SIFT_API = {
  siftDomain: import.meta.env.VITE_APP_SIFT_DOMAIN,
  siftApiKey: import.meta.env.VITE_APP_SIFT_API_KEY,
};
export const WORKFLOWS_API = {
  domain: import.meta.env.VITE_APP_WORKFLOWS_DOMAIN || '',
  apiKey: import.meta.env.VITE_APP_WORKFLOWS_API_KEY || '',
};
export const TEMP_FILE_PUBLIC_KEY = import.meta.env.VITE_APP_TEMP_FILE_PUBLIC_KEY || '';

// LAYOUT
// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 260;

export const DASHBOARD_HEADER_MOBILE = 64;
export const DASHBOARD_HEADER_DESKTOP = 92;
export const DASHBOARD_NAVBAR_WIDTH = 128;
export const DASHBOARD_NAVBAR_COLLAPSE_WIDTH = 88;

export const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48;
export const DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40;
export const DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 32;

export const MAIN_HEADER_DESKTOP = 88;
export const MAIN_HEADER_MOBILE = 64;

// SETTINGS
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeStretch: false,
};
