import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { getSession, setSession } from '../auth/jwt';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

export const useAxiosInterceptors = () => {
  const navigate = useNavigate();
  const { refreshToken } = useAuth();
  const { currentLang } = useLocales();
  const { enqueueError } = useNotifications();

  let isRefreshing = false;
  const emitter = new EventEmitter();

  const errorInterceptor = async (error: any) => {
    if (error?.response?.status === 401) {
      const originalRequest = error.config;
      const token = getSession();

      if (token) {
        if (!isRefreshing) {
          try {
            isRefreshing = true;
            await refreshToken();
            const authHeader = `Bearer ${getSession().accessToken}`;
            const newInstance = axios.create();
            originalRequest.headers.Authorization = authHeader;
            isRefreshing = false;
            emitter.emit(authHeader);
            const result = await newInstance(originalRequest);
            return result;
          } catch {
            isRefreshing = false;
            return Promise.reject(error);
          }
        } else {
          return new Promise((resolve, reject) => {
            emitter.subscribe((authHeader: string) => {
              const newInstance = axios.create();
              originalRequest.headers.Authorization = authHeader;
              resolve(newInstance(originalRequest));
            });
          });
        }
      } else {
        setSession(null);
        navigate('/auth/login', { replace: true });
      }
    } else {
      if (error?.response?.data && typeof error.response.data === 'string') {
        enqueueError(error.response.data);
      }
      return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
  };

  const date = new Date();

  const requestInterceptor = (config: any) => {
    config.headers.Authorization = `Bearer ${getSession().accessToken}`;
    config.headers['Content-Type'] = 'application/json';
    if (!config.headers['Accept-Language']) {
      config.headers['Accept-Language'] = currentLang.value;
    }
    config.headers['Timezone-Offset'] = date.getTimezoneOffset();
    return config;
  };

  useEffect(() => {
    const refreshInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      errorInterceptor
    );
    const authInterceptor = axiosInstance.interceptors.request.use(requestInterceptor, (error) =>
      Promise.reject(error)
    );

    return () => {
      axiosInstance.interceptors.response.eject(refreshInterceptor);
      axiosInstance.interceptors.request.eject(authInterceptor);
    };
  }, [currentLang]);

  return axiosInstance;
};

export default axiosInstance;

class EventEmitter {
  events: Function[];

  constructor() {
    this.events = [];
  }

  emit(data: any) {
    this.events.forEach((fn: Function) => {
      fn.call(null, data);
    });
    this.events = [];
  }

  subscribe(fn: Function) {
    this.events.push(fn);
    return () => {
      this.events = [];
    };
  }
}
