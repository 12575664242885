import { createSlice } from '@reduxjs/toolkit';

import { TenantState } from 'src/@types/tenants/types';
import { PagingFilter } from 'src/@types/shared/pagingFilter';
import aspTenantService from 'src/services/asp-identity/aspTenantService';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: TenantState = {
  isLoading: false,
  tenants: null,
  tenant: null,
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.tenants = initialState.tenants;
      state.tenant = initialState.tenant;
    },

    clearTenant(state) {
      state.tenant = initialState.tenant;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    getTenantSuccess(state, action) {
      state.isLoading = false;
      state.tenant = action.payload;
    },

    getTenantError(state, action) {
      state.isLoading = false;
      state.tenants = initialState.tenants;
    },

    getTenantsSuccess(state, action) {
      state.isLoading = false;
      state.tenants = action.payload;
    },

    getTenantsError(state, action) {
      state.isLoading = false;
      state.tenants = initialState.tenants;
    },

    setActivation(state, action) {
      state.isLoading = false;
      state.tenant = state.tenant ? { ...state.tenant, isDeactivated: action.payload.isDeactivated } : null;
      state.tenants = state.tenants
        ? { ...state.tenants, items: state.tenants.items.map((t) => (t.id === action.payload.id ? { ...t, isDeactivated: action.payload.isDeactivated } : t)) }
        : null;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getPagedTenants(filter: PagingFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspTenantService.getPagedTenants(filter);
    dispatch(slice.actions.getTenantsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getTenantsError(error));
  }
}

export async function getTenant(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspTenantService.getTenant(id);
    dispatch(slice.actions.getTenantSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getTenantError(error));
  }
}

export async function setActivation(id: number, isDeactivated: boolean) {
  dispatch(slice.actions.startLoading());
  try {
    await aspTenantService.setActivation(id, isDeactivated);
    dispatch(slice.actions.setActivation({ id, isDeactivated }));
  } catch (error) {
    dispatch(slice.actions.finishLoading());
  }
}

export function clearTenant() {
  dispatch(slice.actions.clearTenant());
}

export function clearTenantsState() {
  dispatch(slice.actions.clearState());
}
