import React from 'react';

import { Box } from '@mui/material';

import { CalendarDayProps } from '../../@types/calendar/types';
import {
  CALENDAR_CELL_WIDTH,
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_BORDER_COLOR,
  CALENDAR_CELL_BORDER_STYLE,
  CALENDAR_CELL_BORDER_WIDTH,
  CALENDAR_HOLIDAY_CELL_BACKGROUND,
  CALENDAR_WEEKEND_CELL_BACKGROUND,
  CALENDAR_WORKDAY_CELL_BACKGROUND,
} from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopEmptyCellProps = {
  day: CalendarDayProps;
};

const CalendarDesktopEmptyCell: React.FC<CalendarDesktopEmptyCellProps> = ({ day }) => {
  const { isHoliday, isWeekend } = day;

  const cellStyle = {
    width: `${CALENDAR_CELL_WIDTH}px`,
    maxWidth: `${CALENDAR_CELL_WIDTH}px`,
    minWidth: `${CALENDAR_CELL_WIDTH}px`,
    height: `${CALENDAR_CELL_HEIGHT}px`,
    backgroundColor: isHoliday
      ? CALENDAR_HOLIDAY_CELL_BACKGROUND
      : isWeekend
        ? CALENDAR_WEEKEND_CELL_BACKGROUND
        : CALENDAR_WORKDAY_CELL_BACKGROUND,
    borderColor: CALENDAR_CELL_BORDER_COLOR,
    borderWidth: `${CALENDAR_CELL_BORDER_WIDTH}px`,
    borderStyle: CALENDAR_CELL_BORDER_STYLE,
  };

  return <Box sx={{ ...cellStyle }} />;
};

export default CalendarDesktopEmptyCell;
