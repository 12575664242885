import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik } from 'formik';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  Checkbox,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { useSelector } from 'src/redux/store';
import { DocumentVoid } from 'src/@types/documents/types';
import documentService from 'src/services/documentService';

// ----------------------------------------------------------------------

type DocumentVoidDialogProps = {
  handleClose: (rejected: boolean) => void;
};

export default function DocumentVoidDialog({ handleClose }: DocumentVoidDialogProps) {
  const { t } = useLocales();
  const { document } = useSelector((state) => state.document);
  const { enqueueSuccess, enqueueError } = useNotifications();
  const [addMessageChecked, setAddMessageChecked] = useState(true);

  const validationSchema = Yup.object().shape({
    message: Yup.string().max(500, t('shared.errors.maximumValueExceeded')),
  });

  const handleSubmit = async () => {
    if (!document || !formik.isValid) {
      return;
    }

    try {
      formik.setSubmitting(true);
      const request = {
        id: document.id,
        voidedCustomMessage: formik.values.message,
      } as DocumentVoid;
      await documentService.voidDocument(request);
      enqueueSuccess('documents.snackbar.voided');
      handleClose(true);
    } catch (error) {
      formik.setErrors(t(error));
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const onClose = () => {
    formik.resetForm();
    handleClose(false);
  };

  const onAddMessageCheckedChange = () => {
    setAddMessageChecked((x) => !x);
    if (addMessageChecked) {
      formik.setFieldValue('message', '');
    }
  };

  return (
    <Dialog fullWidth open onClose={() => onClose()}>
      <DialogTitle>{t('documents.voiding')}</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }} variant="body1">
          {t('documents.areYouSureWantToVoid')}
        </Typography>

        <FormControlLabel
          label={<>{t('documents.addMessage')}</>}
          control={<Checkbox checked={addMessageChecked} onChange={onAddMessageCheckedChange} />}
        />

        <TextField
          variant="outlined"
          fullWidth
          disabled={formik.isSubmitting || !addMessageChecked}
          label={t('shared.fields.message')}
          {...formik.getFieldProps('message')}
          error={Boolean(formik.touched.message && formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          size="small"
          type="text"
          id="go9kvzoo"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={onClose}
          variant="outlined"
          type="button"
          color="inherit"
          id="mz5nnxqa"
        >
          {t('shared.buttons.cancel')}
        </Button>
        <LoadingButton
          disabled={!formik.isValid}
          loading={formik.isSubmitting}
          onClick={() => handleSubmit()}
          type="submit"
          variant="contained"
          id="pz656ri9"
        >
          {t('documents.buttons.void')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
