import React from 'react';

import ElementLabel from './ElementLabel';
import { ElementProps } from '../../../@types/forms/types';

type HyperlinkElementProps = {
  el: ElementProps;
  pageNumberScale: number;
};

const HyperlinkElement: React.FC<HyperlinkElementProps> = ({ el, pageNumberScale }) => (
  <ElementLabel el={el} label={el.hyperlink ? el.hyperlink.text : el.label} pageNumberScale={pageNumberScale} isStatic />
);

export default HyperlinkElement;
