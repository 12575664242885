import React, { useState, SyntheticEvent } from 'react';

import { Box, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';

import useLocales from '../../../hooks/useLocales';
import FormTypesList from '../../forms/FormTypesList';
import AdminPanelBackButton from '../AdminPanelBackButton';
import FormTemplatesList from '../../forms/FormTemplatesList';

// ----------------------------------------------------------------------

const FormsList: React.FC = () => {
  const { t } = useLocales();
  const [value, setValue] = useState('templates');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <AdminPanelBackButton />

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label={t('formTemplates.formTemplates')} value="templates" />

            <Tab label={t('formTemplates.formTypes.formTypes')} value="types" />
          </TabList>
        </Box>
        <TabPanel value="templates">
          <FormTemplatesList />
        </TabPanel>

        <TabPanel value="types">
          <FormTypesList />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FormsList;
