import { createSlice } from '@reduxjs/toolkit';

import aspRolePermissionService from 'src/services/asp-identity/aspRolePermissionService';
import { RoleWithPermissions, RolePermissionsState } from 'src/@types/rolePermissions/types';

// utils
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: RolePermissionsState = {
  isLoading: false,
  error: null,
  roles: null,
  permissions: null,
  rolePermissions: null,
};

const slice = createSlice({
  name: 'rolePermissions',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.roles = initialState.roles;
      state.permissions = initialState.permissions;
      state.rolePermissions = initialState.rolePermissions;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    addRolePermission(state, action) {
      state.rolePermissions = state.rolePermissions
        ? state.rolePermissions.map((role) =>
            role.roleId === action.payload.roleId ? { ...role, permissionIds: sortPermissionsIds([...role.permissionIds, action.payload.permissionId]) } : role
          )
        : null;
    },

    removeRolePermission(state, action) {
      state.rolePermissions = state.rolePermissions
        ? state.rolePermissions.map((role) =>
            role.roleId === action.payload.roleId ? { ...role, permissionIds: role.permissionIds.filter((p) => p !== action.payload.permissionId) } : role
          )
        : null;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    getPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.permissions = action.payload;
    },

    getRolePermissionsSuccess(state, action) {
      state.isLoading = false;
      state.rolePermissions = [...action.payload.rolePermissions];
    },
  },
});

// Reducer
export default slice.reducer;

export function addRolePermission(roleId: string, permissionId: number) {
  dispatch(slice.actions.addRolePermission({ roleId, permissionId }));
}

export function removeRolePermission(roleId: string, permissionId: number) {
  dispatch(slice.actions.removeRolePermission({ roleId, permissionId }));
}

export async function getRoles() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspRolePermissionService.getRoles();
    dispatch(slice.actions.getRolesSuccess(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function getPermissions() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspRolePermissionService.getPermissions();
    dispatch(slice.actions.getPermissionsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function getRolePermissions() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspRolePermissionService.getRolePermissions();
    dispatch(slice.actions.getRolePermissionsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export async function setRolePermissions(rolePermissions: RoleWithPermissions[]) {
  dispatch(slice.actions.startLoading());
  try {
    await aspRolePermissionService.setRolePermissions(rolePermissions);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

const sortPermissionsIds = (permissions: number[]) => {
  permissions.sort((a, b) => a - b);

  return permissions;
};
