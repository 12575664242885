import { useState, useEffect } from 'react';

import { Stack, Button, Dialog, IconButton, Typography, DialogTitle, DialogContent } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { useSelector } from 'src/redux/store';
import { clearControlCode } from 'src/redux/slices/controlCode';

import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function ControlCodePopup({ onClose = undefined }: { onClose?: () => Promise<void> }) {
  const defaultTimer = 300;
  const { t } = useLocales();

  const { controlCode } = useSelector((state) => state.controlCode);

  const [timeLeft, setTimeLeft] = useState(defaultTimer);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (controlCode) {
      timeout = setTimeout(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearControlCode();
        }
      }, 1000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [timeLeft, controlCode]);

  useEffect(() => {
    if (controlCode) {
      setTimeLeft(defaultTimer);
    }
  }, [controlCode]);

  const close = async () => {
    clearControlCode();
    if (onClose) {
      await onClose();
    }
  };

  const timeLeftString = (): string => {
    const secs = timeLeft % 60;
    const mins = (timeLeft - secs) / 60;

    return `0${mins}:${secs > 9 ? secs : `0${  secs}`}`;
  };

  return (
    <>
      {controlCode && (
        <Dialog open>
            <IconButton onClick={close} size='small' sx={{ position: 'absolute', top: 8, right: 8 }} id="wsuiJe">
              <Iconify icon="eva:close-fill" width={20} height={20} />
            </IconButton>
            <DialogTitle>
              <Typography align='center' sx={{ mt: 2 }}>
                {t('shared.dialogs.controlCode')}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ pr: 10, pl: 10, minWidth: '300px' }}>
              <Typography variant='h1' align='center'>
                {controlCode}
              </Typography>
              <Stack direction='row' sx={{ justifyContent: 'center' }}>
                <Iconify icon="clarity:alarm-clock-line" />
                <Typography variant='caption' align='center' sx={{ mb: 2 }}>
                  {`${timeLeftString()} ${t('shared.dialogs.controlCodeWaiting')}`}
                </Typography>
              </Stack>
              <Stack alignItems="stretch">
                <Button variant='contained' onClick={close} id="mz5nnxqa">
                  {t('shared.buttons.cancel')}
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
      )}
    </>
  );
}
