import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
  Box,
  Card,
  Button,
  useTheme,
  CardHeader,
  Typography,
  CardActions,
  CardContent,
} from '@mui/material';

import { PATH_ADOC, PATH_ADOC_BUSINESS } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import DashboardSectionHeader from './DashboardSectionHeader';

// ----------------------------------------------------------------------

type PlanCardProp = {
  title: string;
  icon: string;
  planText: string[];
  clickPlan: () => void;
  current: boolean;
  btnText: string;
  monthPrice?: string;
  yearPrice?: string;
  handlePlanBtnClick?: () => void;
};

export default function DashboardPlans() {
  const { t } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();

  const planFree = ['dashboard.plansb2c.plan1.5signatures', 'dashboard.plansb2c.plan1.5docChecks'];

  const planProf = [
    'dashboard.plansb2c.plan2.20signatures',
    'dashboard.plansb2c.plan2.additionalSign',
    'dashboard.plansb2c.plan2.freeDocCheck',
    'dashboard.plansb2c.plan2.docCreation',
    'dashboard.plansb2c.plan2.manageSigners',
    'dashboard.plansb2c.plan2.showStatistics',
    'dashboard.plansb2c.plan2.unqualifiedSign',
    'dashboard.plansb2c.plan2.unlimitedDocStorage',
  ];

  const planTeams = [
    'dashboard.plansb2c.plan3.30signatures',
    'dashboard.plansb2c.plan3.additionalSign',
    'dashboard.plansb2c.plan3.freeDocCheck',
    'dashboard.plansb2c.plan3.docCreation',
    'dashboard.plansb2c.plan3.manageSigners',
    'dashboard.plansb2c.plan3.showStatistics',
    'dashboard.plansb2c.plan3.unqualifiedSign',
    'dashboard.plansb2c.plan3.unlimitedDocStorage',
    'dashboard.plansb2c.plan3.personalMails',
    'dashboard.plansb2c.plan3.unlimitedUsesrs',
    'dashboard.plansb2c.plan3.manageUsesrsRights',
    'dashboard.plansb2c.plan3.docTags',
    'dashboard.plansb2c.plan3.cancelOption',
    'dashboard.plansb2c.plan3.signWithEmail',
    'dashboard.plansb2c.plan3.saveDocTree',
    'dashboard.plansb2c.plan3.addressBook',
  ];

  const planOrg = [
    'dashboard.plansb2c.plan4.30signatures',
    'dashboard.plansb2c.plan4.additionalSign',
    'dashboard.plansb2c.plan4.freeDocCheck',
    'dashboard.plansb2c.plan4.docCreation',
    'dashboard.plansb2c.plan4.manageSigners',
    'dashboard.plansb2c.plan4.showStatistics',
    'dashboard.plansb2c.plan4.unqualifiedSign',
    'dashboard.plansb2c.plan4.unlimitedDocStorage',
    'dashboard.plansb2c.plan4.personalMails',
    'dashboard.plansb2c.plan4.unlimitedUsesrs',
    'dashboard.plansb2c.plan4.manageUsesrsRights',
    'dashboard.plansb2c.plan4.docTags',
    'dashboard.plansb2c.plan4.cancelOption',
    'dashboard.plansb2c.plan4.signWithEmail',
    'dashboard.plansb2c.plan4.saveDocTree',
    'dashboard.plansb2c.plan4.addressBook',
    'dashboard.plansb2c.plan4.delegation',
    'dashboard.plansb2c.plan4.personnelModule',
    'dashboard.plansb2c.plan4.accountingModule',
    'dashboard.plansb2c.plan4.creationDocForms',
  ];

  const handlePlanClick = () => {
    window.open(PATH_ADOC_BUSINESS, '_blank');
  };

  const handlePlanBtnClick = () => {
    window.open(PATH_ADOC, '_blank');
  };

  const yearlyPriceSyle = {
    mb: 1,
    color: theme.palette.primary.main,
  };

  const monthlyPriceStyle = {
    mb: 1,
    color: theme.palette.warning.dark,
  };

  const cardStyle = {
    cursor: 'pointer',
    height: '100%',
  };

  const cardActionStyle = {
    mb: 2,
  };

  const PlanCard = ({
    title,
    icon,
    planText,
    clickPlan,
    current,
    btnText,
    yearPrice,
    monthPrice,
    handlePlanBtnClick,
  }: PlanCardProp) => (
    <Card sx={cardStyle}>
      <CardHeader
        onClick={handlePlanClick}
        titleTypographyProps={{ variant: 'h4', textAlign: 'center' }}
        title={t(title)}
        id="tcMhEM"
      />

      <CardContent onClick={handlePlanClick} sx={{ pt: 0 }} id="2rHfIp">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={icon} alt={title} />
        </Box>

        {planText.map((item, i) => (
          <Typography
            key={i}
            variant={i <= 1 ? 'subtitle1' : 'body1'}
            sx={{ mb: 1, display: 'flex', alignItems: 'flex-start', gap: 1, fontSize: '14px' }}
          >
            <CheckRoundedIcon /> {t(item)}
          </Typography>
        ))}
        {monthPrice && yearPrice && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Box>
              <Typography variant="h5" sx={yearlyPriceSyle}>
                {t(yearPrice)}
              </Typography>
              <Typography variant="body1" sx={yearlyPriceSyle}>
                {t('dashboard.plansb2c.yearly')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={monthlyPriceStyle}>
                {t(monthPrice)}
              </Typography>
              <Typography variant="body2" sx={monthlyPriceStyle}>
                {t('dashboard.plansb2c.monthly')}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
      <CardActions sx={cardActionStyle}>
        <Button
          onClick={handlePlanBtnClick}
          disabled={current}
          variant={current ? 'outlined' : 'contained'}
          fullWidth
          id="EKM2WU"
        >
          {t(`${btnText}`)}
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <Box>
      <DashboardSectionHeader localeText="dashboard.plans" />
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
        {/* plan free */}
        <PlanCard
          title="dashboard.plansb2c.plan1.free"
          icon="/icons/ic_plan_free.svg"
          planText={planFree}
          clickPlan={handlePlanClick}
          current
          btnText="dashboard.plansb2c.yourPlan"
        />

        {/* plan pro */}
        <PlanCard
          title="dashboard.plansb2c.plan2.pro"
          icon="/icons/ic_plan_starter.svg"
          planText={planProf}
          clickPlan={handlePlanClick}
          current={false}
          btnText="dashboard.plansb2c.buttons.tryThisPlan"
          yearPrice="dashboard.plansb2c.plan2.priceYear"
          monthPrice="dashboard.plansb2c.plan2.priceMonth"
          handlePlanBtnClick={handlePlanBtnClick}
        />

        {/* plan teams */}
        <PlanCard
          title="dashboard.plansb2c.plan3.teams"
          icon="/icons/ic_plan_premium.svg"
          planText={planTeams}
          clickPlan={handlePlanClick}
          current={false}
          btnText="dashboard.plansb2c.buttons.tryThisPlan"
          yearPrice="dashboard.plansb2c.plan3.priceYear"
          monthPrice="dashboard.plansb2c.plan3.priceMonth"
          handlePlanBtnClick={handlePlanBtnClick}
        />

        {/* plan organizations */}
        <PlanCard
          title="dashboard.plansb2c.plan4.organizations"
          icon="/icons/ic_plan_organizations.svg"
          planText={planOrg}
          clickPlan={handlePlanClick}
          current={false}
          btnText="dashboard.plansb2c.buttons.contactUs"
          handlePlanBtnClick={handlePlanBtnClick}
        />
      </Box>
    </Box>
  );
}
