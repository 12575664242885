import { useRef, useState } from 'react';

import { Badge } from '@mui/material';

import { useSelector } from 'src/redux/store';

import Iconify from '../../../components/Iconify';
import NotificationsList from '../NotificationsList';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { items } = useSelector((state) => state.systemNotifications);

  const totalUnseen = items.filter((x) => !x.isSeen).length;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButtonAnimate ref={anchorRef} size='large' color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <Badge badgeContent={totalUnseen} color='error'>
          <Iconify icon='eva:bell-fill' width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <NotificationsList />
      </MenuPopover>
    </>
  );
}
