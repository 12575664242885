import axios from 'src/utils/httpClients/axios';

import { AUTH0_API } from 'src/config';
import { Module } from 'src/@types/module/types';

// ----------------------------------------------------------------------

const baseUrl = `${AUTH0_API.domain  }api/modules`;

async function getModules() {
  const response = await axios.get<Module[]>(baseUrl);
  return response.data;
}

const aspModuleService = {
  getModules,
};

export default aspModuleService;
