import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useState, useEffect } from 'react';
import { ReactInternetSpeedMeter } from 'react-internet-meter';

// @mui
import { Theme, styled } from '@mui/material/styles';
import { Alert, Backdrop, Snackbar, useTheme, Typography } from '@mui/material';

import useAppSettings from 'src/hooks/useAppSettings';

//
import DashboardHeader from './header';
import DashboardNavbar from './navbar';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
// hooks
import useMenuDrawer from '../../hooks/useMenuDrawer';
import { clearDocumentsState } from '../../redux/slices/document';
// config
import {
  DASHBOARD_NAVBAR_WIDTH,
  DASHBOARD_HEADER_MOBILE,
  DASHBOARD_HEADER_DESKTOP,
} from '../../config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    minHeight: '100%',
  },
}));

const MainStyle = styled('main')(({ theme }: { theme: Theme }) => ({
  flexGrow: 1,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
  paddingTop: DASHBOARD_HEADER_MOBILE + 24,
  paddingBottom: DASHBOARD_HEADER_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    marginLeft: DASHBOARD_NAVBAR_WIDTH,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: DASHBOARD_HEADER_DESKTOP + 24,
    paddingBottom: DASHBOARD_HEADER_DESKTOP + 24,
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { t } = useLocales();
  const { isOpen, isLargeScreen, onClose } = useMenuDrawer();
  const { selectedId } = useSelector((state) => state.document);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const theme = useTheme();
  const moduleData = useAppSettings();
  const { pathname } = useLocation();

  const slowInternetLevel = 0.15;

  const handleNetworkSpeedChange = (speed: string) => {
    const downloadSpeed = Number(speed);

    if (downloadSpeed <= slowInternetLevel) {
      setIsSnackbarOpen(true);
    } else {
      setIsSnackbarOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  // ---- Clear Document State ----
  useEffect(() => {
    if (selectedId && !pathname.includes(`documents/${selectedId}`)) {
      clearDocumentsState();
    }
  }, [pathname]);

  return (
    <RootStyle>
      <ReactInternetSpeedMeter
        txtSubHeading=""
        outputType="empty"
        customClassName={null}
        txtMainHeading=""
        pingInterval={10000} // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={100}
        imageUrl="/logo/logo_orange.png"
        downloadSize="15875" // bytes
        callbackFunctionOnNetworkDown={() => {}}
        callbackFunctionOnNetworkTest={(speed: string) => handleNetworkSpeedChange(speed)}
      />

      <Snackbar open={isSnackbarOpen} autoHideDuration={null} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          <Typography>{t('shared.snackbar.slowInternetConnection')}</Typography>
        </Alert>
      </Snackbar>

      <DashboardHeader />

      <DashboardNavbar />

      <MainStyle
        theme={theme}
        style={{
          background: moduleData.isCompany
            ? undefined
            : `linear-gradient(45deg, ${theme.palette.grey[200]} 10%, white 100%)`,
        }}
      >
        <Outlet />
      </MainStyle>

      <Backdrop sx={{ zIndex: 1100 }} open={!!isOpen && !isLargeScreen} onClick={onClose} />
    </RootStyle>
  );
}
