import React, { useRef, useEffect } from 'react';

import { Box, Card, Skeleton, Typography } from '@mui/material';

import { useSelector } from '../../redux/store';
import { setCardWidthState } from '../../redux/slices/dlxDrive';
import {
  SKELETON_BGCOLOR,
  SKELETON_ANIMATION,
  FOLDER_ICON_LARGE_SIZE,
  FOLDER_ICON_SMALL_SIZE,
  WIDTH_CORRECTION_LARGE_SCREEN,
  WIDTH_CORRECTION_SMALL_SCREEN,
} from '../../utils/constants/dlxDriveConstants';

// ----------------------------------------------------------------

type DLXDriveSkeletonCardProps = {
  isLargeScreen: boolean;
};

const DLXDriveSkeletonCard: React.FC<DLXDriveSkeletonCardProps> = ({ isLargeScreen }) => {
  const { cardWidth } = useSelector((state) => state.dlxDrive);

  const cardRef = useRef<HTMLDivElement | null>(null);
  const settingsRef = useRef<HTMLElement>(null);
  const iconSize = isLargeScreen ? `${FOLDER_ICON_LARGE_SIZE}px` : `${FOLDER_ICON_SMALL_SIZE}px`;

  const columnSkeletons: string[] = new Array(4).fill('column-skeleton');
  const dotSkeletons: string[] = new Array(3).fill('dot-skeleton');

  const handleWindowResize = () => {
    if (cardRef.current) {
      const newCardWidth = cardRef.current.offsetWidth;

      if (newCardWidth !== cardWidth) {
        setCardWidthState(cardRef.current.offsetWidth);
      }
    }
  };

  window.addEventListener('resize', handleWindowResize);

  useEffect(() => {
    handleWindowResize();
  }, [cardRef.current]);

  return (
    <Card
      ref={cardRef}
      sx={{
        px: '8px',
        py: '9px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: '42px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: '1px',
          }}
        >
          <Skeleton
            sx={{ backgroundColor: SKELETON_BGCOLOR, borderRadius: '4px' }}
            animation={SKELETON_ANIMATION}
            variant="rectangular"
            width={18}
            height={18}
          />
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {/*-----------------------------*/}
          {/* Card view for LARGE screens */}
          {/*-----------------------------*/}

          {isLargeScreen && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '59px',
              }}
            >
              <Box
                sx={{
                  width: iconSize,
                  height: iconSize,
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  pl: '4px',
                }}
              >
                <Skeleton
                  sx={{ backgroundColor: SKELETON_BGCOLOR }}
                  animation={SKELETON_ANIMATION}
                  component="div"
                  variant="rounded"
                  width={43}
                  height={53}
                />
              </Box>

              <Box
                sx={{
                  width: cardWidth ? `${cardWidth - WIDTH_CORRECTION_LARGE_SCREEN}px` : '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                }}
              >
                <Typography component="div" variant="h5">
                  <Skeleton
                    animation={SKELETON_ANIMATION}
                    sx={{ backgroundColor: SKELETON_BGCOLOR }}
                  />
                </Typography>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  {columnSkeletons.map((s, i) => (
                    <Typography
                      key={`${s}-${i}`}
                      component="div"
                      variant="body2"
                      sx={{ width: '100%' }}
                    >
                      <Skeleton
                        animation={SKELETON_ANIMATION}
                        sx={{ backgroundColor: SKELETON_BGCOLOR }}
                      />
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          {/*-----------------------------*/}
          {/* Card view for SMALL screens */}
          {/*-----------------------------*/}

          {!isLargeScreen && (
            <Box
              sx={{
                width: cardWidth ? `${cardWidth - WIDTH_CORRECTION_SMALL_SCREEN}px` : '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Skeleton
                  sx={{ backgroundColor: SKELETON_BGCOLOR }}
                  animation={SKELETON_ANIMATION}
                  variant="rounded"
                  width={30}
                  height={30}
                />

                <Skeleton
                  variant="text"
                  sx={{ fontSize: '1rem', backgroundColor: SKELETON_BGCOLOR }}
                  animation={SKELETON_ANIMATION}
                />
              </Box>

              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', backgroundColor: SKELETON_BGCOLOR }}
                animation={SKELETON_ANIMATION}
              />

              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', backgroundColor: SKELETON_BGCOLOR }}
                animation={SKELETON_ANIMATION}
              />
            </Box>
          )}
        </Box>

        <Box
          ref={settingsRef}
          sx={{
            width: '36px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {dotSkeletons.map((s, i) => (
            <Skeleton
              key={`${s}-${i}`}
              sx={{ backgroundColor: SKELETON_BGCOLOR }}
              animation={SKELETON_ANIMATION}
              component="div"
              variant="circular"
              width={4}
              height={4}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export default DLXDriveSkeletonCard;
