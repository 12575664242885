import { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';

import documentService from 'src/services/documentService';
import { DocumentStatusCount } from 'src/@types/documents/types';
import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

import DashboardSectionHeader from '../DashboardSectionHeader';
import { ModuleTypes } from '../../../@types/module/enums/moduleTypes';
import ModuleCheck from '../../../components/access-checks/ModuleCheck';
import DashboardB2CDocumentStatusShortcut from './DashboardB2CDocumentStatusShortcut';

// ----------------------------------------------------------------------

const moduleType = DocumentModuleTypes.Standard;

export default function DashboardB2CQuickActions() {
  const isMobile = useResponsive('down', 'sm');
  const [statusCounts, setStatusCounts] = useState<DocumentStatusCount[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await documentService.getStatusCount([moduleType]);
    setStatusCounts(data);
  };

  function StatusBlock({ status }: { status: DocumentStatuses }) {
    return (
      <Grid item xs>
        <DashboardB2CDocumentStatusShortcut
          status={status}
          count={
            statusCounts.find((x) => x.moduleType === moduleType && x.status === status)?.count || 0
          }
        />
      </Grid>
    );
  }

  return (
    <Box>
      <DashboardSectionHeader localeText="dashboard.quickActions" />
      <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMe} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthers} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMeApprove} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthersApprove} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.Voided} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForMeToAcknowledge} />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <StatusBlock status={DocumentStatuses.WaitingForOthersToAcknowledge} />
        </Grid>

        <ModuleCheck module={ModuleTypes.FormsModule}>
          <Grid item xs={isMobile ? 12 : 4}>
            <StatusBlock status={DocumentStatuses.WaitingForRelatedDocuments} />
          </Grid>
        </ModuleCheck>
      </Grid>
    </Box>
  );
}
