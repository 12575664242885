import { FormElementError } from '../enums/FormElementError';
import { ErrorsListProps, ElementValidationProps } from '../types';

const validateNumberOfCharacters = ({ el, errorMessage, value }: ElementValidationProps) => {
  let newError: ErrorsListProps | null = null;
  let numberOfCharacters: number[] = [];

  if (el.numberOfCharacters) {
    numberOfCharacters = [...el.numberOfCharacters];
  }

  if (value && (value?.length < numberOfCharacters[0] || value?.length > numberOfCharacters[1])) {
    newError = {
      elementID: el.id,
      elementName: el.label,
      errorCode: FormElementError.InvalidNumberOfCharacters,
      errorMessage,
    };

    return newError;
  }

  return newError;
};

export default validateNumberOfCharacters;
