import React from 'react';

import { TablePagination } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------

type DLXDriveRowsPerPageProps = {
  limit: number;
  setLimit: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const DLXDriveRowsPerPage: React.FC<DLXDriveRowsPerPageProps> = ({ limit, setLimit }) => {
  const { t } = useLocales();
  const isMobile = useResponsive('only', 'xs');

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    // functionality is disabled
  };

  return (
    <TablePagination
      component="div"
      count={0} // functionality is disabled
      page={0} // functionality is disabled
      onPageChange={handleChangePage} // functionality is disabled
      rowsPerPage={limit}
      labelRowsPerPage={t('dlxDrive.pagination.rowsPerPage')}
      onRowsPerPageChange={setLimit}
      sx={{
        '& .MuiTablePagination-actions': {
          display: 'none',
        },
        '& .MuiTablePagination-displayedRows': {
          display: 'none',
        },
        '& .MuiTablePagination-toolbar': {
          p: 0,
          m: 0,
        },
        '& .MuiInputBase-root': {
          mr: isMobile ? '10px' : '',
        },
      }}
      id='s9pg959u'
    />
  );
};

export default DLXDriveRowsPerPage;
