import { Handler, Message, PublishOptions, SubscribeOptions } from 'src/@types/eventBus';

const defaultPublishOptions: PublishOptions = {
  targetOrigin: '*',
  targetWindow: window,
};

const defaultSubscribeOptions: SubscribeOptions = {
  targetWindow: window,
};

const publish = <Topic extends keyof Record<string, any>>(message: Message<Record<string, any>, Topic>, options: PublishOptions = defaultPublishOptions) => {
  options.targetWindow.postMessage(message, options.targetOrigin);
};

const subscribe = <Topic extends keyof Record<string, any>>(
  topic: Topic,
  handler: Handler<Record<string, any>[Topic]>,
  options: SubscribeOptions = defaultSubscribeOptions
) => {
  const messageEventHandler = (event: MessageEvent<Message<Record<string, any>, Topic>>) => subscriptionHandler(event, topic, handler);

  const attachEventListener = () => {
    options.targetWindow.addEventListener('message', messageEventHandler);
  };
  const detachEventListener = () => {
    options.targetWindow.removeEventListener('message', messageEventHandler);
  };

  attachEventListener();
  return { unsubscribe: detachEventListener };
};

const subscriptionHandler = <Topic extends keyof Record<string, any>>(
  event: MessageEvent<Message<Record<string, any>, Topic>>,
  topic: Topic,
  handler: Handler<Record<string, any>[Topic]>
) => {
  if (event.data.topic === topic) {
    handler(event.data.payload);
  }
};

const eventBus = { subscribe, publish };

export default eventBus;

export enum EventTypes {
  DocumentSigned = 'DocumentSigned',
  ForbidSign = 'ForbidSign',
  SignersChanged = 'SignersChanged',
  RepeatedInvite = 'RepeatedInvite',
  UploadedDocumentsChanged = 'UploadedDocumentsChanged',
  SigningBlockClicked = 'SigningBlockClicked',
}
