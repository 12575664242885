import { ReactNode } from 'react';

import { getSession } from 'src/utils/auth/jwt';

import { Permissions } from 'src/@types/auth/constants/permissions';

// ----------------------------------------------------------------------

type PermissionCheckProp = {
  permissions: Permissions[];
  children: ReactNode | string;
};

export default function PermissionCheck({ permissions, children }: PermissionCheckProp) {
  const {user} = getSession();

  if (user?.permissions.some((r) => permissions.includes(r))) {
    return <>{children}</>;
  }

  return null;
}
