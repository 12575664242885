import React from 'react';

import { Box } from '@mui/material';

import useMenuDrawer from '../../../hooks/useMenuDrawer';
import { DASHBOARD_HEADER_DESKTOP } from '../../../config';

// ----------------------------------------------------------------------

const LogoFooter: React.FC = () => {
  const menuDrawer = useMenuDrawer();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        height: '16px',
        mt: 2,
        px: 2,
        pb: menuDrawer.isLargeScreen ? `${DASHBOARD_HEADER_DESKTOP + 8}px` : '8px',
        boxSizing: 'content-box',
      }}
    >
      <img style={{ height: '100%', objectFit: 'contain' }} src='/logo/logo_footer.png' alt='Logo' />
    </Box>
  );
};

export default LogoFooter;
