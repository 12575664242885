// NEW FORM BUILDER COMPONENT

import React, { useState, useEffect } from 'react';
import { IDocument } from '@cyntler/react-doc-viewer';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, Grid, IconButton, Typography, CardContent } from '@mui/material';

import { getFileView } from 'src/utils/fileUtils';
import { getFileType } from 'src/utils/getFileFormat';

import { FileExtensions } from 'src/@types/fileView/enums/fileExtensions';

import { UploadSingleFile } from 'src/components/upload';

import tempFileService from '../../../services/tempFileService';
import DocumentsViewer from '../../../utils/documents/DocumentsViewer';

// ----------------------------------------------------------------------

type FileItem = {
  uploadedFile: File;
};

type FormsUploadTemplateFileProps = {
  fileState: File | null;
  getFile: (file: File | null) => void;
  viewerWrapperWidth: number;
};

const FormsUploadTemplateFile: React.FC<FormsUploadTemplateFileProps> = ({
  fileState,
  getFile,
  viewerWrapperWidth,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [docs, setDocs] = useState<IDocument[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMainDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      return;
    }

    const item = {
      uploadedFile: acceptedFiles[0],
    } as FileItem;

    createFileLink(item);
  };

  const createFileLink = async (item: FileItem) => {
    const file = await getFileView(item.uploadedFile);

    setFile(file);
  };

  const handleDeleteFile = () => {
    setFile(null);
    getFile(null);
  };

  useEffect(() => {
    if (fileState) {
      setFile(fileState);
    }
  }, [fileState]);

  const mainDocTypeValidator = (file: File) => {
    const extension = getFileType(file.name);
    if ((extension as FileExtensions) !== FileExtensions.Pdf) {
      return {
        code: 'file-invalid-type',
        message: 'components.uploadSingleFile.fileMustBe',
        parameters: { data: FileExtensions.Pdf },
      };
    }
    if (file.size > 3 * 1000 * 1024) {
      return {
        code: 'file-invalid-size',
        message: 'components.uploadSingleFile.fileSizeMustBe',
      };
    }

    return null;
  };

  const createDocs = async () => {
    if (file) {
      const uri = await tempFileService.createFile(file, 'pdf');

      setDocs([{ uri, fileType: 'pdf', fileData: uri }]);
    }
  };

  useEffect(() => {
    if (file) {
      getFile(file);
      createDocs();
    }
  }, [file]);

  useEffect(() => {
    if (docs.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [docs]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {file && (
          <>
            <Card sx={{ mb: 1 }}>
              <CardContent>
                <DocumentsViewer
                  docs={docs}
                  isLoading={isLoading}
                  setIsLoading={() => {}}
                  showNavigation
                  isZooming
                  setIsZooming={() => {}}
                />
              </CardContent>
            </Card>

            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography>{file.name}</Typography>

                  <IconButton onClick={handleDeleteFile} size="large" id="n7vya3a1">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </>
        )}

        {(file === null || file === undefined) && (
          <UploadSingleFile
            accept={`.${FileExtensions.Pdf}`}
            onDrop={handleMainDrop}
            validator={mainDocTypeValidator}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FormsUploadTemplateFile;
