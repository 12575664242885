import { createTheme } from '@mui/system';

const { breakpoints } = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
});

export default breakpoints;
