import { toLocal } from 'src/utils/dateTimeUtils';

import { IdName } from '../shared/idNameModel';
import { DocumentActionTypes } from './enums/documentActionTypes';

export class DocumentHistoryItem {
  document: IdName;

  executorName: string;

  dateTime: Date;

  action: DocumentActionTypes;

  customData: string;

  rejectMessage: string;

  get actionDisplayLocaleTag(): string {
    switch (this.action) {
      case DocumentActionTypes.Created:
        return 'created';
      case DocumentActionTypes.Signed:
        return 'signed';
      case DocumentActionTypes.Downloaded:
        return 'downloaded';
      case DocumentActionTypes.Opened:
        return 'opened';
      case DocumentActionTypes.RequstedSigning:
        return 'requstedSigning';
      case DocumentActionTypes.RequstedApprove:
        return 'requestedApproving';
      case DocumentActionTypes.Edited:
        return 'edited';
      case DocumentActionTypes.Approved:
        return 'approved';
      case DocumentActionTypes.RepeatedInvite:
        return 'repeatedInvite';
      case DocumentActionTypes.SigningDelegated:
        return 'delegated';
      case DocumentActionTypes.Rejected:
        return 'rejected';
      case DocumentActionTypes.Voided:
        return 'voided';
      case DocumentActionTypes.Acknowledged:
        return 'acknowledged';
      case DocumentActionTypes.RequestedAcknowledge:
        return 'requestedAcknowledging';
      case DocumentActionTypes.AddedToSign:
        return 'addedToSign';
      case DocumentActionTypes.AddedToApprove:
        return 'addedToApprove';
      case DocumentActionTypes.AddedToAcknowledge:
        return 'addedToAcknowledgde';
      case DocumentActionTypes.RemovedFromSign:
        return 'removedFromSign';
      case DocumentActionTypes.RemovedFromApprove:
        return 'removedFromApprove';
      case DocumentActionTypes.RemovedFromAcknowledge:
        return 'removedFromAcknowledge';
      case DocumentActionTypes.AddedToMailGetter:
        return 'docAddedToMailGetter';
      case DocumentActionTypes.SentToMailGetter:
        return 'docSentAsAttachment';
      case DocumentActionTypes.RemovedMailGetter:
        return 'removedMailGetter';
      case DocumentActionTypes.MassApproved:
        return 'massApproved';
      case DocumentActionTypes.CommentChanged:
        return 'commented';
      case DocumentActionTypes.DocumentHistoryExport:
        return 'docHistoryExported';
      case DocumentActionTypes.Moved:
        return 'moved';
      case DocumentActionTypes.Shared:
        return 'shared';
      case DocumentActionTypes.Delegated:
        return 'delegated';
      case DocumentActionTypes.AccompanyingDocumentAdded:
        return 'accompanyingDocumentAdded';
      case DocumentActionTypes.AccompanyingDocumentRemoved:
        return 'accompanyingDocumentRemoved';
      case DocumentActionTypes.AccompanyingDocumentMarkedAsSeen:
        return 'accompanyingDocumentMarkedAsSeen';
      case DocumentActionTypes.AccompanyingDocumentMarkedAsNotSeen:
        return 'accompanyingDocumentMarkedAsNotSeen';
      case DocumentActionTypes.SigningComment:
        return 'signComment';
      default:
        return 'none';
    }
  }

  parseJson(json: any): DocumentHistoryItem | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: DocumentHistoryItem = new DocumentHistoryItem();

    model.document = json.document;
    model.executorName = json.executorName;
    model.dateTime = toLocal(json.dateTime);
    model.action = json.action;
    model.customData = json.customData;
    model.rejectMessage = json.rejectMessage;

    return model;
  }
}
