import { createSlice } from '@reduxjs/toolkit';

// Types
import { DepartmentsState } from 'src/@types/departments/types';
import { UpdateDepartment } from 'src/@types/departments/departments';
import { DepartmentsFilter } from 'src/@types/departments/departmentsFilter';
// Services
import aspDepartmentsService from 'src/services/asp-identity/aspDepartmentsService';

// utils
import { dispatch } from '../store';

const initialState: DepartmentsState = {
  isLoading: false,
  error: null,
  departments: null,
  selectedId: null,
};

const slice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.departments = initialState.departments;
      state.selectedId = initialState.selectedId;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload;
    },

    getDepartmentsError(state, action) {
      state.isLoading = false;
      state.departments = initialState.departments;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export async function getDepartments(filter: DepartmentsFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspDepartmentsService.getPagedDepartments(filter);

    dispatch(slice.actions.getDepartmentsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getDepartmentsError(error));
  }
}

export async function createDepartment(department: UpdateDepartment) {
  try {
    await aspDepartmentsService.createDepartment(department);
  } catch (error) {
    throw error;
  }
}

export async function updateDepartment(department: UpdateDepartment, id: string) {
  try {
    await aspDepartmentsService.updateDepartment(department, id);
  } catch (error) {
    throw error;
  }
}

export async function deleteDepartment(id: string) {
  dispatch(slice.actions.startLoading());
  try {
    await aspDepartmentsService.deleteDepartment(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.finishLoading());
    throw error;
  }
}
