export class UserProfileImage {
    name: string;

    extension: string;

    image: string;

  parseJson(json: any): UserProfileImage | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: UserProfileImage = new UserProfileImage();

    model.name = json.name;
    model.extension = json.extension;
    model.image = json.image;

    return model;
  }
}
