import React, { useState, useEffect } from 'react';

import { Radio, useTheme, FormGroup, FormControl, FormHelperText, FormControlLabel } from '@mui/material';

import ElementLabel from './ElementLabel';
import useLocales from '../../../hooks/useLocales';
import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//---------------------------------------------------------------------------------

type RadioButtonElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isFormFillerPage: boolean;
  elementContentValue?: string[] | undefined;
  handleRadioButtonChange?: (el: ElementProps, index: number) => void;
};

const RadioButtonElement: React.FC<RadioButtonElementProps> = ({ el, pageNumberScale, elementContentValue, isFormFillerPage, handleRadioButtonChange }) => {
  const { t } = useLocales();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({ ...data, errorMessage: t('forms.validationErrorMessage.fieldRequired') });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue[0]);
    }
  }, []);

  return (
    <>
        {el.formGroupOptions && el.formGroupOptions?.length > 0 && (
          <FormControl component='fieldset' variant='standard' error={isFormFillerPage && elementErrorsList.length > 0} 
          sx={{
            backgroundColor: theme.palette.info.lighter, 
            borderRadius: 1, 
            px: 1, 
            width: '100%'
            }} id={el.id}>
            <FormGroup>
              {el.formGroupOptions.map((option, index) => (
                <FormControlLabel
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    lineHeight: `${Math.round(el.fontSize * pageNumberScale)}px`,
                    // m: 0,
                    // p: 0
                  }}
                  key={`${el.id}-${index + 1}`}
                  control={
                    <Radio
                     sx={{
                      fontSize: `${el.fontSize * pageNumberScale}`, 
                      m: 0, 
                      p: 0,
                      pr: 1
                    }}
                      checked={option.isChecked}
                      name={option.label}
                      onClick={() => {
                        if (handleRadioButtonChange) {
                          handleRadioButtonChange(el, index);
                          setValue(option.label);
                        }
                      }}
                    />
                  }
                  label={
                  <ElementLabel 
                    el={el} 
                    label={option.label} 
                    pageNumberScale={pageNumberScale} asChildEl 
                  />}
                />
              ))}
            </FormGroup>

            {isFormFillerPage && elementErrorsList.length > 0 && <FormHelperText sx={{mt: 0}}>{elementErrorsList[0].errorMessage}</FormHelperText>}
          </FormControl>
        )}
      
      {(!el.formGroupOptions || el.formGroupOptions?.length === 0) && <ElementLabel el={el} label={el.label} pageNumberScale={pageNumberScale} />}

    </>
  );
};

export default RadioButtonElement;
