import { createSlice } from '@reduxjs/toolkit';

import tagService from 'src/services/tagService';
import { TagsFilter } from 'src/@types/tags/tagsFilter';
import { TagState, TagCreate, TagUpdate } from 'src/@types/tags/types';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: TagState = {
  isLoading: false,
  tags: null,
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.tags = initialState.tags;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tags = action.payload;
    },

    getTagsError(state, action) {
      state.isLoading = false;
      state.tags = initialState.tags;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getPagedTags(filter: TagsFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await tagService.getPagedTags(filter);

    dispatch(slice.actions.getTagsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getTagsError(error));
  }
}

export function clearTagsState() {
  dispatch(slice.actions.clearState());
}

export async function createTag(tag: TagCreate) {
  try {
    await tagService.createTag(tag);
  } catch (error) {
    throw error;
  }
}

export async function editTag(tag: TagUpdate) {
  try {
    await tagService.editTag(tag);
  } catch (error) {
    throw error;
  }
}

export async function removeTag(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    await tagService.removeTag(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.finishLoading());
    throw error;
  }
}
