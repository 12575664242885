import React from 'react';

import AbcRoundedIcon from '@mui/icons-material/AbcRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CasesRoundedIcon from '@mui/icons-material/CasesRounded';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import RocketRoundedIcon from '@mui/icons-material/RocketRounded';
import BalanceRoundedIcon from '@mui/icons-material/BalanceRounded';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import BackHandRoundedIcon from '@mui/icons-material/BackHandRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import BookmarksRoundedIcon from '@mui/icons-material/BookmarksRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import FolderOffRoundedIcon from '@mui/icons-material/FolderOffRounded';
import FolderZipRoundedIcon from '@mui/icons-material/FolderZipRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CardTravelRoundedIcon from '@mui/icons-material/CardTravelRounded';
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import AttachEmailRoundedIcon from '@mui/icons-material/AttachEmailRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import FolderDeleteRoundedIcon from '@mui/icons-material/FolderDeleteRounded';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import ChildFriendlyRoundedIcon from '@mui/icons-material/ChildFriendlyRounded';
import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';
import FolderSpecialRoundedIcon from '@mui/icons-material/FolderSpecialRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AirportShuttleRoundedIcon from '@mui/icons-material/AirportShuttleRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import AssistantPhotoRoundedIcon from '@mui/icons-material/AssistantPhotoRounded';
import BookmarkRemoveRoundedIcon from '@mui/icons-material/BookmarkRemoveRounded';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

type DLXDriveFolderIconProps = {
  icon: string;
  color: string;
  fontSize: string;
};

const DLXDriveFolderIcon: React.FC<DLXDriveFolderIconProps> = ({ icon, color, fontSize }) => {
  const sxProps = { color, fontSize };

  switch (icon) {
    case 'AbcRoundedIcon':
      return <AbcRoundedIcon sx={{ ...sxProps }} />;

    case 'AcUnitRoundedIcon':
      return <AcUnitRoundedIcon sx={{ ...sxProps }} />;

    case 'AccessTimeRoundedIcon':
      return <AccessTimeRoundedIcon sx={{ ...sxProps }} />;

    case 'AccountBalanceRoundedIcon':
      return <AccountBalanceRoundedIcon sx={{ ...sxProps }} />;

    case 'AccountBalanceWalletRoundedIcon':
      return <AccountBalanceWalletRoundedIcon sx={{ ...sxProps }} />;

    case 'AccountBoxRoundedIcon':
      return <AccountBoxRoundedIcon sx={{ ...sxProps }} />;

    case 'AddAlertRoundedIcon':
      return <AddAlertRoundedIcon sx={{ ...sxProps }} />;

    case 'AirplanemodeActiveRoundedIcon':
      return <AirplanemodeActiveRoundedIcon sx={{ ...sxProps }} />;

    case 'AirportShuttleRoundedIcon':
      return <AirportShuttleRoundedIcon sx={{ ...sxProps }} />;

    case 'AlternateEmailRoundedIcon':
      return <AlternateEmailRoundedIcon sx={{ ...sxProps }} />;

    case 'ApartmentRoundedIcon':
      return <ApartmentRoundedIcon sx={{ ...sxProps }} />;

    case 'AssignmentLateRoundedIcon':
      return <AssignmentLateRoundedIcon sx={{ ...sxProps }} />;

    case 'AssignmentTurnedInRoundedIcon':
      return <AssignmentTurnedInRoundedIcon sx={{ ...sxProps }} />;

    case 'AssignmentRoundedIcon':
      return <AssignmentRoundedIcon sx={{ ...sxProps }} />;

    case 'AssistantPhotoRoundedIcon':
      return <AssistantPhotoRoundedIcon sx={{ ...sxProps }} />;

    case 'AttachEmailRoundedIcon':
      return <AttachEmailRoundedIcon sx={{ ...sxProps }} />;

    case 'AutoStoriesRoundedIcon':
      return <AutoStoriesRoundedIcon sx={{ ...sxProps }} />;

    case 'BackHandRoundedIcon':
      return <BackHandRoundedIcon sx={{ ...sxProps }} />;

    case 'BalanceRoundedIcon':
      return <BalanceRoundedIcon sx={{ ...sxProps }} />;

    case 'BookmarkAddedRoundedIcon':
      return <BookmarkAddedRoundedIcon sx={{ ...sxProps }} />;

    case 'BookmarkRemoveRoundedIcon':
      return <BookmarkRemoveRoundedIcon sx={{ ...sxProps }} />;

    case 'BookmarkRoundedIcon':
      return <BookmarkRoundedIcon sx={{ ...sxProps }} />;

    case 'BookmarksRoundedIcon':
      return <BookmarksRoundedIcon sx={{ ...sxProps }} />;

    case 'BorderColorRoundedIcon':
      return <BorderColorRoundedIcon sx={{ ...sxProps }} />;

    case 'CalculateRoundedIcon':
      return <CalculateRoundedIcon sx={{ ...sxProps }} />;

    case 'CalendarMonthRoundedIcon':
      return <CalendarMonthRoundedIcon sx={{ ...sxProps }} />;

    case 'CalendarTodayRoundedIcon':
      return <CalendarTodayRoundedIcon sx={{ ...sxProps }} />;

    case 'CallRoundedIcon':
      return <CallRoundedIcon sx={{ ...sxProps }} />;

    case 'CardTravelRoundedIcon':
      return <CardTravelRoundedIcon sx={{ ...sxProps }} />;

    case 'CasesRoundedIcon':
      return <CasesRoundedIcon sx={{ ...sxProps }} />;

    case 'CheckBoxRoundedIcon':
      return <CheckBoxRoundedIcon sx={{ ...sxProps }} />;

    case 'CheckCircleRoundedIcon':
      return <CheckCircleRoundedIcon sx={{ ...sxProps }} />;

    case 'ChildFriendlyRoundedIcon':
      return <ChildFriendlyRoundedIcon sx={{ ...sxProps }} />;

    case 'ContactEmergencyRoundedIcon':
      return <ContactEmergencyRoundedIcon sx={{ ...sxProps }} />;

    case 'ContactMailRoundedIcon':
      return <ContactMailRoundedIcon sx={{ ...sxProps }} />;

    case 'DraftsRoundedIcon':
      return <DraftsRoundedIcon sx={{ ...sxProps }} />;

    case 'DriveFileMoveRoundedIcon':
      return <DriveFileMoveRoundedIcon sx={{ ...sxProps }} />;

    case 'DriveFolderUploadRoundedIcon':
      return <DriveFolderUploadRoundedIcon sx={{ ...sxProps }} />;

    case 'FiberNewRoundedIcon':
      return <FiberNewRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderCopyRoundedIcon':
      return <FolderCopyRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderDeleteRoundedIcon':
      return <FolderDeleteRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderOffRoundedIcon':
      return <FolderOffRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderOpenRoundedIcon':
      return <FolderOpenRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderRoundedIcon':
      return <FolderRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderSharedRoundedIcon':
      return <FolderSharedRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderSpecialRoundedIcon':
      return <FolderSpecialRoundedIcon sx={{ ...sxProps }} />;

    case 'FolderZipRoundedIcon':
      return <FolderZipRoundedIcon sx={{ ...sxProps }} />;

    case 'HttpsRoundedIcon':
      return <HttpsRoundedIcon sx={{ ...sxProps }} />;

    case 'LocalPrintshopRoundedIcon':
      return <LocalPrintshopRoundedIcon sx={{ ...sxProps }} />;

    case 'ShoppingCartRoundedIcon':
      return <ShoppingCartRoundedIcon sx={{ ...sxProps }} />;

    case 'SecurityRoundedIcon':
      return <SecurityRoundedIcon sx={{ ...sxProps }} />;

    case 'SendRoundedIcon':
      return <SendRoundedIcon sx={{ ...sxProps }} />;

    case 'SaveRoundedIcon':
      return <SaveRoundedIcon sx={{ ...sxProps }} />;

    case 'RocketRoundedIcon':
      return <RocketRoundedIcon sx={{ ...sxProps }} />;

    case 'LocalFireDepartmentRoundedIcon':
      return <LocalFireDepartmentRoundedIcon sx={{ ...sxProps }} />;

    case 'HomeRoundedIcon':
      return <HomeRoundedIcon sx={{ ...sxProps }} />;

    default:
      return <FolderRoundedIcon sx={{ ...sxProps }} />;
  }
};

export default DLXDriveFolderIcon;
