import React from 'react';

import { Box, Typography } from '@mui/material';

import { CalendarMobileItemProps } from './CalendarMobileLayout';

// ----------------------------------------------------------------

type CalendarMobileLayoutItemProps = {
  item: CalendarMobileItemProps;
};

const CalendarMobileLayoutItem: React.FC<CalendarMobileLayoutItemProps> = ({ item }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography variant="subtitle2" sx={{ minWidth: '112px' }}>
        Till {item.endDate}
      </Typography>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          sx={{
            minWidth: '12px',
            minHeight: '12px',
            backgroundColor: item.isApproved ? item.color : 'none',
            border: `1px solid ${item.color}`,
            borderRadius: '20px',
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="subtitle2">{item.fullName}</Typography>
          <Typography variant="caption">{item.eventName}</Typography>
        </Box>
      </Box>
    </Box>
  );

export default CalendarMobileLayoutItem;
