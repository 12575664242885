import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import { useSelector } from '../../redux/store';
import DLXDriveFolderCard from './DLXDriveFolderCard';
import useResponsive from '../../hooks/useResponsive';
import DLXDriveDocumentCard from './DLXDriveDocumentCard';
import DLXDriveSkeletonCard from './DLXDriveSkeletonCard';
import {
  DLXDriveItem,
  DLXDriveFolderType,
  DLXDriveDocumentType,
} from '../../@types/dlx-drive/types';

// ----------------------------------------------------------------

type DLXDriveListProps = {
  items: DLXDriveItem[];
};

const DLXDriveList: React.FC<DLXDriveListProps> = ({ items }) => {
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [folders, setFolders] = useState<DLXDriveFolderType[]>([]);
  const [documents, setDocuments] = useState<DLXDriveDocumentType[]>([]);
  const cardSkeletons = new Array(7).fill('skeleton');
  const isLargeScreen = useResponsive('up', 'md');

  const separateItems = () => {
    const newFolders: DLXDriveFolderType[] = [];
    const newDocuments: DLXDriveDocumentType[] = [];

    items.forEach((item) => {
      if (!item.extension) {
        newFolders.push(item);
      } else {
        newDocuments.push(item);
      }
    });

    if (newFolders.length > 0) {
      setFolders(newFolders);
    } else {
      setFolders([]);
    }

    if (newDocuments.length > 0) {
      setDocuments(newDocuments);
    } else {
      setDocuments([]);
    }
  };

  useEffect(() => {
    separateItems();
  }, [items]);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1}}>
      {!isLoading &&
        folders &&
        folders.length > 0 &&
        folders.map((folder, index) => (
          <DLXDriveFolderCard
            key={`folder-${index}`}
            folderData={folder}
            isLargeScreen={isLargeScreen !== undefined ? isLargeScreen : true}
            isTagsEnabled={false}
          />
        ))}

      {!isLoading &&
        documents &&
        documents.length > 0 &&
        documents.map((document, index) => (
          <DLXDriveDocumentCard
            key={`document-${index}`}
            documentData={document}
            isLargeScreen={isLargeScreen !== undefined ? isLargeScreen : true}
            isTagsEnabled
          />
        ))}

      {isLoading &&
        cardSkeletons.map((s, i) => (
          <DLXDriveSkeletonCard
            key={`${s}-${i}`}
            isLargeScreen={isLargeScreen !== undefined ? isLargeScreen : true}
          />
        ))}
    </Box>
  );
};

export default DLXDriveList;
