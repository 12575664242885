import { DocumentStatuses } from 'src/@types/documents/enums/documentStatuses';

export function getStatusString(status: DocumentStatuses) {
  switch (status) {
    case DocumentStatuses.New:
      return 'documents.statuses.new';
    case DocumentStatuses.Completed:
      return 'documents.statuses.completed';
    case DocumentStatuses.WaitingForMe:
      return 'documents.statuses.waitingForMe';
    case DocumentStatuses.Rejected:
      return 'documents.statuses.rejected';
    case DocumentStatuses.Voided:
      return 'documents.statuses.voided';
    case DocumentStatuses.WaitingForOthersApprove:
      return 'documents.statuses.waitingForOthersApprove';
    case DocumentStatuses.WaitingForMeApprove:
      return 'documents.statuses.waitingForMeApprove';
    case DocumentStatuses.WaitingForOthers:
      return 'documents.statuses.waitingForOthers';
    case DocumentStatuses.WaitingForRelatedDocuments:
      return 'documents.statuses.waitingForRelatedDocuments';
    case DocumentStatuses.WaitingForMeToAcknowledge:
      return 'documents.statuses.waitingForMeToAcknowledge';
    case DocumentStatuses.WaitingForOthersToAcknowledge:
      return 'documents.statuses.waitingForOthersToAcknowledge';
    default:
      return 'documents.statuses.waitingForOthers';
  }
}
