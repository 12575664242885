import { createSlice } from '@reduxjs/toolkit';

import formTemplateService from 'src/services/formTemplateService';
import { FormTemplatesFilter } from 'src/@types/form-templates/formTemplatesFilter';
import { FormTemplateState, FormTemplateCreate, FormTemplateUpdate } from 'src/@types/form-templates/types';

import { dispatch } from '../store';
// utils

// ----------------------------------------------------------------------

const initialState: FormTemplateState = {
  isLoading: false,
  error: null,
  selectedId: null,
  formTemplates: null,
  formTemplate: null,
};

const slice = createSlice({
  name: 'formTemplate',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.formTemplates = initialState.formTemplates;
      state.selectedId = initialState.selectedId;
      state.formTemplate = initialState.formTemplate;
    },

    clearFormTemplate(state) {
      state.selectedId = initialState.selectedId;
      state.formTemplate = initialState.formTemplate;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFormTemplatesSuccess(state, action) {
      state.isLoading = false;
      state.formTemplates = action.payload;
    },

    getFormTemplateSuccess(state, action) {
      state.isLoading = false;
      state.selectedId = action.payload.id;
      state.formTemplate = action.payload;
    },

    getFormTemplatesError(state, action) {
      state.isLoading = false;
      state.formTemplates = initialState.formTemplates;
      state.error = action.payload;
    },

    formTemplateSelected(state, action) {
      state.selectedId = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function clearFormTemplatesState() {
  dispatch(slice.actions.clearState());
}

export function clearFormTemplate() {
  dispatch(slice.actions.clearFormTemplate());
}

export async function getPagedFormTemplates(filter: FormTemplatesFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await formTemplateService.getPagedFormTemplates(filter);

    dispatch(slice.actions.getFormTemplatesSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getFormTemplatesError(error));
  }
}

export async function getFormTemplate(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await formTemplateService.getFormTemplate(id);
    dispatch(slice.actions.getFormTemplateSuccess(data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function createFormTemplate(formTemplate: FormTemplateCreate) {
  dispatch(slice.actions.startLoading());
  try {
    await formTemplateService.createFormTemplate(formTemplate);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function updateFormTemplate(formTemplate: FormTemplateUpdate) {
  dispatch(slice.actions.startLoading());
  try {
    await formTemplateService.editFormTemplate(formTemplate);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function removeFormTemplate(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    await formTemplateService.deleteFormTemplate(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}
