import { FormElementError } from '../enums/FormElementError';
import { ErrorsListProps, ElementValidationProps } from '../types';

const validateIsEmail = ({ el, errorMessage, value }: ElementValidationProps) => {
  let newError: ErrorsListProps | null = null;
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const isEmail = value?.match(regex);

  if (!isEmail) {
    newError = {
      elementID: el.id,
      elementName: el.label,
      errorCode: FormElementError.InvalidEmail,
      errorMessage,
    };

    return newError;
  }

  return newError;
};

export default validateIsEmail;
