import React from 'react';

import { Box, Tooltip } from '@mui/material';

import TagLabel from '../../components/TagLabel';
import { DLXDriveTagType } from '../../@types/dlx-drive/types';

// ----------------------------------------------------------------------

type DLXDriveTagProps = {
  tag: DLXDriveTagType;
};

const DLXDriveTag: React.FC<DLXDriveTagProps> = ({ tag }) => {
  const { id, name, color } = tag;

  return (
    <TagLabel id={id.toString()} color={color}>
      <Tooltip title={name}>
        <Box component="span" style={{ overflow: 'hidden', lineHeight: 'initial' }}>
          {name}
        </Box>
      </Tooltip>
    </TagLabel>
  );
};

export default DLXDriveTag;
