import React from 'react';
import { useNavigate } from 'react-router';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import useNotifications from '../../../hooks/useNotifications';
import { deleteItems } from '../../../services/dlxDriveService';
import { DLXDriveBulkDeleteModelType } from '../../../@types/dlx-drive/types';
import {
  addStopLoadingState,
  setDriveListIdState,
  addStartLoadingState,
} from '../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveSingleDeleteDialogProps = {
  itemId: number;
  itemName: string;
  isFolder: boolean;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: (value: boolean) => void;
};

const DLXDriveSingleDeleteDialog: React.FC<DLXDriveSingleDeleteDialogProps> = ({
  itemId,
  itemName,
  isFolder,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading, breadcrumbs } = useSelector((state) => state.dlxDrive);
  const { enqueueSuccess } = useNotifications();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    let model: DLXDriveBulkDeleteModelType = {};

    if (isFolder) {
      model = {
        folderIds: [itemId],
        documentIds: [],
      };
    } else {
      model = {
        documentIds: [itemId],
        folderIds: [],
      };
    }

    try {
      await deleteItems(model);

      enqueueSuccess('dlxDrive.notifications.deletedSuccessfully', {
        item: isFolder ? 'Folder' : 'Document',
        itemName,
      });

      if (breadcrumbs[breadcrumbs.length - 1].id === itemId && isFolder) {
        addStartLoadingState();
        navigate(`/folders/${breadcrumbs[breadcrumbs.length - 2].id}/1`);
      } else {
        setDriveListIdState();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleteDialogOpen(false);
      addStopLoadingState();
    }
  };

  return (
    <Dialog open={isDeleteDialogOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>
        {isFolder
          ? t('dlxDrive.dialogs.deleteSingleFolder', { itemName })
          : t('dlxDrive.dialogs.deleteSingleDocument', { itemName })}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('dlxDrive.dialogs.deleteSingleInfoText', {
            item: isFolder ? 'folder' : 'document',
            itemName,
          })}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton loading={isLoading} variant="contained" onClick={handleDelete} id="yd0sown5">
          {`${t('shared.buttons.delete')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveSingleDeleteDialog;
