import React from 'react';

import { Box, Badge, useTheme } from '@mui/material';

import { CalendarDayProps } from '../../@types/calendar/types';
import CalendarDesktopBankHolidayTooltip from './CalendarDesktopBankHolidayTooltip';
import {
  CALENDAR_CELL_WIDTH,
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_BORDER_COLOR,
  CALENDAR_CELL_BORDER_STYLE,
  CALENDAR_CELL_BORDER_WIDTH,
  CALENDAR_HOLIDAY_CELL_BACKGROUND,
  CALENDAR_WEEKEND_CELL_BACKGROUND,
  CALENDAR_WORKDAY_CELL_BACKGROUND,
} from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopDayCellProps = {
  day: CalendarDayProps;
};

const CalendarDesktopDayCell: React.FC<CalendarDesktopDayCellProps> = ({ day }) => {
  const theme = useTheme();

  const { date, isHoliday, isWeekend, bankHoliday } = day;

  const today = new Date().toLocaleDateString('lt-LT');
  const isDayToday = today === date;

  const cellStyle = {
    width: `${CALENDAR_CELL_WIDTH}px`,
    maxWidth: `${CALENDAR_CELL_WIDTH}px`,
    minWidth: `${CALENDAR_CELL_WIDTH}px`,
    height: `${CALENDAR_CELL_HEIGHT / 2}px`,
    backgroundColor: isHoliday
      ? CALENDAR_HOLIDAY_CELL_BACKGROUND
      : isWeekend
        ? CALENDAR_WEEKEND_CELL_BACKGROUND
        : CALENDAR_WORKDAY_CELL_BACKGROUND,
    borderColor: CALENDAR_CELL_BORDER_COLOR,
    borderWidth: `${CALENDAR_CELL_BORDER_WIDTH}px`,
    borderStyle: CALENDAR_CELL_BORDER_STYLE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const dayNumber = date.split('-').slice(-1);

  return (
    <>
      {bankHoliday && bankHoliday.length === 0 && (
        <Box sx={{ ...cellStyle }}>
          {!isDayToday && dayNumber}

          {isDayToday && (
            <Box
              sx={{
                width: `${CALENDAR_CELL_HEIGHT / 2 - 4}px`,
                height: `${CALENDAR_CELL_HEIGHT / 2 - 4}px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100px',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
              }}
            >
              {dayNumber}
            </Box>
          )}
        </Box>
      )}

      {bankHoliday && bankHoliday.length > 0 && (
        <CalendarDesktopBankHolidayTooltip date={date} bankHoliday={bankHoliday}>
          <Box sx={{ ...cellStyle }}>
            {!isDayToday && (
              <Badge color="error" variant="dot">
                {dayNumber}
              </Badge>
            )}

            {isDayToday && (
              <Box
                sx={{
                  width: `${CALENDAR_CELL_HEIGHT / 2 - 4}px`,
                  height: `${CALENDAR_CELL_HEIGHT / 2 - 4}px`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '100px',
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                }}
              >
                <Badge color="error" variant="dot">
                  {dayNumber}
                </Badge>
              </Box>
            )}
          </Box>
        </CalendarDesktopBankHolidayTooltip>
      )}
    </>
  );
};

export default CalendarDesktopDayCell;
