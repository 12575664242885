import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { AccessGroupListItem, AccessGroupListState } from '../../@types/access-groups/types';

// ----------------------------------------------------------------------

const initialState: AccessGroupListState = {
  accessGroupList: [],
  reloadAccessGroupListState: 0,
};

const slice = createSlice({
  name: 'accessGroup',
  initialState,
  reducers: {
    setAccessGroupList(state, action) {
      state.accessGroupList = action.payload;
    },

    setReloadAccessGroupListState(state) {
      state.reloadAccessGroupListState += 1;
    },
  },
});

export const setAccessGroupList = (list: AccessGroupListItem[]) => {
  dispatch(slice.actions.setAccessGroupList(list));
};

export const setReloadAccessGroupListState = () => {
  dispatch(slice.actions.setReloadAccessGroupListState());
};

export default slice.reducer;
