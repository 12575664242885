// NEW FORM BUILDER COMPONENT

import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Form, useFormik, FormikProvider } from 'formik';
import React, { useRef, useState, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Card,
  Link,
  Button,
  TextField,
  IconButton,
  Typography,
  CardContent,
  Autocomplete,
} from '@mui/material';

import { Tag } from 'src/@types/tags/tag';

import TagSelectionPanel from 'src/sections/shared/tags-selection-panel/TagSelectionPanel';

import generateBlankPDF from './generateBlankPDF';
import useLocales from '../../../hooks/useLocales';
import { PATH_ADMIN } from '../../../routes/paths';
import useResponsive from '../../../hooks/useResponsive';
import useAppSettings from '../../../hooks/useAppSettings';
import TypeAddEditDialog from '../shared/TypeAddEditDialog';
import { getBase64FromFile } from '../../../utils/fileUtils';
import useNotifications from '../../../hooks/useNotifications';
import FormsUploadTemplateFile from './FormsUploadTemplateFile';
import { FormApprovalStepProps } from '../../../@types/forms/types';
import { ModuleTypes } from '../../../@types/module/enums/moduleTypes';
import { createForm, getFormTypes } from '../../../services/formService';
import { DocumentModuleTypes } from '../../../@types/shared/enums/documentModuleTypes';
import {
  pageFormats,
  PageFormatProps,
  pageOrientation,
  PageOrientationProps,
} from './generatePageProperties';

// ----------------------------------------------------------------------

export type ModuleProps = {
  formModuleType: number;
  moduleTitle: string;
};

export type TypeProps = {
  id?: number;
  moduleType: number;
  nameEN: string;
  nameLT: string;
  nameLV: string;
  nameEE: string;
  nameRU: string;
};

export type FormProps = {
  id?: number;
  name: string;
  formModuleType: number;
  formTypeId: number;
  approvalInDoclogix: boolean;
  managerApproval: boolean;
  ceoApproval: boolean;
  doclogixCoreProfileId: number;
  doclogixCoreFileId: number;
  file: string;
  fileName: string;
  fileExtension: string;
  relatedFormTemplates: number[];
  departmentIds: number[];
  structure?: string;
  tags?: Tag[] | number[] | [];
  sortWeight: number;
  mailGetterEmails: string[];
  defaultFormTemplate: boolean;
  approvalSteps: FormApprovalStepProps[];
};

type FormsCreateNewFormProps = {
  titleLocale: string;
};

const FormsCreateNewForm: React.FC<FormsCreateNewFormProps> = ({ titleLocale }) => {
  const { t, currentLang } = useLocales();
  const { enqueueSuccess } = useNotifications();
  const modulesData = useAppSettings();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useResponsive('down', 'lg');
  const [selectedTab, setSelectedTab] = useState('1');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [availableModules, setAvailableModules] = useState<ModuleProps[]>([]);
  const [availableTypes, setAvailableTypes] = useState<TypeProps[]>([]);
  const [selectedModule, setSelectedModule] = useState<ModuleProps | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [bytes, setBytes] = useState<string>('');
  const [generatePageFormat, setGeneratePageFormat] = useState<PageFormatProps>(pageFormats[0]);
  const [generatePageOrientation, setGeneratePageOrientation] = useState<PageOrientationProps>(
    pageOrientation[0]
  );
  const [generatePageCount, setGeneratePageCount] = useState(1);
  const [viewerWrapperWidth, setViewerWrapperWidth] = useState(300);
  const navigate = useNavigate();
  const viewerWrapper = useRef<HTMLElement | null>(null);
  const [formTags, setFormTags] = useState<Tag[]>([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('shared.errors.nameRequired')),
    formModuleType: Yup.number()
      .min(0, t('shared.errors.moduleRequired'))
      .required(t('shared.errors.moduleRequired')),
    formTypeId: Yup.number()
      .min(0, t('shared.errors.typeRequired'))
      .required(t('shared.errors.typeRequired')),
    approvalInDoclogix: Yup.boolean(),
    managerApproval: Yup.boolean(),
    ceoApproval: Yup.boolean(),
    doclogixCoreProfileId: Yup.number(),
    doclogixCoreFileId: Yup.number(),
    file: Yup.string(),
    fileName: Yup.string(),
    fileExtension: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      formModuleType: -1,
      formTypeId: -1,
      approvalInDoclogix: false,
      managerApproval: false,
      ceoApproval: false,
      doclogixCoreProfileId: -1,
      doclogixCoreFileId: -1,
      file: '',
      fileName: '',
      fileExtension: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const sendTags = formTags.map((t) => t.id);

      const model = {
        name: values.name,
        formModuleType: values.formModuleType,
        formTypeId: values.formTypeId,
        approvalInDoclogix: values.approvalInDoclogix,
        managerApproval: values.managerApproval,
        ceoApproval: values.ceoApproval,
        doclogixCoreProfileId: values.doclogixCoreProfileId,
        doclogixCoreFileId: values.doclogixCoreFileId,
        file: values.file,
        fileName: values.fileName,
        fileExtension: values.fileExtension,
        tags: sendTags,
      } as FormProps;

      try {
        setIsLoading(true);
        const formId = await createForm(model);

        enqueueSuccess(t('forms.snackbar.formCreated'));
        setSubmitting(false);
        navigate(PATH_ADMIN.formsEdit(formId));
      } catch (error) {
        setSubmitting(false);
        setErrors(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getAvailableModules = () => {
    const modules: ModuleProps[] = [];

    if (modulesData.modules.includes(ModuleTypes.HR)) {
      modules.push({
        formModuleType: DocumentModuleTypes.HR,
        moduleTitle: t('formTemplates.modules.hr'),
      });
    }

    if (modulesData.modules.includes(ModuleTypes.Accounting)) {
      modules.push({
        formModuleType: DocumentModuleTypes.Accounting,
        moduleTitle: t('formTemplates.modules.accounting'),
      });
    }

    setAvailableModules(modules);
  };

  const getAvailableTypes = async () => {
    const module = selectedModule?.formModuleType;

    if (module !== undefined) {
      const newAvailableTypes = await getFormTypes(module);

      setAvailableTypes(newAvailableTypes);
    }
  };

  const moduleProps = {
    options: availableModules,
    getOptionLabel: (option: ModuleProps) => option.moduleTitle,
  };

  const typeProps = {
    options: availableTypes,
    getOptionLabel: (option: TypeProps) => {
      if (currentLang.value === 'en') {
        return option.nameEN;
      }

      if (currentLang.value === 'lt') {
        return option.nameLT;
      }

      if (currentLang.value === 'lv') {
        return option.nameLV;
      }

      if (currentLang.value === 'ru') {
        return option.nameRU;
      }

      if (currentLang.value === 'ee') {
        return option.nameEE;
      }

      return '';
    },
  };

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleOnCloseDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleOnSaveDialog = () => {
    getAvailableTypes().then();
    handleOnCloseDialog();
  };

  const handlePageCountChange = (page: number) => {
    setGeneratePageCount(generatePageCount + page);
  };

  const handleGeneratePDF = async () => {
    setSelectedTab('1');
    const blankPDF = await generateBlankPDF(
      generatePageFormat,
      generatePageOrientation,
      generatePageCount
    );
    const file = new File([blankPDF], `generated-pdf-${Date.now()}`, {
      type: 'application/pdf',
      lastModified: Date.now(),
    });

    setFile(file);
  };

  const getFileBytes = async () => {
    if (file !== null) {
      const bytes = await getBase64FromFile(file);
      setBytes(bytes);
    }
  };

  useEffect(() => {
    getAvailableTypes().then();
  }, [selectedModule]);

  useEffect(() => {
    getFileBytes().then();
  }, [file]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      file: bytes,
      fileName: file?.name ? file.name : '',
      fileExtension: file?.type.split('/')[1] ? file.type : '',
    });
  }, [bytes]);
  useEffect(() => {
    getAvailableModules();

    if (viewerWrapper.current) {
      setViewerWrapperWidth(viewerWrapper.current.offsetWidth - 48);
    }
  }, []);

  const SubmitButton = () => (
    <Box sx={{ width: '100%' }}>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={!file}
        size="medium"
        id="yd0sown5"
      >
        {t('forms.buttons.save')}
      </LoadingButton>
    </Box>
  );

  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              justifyContent: 'space-between',
              gap: { xs: 2, lg: 3 },
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Card>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Typography variant="h5">{t(titleLocale)}</Typography>
                  <TextField
                    label={t('formTemplates.writeName')}
                    id="template-name"
                    {...formik.getFieldProps('name')}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    size="small"
                  />

                  <Autocomplete
                    {...moduleProps}
                    id="select-module"
                    onChange={(_, value) => {
                      if (value !== undefined && value !== null) {
                        setSelectedModule(value);
                      }

                      formik.setFieldValue('formModuleType', value?.formModuleType);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t('formTemplates.selectModule')}
                        {...formik.getFieldProps('formModuleType')}
                        {...params}
                        error={Boolean(
                          formik.touched.formModuleType && formik.errors.formModuleType
                        )}
                        helperText={formik.touched.formModuleType && formik.errors.formModuleType}
                        id="skt2dh36"
                      />
                    )}
                    size="small"
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <Autocomplete
                      sx={{ width: '100%' }}
                      disabled={availableTypes.length === 0}
                      {...typeProps}
                      onChange={(_, value) => {
                        formik.setFieldValue('formTypeId', value?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label={t('formTemplates.selectType')}
                          {...formik.getFieldProps('formTypeId')}
                          {...params}
                          error={Boolean(formik.touched.formTypeId && formik.errors.formTypeId)}
                          helperText={formik.touched.formTypeId && formik.errors.formTypeId}
                          id="n5aphqbu"
                        />
                      )}
                      size="small"
                    />

                    <Link
                      sx={{ minWidth: 'max-content', p: 2 }}
                      component="button"
                      variant="body1"
                      onClick={(event) => {
                        event.preventDefault();
                        handleOnCloseDialog();
                      }}
                      id="8hgwfjqn"
                    >
                      {t('forms.buttons.addAnotherType')}
                    </Link>
                  </Box>

                  <TagSelectionPanel tags={formTags} setTags={setFormTags} />
                </CardContent>
              </Card>

              {!isMobile && <SubmitButton />}
            </Box>

            <Box sx={{ width: '100%' }}>
              <TabContext value={selectedTab}>
                <TabList onChange={handleChange}>
                  <Tab label={t('formTemplates.upload')} value="1" data-testid="j545mgwf" />
                  <Tab label={t('formTemplates.generatePDF')} value="2" data-testid="29ydasls" />
                </TabList>

                <TabPanel ref={viewerWrapper} value="1" sx={{ mt: 3 }}>
                  <FormsUploadTemplateFile
                    fileState={file}
                    getFile={(file) => setFile(file)}
                    viewerWrapperWidth={viewerWrapperWidth}
                  />
                </TabPanel>

                <TabPanel value="2">
                  <Card sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 3, p: 3 }}>
                    <Autocomplete
                      id="select-format"
                      sx={{ width: '100%' }}
                      value={generatePageFormat}
                      options={pageFormats}
                      getOptionLabel={(option) => option.label}
                      onChange={(_, value) => {
                        if (value !== undefined && value !== null) {
                          setGeneratePageFormat(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('formTemplates.selectFormat')}
                          id="rj05cs0g"
                        />
                      )}
                      size="small"
                    />

                    <Autocomplete
                      id="orientation"
                      sx={{ width: '100%' }}
                      value={generatePageOrientation}
                      options={pageOrientation}
                      getOptionLabel={(option) => option.label}
                      onChange={(_, value) => {
                        if (value !== undefined && value !== null) {
                          setGeneratePageOrientation(value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('formTemplates.orientation')}
                          id="r4zqby9g"
                        />
                      )}
                      size="small"
                    />

                    <Typography variant="subtitle2">{t('formTemplates.numberOfPages')}</Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        gap: 1,
                        mt: -2,
                      }}
                    >
                      <IconButton
                        disabled={generatePageCount === 1}
                        size="small"
                        onClick={() => handlePageCountChange(-1)}
                        id="sbkyd1zb"
                      >
                        <RemoveIcon fontSize="inherit" />
                      </IconButton>

                      <Typography>{generatePageCount}</Typography>

                      <IconButton
                        size="small"
                        onClick={() => handlePageCountChange(1)}
                        id="ky3dlo7n"
                      >
                        <AddIcon fontSize="inherit" />
                      </IconButton>
                    </Box>

                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleGeneratePDF}
                      id="3gh1wopl"
                    >
                      {t('forms.buttons.generate')}
                    </Button>
                  </Card>
                </TabPanel>
              </TabContext>
            </Box>

            {isMobile && <SubmitButton />}
          </Box>
        </Form>
      </FormikProvider>

      <TypeAddEditDialog
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveDialog}
        formId={-1}
        isTypeAddEditDialogOpen={isDialogOpen}
      />
    </Box>
  );
};

export default FormsCreateNewForm;
