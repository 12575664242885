export enum FormBlankPageFormat {
  A0 = 0,
  A1 = 1,
  A2 = 2,
  A3 = 3,
  A4 = 4,
  A5 = 5,
  A6 = 6,
  A7 = 7,
  A8 = 8,
}
