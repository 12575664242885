import React from 'react';

import { Box } from '@mui/material';

import { CalendarEventProps } from '../../@types/calendar/types';
import { CALENDAR_EVENT_BORDER_RADIUS } from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopEventComponentProps = {
  event: CalendarEventProps;
};

const CalendarDesktopEventBar: React.FC<CalendarDesktopEventComponentProps> = ({ event }) => {
  const { approved, start, end, eventTypeColor } = event;

  const eventStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc(100% + 1px)',
    height: 'inherit',
    background: approved
      ? `${eventTypeColor}`
      : `repeating-linear-gradient(-45deg, white 0, ${eventTypeColor} 2px, white 3px)`,
    borderTopLeftRadius: start ? CALENDAR_EVENT_BORDER_RADIUS : 0,
    borderBottomLeftRadius: start ? CALENDAR_EVENT_BORDER_RADIUS : 0,
    borderTopRightRadius: end ? CALENDAR_EVENT_BORDER_RADIUS : 0,
    borderBottomRightRadius: end ? CALENDAR_EVENT_BORDER_RADIUS : 0,
    opacity: approved ? 1 : 0.6,
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box sx={{ ...eventStyle }} />
    </Box>
  );
};

export default CalendarDesktopEventBar;
