import { mapJsonArray } from 'src/utils/mapUtils';

import { Role } from '../rolePermissions/role';

export class User {
  id: string;

  email: string;

  roles: Role[];

  isDeactivated: boolean;

  userProfileImage: {
    extension: string;
    image: string;
    name: string;
  } | null;

  firstName: string;

  lastName: string;

  parseJson(json: any): User | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: User = new User();

    model.id = json.id;
    model.email = json.email;
    model.roles = mapJsonArray(json.roles, Role);
    model.isDeactivated = json.isDeactivated;
    model.firstName = json.firstName;
    model.lastName = json.lastName;
    model.userProfileImage = json.userProfileImage;

    return model;
  }
}
