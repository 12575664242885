import React, { useRef, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField, InputAdornment } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------

type DLXDriveSearchBarProps = {
  setSearchQuery: (value: string | null) => void;
};

const DLXDriveSearchBar: React.FC<DLXDriveSearchBarProps> = ({ setSearchQuery }) => {
  const { t } = useLocales();
  const { reloadSearch } = useSelector((state) => state.dlxDrive);
  const searchInp = useRef<HTMLDivElement | null>(null);

  let timer: ReturnType<typeof setTimeout>;
  const waitTime: number = 300;

  const handleSearchInputChange = (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    if (searchInp.current) {
      searchInp.current.addEventListener('keyup', (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        const text = target.value;

        clearTimeout(timer);

        timer = setTimeout(() => {
          handleSearchInputChange(text);
        }, waitTime);
      });
    }
  }, [searchInp.current]);

  return (
    <Box flexGrow={1} sx={{ width: '100%' }}>
      <TextField
        ref={searchInp}
        key={reloadSearch}
        sx={{ width: '100%' }}
        size="small"
        variant="outlined"
        placeholder={`${t('shared.fields.search')}...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DLXDriveSearchBar;
