import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import {
  DLXDriveItem,
  DLXDriveState,
  DLXDriveSelectedItem,
  DLXDriveSelectedFolder,
  DLXDriveBreadcrumbsType,
} from '../../@types/dlx-drive/types';

const initialState: DLXDriveState = {
  isLoading: false,
  checkedItems: [],
  breadcrumbs: [],
  children: [],
  selectedFolder: { id: -1, name: '', prefix: undefined, actions: [], color: '', icon: '' },
  reloadDriveList: 1,
  reloadFillForm: 1,
  reloadNavigation: 1,
  reloadSearch: 1,
  pinnedFolderIds: [],
  cardWidth: -1,
};

const slice = createSlice({
  name: 'dlxDrive',
  initialState,
  reducers: {
    clearCheckedItemsState(state) {
      state.checkedItems = initialState.checkedItems;
    },

    addCheckedItem(state, action) {
      state.checkedItems.push(action.payload);
    },

    removeCheckedItem(state, action) {
      state.checkedItems = state.checkedItems.filter((item) => item.id !== action.payload.id);
    },

    setCheckedItems(state, action) {
      state.checkedItems = action.payload;
    },

    addPinnedFolderIds(state, action) {
      state.pinnedFolderIds = action.payload;
    },

    addBreadcrumbs(state, action) {
      state.breadcrumbs = action.payload;
    },

    addChildren(state, action) {
      state.children = action.payload;
    },

    setReloadDriveList(state) {
      state.reloadDriveList += 1;
    },

    setReloadFillForm(state) {
      state.reloadFillForm += 1;
    },

    setReloadNavigation(state) {
      state.reloadNavigation += 1;
    },

    setReloadSearch(state) {
      state.reloadSearch += 1;
    },

    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    setCardWidth(state, action) {
      state.cardWidth = action.payload;
    },
  },
});

export const addItemState = (item: DLXDriveSelectedItem) => {
  dispatch(slice.actions.addCheckedItem(item));
};

export const removeItemState = (item: DLXDriveSelectedItem) => {
  dispatch(slice.actions.removeCheckedItem(item));
};

export const setCheckedItemsState = (items: DLXDriveSelectedItem[]) => {
  dispatch(slice.actions.setCheckedItems(items));
};

export const addBreadcrumbsState = (breadcrumbs: DLXDriveBreadcrumbsType[]) => {
  dispatch(slice.actions.addBreadcrumbs(breadcrumbs));
};

export const addChildrenState = (items: DLXDriveItem[]) => {
  dispatch(slice.actions.addChildren(items));
};

export const clearCheckedItemsState = () => {
  dispatch(slice.actions.clearCheckedItemsState());
};

export const addPinnedFolders = (ids: number[]) => {
  dispatch(slice.actions.addPinnedFolderIds(ids));
};

export const addSelectedFolderState = (folder: DLXDriveSelectedFolder) => {
  dispatch(slice.actions.setSelectedFolder(folder));
};

export const addStartLoadingState = () => {
  dispatch(slice.actions.startLoading());
};

export const addStopLoadingState = () => {
  dispatch(slice.actions.stopLoading());
};

export const setDriveListIdState = () => {
  dispatch(slice.actions.setReloadDriveList());
};

export const setFillFormState = () => {
  dispatch(slice.actions.setReloadFillForm());
};

export const setNavigationState = () => {
  dispatch(slice.actions.setReloadNavigation());
};

export const setSearchState = () => {
  dispatch(slice.actions.setReloadSearch());
};

export const setCardWidthState = (width: number) => {
  dispatch(slice.actions.setCardWidth(width));
};

export default slice.reducer;
