import React from 'react';

import { Box, Card, Button, Typography, LinearProgress } from '@mui/material';

import { PATH_ADOC } from 'src/routes/paths';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';

import palette from 'src/theme/palette';
import { useSelector } from 'src/redux/store';

import MyAvatar from 'src/components/MyAvatar';

const UserPlanB2C = () => {
  const { t } = useLocales();
  // For now an user has 5 free signatues so 5 = 100%
  // 1 signature = 20%
  const freeSignaturesAsPercentage = 20;
  const { freeSignatures } = useSelector((state) => state.user);
  const { user } = useAuth();

  const handlePlanBtnClick = () => {
    window.open(PATH_ADOC, '_blank');
  };

  return (
    <Card sx={{ p: 1 }}>
      <Box
        sx={{
          width: '100%',
          py: 1,
          px: 2,
          flexShrink: 0,
          borderRadius: 1.5,
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <MyAvatar />
        <Typography variant="body2" sx={{ fontWeight: 600, pb: 1 }}>
          {`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 2, pb: 1 }}>
        <Typography variant="caption">
          {t('layout.userPlanCard.plan')}: {t('layout.userPlanCard.free')}
        </Typography>
        <Typography variant="caption" sx={{ mb: 1 }}>
          {t('layout.userPlanCard.signaturesLeft', { sLeft: freeSignatures })}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(freeSignatures ?? 0) * freeSignaturesAsPercentage}
          sx={{ width: '90%' }}
        />
      </Box>
      <Button
        onClick={handlePlanBtnClick}
        variant="contained"
        color="inherit"
        sx={{
          my: 1,
          mx: 2,
          bgcolor: palette.light.grey['600'],
          color: palette.light.text.contrast,
        }}
        id="jH08Za"
      >
        {t('layout.userPlanCard.buttons.upgradeYourPlan')}
      </Button>
    </Card>
  );
};

export default UserPlanB2C;
