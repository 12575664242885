import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

// ----------------------------------------------------------------

const CalendarDesktopLayoutItem: React.FC = () => {
  const events: number[] = new Array(3).fill(0);

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        {events.map((_, i) => (
          <Box key="" sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant="rounded" height={30} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 2,
          mt: 3,
        }}
      >
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Skeleton variant="circular" width={20} height={20} />

          <Typography variant="h5" sx={{ width: '100%' }}>
            <Skeleton />
          </Typography>
        </Box>

        <Box
          sx={{
            width: '30%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Skeleton variant="circular" width={20} height={20} />

          <Typography variant="h5" sx={{ width: '100%' }}>
            <Skeleton />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CalendarDesktopLayoutItem;
