import { createSlice } from '@reduxjs/toolkit';

import { HOST_API } from 'src/config';
import { Form } from 'src/@types/forms/form';
import { FormsFilter } from 'src/@types/forms/formsFilter';
import { FormEdit, FormState } from 'src/@types/forms/types';
import { PagedResponse } from 'src/@types/shared/pagedResponse';

import { dispatch } from '../store';
// utils
import axios from '../../utils/httpClients/axios';

// ----------------------------------------------------------------------

const initialState: FormState = {
  isLoading: false,
  error: null,
  forms: null,
  file: null,
  selectedId: null,
  form: null,
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.forms = initialState.forms;
      state.file = initialState.file;
      state.selectedId = initialState.selectedId;
      state.form = initialState.form;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFormsSuccess(state, action) {
      state.isLoading = false;
      state.forms = action.payload;
    },

    getFormSuccess(state, action) {
      state.isLoading = false;
      state.selectedId = action.payload.id;
      state.form = action.payload;
    },

    getFormsError(state, action) {
      state.isLoading = false;
      state.forms = initialState.forms;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getPagedForms(filter: FormsFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<PagedResponse<Form>>(`${HOST_API  }forms`, { params: { ...filter } });

    const data = {
      total: response.data.total,
      items: response.data.items.map((item) => new Form().parseJson(item)),
    };

    dispatch(slice.actions.getFormsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getFormsError(error));
  }
}

export function clearFormsState() {
  dispatch(slice.actions.clearState());
}

export async function getForm(formTemplateId: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get<Form>(`${HOST_API  }forms/${formTemplateId}`);
    dispatch(slice.actions.getFormSuccess(new Form().parseJson(response.data)));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function editForm(form: FormEdit) {
  dispatch(slice.actions.startLoading());
  try {
    await axios.put(`${HOST_API  }forms/${form.formTemplateId}`, form);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}
