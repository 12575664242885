import React, { useRef, useState, ReactNode, useEffect } from 'react';
import { Rnd, Position, DraggableData, ResizableDelta } from 'react-rnd';

import { Box } from '@mui/system';
import { Chip, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { ElementProps } from '../../../@types/forms/types';

type ElementWrapperProps = {
  children: ReactNode;
  element: ElementProps;
  pageNumberScale: number;
  isDialogOpen: boolean;
  handleSettingsButtonClick: (element: ElementProps) => void;
  handleDragStart: (id: string) => void;
  handleDragStop: (id: string, d: DraggableData) => void;
  handleResizeStart: (id: string) => void;
  handleResizeStop: (id: string, delta: ResizableDelta, position: Position) => void;
  handleDeleteElement: (id: string) => void;
};

const ElementWrapper: React.FC<ElementWrapperProps> = ({
  children,
  element,
  pageNumberScale,
  isDialogOpen,
  handleSettingsButtonClick,
  handleDragStart,
  handleDragStop,
  handleResizeStart,
  handleResizeStop,
  handleDeleteElement,
}) => {
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const [isDragResize, setIsDragResize] = useState<boolean>(false);
  const [dividerHeight, setDividerHeight] = useState<number>(0);
  const [rndHeight, setRndHeight] = useState<number>(element.height * pageNumberScale);

  const dividerRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const ELEMENT_LABEL_FONT_SIZE = 8;
  const RAY_LENGTH = 9999;

  useEffect(() => {
    setDividerHeight(dividerRef.current.clientHeight);
  }, [dividerRef]);

  useEffect(() => {
    if (!isDialogOpen) {
      setRndHeight(containerRef.current.clientHeight);
    }
  }, [isDialogOpen]);

  return (
    <Rnd
      bounds='parent'
      enableResizing={element.enableResizing}
      position={{ x: element.x * pageNumberScale, y: element.y * pageNumberScale }}
      size={{ width: element.width * pageNumberScale, height: rndHeight }}
      onDragStart={() => {
        handleDragStart(element.id);
        setIsDragResize(true);
      }}
      onDragStop={(e, d) => {
        handleDragStop(element.id, d);
        setIsDragResize(false);
      }}
      onResizeStart={() => {
        handleResizeStart(element.id);
        setIsDragResize(true);
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleResizeStop(element.id, delta, position);
        setIsDragResize(false);
      }}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
    >
      <Box ref={containerRef} sx={{ position: 'relative', width: '100%', minHeight: `${element.fontSize * pageNumberScale}px` }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: isMouseOver ? 'flex' : 'none',
            justifyContent: 'right',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            border: isMouseOver && !isDragResize ? '0.5px solid rgba(99, 115, 129, 0.75)' : '',
            backgroundColor: isMouseOver && !isDragResize ? 'rgba(255,255,255,0.5)' : '',
          }}
        />

        {/* Horizontal/Top auxiliary line */}
        <Box
          display={isDragResize ? 'block' : 'none'}
          sx={{
            position: 'absolute',
            top: 0,
            left: -RAY_LENGTH / 2,
            width: RAY_LENGTH,
            height: '0.5px',
            borderTop: '0.5px solid rgba(99, 115, 129, 0.75)',
          }}
        />

        {/* Horizontal/Bottom auxiliary line */}
        <Box
          display={isDragResize ? 'block' : 'none'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: -RAY_LENGTH / 2,
            width: RAY_LENGTH,
            height: '0.5px',
            borderTop: '0.5px solid rgba(99, 115, 129, 0.75)',
          }}
        />

        {/* Vertical/Left auxiliary line */}
        <Box
          display={isDragResize ? 'block' : 'none'}
          sx={{
            position: 'absolute',
            top: -RAY_LENGTH / 2,
            left: 0,
            width: '0.5px',
            height: RAY_LENGTH,
            borderLeft: '0.5px solid rgba(99, 115, 129, 0.75)',
          }}
        />

        {/* Vertical/Right auxiliary line */}
        <Box
          display={isDragResize ? 'block' : 'none'}
          sx={{
            position: 'absolute',
            top: -RAY_LENGTH / 2,
            right: 0,
            width: '0.5px',
            height: RAY_LENGTH,
            borderLeft: '0.5px solid rgba(99, 115, 129, 0.75)',
          }}
        />

        {children}
      </Box>

      <Divider
        ref={dividerRef}
        sx={{
          '&::before, &::after': {
            borderColor: 'rgba(99, 115, 129, 0.75)',
          },
          position: 'absolute',
          top: dividerHeight * -1 - 6,
          left: 0,
          width: '100%',
          zIndex: 10,
          // display: !isDragResize ? 'block' : 'none',
        }}
      >
        {!isMobile && (
          <Chip
            label={element.label}
            sx={{
              fontSize: `${ELEMENT_LABEL_FONT_SIZE * pageNumberScale}px`,
              zIndex: 30,
              overflow: 'hidden',
              maxWidth: `${element.width}px`,
            }}
            variant='filled'
            color={isMouseOver ? 'primary' : 'default'}
            size='small'
            icon={<SettingsIcon onClick={() => handleSettingsButtonClick(element)} sx={{ cursor: 'pointer' }} />}
            onDelete={() => handleDeleteElement(element.id)}
          />
        )}

        {isMobile && (
          <Chip
            label={element.label}
            sx={{
              fontSize: `${ELEMENT_LABEL_FONT_SIZE * pageNumberScale}px`,
              zIndex: 30,
              overflow: 'hidden',
              maxWidth: `${element.width}px`,
            }}
            variant='filled'
            color={isMouseOver ? 'primary' : 'default'}
            size='small'
            icon={<SettingsIcon onTouchStart={() => handleSettingsButtonClick(element)} />}
            onTouchStart={() => setIsMouseOver(true)}
            onTouchEnd={() => setIsMouseOver(false)}
          />
        )}
      </Divider>
    </Rnd>
  );
};

export default ElementWrapper;
