import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }

  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }

  interface PaletteColor {
    lighter: string;
    darker: string;
  }

  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }

  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    contrast: string;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
// Old colors
// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
// };
const PRIMARY = {
  lighter: '#ffe6de',
  light: '#f48e6d',
  main: '#E8683E', // '#F26131', // '#D65C34', // fe612c
  dark: '#B63F17',
  darker: '#7D2203',
};
const SECONDARY = {
  lighter: '#DEE9F4', // '#C5DEFF', // '#dee9f4'
  light: '#799BC7',
  main: '#486284',
  dark: '#2D496F',
  darker: '#1E3046', // '#1D3451', // '#213A59',
};
const INFO = {
  lighter: alpha('#D0F2FF', 0.7),
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#5CD196', // '#AAF27F',
  main: '#5CC53C', // '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF', // '#FFFFFF',
  100: '#F3F5F8', // '#F9FAFB',
  200: '#F0F2F5', // '#F4F6F8',
  300: '#D5DDE7', // '#E1E7EE', // '#DFE3E8',
  400: '#B4C4D9', // '#A8BED9', // '#B4C1D0', // '#C2CBD6', // '#C4CDD5',
  500: '#859CB8', // '#90A3BA', // '#94A4B8', //  '#919EAB', // '#919EAB',
  600: '#607A9B', // '#667D99', // '#637381',
  700: '#43556B', // '#455568', //  '#454F5B',
  800: '#303A46', // '#29323D', // '#212B36',
  900: '#1F252E', // '#161C24',
  500_8: alpha('#486284', 0.08),
  500_12: alpha('#486284', 0.12),
  500_16: alpha('#486284', 0.16),
  500_24: alpha('#486284', 0.24),
  500_32: alpha('#486284', 0.32),
  500_48: alpha('#486284', 0.48),
  500_56: alpha('#486284', 0.56),
  500_80: alpha('#486284', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#6C4592', '#4F3866', '#D0AEFF', '#F7D2FF'],
  blue: [ '#799BC7', '#5e7998', '#2D496F', '#CCFAFF'],
  green: ['#255777', '#1D4A66', '#A4F7CC', '#C0F2DC'],
  yellow: ['#E8683E', '#B63F17', '#F26131', '#D95529'],
  red: ['#AC4183', '#7C3260', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: GREY[900], white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], contrast: GREY[0] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: GREY[200], secondary: GREY[500], disabled: GREY[600], contrast: GREY[0] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
