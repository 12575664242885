import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import { MobileDatePicker } from '@mui/x-date-pickers';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Box, TextField, IconButton, TextFieldProps } from '@mui/material';

import { urlParamsProps } from '../../@types/calendar/types';
import { SystemLocales } from '../../locales/forms/SystemLocales';
import { CalendarDateRangeType } from '../../@types/calendar/enums/CalendarDateRangeType';

// ----------------------------------------------------------------------

type CalendarMobileDateFilterProps = {
  urlParams: urlParamsProps;
  setSearchParams: (params: urlParamsProps) => void;
};

const CustomDateField = (params: TextFieldProps) => (
  <TextField {...params} inputProps={{ style: { textAlign: 'center' } }} variant="standard" />
);

const CalendarMobileDateFilter: React.FC<CalendarMobileDateFilterProps> = ({
  urlParams,
  setSearchParams,
}) => {
  const [currentDate, setCurrentDate] = useState<Dayjs | null>(null);
  const [nextDate, setNextDate] = useState<string>('');
  const [prevDate, setPrevDate] = useState<string>('');

  const handleDayChange = (date: string) => {
    const newURLParams = { ...urlParams };

    newURLParams.date = date;
    newURLParams.range = `${CalendarDateRangeType.OneDay}`;

    setSearchParams({ ...newURLParams });
  };

  useEffect(() => {
    if (urlParams.date && urlParams.range) {
      const { date } = urlParams;

      setCurrentDate(dayjs(date));

      // Next date
      const newNextDate = new Date(date);
      newNextDate.setDate(newNextDate.getDate() + 1).toLocaleString(SystemLocales.lt);

      setNextDate(newNextDate.toLocaleString(SystemLocales.lt).split(' ')[0]);

      // Prev date
      const newPrevDate = new Date(date);
      newPrevDate.setDate(newPrevDate.getDate() - 1);

      setPrevDate(newPrevDate.toLocaleString(SystemLocales.lt).split(' ')[0]);
    }
  }, [urlParams]);

  useEffect(() => {
    if (currentDate) {
      const newDate = new Date(currentDate.toDate());

      handleDayChange(newDate.toLocaleString(SystemLocales.lt).split(' ')[0]);
    }
  }, [currentDate]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <IconButton id="calendar-next-day" size="small" onClick={() => handleDayChange(prevDate)}>
        <NavigateBeforeIcon />
      </IconButton>

      <MobileDatePicker
        value={currentDate}
        onChange={(newValue) => setCurrentDate(newValue)}
        closeOnSelect
        slots={{ textField: CustomDateField }}
      />

      <IconButton id="calendar-prev-day" size="small" onClick={() => handleDayChange(nextDate)}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default CalendarMobileDateFilter;
