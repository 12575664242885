import { AxiosResponse } from 'axios';

import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { HOST_API } from 'src/config';
import { Form } from 'src/@types/forms/form';
import { FormsFilter } from 'src/@types/forms/formsFilter';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';
import {
  FormEdit,
  FormItem,
  FormCopyProps,
  FormColumnGroup,
  FormItemsFilter,
  DLXDocumentGenerationProps,
} from 'src/@types/forms/types';

import { setStartLoading, setFinishLoading } from '../redux/slices/formBuilder';
import { FormProps, TypeProps } from '../sections/forms/create-form/FormsCreateNewForm';

// ----------------------------------------------------------------------

const baseUrl = `${HOST_API}forms`;

async function getPagedForms(filter: FormsFilter): Promise<PagedResponse<Form>> {
  const response = await axios.get<PagedResponse<Form>>(baseUrl, { params: { ...filter } });

  const data = {
    total: response.data.total,
    items: mapJsonArray(response.data.items, Form),
  };

  return data;
}

async function getItems(filter: FormItemsFilter): Promise<FormItem[]> {
  const response = await axios.get<FormItem[]>(`${baseUrl}/items`, { params: { ...filter } });
  return response.data;
}

async function getForm(formTemplateId: number) {
  const response = await axios.get<Form>(`${baseUrl}/${formTemplateId}`);
  return new Form().parseJson(response.data);
}

async function editForm(model: FormEdit) {
  await axios.put(`${baseUrl}/${model.formTemplateId}`, { ...model });
}

async function getFields(moduleType: DocumentModuleTypes): Promise<FormColumnGroup[]> {
  const response = await axios.get<FormColumnGroup[]>(`${baseUrl}/fields`, {
    params: { moduleType },
  });
  const fbResult = await getFormBuilderFields(moduleType);
  return [...response.data, ...fbResult];
}

// NEW FORM BUILDER SERVICE

const formsBaseUrl = HOST_API;

export const getFormTypes = async (module: number) => {
  let response: AxiosResponse<any>;

  if (module === -1) {
    response = await axios.get(`${formsBaseUrl}FormBuilder/type/list`, { params: { all: true } });
  } else {
    response = await axios.get(`${formsBaseUrl}FormBuilder/type/list`, {
      params: { type: module },
    });
  }

  return response.data;
};

export const createType = async (model: TypeProps) => {
  setStartLoading;

  try {
    await axios.post(`${formsBaseUrl}FormBuilder/type/create`, model);
  } catch (error) {
    console.error(error);
  } finally {
    setFinishLoading();
  }
};

export const updateType = async (id: number, model: TypeProps) => {
  setStartLoading;

  try {
    await axios.put(`${formsBaseUrl}FormBuilder/type/update`, { ...model, id });
  } catch (error) {
    console.error(error);
  } finally {
    setFinishLoading();
  }
};

export const createForm = async (model: FormProps) => {
  let response: AxiosResponse<any>;
  response = await axios.post(`${formsBaseUrl}FormBuilder/template/create`, model);

  return response.data;
};

export const editFormById = async (model: FormProps) => {
  let response: AxiosResponse<any>;
  response = await axios.post(`${formsBaseUrl}FormBuilder/template/update/false`, model);

  return response.data;
};

export const createFormCopyById = async (model: FormCopyProps) => {
  let response: AxiosResponse<any>;
  response = await axios.post(`${formsBaseUrl}FormBuilder/template/copy`, model);

  return response.data;
};

export const setRelatedDocuments = async (id: number, rId: number) => {
  await axios.put(`${HOST_API}/api/documents/${id}/related-documents/add/${rId}`);
};

export const fillForm = async (model: any) => {
  let response: AxiosResponse<any>;
  response = await axios.post(`${formsBaseUrl}FormBuilder/fill/create-new`, model);

  return response.data;
};

export const getTypeListByModule = async (module: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/type/list?all=false&type=${module}`);

  return response.data;
};

export const getFormByModuleAndType = async (module: number, type: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/template/${module}/list/${type}`);

  return response.data;
};

export const getFormTemplatesList = async () => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/templates/list`);

  return response.data;
};

export const getFormDocument = async (id: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/fill?id=${id}&all=false`);

  return response.data;
};

export const getFormDocumentAttachment = async (id: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/fill/${id}/file`, {
    responseType: 'blob',
  });

  return response.data;
};

export const getFormDocumentEntities = async (id: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/form/${id}/entities`);

  return response.data;
};

export const getCustomLists = async () => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}CustomList/list`);

  return response.data;
};

export const getCustomListById = async (id: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}CustomList/${id}`);

  return response.data;
};

export const setGeneratedAttachment = async (
  documentId: number,
  data: DLXDocumentGenerationProps
) => {
  await axios.post(`${formsBaseUrl}FormBuilder/fill/attachment?documentId=${documentId}`, data);
};

export const getFormById = async (id: number) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${formsBaseUrl}FormBuilder/template/${id}`);

  return response.data;
};

export const getFormBuilderFields = async (moduleType: DocumentModuleTypes) => {
  const response = await axios.get<FormColumnGroup[]>(`${formsBaseUrl}FormBuilder/form/fields`, {
    params: { moduleType },
  });
  return response.data;
};

const formService = {
  getPagedForms,
  getItems,
  getForm,
  editForm,
  getFields,
};

export default formService;
