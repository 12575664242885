import { cloneDeep } from 'lodash';
import { useState, useEffect } from 'react';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  Grid,
  Stack,
  Table,
  Button,
  Dialog,
  Divider,
  ListItem,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  CardHeader,
  IconButton,
  Pagination,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  List as MUIList,
} from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useNotifications from 'src/hooks/useNotifications';

import { PagingFilter } from 'src/@types/shared/pagingFilter';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { CustomList, CustomListCreate } from 'src/@types/customLists/CustomList';
import { useConfirmationModalContext } from 'src/contexts/ConfirmationModalContext';
import {
  getCustomLists,
  createCustomList,
  deleteCustomList,
  updateCustomList,
} from 'src/services/customListService';

import Tip from 'src/components/Tip';
import Mobile from 'src/components/Mobile';
import Iconify from 'src/components/Iconify';
import Desktop from 'src/components/Desktop';
import Scrollbar from 'src/components/Scrollbar';

import AdminPanelBackButton from '../../../sections/admin/AdminPanelBackButton';

//-------------------------------------------------------------------

type ListFilterState = {
  page: number;
  pageSize: number;
};

export default function List() {
  const { t } = useLocales();
  const { user } = useAuth();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [filter, setFilter] = useState({
    page: 1,
    pageSize: 10,
  } as ListFilterState);

  const [lists, setLists] = useState<PagedResponse<CustomList> | undefined>(undefined);

  const [selectedListDialog, setSelectedListDialog] = useState<CustomList | undefined>(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    refreshCustomList();
  }, []);

  useEffect(() => {
    refreshCustomList();
  }, [filter]);

  function refreshCustomList() {
    const f = {
      limit: filter.pageSize,
      offset: filter.page - 1,
    } as PagingFilter;
    getCustomLists(f).then((x) => setLists(x));
  }

  function onRemove() {
    refreshCustomList();
  }

  function pageCount() {
    return Math.ceil((lists?.total || 0) / filter.pageSize);
  }

  const handlePageChange = (event: any, value: number) => {
    setFilter({ ...filter, page: value });
  };

  const handleEditList = (list: CustomList) => {
    setSelectedListDialog(list);
    setOpen(true);
  };
  const handleNewList = () => {
    setSelectedListDialog(undefined);
    setOpen(true);
  };

  const closeListDialog = (update?: boolean) => {
    setSelectedListDialog(undefined);
    setOpen(false);

    if (update) {
      refreshCustomList();
    }
  };

  const tableHeaderStyle = {
    backgroundColor: isLight ? theme.palette.grey[400] : theme.palette.grey[700],
    color: isLight ? theme.palette.grey[700] : theme.palette.grey[300],
    py: 2,
  };

  return (
    <Box sx={{ mx: 2 }}>
      <AdminPanelBackButton />

      <Stack px={1} py={2} direction="row" justifyContent="space-between">
        <CardHeader title={t('customList.customList')} sx={{ p: 0 }} />
        <Button variant="contained" onClick={handleNewList} id="2t4z782j">
          {t('customList.buttons.add')}
        </Button>
      </Stack>
      {open && <ListDialog list={selectedListDialog} open={open} handleClose={closeListDialog} />}

      <Desktop>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 300 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {[t('customList.table.name'), t('customList.table.options')].map((item) => (
                    <TableCell key={item} sx={tableHeaderStyle}>
                      {item}
                    </TableCell>
                  ))}

                  <TableCell sx={tableHeaderStyle} align="right">
                    {t('customList.table.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lists?.items?.map((item: CustomList) => (
                  <CustomListItem
                    key={item.id}
                    list={item}
                    handleEditList={handleEditList}
                    onRemove={onRemove}
                    isAdmin={user?.isAdmin as boolean}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Desktop>
      <Mobile>
        {/* content */}
        <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {lists?.items?.map((item: CustomList) => (
            <CustomListItem
              key={item.id}
              list={item}
              handleEditList={handleEditList}
              onRemove={onRemove}
              isAdmin={user?.isAdmin as boolean}
            />
          ))}
        </Stack>
      </Mobile>

      <Divider />

      <Box sx={{ p: 2 }}>
        <Pagination
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}
          count={pageCount()}
          onChange={handlePageChange}
          page={filter.page}
        />
      </Box>
    </Box>
  );
}

type CustomListItemProps = {
  list: CustomList;
  onRemove: (id: number) => void;
  handleEditList: (list: CustomList) => void;
  isAdmin: boolean;
};

function CustomListItem({ list, onRemove, handleEditList, isAdmin }: CustomListItemProps) {
  const modalContext = useConfirmationModalContext();
  const { t } = useLocales();
  const theme = useTheme();
  const { enqueueSuccess } = useNotifications();
  const isLight = theme.palette.mode === 'light';

  const rowStyle = {
    boxShadow: isLight ?  '2px 3px 4px #CCCCCC' : 'none',
    backgroundColor: theme.palette.grey[0],
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const result = await modalContext.showConfirmation(
      t('components.confirmationModal.confirmation'),
      t('customList.areYouSureWantToRemovePermanently')
    );

    if (result) {
      await deleteCustomList(list.id);
      enqueueSuccess('customList.snackbar.delete');
      onRemove(list.id);
    }
  };

  return (
    <>
      <Desktop>
        <TableRow sx={rowStyle}>
          <TableCell>{list.name}</TableCell>
          <TableCell>
            {list.options !== undefined && list.options !== null ? list.options.length : 0}
          </TableCell>
          <TableCell align="right">
            {isAdmin && (
              <>
                <Tip titleLocale="shared.buttons.edit">
                  <IconButton size="small" onClick={() => handleEditList(list)} id="r9fggu84">
                    <Iconify icon="eva:edit-fill" width={20} height={20} />
                  </IconButton>
                </Tip>

                <Tip titleLocale="shared.buttons.delete">
                  <IconButton size="small" onClick={handleRemove} id="5m1pew4f">
                    <Box sx={{ height: '20px', width: '20px' }}>
                      <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
                    </Box>
                  </IconButton>
                </Tip>
              </>
            )}
          </TableCell>
        </TableRow>
      </Desktop>

      <Mobile>
        <Card
          sx={{
            gap: 0.5,
            pl: 1,
            pr: 0,
            py: 2,
            border: '1px solid #fff',
            display: 'flex',
            width: '100%',
          }}
        >
          <Box sx={{ flex: 1, pl: 1 }} onClick={() => handleEditList(list)} id='cp756uen'>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  maxWidth: '250px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'wrap',
                }}
              >
                {list.name}
              </Typography>
            </Box>

            <Box sx={{ color: theme.palette.text.secondary, display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('customList.table.options')}:
              </Typography>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {list.options !== undefined && list.options !== null ? list.options.length : 0}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1
            }}
          >
             <IconButton onClick={handleRemove} size="small" id="vj93xllh">
              <Box sx={{ height: '20px', width: '20px' }}>
                <img src="/icons/icon_trashbin.svg" width={20} height={20} alt="delete" />
              </Box>
          </IconButton>
          </Box>
        </Card>
      </Mobile>
    </>
  );
}

type ListDialogProps = {
  list?: CustomList;
  open: boolean;
  handleClose: (update?: boolean) => void;
};

function ListDialog({ list, open, handleClose }: ListDialogProps) {
  const { t } = useLocales();
  const { enqueueSuccess } = useNotifications();
  const [listState, setListState] = useState<CustomList | undefined>(undefined);

  useEffect(() => {
    if (list) {
      const ref = cloneDeep(list);
      setListState(ref);
    } else {
      setListState({
        id: -1,
        name: '',
        options: [],
      } as CustomList);
    }
  }, []);

  const onClose = () => {
    handleClose();
  };

  const onSubmit = async () => {
    if (list) {
      if (listState === undefined) {
        return;
      }
      const dto = {
        id: list.id,
        name: listState.name,
        options: listState.options,
      } as CustomList;
      await updateCustomList(dto);
    } else {
      if (listState === undefined) {
        return;
      }
      const dt = { name: listState.name, options: listState.options } as CustomListCreate;
      await createCustomList(dt);
    }

    enqueueSuccess(list ? 'customList.snackbar.updated' : 'customList.snackbar.created');
    handleClose(true);
  };

  const handleAddValue = () => {
    if (listState !== undefined) {
      const ref = cloneDeep(listState);
      ref.options.push('');
      setListState(ref);
    }
  };

  const handleChange = (event: any) => {
    if (listState !== undefined) {
      listState.name = event;
      const ref = cloneDeep(listState);
      setListState(ref);
    }
  };

  const handleUpdateValue = (index: number, event: any) => {
    if (listState !== undefined) {
      listState.options[index] = event;
      const ref = cloneDeep(listState);
      setListState(ref);
    }
  };

  const handleDeleteValue = (index: number) => {
    if (listState !== undefined) {
      const ref = cloneDeep(listState);
      ref.options.splice(index, 1);
      setListState(ref);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={() => handleClose()}>
      <DialogTitle sx={{ mb: 2 }}>{list ? list?.name : t('customList.new')}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              label={t('customList.dialog.nameLabel')}
              value={listState?.name}
              onChange={(e) => handleChange(e.target.value)}
              id="tkct9wgz"
            />
          </Grid>
          <Grid item>
            <Button fullWidth color='secondary' variant="contained" onClick={handleAddValue} id="d4ccnj8s">
              {t('customList.buttons.option')}
            </Button>
          </Grid>
          <Grid item>
            <MUIList>
              <Scrollbar>
                {listState?.options.map((item, index) => (
                  <ListItem key={index}>
                    <TextField
                      fullWidth
                      label={t('customList.dialog.elementLabel')}
                      value={item}
                      onChange={(e) => handleUpdateValue(index, e.target.value)}
                      id="wzrw4qrd"
                    />
                    <IconButton
                      onClick={() => handleDeleteValue(index)}
                      edge="end"
                      aria-label="delete"
                      id="rzjnup2f"
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </Scrollbar>
            </MUIList>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="inherit" id="mz5nnxqa">
          {t('shared.buttons.cancel')}
        </Button>
        <LoadingButton type="submit" variant="contained" onClick={onSubmit} id="yd0sown5">
          {t('shared.buttons.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
