import axios from 'src/utils/httpClients/axios';
import { getBase64FromFile } from 'src/utils/fileUtils';

import { DS_API } from 'src/config';
import { DSResponse } from 'src/@types/shared/dsResponse';
import { ValidationData, AdocValidationData } from 'src/@types/documents/validationReport';

export async function validateDocument(file: File) {
  const model = {
    files: [
      {
        content: await getBase64FromFile(file),
        name: file.name,
      },
    ],
  };

  try {
    const response = await axios.post<DSResponse<ValidationData[]>>(`${DS_API  }/document/validate`, model);
    return response.data.data[0];
  } catch (error: any) {
    throw error;
  }
}

export async function validateAdocDocument(file: File) {
  const model = {
    files: [
      {
        content: await getBase64FromFile(file),
        name: file.name,
      },
    ],
  };

  try {
    const response = await axios.post<DSResponse<AdocValidationData[]>>(`${DS_API  }/document/validateadoc`, model);
    return response.data.data[0];
  } catch (error: any) {
    throw error;
  }
}

const dsDocumentService = {
  validateDocument,
  validateAdocDocument,
};

export default dsDocumentService;
