import React from 'react';
import { HexColorPicker } from 'react-colorful';

import { Stack } from '@mui/material';

import './styles.css';

//--------------------------------------------

const presetColors: string[] = [
  '#000',
  '#9c27b0',
  '#3f51b5',
  '#2196f3',
  '#4caf50',
  '#ff9800',
  '#f5125e',
];

type ColorPickerProps = {
  color: string;
  setColor: (color: string) => void;
  spacing: number;
  colors?: string[];
};

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor, spacing, colors }) => (
  <Stack direction="column" spacing={spacing} sx={{ width: '100%' }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <button
        type="button"
        style={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          border: '1px solid #CCDDDD',
          background: color,
          cursor: 'pointer',
        }}
        onClick={() => setColor(color)}
        id="fb18oj"
      />

      {colors &&
        colors.map((color, index) => (
          <button
            type="button"
            key={index}
            style={{
              width: 32,
              height: 32,
              borderRadius: '50%',
              border: 'none',
              background: color,
              cursor: 'pointer',
            }}
            onClick={() => setColor(color)}
            id="22t1vu"
          />
        ))}

      {!colors &&
        presetColors.map((color, index) => (
          <button
            type="button"
            key={index}
            style={{
              width: 32,
              height: 32,
              borderRadius: '50%',
              border: 'none',
              background: color,
              cursor: 'pointer',
            }}
            onClick={() => setColor(color)}
            id="01pAVa"
          />
        ))}
    </Stack>

    <HexColorPicker className="responsive-picker" color={color} onChange={setColor} />
  </Stack>
);

export default ColorPicker;
