import { useEffect } from 'react';

import useLocales from './useLocales';

const useFormTranslate = (formik: any) => {
  const { errors, touched, setFieldTouched } = formik;
  const { i18n } = useLocales();
  useEffect(() => {
    i18n.on('languageChanged', () => {
      Object.keys(errors).forEach((fieldName) => {
        if (touched.hasOwnProperty(fieldName)) {
          setFieldTouched(fieldName, false);
          setTimeout(() => {
            setFieldTouched(fieldName);
          }, 0);
        }
      });
    });
    return () => {
      i18n.off('languageChanged', () => {});
    };
  }, [errors, touched]);
};

export default useFormTranslate;
