export type ValidationData = {
  name: string;
  simpleReport?: ValidationReport;
  validationResult: ValidationStatus;
};

export type AdocValidationData = {
  name: string;
  adocSimpleReport?: AdocValidationReport;
  validationResult: ValidationStatus;
  adocStructureErrors: string;
};

export enum ValidationStatus {
  Invalid = 0,
  Valid = 1,
  NoSignatures = 2,
}

type ValidationPolicy = {
  policyName: string;
  policyDescription: string;
};

type SignatureOrTimestampReport = {
  signature?: SignatureReport;
};

type SignatureLevel = {
  value: string;
  description: string;
};

type СertificateChain = {
  certificate?: Сertificate;
};

type Сertificate = {
  id: string;
  qualifiedName: string;
};

type SignatureReport = {
  signingTime: Date;
  bestSignatureTime: Date;
  signedBy: string;
  signatureLevel?: SignatureLevel;
  certificateChain?: СertificateChain;
  indication: string;
  subIndication: string;
  signatureFormat: string;
  certificateExpirationDate: string;
};

type BaseReport = {
  validationPolicy?: ValidationPolicy;
  documentName: string;
  validSignaturesCount: number;
  signaturesCount: number;
  containerType: string;
  semantic: string;
  validationTime: Date;
};

type ValidationReport = BaseReport & {
  signatureOrTimestamp?: SignatureOrTimestampReport[];
};

type AdocValidationReport = BaseReport & {
  creationReportMetadata: CreationReportMetadata;
  unsignableReportMetadata: UnsignableReportMetadata;
  signatureOrTimestamp: ExtendedAdocMetadataSignatureOrTimestamp[];
};

type CreationReportMetadata = {
  authors: string;
  documentName: string;
  documentRegistration: string;
};

type UnsignableReportMetadata = {
  documentCreationSoftware: string;
  documentFormat: string;
  adocType: string;
};

type ExtendedAdocMetadataSignatureOrTimestamp = SignatureOrTimestampReport & {
  signatureAdocMetadata: SignatureReportMetadata;
};

type SignatureReportMetadata = {
  signatureFileName: string;
  signingReason: string;
  signatureAuthor: string;
  signingTime: string;
};
