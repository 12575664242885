import { useNavigate } from 'react-router-dom';

import { Button, SxProps, ButtonProps } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import usePrevLocation from 'src/hooks/usePrevLocation';

import Iconify from './Iconify';

interface Props {
  sx?: SxProps;
  paths: string[];
  other?: ButtonProps;
}

export default function BackButton({ paths, sx, ...other }: Props) {
  const { t } = useLocales();
  const navigate = useNavigate();
  const prevLocation = usePrevLocation();

  const goBack = () => {
    const previous = (prevLocation?.pathname || '') as string;
    const search = prevLocation?.search;

    if (paths.includes(previous)) {
      if (search) {
        navigate(previous + search);
      } else {
        navigate(previous);
      }
    } else {
      navigate(paths[0]);
    }
  };

  return (
    <Button onClick={goBack} startIcon={<Iconify icon="akar-icons:arrow-left" />} sx={{ mb: 2, ...sx }} {...other} id="eX6XEj">
      {t('shared.buttons.backToList')}
    </Button>
  );
}
