import { HOST_API } from '../config';
import axios from '../utils/httpClients/axios';
import { addStartLoadingState } from '../redux/slices/dlxDrive';
import DLXDriveAccessGroupType from '../@types/dlx-drive/enums/DLXDriveAccessGroupType';
import {
  DLXDriveItem,
  DLXDriveFilterType,
  DLXDriveFolderType,
  DLXDriveFolderListItem,
  DLXDriveShareModelType,
  DLXDriveFolderAccessType,
  DLXDriveBulkMoveModelType,
  DLXDriveBulkDeleteModelType,
  DLXDriveBulkApproveModelType,
  DLXDriveFolderAccessUserType,
  DLXDriveBulkDownloadModelType,
  DLXDriveMoveDocumentsModelType,
  DLXDriveFolderAccessAccessGroupType,
  DLXDriveFolderAccessDepartmentsType,
} from '../@types/dlx-drive/types';

// ----------------------------------------------------------------

const baseFoldersUrl = `${HOST_API}folders`;
const baseDocumentsUrl = `${HOST_API}documents`;
const formBuilderUrl = `${HOST_API}formBuilder`;
const sharedFilesUrl = `${HOST_API}SharedFiles`;
const accessGroupUrl = `${HOST_API}accessGroup`;

export const getFolders = async (filter: DLXDriveFilterType): Promise<DLXDriveFolderType> => {
  const response = await axios.post<Promise<DLXDriveFolderType>>(baseFoldersUrl, filter);

  return response.data;
};

export const updateFolder = async (model: any) => {
  const response = await axios.put(`${baseFoldersUrl}/update`, { ...model });

  return response.data;
};

export const getFolderNamePrefix = (folderId: number, items: DLXDriveItem[]) =>
  items.filter((b) => b.id === folderId);

export const bulkDownload = async (model: DLXDriveBulkDownloadModelType) => {
  addStartLoadingState();

  const response = await axios.post(
    `${baseDocumentsUrl}/bulk-download`,
    {
      ...model,
    },
    { responseType: 'blob' }
  );

  const contentDispositionFileName = response.headers['content-disposition'];
  let fileName = 'file';

  if (contentDispositionFileName.includes('filename*=')) {
    const fileNameArr = contentDispositionFileName.split(';');
    const fileNameIndex = fileNameArr.findIndex((n: string) => n.includes('filename*='));
    const encodedFileName = fileNameArr[fileNameIndex].split('=')[1].split("''")[1];

    fileName = decodeURIComponent(encodedFileName);
  } else {
    fileName = contentDispositionFileName
      .split(';')
      .find((n: string) => n.includes('filename='))
      .replace('filename=', '')
      .trim()
      .replaceAll('"', '');
  }

  return {
    file: new File([response.data], fileName, { type: response.headers['content-type'] }),
    fileName,
    mime: response.headers['content-type'],
  };
};

export const getFolderList = async (isGrouped?: boolean, ids?: string) => {
  let url = `${baseFoldersUrl}/list`;

  if (ids) {
    url += `?${ids}`;
  }

  const response = await axios.get(url);
  const list = response.data;

  if (list && list.length > 0 && isGrouped) {
    return list.map((option: Omit<DLXDriveFolderListItem, 'firstLetter'>) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        accessRights: '0',
        ...option,
      };
    });
  }

  return list;
};

export const createFolder = async (model: any) => {
  addStartLoadingState();

  const response = await axios.post(`${baseFoldersUrl}/create`, model);

  return response.data;
};

export const moveFolder = async (model: any) => {
  addStartLoadingState();

  const response = await axios.post(`${baseFoldersUrl}/folder/move`, model);

  return response.data;
};

export const getFormTemplates = async () => {
  const response = await axios.get(`${formBuilderUrl}/template/list`);

  return response.data;
};

export const getFolderFormTemplates = async (folderId: number) => {
  const response = await axios.get(`${baseFoldersUrl}/${folderId}/formTemplates`);

  return response.data;
};

export const moveDocuments = async (model: DLXDriveMoveDocumentsModelType) => {
  addStartLoadingState();

  const response = await axios.put(`${baseFoldersUrl}/add/documents`, { ...model });

  return response.data;
};

export const shareItems = async (model: DLXDriveShareModelType) => {
  addStartLoadingState();

  const response = await axios.post(`${sharedFilesUrl}/create`, { ...model });

  return response.data;
};

export const deleteItems = async (model: DLXDriveBulkDeleteModelType) => {
  addStartLoadingState();

  const response = await axios.delete(`${baseFoldersUrl}/bulk-delete`, { data: { ...model } });

  return response.data;
};

export const moveItems = async (folderId: number, model: DLXDriveBulkMoveModelType) => {
  addStartLoadingState();

  const response = await axios.post(`${baseFoldersUrl}/bulk-move-all/${folderId}`, { ...model });

  return response.data;
};

export const bulkApprove = async (model: DLXDriveBulkApproveModelType) => {
  addStartLoadingState();

  const response = await axios.post(`${baseDocumentsUrl}/mass-approve`, { ...model });

  return response.data;
};

export const getFolderAccess = async (folderId: number) => {
  const response = await axios.get(`${baseFoldersUrl}/access/${folderId}`);

  return response.data;
};

export const getFolderAccessUserList = async (isGrouped: boolean) => {
  const response = await axios.get(`${accessGroupUrl}/items`);
  const { accessGroups, departments, users } = response.data;

  // ---- Access Groups ----

  const newAccessGroups: DLXDriveFolderAccessAccessGroupType[] = [];

  if (accessGroups.length > 0) {
    accessGroups.forEach((accessGroup: DLXDriveFolderAccessAccessGroupType) => {
      const firstLetter = /[0-9]/.test(accessGroup.name[0].toUpperCase())
        ? '0-9'
        : accessGroup.name[0].toUpperCase();

      newAccessGroups.push({
        ...accessGroup,
        type: DLXDriveAccessGroupType.AccessGroup,
        firstLetter,
      });
    });
  }

  // ---- Departments ----

  const newDepartments: DLXDriveFolderAccessDepartmentsType[] = [];

  if (departments.length > 0) {
    departments.forEach((department: DLXDriveFolderAccessDepartmentsType) => {
      const firstLetter = /[0-9]/.test(department.name[0].toUpperCase())
        ? '0-9'
        : department.name[0].toUpperCase();

      newDepartments.push({
        ...department,
        type: DLXDriveAccessGroupType.Department,
        firstLetter,
      });
    });
  }

  // ---- Users ----

  const newUsers: DLXDriveFolderAccessUserType[] = [];

  if (users.length > 0) {
    users.forEach((user: DLXDriveFolderAccessUserType) => {
      let firstLetter = '';

      if (user.name && user.name.trim().length > 0) {
        firstLetter = /[0-9]/.test(user.name.trim()[0].toUpperCase())
          ? '0-9'
          : user.name.trim()[0].toUpperCase();
      } else {
        firstLetter = /[0-9]/.test(user.email.trim()[0].toUpperCase())
          ? '0-9'
          : user.email.trim()[0].toUpperCase();
      }

      newUsers.push({
        ...user,
        type: DLXDriveAccessGroupType.User,
        firstLetter,
      });
    });
  }

  return [...newAccessGroups, ...newDepartments, ...newUsers] as DLXDriveFolderAccessType[];
};

export const updateFolderAccessUserList = async (
  id: number,
  newAccessGroups: DLXDriveFolderAccessAccessGroupType[],
  newDepartments: DLXDriveFolderAccessDepartmentsType[],
  newUsers: DLXDriveFolderAccessUserType[]
) => {
  addStartLoadingState();

  // ---- Access Groups ----

  const accessGroups = {};
  newAccessGroups.forEach((group) => {
    accessGroups[group.id] = group.accessLevel;
  });

  // ---- Departments ----

  const departments = {};
  newDepartments.forEach((department) => {
    departments[department.id] = department.accessLevel;
  });

  // ---- Users ----

  const users = {};
  newUsers.forEach((user) => {
    users[user.email] = user.accessLevel;
  });

  // ---- Model ----

  const model = {
    id,
    accessGroups,
    departments,
    users,
  };

  const response = await axios.post(`${baseFoldersUrl}/access/update`, model);

  return response.data;
};

export const pinToNavigation = async (folderId: number) => {
  const response = await axios.post(`${baseFoldersUrl}/pin?folderId=${folderId}`);

  return response.data;
};

export const getPinnedFolders = async () => {
  const response = await axios.get(`${baseFoldersUrl}/pinned`);

  return response.data;
};

export const changeFolderColor = async (id: number, propertyValue: string) => {
  const response = await axios.post(`${baseFoldersUrl}/color`, { id, propertyValue });

  return response.data;
};

export const changeFolderIcon = async (id: number, propertyValue: string) => {
  const response = await axios.post(`${baseFoldersUrl}/icon`, { id, propertyValue });

  return response.data;
};
