import React, { useState, useEffect } from 'react';

import { Box, useTheme, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import DLXDriveFolderIcon from '../../utils/dlx-drive/DLXDriveFolderIcon';
import {
  FOLDER_ICON_MEDIUM_SIZE,
  FOLDER_DEFAULT_ICON_COLOR,
} from '../../utils/constants/dlxDriveConstants';

const DLXDriveTitle: React.FC = () => {
  const { t } = useLocales();
  const { selectedFolder } = useSelector((state) => state.dlxDrive);
  const [folderIconColor, setFolderIconColor] = useState<string>(FOLDER_DEFAULT_ICON_COLOR);
  const theme = useTheme();

  useEffect(() => {
    if (selectedFolder.color.length > 0 && selectedFolder.color !== folderIconColor) {
      setFolderIconColor(selectedFolder.color);
    }
  }, [selectedFolder]);

  return (
    <Box
      flexGrow={1}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <DLXDriveFolderIcon
        icon={selectedFolder.icon}
        color={selectedFolder.color.length > 0 ? selectedFolder.color : FOLDER_DEFAULT_ICON_COLOR}
        fontSize={`${FOLDER_ICON_MEDIUM_SIZE}px`}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Typography variant="h5" noWrap>
          {selectedFolder.name}
        </Typography>

        {selectedFolder.prefix && (
          <Typography variant="body2" color={theme.palette.text.secondary} noWrap>
            {`${t('documentTree.table.prefix')}: ${selectedFolder.prefix}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DLXDriveTitle;
