import { FormElementError } from '../enums/FormElementError';
import { ErrorsListProps, ElementValidationProps } from '../types';

const validateIsRequired = ({ el, errorMessage, value }: ElementValidationProps) => {
  let newError: ErrorsListProps | null = null;

  if (!value || value.length === 0 || value === 'Invalid Date') {
    newError = {
      elementID: el.id,
      elementName: el.label,
      errorCode: FormElementError.Required,
      errorMessage,
    };

    return newError;
  }
};

export default validateIsRequired;
