export enum FontFamily {
  Arial = 'Arial',
  Helvetica = 'Helvetica',
  TimesNewRoman = 'Times New Roman',
}

export enum TextAlignment {
  Left = 0,
  Center = 1,
  Right = 2,
}

export enum TextStyle {
  Regular = 'Regular',
  Bold = 'Bold',
  Italic = 'Italic',
  BoldItalic = 'BoldItalic',
}

export enum TextDecoration {
  None = 0,
  Underline = 1,
}
