export enum UserDataType {
  Name = 0,
  Email = 1,
  Phone = 2,
  Company = 3,
  Job = 4,
  Address = 5,
  ManagersName = 6,
  ManagersEmail = 7,
}
