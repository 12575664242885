import axios from 'src/utils/httpClients/axios';

import { HOST_API } from 'src/config';
import { FoldersFilter } from 'src/@types/folders/foldersFilter';
import { FolderProp, CreateFolder, FolderTreeProp } from 'src/@types/folders/types';

type addDocToFolderProps = {
  folderId: number;
  docId: number;
};

const baseUrl = `${HOST_API  }Folders`;

async function getPagedFolders(filter: FoldersFilter): Promise<FolderProp> {
  const response = await axios.post<Promise<FolderProp>>(baseUrl, filter);
  const result = await response.data;

  return result;
}

async function getFolderTree() {
  const response = await axios.get<FolderTreeProp[]>(`${baseUrl  }/folder-tree`);
  return response.data;
}

async function getFolderName(id: string): Promise<string | null> {
  const response = await axios.get<string>(`${baseUrl  }/name?folderId=${id}`);
  return response.data;
}

async function removeFolder(id: number) {
  await axios.delete(`${baseUrl  }/delete?folderId=${id}`);
}

async function removeBulkFolders(id: number[]) {
  await axios.delete(`${baseUrl  }/bulk-delete`, {
    data: {
      folderIds: id,
    },
  });
}

async function removeDocumentFromFolder(folderId: number, documentId: number) {
  await axios.delete(`${baseUrl  }/delete-document-from-folder/?folderId=${folderId}&documentId=${documentId}`);
}

async function createFolder(model: CreateFolder) {
  await axios.post(`${baseUrl  }/create`, model);
}

async function updateFolder(model: CreateFolder, id: number) {
  const body = {
    ...model,
    id,
  };
  await axios.put(`${baseUrl  }/update`, { ...body });
}

async function addDocToFolder(model: addDocToFolderProps) {
  const { folderId, docId } = model;
  await axios.put(`${baseUrl  }/add/document?folderId=${  folderId  }&documentId=${  docId}`);
}

const folderService = {
  getPagedFolders,
  createFolder,
  removeFolder,
  removeBulkFolders,
  removeDocumentFromFolder,
  updateFolder,
  addDocToFolder,
  getFolderName,
  getFolderTree,
};

export default folderService;
