import { Box, Grid, Container } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useAppSettings from 'src/hooks/useAppSettings';

import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';

import ModuleCheck from 'src/components/access-checks/ModuleCheck';

import DashboardPlans from 'src/sections/dashboard/DashboardPlans';
import DashboardWelcomeUpload from 'src/sections/dashboard/DashboardWelcomeUpload';
import DashboardB2BQuickActions from 'src/sections/dashboard/b2b/DashboardB2BQuickActions';
import DashboardB2CQuickActions from 'src/sections/dashboard/b2c/DashboardB2CQuickActions';
import DashboardB2BAnalytics from 'src/sections/dashboard/b2b/dashboard-b2b-analytics/DashboardB2BAnalytics';

import UserPlanB2C from './UserPlanB2C';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const { t } = useLocales();
  const { themeStretch } = useSettings();
  const moduleData = useAppSettings();

  return (
    <Page title={t('dashboard.dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container direction="row" spacing={2}>
          {!moduleData.isCompany && (
            <Grid item xs={12} md={5}>
              <UserPlanB2C />
            </Grid>
          )}

          {moduleData.isCompany && (
            <Grid item xs={12}>
              <DashboardWelcomeUpload />
            </Grid>
          )}

          <Grid item xs={12}>
            {moduleData.isCompany && <DashboardB2BQuickActions />}
            {!moduleData.isCompany && <DashboardB2CQuickActions />}
          </Grid>

          {!moduleData.isCompany && (
            <Grid item xs={12}>
              <DashboardPlans />
            </Grid>
          )}

          <ModuleCheck module={ModuleTypes.StatisticsModule}>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <DashboardB2BAnalytics />
              </Box>
            </Grid>
          </ModuleCheck>
        </Grid>
      </Container>
    </Page>
  );
}
