import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import useLocales from '../hooks/useLocales';

type CountdownTimerProps = {
  logout: VoidFunction;
  closeConfirmation: VoidFunction;
  sessionExpireTime: number;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ logout, closeConfirmation, sessionExpireTime }) => {
  const { t } = useLocales();
  const [leftTime, setLeftTime] = useState(sessionExpireTime - Date.now());
  const [showTime, setShowTime] = useState('00:00');

  const countDownCounter = () => {
    if (leftTime > 1) {
      setLeftTime(sessionExpireTime - Date.now());
    } else {
      stopCounter();
      closeConfirmation();
      logout();
    }
  };

  const counterInterval = setInterval(countDownCounter, 1000);

  const stopCounter = () => {
    clearInterval(counterInterval);
  };

  const timeFormatter = (leftTime: number) => {
    let minutes = Math.floor((leftTime % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((leftTime % (1000 * 60)) / 1000);

    if (minutes < 1) {
      minutes = 0;
    }

    if (seconds < 1) {
      seconds = 0;
    }

    setShowTime(`${minutes < 10 ? `0${  minutes}` : minutes}:${seconds < 10 ? `0${  seconds}` : seconds}`);
  };

  useEffect(() => {
    timeFormatter(leftTime);
  }, [leftTime]);

  return <Box component='span'>{t('components.sessionModal', { time: showTime })}</Box>;
};

export default CountdownTimer;
