import documentService from '../../services/documentService';
import dsDocumentService from '../../services/ds-api/dsDocumentService';
import { FileExtensions } from '../../@types/fileView/enums/fileExtensions';

// --------------------------------------------------------

const viewerFileValidation = async (id: number, name: string, extension: FileExtensions) => {
  const blob = await documentService.getFile(id);
  const file = new File([blob], name);

  try {
    if (extension === FileExtensions.Adoc) {
      return await dsDocumentService.validateAdocDocument(file);
    }
    return await dsDocumentService.validateDocument(file);
  } catch (error) {
    return error;
  }
};

export default viewerFileValidation;
