import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Box, useTheme, Typography } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';

import { Analytics } from 'src/@types/analytics/types';
import { FileExtensions } from 'src/@types/fileView/enums/fileExtensions';

const DOCUMENT_TYPES: string[] = [
  FileExtensions.Pdf,
  FileExtensions.Adoc,
  FileExtensions.Asic,
  FileExtensions.Edoc,
  FileExtensions.Bdoc,
].map((a) => a.toUpperCase());

type DoughnutChartProps = {
  analyticsCounts: Analytics;
};

//----------------------------------------------

export default function DoughnutChart({ analyticsCounts }: DoughnutChartProps) {
  const theme = useTheme();

  const { pdf, adoc, asice, edoc, bdoc } = analyticsCounts;
  const dataArr = [pdf, adoc, asice, edoc, bdoc];
  const analyticsSum = pdf + adoc + asice + edoc + bdoc;
  const textFormat = (text: string, num: number) => `${text}: ${num}`;
  const isLight = theme.palette.mode === 'light';
  const isMobile = useResponsive('down', 'sm');

  const textAndNumber = dataArr.map((x, index) => textFormat(DOCUMENT_TYPES[index], x));

  const emptyColor = isLight ? theme.palette.grey[100] : theme.palette.grey[500_12];
  const borderC = isLight ? theme.palette.grey[100] : theme.palette.grey[700];
  const textColor = isLight ? theme.palette.grey[600] : theme.palette.grey[500];

  const lightColors = [
    theme.palette.chart.yellow[0],
    theme.palette.chart.red[0],
    theme.palette.chart.violet[0],
    theme.palette.chart.blue[0],
    theme.palette.chart.green[0],
  ];
  const data = {
    // labels: [...textAndNumber],
    datasets: [
      {
        data: [pdf, adoc, asice, edoc, bdoc, analyticsSum < 1 && 1],
        backgroundColor: [...lightColors, analyticsSum < 1 && emptyColor],
        hoverBackgroundColor: [
          theme.palette.chart.yellow[1],
          theme.palette.chart.red[1],
          theme.palette.chart.violet[1],
          theme.palette.chart.blue[1],
          theme.palette.chart.green[1],
          analyticsSum < 1 && emptyColor,
        ],
        borderColor: borderC,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: textColor,
          font: {
            size: 12,
            family: theme.typography.fontFamily,
            weight: 'lighter',
          },
        },
      },
      colors: {
        forceOveride: true,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const docTypeTotal = (color: string, txt: string) => (
    <Box sx={{ display: 'flex', height: '1rem', width: '9rem', alignItems: 'center', my: 2 }}>
      <Box sx={{ borderRadius: '5px', px: isMobile ? 2 : 3, py: 1, backgroundColor: color }} />
      <Typography variant="body2" color="text.secondary" sx={{ ml: '1rem' }}>
        {txt}
      </Typography>
    </Box>
  );

  const dataBlocks = textAndNumber.map((item, index) => [lightColors[index], item]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: isMobile ? 2 : 3,
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ height: '100%', width: '50%', overflowX: 'hidden' }}>
        <Doughnut data={data} options={options} placeholder="Chart" />
      </Box>

      <Box>{dataBlocks.map((item) => docTypeTotal(item[0], item[1]))}</Box>
    </Box>
  );
}
