import { createSlice } from '@reduxjs/toolkit';

import { AddressBookState } from 'src/@types/addressBook/types';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { AddressBookFilter } from 'src/@types/addressBook/addressBookFilter';
import { UpdateUser, AddressBookUser } from 'src/@types/users/addressBookUser';
import aspAddressBookService from 'src/services/asp-identity/aspAddressBookService';

import { dispatch } from '../store';

const initialState: AddressBookState = {
  isLoading: false,
  error: null,
  users: null,
  selectedId: null,
};

const slice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.users = initialState.users;
      state.selectedId = initialState.selectedId;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    getUsersError(state, action) {
      state.isLoading = false;
      state.users = initialState.users;
      state.error = action.payload;
    },

    updateProfile(state, action) {
      state.isLoading = false;
      state.users = {
        items: state.users?.items.map((u) => (u.id === action.payload.id ? { ...u, ...action.payload.model } : u)),
        total: state.users?.total,
      } as PagedResponse<AddressBookUser>;
    },

    switchFavorite(state, action) {
      state.users = {
        items: state.users?.items.map((u) => (u.id === action.payload.id ? { ...u, isFavorite: action.payload.isFavorite } : u)),
        total: state.users?.total,
      } as PagedResponse<AddressBookUser>;
    },
  },
});

export default slice.reducer;

export function clearAddressBookState() {
  dispatch(slice.actions.clearState());
}

export async function getAddressBookUsers(filter: AddressBookFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspAddressBookService.getPagedUsers(filter);

    dispatch(slice.actions.getUsersSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getUsersError(error));
  }
}

export async function getFavoriteUsers(filter: AddressBookFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspAddressBookService.getFavoriteUsers(filter);

    dispatch(slice.actions.getUsersSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getUsersError(error));
  }
}

export async function updateUserProfile(model: UpdateUser, id: string) {
  try {
    dispatch(slice.actions.startLoading());
    await aspAddressBookService.updateUser(model, id);
    dispatch(slice.actions.updateProfile({ model, id }));
  } catch (error) {
    throw error;
  }
}

export async function addNewUser(model: UpdateUser) {
  try {
    dispatch(slice.actions.startLoading());
    await aspAddressBookService.createUser(model);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    throw error;
  }
}

export async function switchFavorite(id: string, isFavorite: boolean) {
  try {
    await aspAddressBookService.switchFavorite(id);
    dispatch(slice.actions.switchFavorite({ id, isFavorite }));
  } catch (error) {
    throw error;
  }
}
