export class UserItem {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  parseJson(json: any): UserItem | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: UserItem = new UserItem();

    model.id = json.id;
    model.firstName = json.firstName;
    model.lastName = json.lastName;
    model.email = json.email;

    return model;
  }
}
