import React from 'react';
import { useNavigate } from 'react-router';

import HomeIcon from '@mui/icons-material/Home';
import { Link, Breadcrumbs } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useSelector } from '../../redux/store';
import useResponsive from '../../hooks/useResponsive';
import DLXDriveSkeletonBreadcrumbs from './DLXDriveSkeletonBreadcrumbs';

// ----------------------------------------------------------------

const DLXDriveBreadcrumbs: React.FC = () => {
  const { breadcrumbs, isLoading } = useSelector((state) => state.dlxDrive);
  const isSmallScreen = useResponsive('down', 'sm');

  const navigate = useNavigate();

  const handleBreadcrumbsClick = (id: number) => {
    navigate(`/folders/${id}/1`);
  };

  return (
    <>
      {!isLoading && (
        <Breadcrumbs
          maxItems={isSmallScreen ? 2 : 8}
          separator={<FiberManualRecordIcon sx={{ width: '6px', height: '6px' }} />}
        >
          {breadcrumbs &&
            breadcrumbs.map((b, i) => (
              <Link
                key={`breadcrumbs-${i}`}
                underline={i !== breadcrumbs.length - 1 ? 'hover' : 'none'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor:
                    i !== breadcrumbs.length - 1 || breadcrumbs.length === 1
                      ? 'pointer'
                      : 'default',
                }}
                color={i !== breadcrumbs.length - 1 ? 'inherit' : 'text.primary'}
                onClick={() => handleBreadcrumbsClick(b.id)}
                id="wPtD3j"
              >
                {i === 0 && <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                {b.name.length > 30 ? `${b.name.slice(0, 27)}...` : b.name}
              </Link>
            ))}
        </Breadcrumbs>
      )}

      {isLoading && <DLXDriveSkeletonBreadcrumbs />}
    </>
  );
};

export default DLXDriveBreadcrumbs;
