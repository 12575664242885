export class ValidationPatterns {
  public static email = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;

  public static password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_\-+={}[\]|\\:;"'<>,./])[A-Za-z\d@$!%*?&~`#^()_\-+={}[\]|\\:;"'<>,./]{8,}$/;

  public static letters = /^(?!.*[@$!%*?&~`#^()_+={}[\]|\\:;"'<>,./])[\D]+$/;

  public static lettersEmail = /^(?!.*[@$!%*?&~`#^()_+={}[\]|\\:;"'<>,.\/])[\D]+[\w.]+@([\w-]+\.)+[\w-]{2,10}$/;

  public static hex = /[0-9a-fA-F]/;

  public static pdfUTF8Char = /(#(([0-9,A-F]){2})){2}/gm;

  public static date = '____/__/__';

  public static dateWithTime = '____/__/__ __:__';

  public static tel = /[^+0-9]/g;

  public static fte = /^\d{0,2}(\.\d{0,2})?$/;

  public static numbers = /^\d+$/;

  public static getRepeatedMask = (
    pattern: string | RegExp,
    length: number
  ): (string | RegExp)[] => {
    const array: (string | RegExp)[] = [];
    for (let i = 0; i < length; i++) {
      array.push(pattern);
    }

    return array;
  };

  public static presonalCodeValueFilter = (value: string) => value.replace(/[^0-9-]/g, '');
}
