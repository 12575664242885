import fileDownload from 'js-file-download';
import { IDocument } from '@cyntler/react-doc-viewer';

import axios from '../httpClients/axios';
import encryptIDWithRsaPublicKey from './encryptIDWithRsaPublicKey';
import { DOC_VIEWER_DOCUMENT_EXTENSIONS } from '../constants/documentsViewerConstants';

const viewerFileDownload = async (document: IDocument) => {
  if (document && document.fileData && typeof document.fileData === 'string') {
    const fileUrl = `${encryptIDWithRsaPublicKey(document?.fileData)}`;
    let fileName = '';

    if (document.fileName) {
      fileName += document.fileName;
    }

    if (
      document.fileType &&
      !DOC_VIEWER_DOCUMENT_EXTENSIONS.includes(document.fileType) &&
      document.fileType !== 'pdf'
    ) {
      fileName += '.pdf';
    }

    try {
      await axios
        .get(fileUrl, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, fileName);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export default viewerFileDownload;
