export const WIDTH_CORRECTION_LARGE_SCREEN = 184;
export const WIDTH_CORRECTION_SMALL_SCREEN = 108;

export const FOLDER_ICON_LARGE_SIZE = 54;
export const FOLDER_ICON_MEDIUM_SIZE = 46;
export const FOLDER_ICON_SMALL_SIZE = 28;

export const FOLDER_ACTIONS_MENU_ICON_SIZE = 20;

export const FOLDER_DEFAULT_ICON_COLOR = '#FFCA28';

export const SKELETON_BGCOLOR = 'grey.300';
export const SKELETON_ANIMATION = 'wave';
