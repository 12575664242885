import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Dialog,
  Checkbox,
  ListItem,
  TextField,
  DialogTitle,
  Autocomplete,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  DialogActions,
  DialogContent,
  ToggleButtonGroup,
} from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import useNotifications from '../../hooks/useNotifications';
import { getFolderList } from '../../services/dlxDriveService';
import { addStopLoadingState } from '../../redux/slices/dlxDrive';
import { DLXDriveFolderListItem } from '../../@types/dlx-drive/types';
import { setReloadAccessGroupListState } from '../../redux/slices/accessGroup';
import {
  getMembersList,
  editAccessGroup,
  createAccessGroup,
  getDepartmentsList,
} from '../../services/accessGroupsService';
import {
  AccessGroupListItem,
  AccessGroupsMemberListItem,
  AccessGroupsDepartmentListItem,
} from '../../@types/access-groups/types';

// ----------------------------------------------------------------------

type AccessGroupsAddDialogProps = {
  isAccessGroupsAddDialogOpen: boolean;
  setIsAccessGroupsAddDialogOpen: (value: boolean) => void;
  group: AccessGroupListItem | null;
};

const AccessGroupsAddDialog: React.FC<AccessGroupsAddDialogProps> = ({
  isAccessGroupsAddDialogOpen,
  setIsAccessGroupsAddDialogOpen,
  group,
}) => {
  const { t } = useLocales();
  const { enqueueSuccess } = useNotifications();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [accessGroupName, setAccessGroupName] = useState<string>('');

  const [folderList, setFolderList] = useState<DLXDriveFolderListItem[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<DLXDriveFolderListItem[]>([]);

  const [departmentList, setDepartmentList] = useState<AccessGroupsDepartmentListItem[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<AccessGroupsDepartmentListItem[]>(
    []
  );

  const [memberList, setMemberList] = useState<AccessGroupsMemberListItem[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<AccessGroupsMemberListItem[]>([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const clearState = () => {
    setAccessGroupName('');
    setSelectedFolders([]);
    setSelectedDepartments([]);
    setSelectedMembers([]);
  };

  const handleAccessRightsChange = (folderId: number, accessRights: '0' | '1') => {
    const newSelectedFolders = [...selectedFolders];

    newSelectedFolders.forEach((f) => {
      if (f.id === folderId) {
        f.accessRights = accessRights;
      }
    });

    setSelectedFolders(newSelectedFolders);
  };

  const handleClose = () => {
    setIsAccessGroupsAddDialogOpen(false);
  };

  const handleSave = async () => {
    const folders = {};

    selectedFolders.forEach((f) => {
      folders[f.id] = f.accessRights === '0' ? 0 : 1;
    });

    const model = {
      name: accessGroupName,
      departments: selectedDepartments.map((d) => d.id),
      emails: selectedMembers.map((m) => m.email),
      folders,
    };

    try {
      if (group) {
        const editModel = { ...model, id: group.id };

        await editAccessGroup(editModel);

        enqueueSuccess(t('accessGroups.notifications.accessGroupEdited'), {
          groupName: accessGroupName,
        });
      } else {
        await createAccessGroup(model);

        enqueueSuccess(t('accessGroups.notifications.accessGroupCreated'), {
          groupName: accessGroupName,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setReloadAccessGroupListState();
      addStopLoadingState();
      clearState();
      handleClose();
    }
  };

  const fetchFolderList = async () => {
    const folderList = await getFolderList(true);

    setFolderList(folderList);
  };

  const fetchDepartmentList = async () => {
    const departmentList = await getDepartmentsList(true);

    setDepartmentList(departmentList);
  };

  const fetchMemberList = async () => {
    const memberList = await getMembersList(true);

    setMemberList(memberList);
  };

  const getData = async () => {
    await fetchFolderList();
    await fetchDepartmentList();
    await fetchMemberList();

    addStopLoadingState();
  };

  useEffect(() => {
    // ---- Access Group Name ----
    if (group) {
      setAccessGroupName(group.name);
    }

    // ---- Folders ----
    if (folderList.length > 0 && group && group.folders.length > 0) {
      const newSelectedFolders: DLXDriveFolderListItem[] = [];
      const newFolderList = [...folderList];

      group.folders.forEach((folder) => {
        newFolderList.forEach((f) => {
          if (f.id === folder.folderId) {
            f.accessRights = folder.accessLevel === 0 ? '0' : '1';
          }
        });

        setFolderList(newFolderList);

        const newFolder = folderList.find((f) => f.id === folder.folderId);

        if (newFolder) {
          newSelectedFolders.push(newFolder);
        }
      });

      setSelectedFolders(newSelectedFolders);
    }

    // ---- Departments ----
    if (departmentList.length > 0 && group && group.departments.length > 0) {
      const newSelectedDepartments: AccessGroupsDepartmentListItem[] = [];

      group.departments.forEach((department) => {
        const newDepartment = departmentList.filter((d) => d.id === department.departmentId)[0];

        newSelectedDepartments.push(newDepartment);
      });

      setSelectedDepartments(newSelectedDepartments);
    }

    // ---- Members ----
    if (memberList.length > 0 && group && group.users.length > 0) {
      const newSelectedMembers: AccessGroupsMemberListItem[] = [];

      group.users.forEach((user) => {
        const newMember = memberList.filter((m) => m.email === user.email)[0];

        newSelectedMembers.push(newMember);
      });

      setSelectedMembers(newSelectedMembers);
    }

    if (!group) {
      clearState();
    }
  }, [group]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={isAccessGroupsAddDialogOpen} fullWidth maxWidth="sm">
      <DialogTitle>
        {group ? t('accessGroups.editAccessGroup') : t('accessGroups.createAccessGroup')}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          id="access-group-name"
          label={t('accessGroups.accessGroupName')}
          variant="outlined"
          size="small"
          value={accessGroupName}
          onChange={(event) => setAccessGroupName(event.target.value)}
        />

        <Autocomplete
          id="access-group-folders"
          multiple
          disableCloseOnSelect
          value={selectedFolders}
          options={folderList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: DLXDriveFolderListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.name} (id: ${option.id})`}
          onChange={(event: any, newValue: DLXDriveFolderListItem[] | null) => {
            if (newValue) {
              setSelectedFolders(newValue);
            }
          }}
          renderOption={(props, option, { selected }) => (
            <ListItem
              key={option.id}
              secondaryAction={
                selected ? (
                  <ToggleButtonGroup
                    size="small"
                    sx={{ my: 1 }}
                    color="primary"
                    value={selectedFolders.filter((f) => f.id === option.id)[0].accessRights}
                    exclusive
                    onChange={(event, value) => handleAccessRightsChange(option.id, value)}
                  >
                    <ToggleButton size="small" value="0">
                      Read
                    </ToggleButton>

                    <ToggleButton size="small" value="1">
                      Write
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : null
              }
              disablePadding
            >
              <span
                style={{
                  width: '100%',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  marginTop: '2px',
                  marginBottom: '2px',
                }}
                {...props}
              >
                <ListItemIcon>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                </ListItemIcon>
                <ListItemText
                  primary={`${option.name} (id: ${option.id})`}
                  sx={{ maxWidth: '494px' }}
                />
              </span>
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('accessGroups.selectFolders')}
              placeholder={t('shared.fields.selected')}
            />
          )}
        />

        <Autocomplete
          id="access-group-departments"
          multiple
          disableCloseOnSelect
          value={selectedDepartments}
          options={departmentList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: AccessGroupsDepartmentListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.name}`}
          onChange={(event: any, newValue: AccessGroupsDepartmentListItem[] | null) => {
            newValue ? setSelectedDepartments(newValue) : setSelectedDepartments([]);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('accessGroups.addDepartments')}
              placeholder={t('shared.fields.selected')}
            />
          )}
        />

        <Autocomplete
          id="access-group-emails"
          multiple
          disableCloseOnSelect
          value={selectedMembers}
          options={memberList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: AccessGroupsMemberListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
          onChange={(event: any, newValue: AccessGroupsMemberListItem[] | null) => {
            newValue ? setSelectedMembers(newValue) : setSelectedMembers([]);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${option.firstName} ${option.lastName} (${option.email})`}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('accessGroups.addMembers')}
              placeholder={t('shared.fields.selected')}
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton
          id="access-group-cancel"
          loading={isLoading}
          variant="outlined"
          onClick={handleClose}
        >
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          id="access-group-save"
          disabled={accessGroupName.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleSave}
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AccessGroupsAddDialog;
