import { useState, ReactNode, useEffect, createContext } from 'react';

import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

// ----------------------------------------------------------------------

type DocumentsTypeProviderProps = {
  documentType: DocumentModuleTypes;
  children: ReactNode;
};

const initialState = DocumentModuleTypes.Standard;
const DocumentsTypeContext = createContext(initialState);

function DocumentsTypeProvider({ documentType, children }: DocumentsTypeProviderProps) {
  const [docType, setDocType] = useState<DocumentModuleTypes>(initialState);

  useEffect(() => {
    setDocType(documentType);
  }, [documentType]);

  return <DocumentsTypeContext.Provider value={docType}>{children}</DocumentsTypeContext.Provider>;
}

export { DocumentsTypeContext, DocumentsTypeProvider };
