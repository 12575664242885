import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Dialog,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../components/Scrollbar';
import useNotifications from '../../../../hooks/useNotifications';
import { setDriveListIdState } from '../../../../redux/slices/dlxDrive';
import { changeFolderIcon } from '../../../../services/dlxDriveService';
import DLXDriveFolderIcon from '../../../../utils/dlx-drive/DLXDriveFolderIcon';
import {
  FOLDER_ICON_LARGE_SIZE,
  FOLDER_DEFAULT_ICON_COLOR,
} from '../../../../utils/constants/dlxDriveConstants';

// ----------------------------------------------------------------

type DLXDriveChangeFolderIconDialogProps = {
  folderId: number;
  folderName: string;
  folderColor: string;
  folderIcon: string;
  isChangeFolderIconDialogOpen: boolean;
  setIsChangeFolderIconDialogOpen: (value: boolean) => void;
};

const DLXDriveChangeFolderIconDialog: React.FC<DLXDriveChangeFolderIconDialogProps> = ({
  folderId,
  folderName,
  folderColor,
  folderIcon,
  isChangeFolderIconDialogOpen,
  setIsChangeFolderIconDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [newIcon, setNewIcon] = useState<string>('');
  const { enqueueSuccess } = useNotifications();
  const theme = useTheme();

  const predefinedIcons = [
    'AbcRoundedIcon',
    'AcUnitRoundedIcon',
    'AccessTimeRoundedIcon',
    'AccountBalanceRoundedIcon',
    'AccountBalanceWalletRoundedIcon',
    'AccountBoxRoundedIcon',
    'AddAlertRoundedIcon',
    'AirplanemodeActiveRoundedIcon',
    'AirportShuttleRoundedIcon',
    'AlternateEmailRoundedIcon',
    'ApartmentRoundedIcon',
    'AssignmentLateRoundedIcon',
    'AssignmentTurnedInRoundedIcon',
    'AssignmentRoundedIcon',
    'AssistantPhotoRoundedIcon',
    'AttachEmailRoundedIcon',
    'AutoStoriesRoundedIcon',
    'BackHandRoundedIcon',
    'BalanceRoundedIcon',
    'BookmarkAddedRoundedIcon',
    'BookmarkRemoveRoundedIcon',
    'BookmarkRoundedIcon',
    'BookmarksRoundedIcon',
    'BorderColorRoundedIcon',
    'CalculateRoundedIcon',
    'CalendarMonthRoundedIcon',
    'CalendarTodayRoundedIcon',
    'CallRoundedIcon',
    'CardTravelRoundedIcon',
    'CasesRoundedIcon',
    'CheckBoxRoundedIcon',
    'CheckCircleRoundedIcon',
    'ChildFriendlyRoundedIcon',
    'ContactEmergencyRoundedIcon',
    'ContactMailRoundedIcon',
    'DraftsRoundedIcon',
    'DriveFileMoveRoundedIcon',
    'DriveFolderUploadRoundedIcon',
    'FiberNewRoundedIcon',
    'FolderCopyRoundedIcon',
    'FolderDeleteRoundedIcon',
    'FolderOffRoundedIcon',
    'FolderOpenRoundedIcon',
    'FolderRoundedIcon',
    'FolderSharedRoundedIcon',
    'FolderSpecialRoundedIcon',
    'FolderZipRoundedIcon',
    'HttpsRoundedIcon',
    'LocalPrintshopRoundedIcon',
    'ShoppingCartRoundedIcon',
    'SecurityRoundedIcon',
    'SendRoundedIcon',
    'SaveRoundedIcon',
    'RocketRoundedIcon',
    'LocalFireDepartmentRoundedIcon',
    'HomeRoundedIcon',
  ];

  const PreviewBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '108px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[800],
  }));

  const lightModeBackgroundColor = {
    backgroundColor: theme.palette.grey[0],
  };

  const darkModeBackgroundColor = {
    backgroundColor: theme.palette.grey[800],
  };

  const handleClose = () => {
    setIsChangeFolderIconDialogOpen(false);
  };

  const handleIconChange = async () => {
    try {
      await changeFolderIcon(folderId, newIcon);

      enqueueSuccess('dlxDrive.notifications.iconUpdatedSuccessfully', { folderName });
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsChangeFolderIconDialogOpen(false);
    }
  };

  useEffect(() => {
    setNewIcon(folderIcon);
  }, [folderIcon]);

  return (
    <Dialog open={isChangeFolderIconDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.changeIcon', { folderName })}</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>{t('dlxDrive.dialogs.chooseIcon')}</DialogContentText>

        <Scrollbar sx={{ maxHeight: '236px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            {predefinedIcons.map((icon, index) => (
              <Box
                key={`${icon}-${index}`}
                sx={{ cursor: 'pointer' }}
                onClick={() => setNewIcon(icon)}
              >
                <DLXDriveFolderIcon
                  icon={icon}
                  color={folderColor.length > 0 ? folderColor : FOLDER_DEFAULT_ICON_COLOR}
                  fontSize="40px"
                />
              </Box>
            ))}
          </Box>
        </Scrollbar>

        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Divider>{t('layout.preview')}</Divider>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <PreviewBox style={lightModeBackgroundColor}>
                <DLXDriveFolderIcon
                  icon={newIcon}
                  color={folderColor.length > 0 ? folderColor : FOLDER_DEFAULT_ICON_COLOR}
                  fontSize={`${FOLDER_ICON_LARGE_SIZE}px`}
                />
              </PreviewBox>

              {/* ---- Will be activated when dark mode is ready ----*/}
              {/* <Typography variant="caption">{t('layout.lightMode')}</Typography> */}
            </Box>

            {/* ---- Will be activated when dark mode is ready ----*/}
            {/* <Box sx={{ flex: 1 }}> */}
            {/*  <PreviewBox style={darkModeBackgroundColor}> */}
            {/*    <DLXDriveFolderIcon */}
            {/*      icon={newIcon} */}
            {/*      color={folderColor.length > 0 ? folderColor : FOLDER_DEFAULT_ICON_COLOR} */}
            {/*      fontSize={`${FOLDER_ICON_LARGE_SIZE}px`} */}
            {/*    /> */}
            {/*  </PreviewBox> */}

            {/*  <Typography variant="caption">{t('layout.darkMode')}</Typography> */}
            {/* </Box> */}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={newIcon === folderIcon}
          loading={isLoading}
          variant="contained"
          onClick={handleIconChange}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveChangeFolderIconDialog;
