import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const TablePagination = (theme: Theme) => ({
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '& .MuiTablePagination-root': {
          borderTop: 'none',
        },
        '& .MuiSvgIcon-root, & .MuiSelect-icon': {
          top: 'calc(50% - .8em)',
        },
      },
    },
  },
});

export default TablePagination;
