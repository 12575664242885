import React, { useState } from 'react';

import { Box, Tooltip, useTheme, IconButton } from '@mui/material';

import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------

type DLXDriveBulkActionComponentButtonProps = {
  isDisabled: boolean;
  icon: string;
  tooltipTitle: string;
  onClick: () => void;
  id: string;
};

const DLXDriveBulkActionComponentButton: React.FC<DLXDriveBulkActionComponentButtonProps> = ({
  isDisabled,
  icon,
  tooltipTitle,
  onClick,
  id,
}) => {
  const theme = useTheme();
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Box>
        <IconButton
          disabled={isDisabled}
          onClick={onClick}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          id={id}
        >
          <SvgIconStyle
            src={`/icons/dlx-drive/actions/${icon}.svg`}
            sx={{
              width: '20px',
              height: '20px',
              color: isMouseOver
                ? theme.palette.primary.main
                : isDisabled
                  ? null
                  : theme.palette.grey[400],
            }}
          />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default DLXDriveBulkActionComponentButton;
