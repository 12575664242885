import { createSlice } from '@reduxjs/toolkit';

import { Tag } from 'src/@types/tags/tag';
import documentService from 'src/services/documentService';
import { DocumentState, DocumentSetTags } from 'src/@types/documents/types';
import { OwnDocumentsFilter } from 'src/@types/documents/ownDocumentsFilter';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: DocumentState = {
  isLoading: false,
  isFileUploading: false,
  error: null,
  documents: null,
  file: null,
  selectedId: null,
  document: null,
  containerFiles: null,
  documentReloadState: 1,
};

const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    clearState(state) {
      state.isFileUploading = initialState.isFileUploading;
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.documents = initialState.documents;
      state.file = initialState.file;
      state.selectedId = initialState.selectedId;
      state.document = initialState.document;
      state.containerFiles = initialState.containerFiles;
      state.documentReloadState = initialState.documentReloadState;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },

    getFileSuccess(state, action) {
      state.isLoading = false;
      const fileName =
        state.document?.name ||
        state.documents?.items.find((x) => x.id === state.selectedId)?.name ||
        '';
      const file = new File(action.payload, fileName);
      state.file = file;
    },

    fileUploaded(state, action) {
      state.file = action.payload;
    },

    fileCleared(state) {
      state.file = null;
      state.isLoading = false;
    },

    getDocumentsError(state, action) {
      state.isLoading = false;
      state.documents = initialState.documents;
      state.error = action.payload;
    },

    getFileError(state, action) {
      state.isLoading = false;
      state.file = null;
      state.error = action.payload;
    },

    documentSelected(state, action) {
      state.selectedId = action.payload;
    },

    getDocumentSuccess(state, action) {
      state.isLoading = false;
      state.document = action.payload;
      state.selectedId = action.payload.id;
    },

    getDocumentError(state, action) {
      state.isLoading = false;
      state.document = null;
      state.selectedId = null;
      state.error = action.payload;
    },

    tagsUpdated(state, action) {
      state.document = state.document ? { ...state.document, tags: action.payload } : null;
    },

    containerFilesChanged(state, action) {
      state.containerFiles = { files: action.payload, selectedIndex: 0 };
    },

    containerFileSelected(state, action) {
      state.containerFiles = state.containerFiles
        ? {
            ...state.containerFiles,
            selectedIndex: action.payload,
          }
        : state.containerFiles;
    },

    setIsOnlyApprove(state, action) {
      state.document = state.document
        ? { ...state.document, isOnlyApprove: action.payload }
        : state.document;
    },

    setIsOnlyHandwritten(state, action) {
      state.document = state.document
        ? { ...state.document, isOnlyHandwritten: action.payload }
        : state.document;
    },

    increaseDocumentReloadState(state) {
      state.documentReloadState += 1;
    },
  },
});

// Reducer
export default slice.reducer;

export function setSelectedDocumentId(id: number | null) {
  dispatch(slice.actions.documentSelected(id));
}

export async function getOwnDocuments(filter: OwnDocumentsFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await documentService.getOwnDocuments(filter);
    dispatch(slice.actions.getDocumentsSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getDocumentsError(error));
  }
}

export function uploadFile(file: File) {
  dispatch(slice.actions.fileUploaded(file));
}

export function setContainerFiles(files: File[]) {
  dispatch(slice.actions.containerFilesChanged(files));
}

export function selectContainerFile(index: number) {
  dispatch(slice.actions.containerFileSelected(index));
}

export function clearFile() {
  dispatch(slice.actions.fileCleared());
}

export function clearDocumentsState() {
  dispatch(slice.actions.clearState());
}

export async function getFile(id: number | null) {
  if (id) {
    dispatch(slice.actions.startLoading());
    try {
      const blob = await documentService.getFile(id);
      dispatch(slice.actions.getFileSuccess([blob]));
    } catch (error) {
      dispatch(slice.actions.getFileError(error));
    }
  } else {
    dispatch(slice.actions.getFileError('documents.errors.documentNotFound'));
  }
}

export async function getDocument(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await documentService.getDocument(id);
    dispatch(slice.actions.getDocumentSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getDocumentError(error));
    throw error;
  }
}

export async function removeDocument(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    await documentService.removeDocument(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function hideDocument(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    await documentService.hideDocument(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function editDocumentFile(id: number, file: File) {
  if (!id || !file) {
    return;
  }
  dispatch(slice.actions.startLoading());
  try {
    await documentService.editDocumentFile(id, file);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function setDocumentTags(id: number, tags: Tag[]) {
  dispatch(slice.actions.startLoading());
  try {
    const request = {
      id,
      tagIds: tags.map((t) => t.id),
    } as DocumentSetTags;

    await documentService.setDocumentTags(request);
    dispatch(slice.actions.finishLoading());
    dispatch(slice.actions.tagsUpdated(tags));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export function setApproveOnly(isOnlyApprove: boolean) {
  dispatch(slice.actions.setIsOnlyApprove(isOnlyApprove));
}

export function setHandwrittenSignOnly(IsOnlyHandwritten: boolean) {
  dispatch(slice.actions.setIsOnlyHandwritten(IsOnlyHandwritten));
}

export const increaseDocumentReloadState = () => {
  dispatch(slice.actions.increaseDocumentReloadState());
};
