import { FormElementValidationType } from '../enums/FormElementValidationType';

export type ElementValidationsProps = {
  label: string;
  value: FormElementValidationType;
};

export const ELEMENT_NO_VALIDATION: ElementValidationsProps = { label: 'forms.validations.noValidation', value: FormElementValidationType.NoValidation };
export const ELEMENT_IS_NUMBER: ElementValidationsProps = { label: 'forms.validations.numbersOnly', value: FormElementValidationType.OnlyNumbers };
export const ELEMENT_IS_EMAIL: ElementValidationsProps = { label: 'forms.validations.emailAddress', value: FormElementValidationType.Email };

export const ELEMENT_NUMBER_OF_CHARACTERS: ElementValidationsProps = {
  label: 'forms.validations.numberOfCharacters',
  value: FormElementValidationType.NumberOfCharacters,
};
