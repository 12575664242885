import { createSlice } from '@reduxjs/toolkit';

import { ModuleState } from 'src/@types/module/types';
import aspModuleService from 'src/services/asp-identity/aspModuleService';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: ModuleState = {
  isLoading: false,
  modules: null,
};

const slice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.modules = initialState.modules;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    getModulesSuccess(state, action) {
      state.isLoading = false;
      state.modules = action.payload;
    },

    getModulesError(state, action) {
      state.isLoading = false;
      state.modules = initialState.modules;
    },
  },
});

// Reducer
export default slice.reducer;

export async function getModules() {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspModuleService.getModules();
    dispatch(slice.actions.getModulesSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getModulesError(error));
  }
}
