const ltLT = {
  components: {
    MuiBreadcrumbs: {
      defaultProps: {
        expandText: 'Rodyti visą kelią',
      },
    },
    MuiTablePagination: {
      defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === 'first') {
            return 'Eiti į pirmąjį puslapį';
          }

          if (type === 'last') {
            return 'Eiti į paskutinį puslapį';
          }

          if (type === 'next') {
            return 'Eiti į kitą puslapį';
          } // if (type === 'previous') {

          return 'Eiti į ankstesnį puslapį';
        },
        labelRowsPerPage: 'Eilutės puslapyje:',
        labelDisplayedRows: ({ from, to, count }) => `${from}–${to} nuo ${count !== -1 ? count : `daugiau nei ${to}`}`,
      },
    },
    MuiRating: {
      defaultProps: {
        getLabelText: (value) => {
          let pluralForm = 'Žvaigždės';
          const lastDigit = value % 10;

          if (lastDigit > 1 && lastDigit < 5) {
            pluralForm = 'Žvaigždės';
          } else if (lastDigit === 1) {
            pluralForm = 'Žvaigždė';
          }

          return `${value} ${pluralForm}`;
        },
        emptyLabelText: 'Nėra įvertinimo',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: 'Išvalyti',
        closeText: 'Uždaryti',
        loadingText: 'Įkeliama…',
        noOptionsText: 'Nėra parinkčių',
        openText: 'Atidaryti',
      },
    },
    MuiAlert: {
      defaultProps: {
        closeText: 'Uždaryti',
      },
    },
    MuiPagination: {
      defaultProps: {
        'aria-label': 'Puslapio naršymas',
        getItemAriaLabel: (type, page, selected) => {
          if (type === 'page') {
            if (selected) {
              return `${page} puslapį`;
            }

            return `Eiti į ${page} puslapį`;
          }

          if (type === 'first') {
            return 'Eiti į pirmąjį puslapį';
          }

          if (type === 'last') {
            return 'Eiti į paskutinį puslapį';
          }

          if (type === 'next') {
            return 'Eiti į kitą puslapį';
          }

          return 'Eiti į ankstesnį puslapį';
        },
      },
    },
  },
};

export default ltLT;
