export const handleTextAlign = (alignEnum: number) => {
  let textAlign = '';

  switch (alignEnum) {
    case 0:
      textAlign = 'left';
      break;
    case 1:
      textAlign = 'center';
      break;
    case 2:
      textAlign = 'right';
      break;
    default:
      textAlign = 'left';
  }

  return textAlign;
};
