const autoLogoutExpiredTime = 'autoLogoutExpiredTime';
const autoSessionExpiredTimePopup = 'autoSessionExpiredTimePopup';

export class IdleTimer {
  timeout: number | undefined;

  onTimeout: VoidFunction;

  onPopup: VoidFunction;

  eventHandler: () => void;

  interval: NodeJS.Timer;

  timeoutTracker: NodeJS.Timer;

  constructor(timeout: number, onTimeout: VoidFunction, onPopup: VoidFunction) {
    if (!timeout) {
      return;
    }

    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.onPopup = onPopup;

    this.tracker();
    this.startInterval();
  }

  startInterval = () => {
    if (this.timeout) {
      localStorage.setItem(autoLogoutExpiredTime, (Date.now() + this.timeout * 1000).toString());
      localStorage.setItem(
        autoSessionExpiredTimePopup,
        (Date.now() + (this.timeout * 1000) / 2).toString()
      );
    }

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(autoLogoutExpiredTime) || '0', 10);
      const expiredTimePopup = parseInt(
        localStorage.getItem(autoSessionExpiredTimePopup) || '0',
        10
      );

      if (expiredTime && expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }

      if (expiredTimePopup && expiredTimePopup < Date.now()) {
        this.onPopup();
        localStorage.removeItem(autoSessionExpiredTimePopup);
      }
    }, 1000);
  };

  updateExpiredTime = () => {
    if (this.timeoutTracker) {
      // @ts-ignore
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      const expiredTime = parseInt(localStorage.getItem(autoLogoutExpiredTime) || '0', 10);
      if (expiredTime && this.timeout) {
        localStorage.setItem(autoLogoutExpiredTime, (Date.now() + this.timeout * 1000).toString());
        localStorage.setItem(
          autoSessionExpiredTimePopup,
          (Date.now() + (this.timeout * 1000) / 2).toString()
        );
      }
    }, 300);
  };

  tracker = () => {
    window.addEventListener('mousemove', this.updateExpiredTime);
    window.addEventListener('scroll', this.updateExpiredTime);
    window.addEventListener('keydown', this.updateExpiredTime);
  };

  cleanUp = () => {
    localStorage.removeItem(autoLogoutExpiredTime);
    // @ts-ignore
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.updateExpiredTime);
    window.removeEventListener('scroll', this.updateExpiredTime);
    window.removeEventListener('keydown', this.updateExpiredTime);
  };
}
