import { toLocal } from 'src/utils/dateTimeUtils';

export class DelegationModel {
  dateFrom: Date;

  dateTo: Date;

  email: string;

  isEnabled: boolean;

  parseJson(json: any): DelegationModel | null {
    if (json === null || json === undefined || json === '') {
      return null;
    }

    const model: DelegationModel = new DelegationModel();

    model.dateFrom = toLocal(json.dateFrom);
    model.dateTo = toLocal(json.dateTo);
    model.email = json.email;
    model.isEnabled = json.isEnabled;

    return model;
  }
}
