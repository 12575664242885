// @mui
import { BoxProps } from '@mui/material';
import { Theme, styled, useTheme } from '@mui/material/styles';

// -----------------------------------

const RootStyle = styled('span')(
  ({
    theme,
    ownerState,
  }: {
    theme: Theme;
    ownerState: {
      color: string;
    };
  }) => {
    const { color } = ownerState;

    function readableColour() {
      if (color?.length !== 7) {
        return '#000000';
      }

      const r = Number(`0x${  color.substring(1, 3)}`);
      const g = Number(`0x${  color.substring(3, 5)}`);
      const b = Number(`0x${  color.substring(5, 7)}`);

      const contrast = Math.sqrt(r * r * 0.241 + g * g * 0.691 + b * b * 0.068);

      if (contrast > 130) {
        return '#000000';
      } 
        return '#FFFFFF';
      
    }

    return {
      height: 24,
      minWidth: 24,
      lineHeight: 0,
      borderRadius: 6,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightBold,
      // color: color,
      color: readableColour(),
      backgroundColor: color,
      overflow: 'hidden',
      border: `1px solid ${theme.palette.grey[500_16]}`,
    };
  }
);

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  color?: string;
}

export default function TagLabel({ color = '#FFFFFF', children, sx }: Props) {
  const theme = useTheme();

  return (
    <RootStyle ownerState={{ color }} sx={sx} theme={theme}>
      {children}
    </RootStyle>
  );
}
