import { useSearchParams } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import { useGetDimensions } from 'react-hooks-get-dimensions';

import { Box, Card } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import CalendarSearch from './CalendarSearch';
import CalendarLegend from './CalendarLegend';
import { useSelector } from '../../redux/store';
import CalendarDepartments from './CalendarDepartments';
import CalendarMobileLayout from './CalendarMobileLayout';
import { setIsLoading } from '../../redux/slices/calendar';
import CalendarDesktopLayout from './CalendarDesktopLayout';
import { SystemLocales } from '../../locales/forms/SystemLocales';
import CalendarMobileDateFilter from './CalendarMobileDateFilter';
import CalendarDesktopDateFilter from './CalendarDesktopDateFilter';
import CalendarDesktopLayoutItem from './CalendarDesktopLayoutItem';
import CalendarMobileSkeletonLayout from './CalendarMobileSkeletonLayout';
import { CALENDAR_BANK_HOLIDAY_COUNTRIES } from '../../@types/calendar/constants/cell';
import { CalendarDateRangeType } from '../../@types/calendar/enums/CalendarDateRangeType';
import {
  getBankHoliday,
  getCalendarData,
  getCalendarMobileData,
} from '../../services/calendarService';
import {
  QueryProps,
  urlParamsProps,
  LegendItemProps,
  CalendarDayProps,
  CalendarUserProps,
  CalendarDepartmentProps,
  CalendarBankHolidayProps,
} from '../../@types/calendar/types';

// ----------------------------------------------------------------

type CalendarComponentProps = {
  externalQuery?: urlParamsProps;
};

const CalendarComponent: React.FC<CalendarComponentProps> = ({ externalQuery }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState<urlParamsProps>({});
  const [calendarDays, setCalendarDays] = useState<CalendarDayProps[]>([]);
  const [calendarDepartments, setCalendarDepartments] = useState<CalendarDepartmentProps[]>([]);
  const [calendarMobileData, setCalendarMobileData] = useState<CalendarUserProps[]>([]);
  const [calendarLegend, setCalendarLegend] = useState<LegendItemProps[]>([]);
  const { settings } = useSelector((state) => state.company);
  const { isLoading } = useSelector((state) => state.calendar);
  const isMobile = useMediaQuery('(max-width:480px)');
  const itemWrapperWidth = useRef();
  const { width } = useGetDimensions(itemWrapperWidth);

  const [query, setQuery] = useState<QueryProps>({
    date: '',
    range: isMobile ? CalendarDateRangeType.OneDay : CalendarDateRangeType.FourWeeks,
    departmentIds: [],
    searchQuery: '',
    calendarTypeIds: [],
  });

  const handleQueryChange = async () => {
    if (query.date !== '') {
      try {
        const data = await getCalendarData(query);

        const { dates, departments, legend } = data;

        const bankHoliday = await getBankHoliday(
          dates[0].date,
          dates[dates.length - 1].date,
          settings && settings.locales ? settings.locales : CALENDAR_BANK_HOLIDAY_COUNTRIES
        );

        setCalendarDays(
          dates.map((item: CalendarDayProps) => ({
            ...item,
            bankHoliday: bankHoliday.filter(
              (holiday: CalendarBankHolidayProps) => item.date === holiday.date
            ),
          }))
        );

        setCalendarDepartments(departments);

        setCalendarLegend(legend);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleMobileQueryChange = async () => {
    if (query.date !== '') {
      try {
        const data = await getCalendarMobileData(query);

        const { users, legend } = data;

        setCalendarMobileData(users);
        setCalendarLegend(legend);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isMobile && query.range === CalendarDateRangeType.OneDay) {
      handleMobileQueryChange();
    } else {
      handleQueryChange();
    }
  }, [query]);

  useEffect(() => {
    const { date, range, departments, search, events } = urlParams;

    let newQuery: QueryProps = { ...query };

    if (!date) {
      const dateNow = new Date().toLocaleDateString(SystemLocales.lt);

      setSearchParams({
        date: dateNow,
        range: range || `${CalendarDateRangeType.FourWeeks}`,
      });
    }

    if (date) {
      newQuery.date = date;

      if (range) {
        newQuery.range = parseInt(range, 10) as CalendarDateRangeType;
      } else {
        newQuery.range = isMobile ? CalendarDateRangeType.OneDay : CalendarDateRangeType.FourWeeks;
      }

      if (departments) {
        newQuery.departmentIds = departments
          .trim()
          .replaceAll(' ', '')
          .split('-')
          .map((item) => parseInt(item, 10));
      } else {
        newQuery.departmentIds = [];
      }

      if (search) {
        newQuery.searchQuery = search;
      } else {
        newQuery.searchQuery = '';
      }

      if (events) {
        newQuery.calendarTypeIds = events
          .trim()
          .replaceAll(' ', '')
          .split(',')
          .map((item) => parseInt(item, 10));
      }
    }

    setQuery(newQuery);
  }, [urlParams]);

  useEffect(() => {
    const date = searchParams.get('date');
    const range = searchParams.get('range');
    const departments = searchParams.get('departments');
    const search = searchParams.get('search');
    const events = searchParams.get('events');

    const newUrlParams: urlParamsProps = {};

    if (date) {
      newUrlParams.date = date;
    }

    if (range) {
      newUrlParams.range = range;
    }

    if (departments) {
      newUrlParams.departments = departments;
    }

    if (search) {
      newUrlParams.search = search;
    }

    if (events) {
      newUrlParams.events = events;
    }

    setUrlParams(newUrlParams);
  }, [searchParams]);

  useEffect(() => {
    if (isMobile && urlParams.range !== `${CalendarDateRangeType.OneDay}`) {
      setSearchParams({
        date: query.date,
        range: `${CalendarDateRangeType.OneDay}`,
      });
    }

    if (!isMobile && urlParams.range === `${CalendarDateRangeType.OneDay}`) {
      setSearchParams({
        date: query.date,
        range: `${CalendarDateRangeType.CurrentMonth}`,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (externalQuery) {
      const newParams: urlParamsProps = {};
      const { date, range, departments, search } = externalQuery;

      if (date) {
        newParams.date = date;
      }

      if (range) {
        newParams.range = `${range}`;
      }

      if (departments) {
        newParams.departments = departments;
      }

      if (search) {
        newParams.search = search;
      }

      setSearchParams(newParams);
      setUrlParams(newParams);
    }
  }, []);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, p: 2 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <CalendarDepartments urlParams={urlParams} setSearchParams={setSearchParams} />

        <CalendarSearch urlParams={urlParams} setSearchParams={setSearchParams} />
      </Box>

      {/* ---- Date Filter ----*/}
      {!isMobile && (
        <CalendarDesktopDateFilter urlParams={urlParams} setSearchParams={setSearchParams} />
      )}

      {isMobile && (
        <CalendarMobileDateFilter urlParams={urlParams} setSearchParams={setSearchParams} />
      )}

      {/* ---- Layout ----*/}
      {!isMobile && (
        <Box
          sx={{
            height:
              calendarLegend.length > 0
                ? `calc(100vh - ${externalQuery ? '560px' : '380px'})`
                : `calc(100vh - ${externalQuery ? '496px' : '316px'})`,
            overflow: 'auto',
            width: '100%',
            pt: '2px',
          }}
        >
          {!isLoading && (
            <CalendarDesktopLayout days={calendarDays} departments={calendarDepartments} />
          )}

          {/* ---- Desktop Skeleton ----*/}
          {isLoading && <CalendarDesktopLayoutItem />}
        </Box>
      )}

      {isMobile && !isLoading && <CalendarMobileLayout calendarMobileData={calendarMobileData} />}

      {/* ---- Mobile Skeleton ----*/}
      {isMobile && isLoading && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <CalendarMobileSkeletonLayout />
        </Box>
      )}

      {/* ---- Legend ----*/}
      <Box ref={itemWrapperWidth} sx={{ width: '100%' }}>
        {calendarLegend.length > 0 && !isLoading && (
          <Box ref={itemWrapperWidth}>
            <CalendarLegend legend={calendarLegend} isMobile={isMobile} wrapperWidth={width} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CalendarComponent;
