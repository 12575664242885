import React from 'react';

import { Box, Divider, Tooltip, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import useLocales from '../../hooks/useLocales';
import { CALENDAR_CELL_WIDTH } from '../../@types/calendar/constants/cell';
import { CalendarUserProps, CalendarEventProps } from '../../@types/calendar/types';

// ----------------------------------------------------------------

type CalendarDesktopEventTooltipProps = {
  children: any;
  events: CalendarEventProps[];
  date: string;
  user: CalendarUserProps;
};

const CalendarDesktopEventTooltip: React.FC<CalendarDesktopEventTooltipProps> = ({
  children,
  events,
  date,
  user,
}) => {
  const { t, currentLang } = useLocales();

  return (
    <Tooltip
      title={
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
            <CalendarMonthIcon
              sx={{
                width: `${CALENDAR_CELL_WIDTH}px`,
                height: `${CALENDAR_CELL_WIDTH}px`,
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2" noWrap>
                {user.fullName}
              </Typography>

              <Typography variant="body2" noWrap>
                {date}
              </Typography>
            </Box>
          </Box>

          <Divider />

          {events.map((event, index) => {
            const { eventTypeName, eventTypeColor, approved } = event;

            return (
              <Box
                key={`tooltip-key-${index}`}
                sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}
              >
                {approved && (
                  <Box
                    sx={{
                      width: `${CALENDAR_CELL_WIDTH}px`,
                      height: `${CALENDAR_CELL_WIDTH}px`,
                      border: `1px solid ${eventTypeColor}`,
                      borderRadius: '50%',
                    }}
                  >
                    <Box
                      sx={{
                        width: `${CALENDAR_CELL_WIDTH}px`,
                        height: `${CALENDAR_CELL_WIDTH}px`,
                        borderRadius: '50%',
                        backgroundColor: `${eventTypeColor}`,
                      }}
                    />
                  </Box>
                )}

                {!approved && (
                  <Box
                    sx={{
                      width: `${CALENDAR_CELL_WIDTH}px`,
                      height: `${CALENDAR_CELL_WIDTH}px`,
                      border: `1px solid ${eventTypeColor}`,
                      borderRadius: '50%',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Box
                      sx={{
                        width: `${CALENDAR_CELL_WIDTH}px`,
                        height: `${CALENDAR_CELL_WIDTH}px`,
                        borderRadius: '50%',
                        opacity: 0.4,
                        background: `repeating-linear-gradient(45deg, ${eventTypeColor}, ${eventTypeColor} 2px, #fff 2px, #fff 4px)`,
                      }}
                    />
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="subtitle1">
                    {JSON.parse(eventTypeName)[currentLang.value]}
                  </Typography>

                  {!approved && (
                    <Typography variant="caption">
                      {t('calendar.events.awaitingApproval')}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      }
      placement="top"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6],
              },
            },
          ],
        },
      }}
      sx={{ flex: 1 }}
    >
      {children}
    </Tooltip>
  );
};

export default CalendarDesktopEventTooltip;
