import React from 'react';

import { Box, Divider, Tooltip, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { CalendarBankHolidayProps } from '../../@types/calendar/types';
import { CALENDAR_CELL_WIDTH } from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopBankHolidayTooltipProps = {
  children: any;
  date: string;
  bankHoliday: CalendarBankHolidayProps[];
};

const CalendarDesktopBankHolidayTooltip: React.FC<CalendarDesktopBankHolidayTooltipProps> = ({
  children,
  date,
  bankHoliday,
}) => (
    <Tooltip
      title={
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
            <CalendarMonthIcon
              sx={{
                width: `${CALENDAR_CELL_WIDTH}px`,
                height: `${CALENDAR_CELL_WIDTH}px`,
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2" noWrap>
                {date}
              </Typography>
            </Box>
          </Box>

          <Divider />

          {bankHoliday.map((holiday, index) => (
            <Box
              key={`bank-holiday-key-${index}`}
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}
            >
              <img
                loading="lazy"
                src={`https://flagcdn.com/40x30/${holiday.countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/80x60/${holiday.countryCode.toLowerCase()}.png 2x, https://flagcdn.com/120x90/${holiday.countryCode.toLowerCase()}.png 3x`}
                width="40"
                height="30"
                alt={holiday.countryCode}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">{holiday.localName}</Typography>

                <Typography variant="caption">{holiday.name}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      }
      placement="top"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -6],
              },
            },
          ],
        },
      }}
      sx={{ flex: 1 }}
    >
      {children}
    </Tooltip>
  );

export default CalendarDesktopBankHolidayTooltip;
