import JSZip from 'jszip';

import { getFileType } from 'src/utils/getFileFormat';

import { FileExtensions } from 'src/@types/fileView/enums/fileExtensions';

export type FileType =
  | 'application/pdf'
  | 'application/zip'
  | 'application/vnd.etsi.asic-e+zip'
  | 'application/vnd.lt.archyvai.adoc-2008'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-powerpoint'
  | 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  | 'image/jpg'
  | 'image/png'
  | 'image/gif'
  | 'image/tiff';

export async function getBytesFromFile(file: File) {
  return new Promise<number[]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = event.target.result;
      const fileBytes = new Uint8Array(data as ArrayBuffer);
      const byteArray = Array.from(fileBytes);
      resolve(byteArray);
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function getFileBytesFromFile(file: File) {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = event.target.result;
      const fileBytes = new Uint8Array(data as ArrayBuffer);
      resolve(fileBytes);
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function getBase64FromFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = event.target.result.split('base64,')[1];
      resolve(data);
    };
    reader.readAsDataURL(file);
  });
}

export async function getFileDataAsBinaryString(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
}

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
  type: FileType
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type });
}

export function getFileStringType(base64: string): FileType | null {
  switch (base64.charAt(0)) {
    case '/':
      return 'image/jpg';
    case 'i':
      return 'image/png';
    case 'J':
      return 'application/pdf';
    default:
      return null;
  }
}

export async function getContainerFile(file: File): Promise<File | null> {
  const availableExtensions = [
    FileExtensions.Pdf,
    FileExtensions.Docx,
    FileExtensions.Xlsx,
    FileExtensions.Pptx,
  ];

  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const mainFileKey = Object.keys(zip.files).find((f: string) =>
    availableExtensions.includes(getFileType(f) as FileExtensions)
  );
  const mainFile = zip.files[mainFileKey || ''];
  if (!mainFile) {
    return null;
  }

  const blob = await mainFile.async('blob');
  const zfile = new File([blob], mainFile.name, {
    lastModified: mainFile.date.getTime(),
    type: 'blob',
  });

  return zfile;
}

const isContainerFile = (fileKey: string) => {
  const arr = fileKey.split('.');
  const ext = arr[arr.length - 1];

  return ext === 'adoc' || ext === 'asice' || ext === 'edoc' || ext === 'bdoc';
};

export async function getAllContainerFiles(file: File): Promise<File[]> {
  const availableExtensions = [
    FileExtensions.Pdf,
    FileExtensions.Doc,
    FileExtensions.Docx,
    FileExtensions.Pptx,
    FileExtensions.Ppt,
    FileExtensions.Xlsx,
    FileExtensions.Xls,
    FileExtensions.Adoc,
    FileExtensions.Asic,
    FileExtensions.Bdoc,
    FileExtensions.Edoc,
  ];

  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const fileKeys = Object.keys(zip.files).filter((f: string) =>
    availableExtensions.includes(getFileType(f) as FileExtensions)
  );

  const files = await Promise.all(
    fileKeys.map(async (fk) => {
      const isContainer = isContainerFile(fk);

      const zipFile = zip.files[fk];
      const blob = await zipFile.async('blob');
      const zfile = new File([blob], zipFile.name, {
        lastModified: zipFile.date.getTime(),
        type: 'blob',
      });

      if (isContainer) {
        return getAllContainerFiles(zfile);
      }
      return zfile;
    })
  );

  const mergedArr = files.flat(Infinity);
  return [...mergedArr] as File[];
}

type BaseFile = {
  content: string;
  name: string;
};

export async function getAllContainerFilesBase64(file: File): Promise<BaseFile[]> {
  const availableExtensions = [
    FileExtensions.Pdf,
    FileExtensions.Pptx,
    FileExtensions.Docx,
    FileExtensions.Xlsx,
  ];
  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const fileKeys = Object.keys(zip.files).filter((f: string) =>
    availableExtensions.includes(getFileType(f) as FileExtensions)
  );
  const files = await Promise.all(
    fileKeys.map(async (fk) => {
      const zipFile = zip.files[fk];
      const base = await zipFile.async('base64');
      return { content: base, name: fk };
    })
  );

  return files;
}

export async function containerHasMimetype(file: File): Promise<boolean> {
  const jsZip = new JSZip();
  const zip = await jsZip.loadAsync(file);
  const exists = Object.keys(zip.files).includes('mimetype');
  return exists;
}

export async function getFileView(file: File): Promise<File | null> {
  let fileView: File | null = null;

  switch (getFileType(file.name)) {
    case FileExtensions.Adoc:
    case FileExtensions.Asic:
    case FileExtensions.Bdoc:
    case FileExtensions.Edoc:
      fileView = await getContainerFile(file);
      break;
    default:
      fileView = file;
      break;
  }

  return fileView;
}

export function getFileMediaTypeByExtension(extension: FileExtensions): FileType {
  switch (extension) {
    case FileExtensions.Asic:
    case FileExtensions.Bdoc:
    case FileExtensions.Edoc:
      return 'application/vnd.etsi.asic-e+zip';

    case FileExtensions.Adoc:
      return 'application/vnd.lt.archyvai.adoc-2008';

    case FileExtensions.Doc:
      return 'application/msword';

    case FileExtensions.Docx:
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    case FileExtensions.Xls:
      return 'application/vnd.ms-excel';

    case FileExtensions.Xlsx:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    case FileExtensions.Ppt:
      return 'application/vnd.ms-powerpoint';

    case FileExtensions.Pptx:
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

    case FileExtensions.Jpg:
    case FileExtensions.Jpeg:
      return 'image/jpg';

    case FileExtensions.Png:
      return 'image/png';

    case FileExtensions.Gif:
      return 'image/gif';

    case FileExtensions.Tiff:
      return 'image/tiff';

    default:
      return 'application/pdf';
  }
}

export const getExtensionByFileMediaType = (fileMediaType: string) => {
  switch (fileMediaType) {
    case 'application/msword':
      return FileExtensions.Doc;

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileExtensions.Docx;

    case 'application/vnd.ms-excel':
      return FileExtensions.Xls;

    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return FileExtensions.Xlsx;

    case 'application/vnd.ms-powerpoint':
      return FileExtensions.Ppt;

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return FileExtensions.Pptx;

    case 'image/jpg':
      return FileExtensions.Jpg;

    case 'image/jpeg':
      return FileExtensions.Jpeg;

    case 'image/png':
      return FileExtensions.Png;

    case 'image/gif':
      return FileExtensions.Gif;

    case 'image/tiff':
      return FileExtensions.Tiff;

    case 'image/bmp':
      return FileExtensions.Bmp;

    default:
      return 'pdf';
  }
};
