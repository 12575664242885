// NEW FORM BUILDER COMPONENT

import React from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import getElementIcon from '../../../utils/forms/getElementIcon';
import { FormElementType } from '../../../@types/forms/enums/FormElementType';

// ----------------------------------------------------------------------

type FormsElementButtonProps = {
  type: number;
  onClick: (type: number) => void;
};

const FormsElementButton: React.FC<FormsElementButtonProps> = ({ type, onClick }) => {
  const { t } = useLocales();
  const elementIcon = getElementIcon(type);
  const theme = useTheme();

  return (
    <Box>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          border: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
        }}
        variant="outlined"
        color="inherit"
        fullWidth
        startIcon={
          <img
            width={24}
            height={24}
            src={`/icons/${elementIcon}`}
            alt={t(`forms.elements.${FormElementType[type]}`)}
          />
        }
        onClick={() => onClick(type)}
            id={`yX5nAY_${FormElementType[type]}`}
      >
        <Typography variant="button" noWrap>
          {t(`forms.elements.${FormElementType[type]}`)}
        </Typography>
      </Button>
    </Box>
  );
};

export default FormsElementButton;
