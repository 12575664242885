import { createSlice } from '@reduxjs/toolkit';

// utils
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type ControlCodeState = {
  controlCode: string;
};

const initialState: ControlCodeState = {
  controlCode: '',
};

const slice = createSlice({
  name: 'controlCode',
  initialState,
  reducers: {
    clearState(state) {
      state.controlCode = initialState.controlCode;
    },

    setControlCode(state, action) {
      state.controlCode = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function clearControlCode() {
  dispatch(slice.actions.clearState());
}

export function setControlCode(controlCode: string) {
  if (!controlCode) {
    return;
  }

  dispatch(slice.actions.setControlCode(controlCode));
}
