import { useTheme, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type DashboardSectionHeaderProps = {
  localeText: string;
};

export default function DashboardSectionHeader({ localeText }: DashboardSectionHeaderProps) {
  const { t } = useLocales();
  const theme = useTheme();

  return (
    <Typography variant='h5' sx={{ mb: 2, color: theme.palette.text.primary }}>
        {t(localeText)}
      </Typography>
  );
}
