import { ReactNode } from 'react';
import { Navigate } from 'react-router';

import useAppSettings from 'src/hooks/useAppSettings';

import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';

// ----------------------------------------------------------------------

type ModuleGuardProps = {
  moduleKey: ModuleTypes;
  children: ReactNode | string;
};

export default function ModuleGuard({ moduleKey, children }: ModuleGuardProps) {
  const modulesData = useAppSettings();

  if (!modulesData.modules.includes(moduleKey)) {
    return <Navigate to='/404' replace />;
  }

  return <>{children}</>;
}
