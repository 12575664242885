export class UserSignatureBalance {
    id: string;

    email: string;

    freeSignatures: number;

    lastUpdated: Date;

    parseJson(json: any): UserSignatureBalance | null {
        if (json === null || json === undefined) {
          return null;
        }
    
        const model: UserSignatureBalance = new UserSignatureBalance();
    
        model.id = json.id;
        model.email = json.email;
        model.freeSignatures = json.freeSignatures;
        model.lastUpdated = json.lastUpdated;
    
        return model;
      }
}