import { createSlice } from '@reduxjs/toolkit';

import { SystemNotification, SystemNotificationsState } from 'src/@types/system-notifications/types';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: SystemNotificationsState = {
  items: [],
};

const slice = createSlice({
  name: 'systemNotifications',
  initialState,
  reducers: {
    clearState(state) {
      state.items = initialState.items;
    },

    addNotification(state, action) {
      state.items = [action.payload, ...state.items];
    },

    removeNotification(state, action) {
      state.items = state.items.filter((x) => x !== action.payload);
    },

    markNotificationSeen(state, action) {
      if (!action.payload.hasOwnProperty('isSeen')) {
        const itemDateTime = action.payload.dateTime;
        const itemLocaleObjectKey = action.payload.localeObject.key;

        for (let i = 0; i < state.items.length; i++) {
          if (state.items[i].dateTime === itemDateTime && state.items[i].localeObject.key === itemLocaleObjectKey) {
            state.items[i].isSeen = true;
          }
        }
      }
    },

    markAllNotificationsSeen(state) {
      state.items = state.items.map((x) => ({ ...x, isSeen: true }));
    },

    clearSeenNotifications(state) {
      state.items = state.items.filter((x) => !x.isSeen);
    },
  },
});

// Reducer
export default slice.reducer;

export function clearNotificationsState() {
  dispatch(slice.actions.clearState());
}

export function addNotification(notification: SystemNotification) {
  dispatch(slice.actions.addNotification(notification));
}

export function removeNotification(notification: SystemNotification) {
  dispatch(slice.actions.removeNotification(notification));
}

export function markNotificationSeen(notification: SystemNotification) {
  dispatch(slice.actions.markNotificationSeen(notification));
}

export function markAllNotificationsSeen() {
  dispatch(slice.actions.markAllNotificationsSeen());
}

export function clearSeenNotifications() {
  dispatch(slice.actions.clearSeenNotifications());
}
