import { Grid, Container } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';

import Page from 'src/components/Page';

import AccessGroupsList from '../../../sections/access-groups/AccessGroupsList';

// ----------------------------------------------------------------------

const AccessGroups = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('accessGroups.accessGroups')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <AccessGroupsList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AccessGroups;
