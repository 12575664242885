import { Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import HelpIcon from '@mui/icons-material/Help';
import { darken, styled, lighten } from '@mui/system';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {
  Box,
  Dialog,
  Divider,
  Tooltip,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
} from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../components/Scrollbar';
import DLXDriveManageAccessItem from './DLXDriveManageAccessItem';
import useNotifications from '../../../../hooks/useNotifications';
import { addStopLoadingState } from '../../../../redux/slices/dlxDrive';
import DLXDriveAccessGroupType from '../../../../@types/dlx-drive/enums/DLXDriveAccessGroupType';
import {
  getFolderAccess,
  getFolderAccessUserList,
  updateFolderAccessUserList,
} from '../../../../services/dlxDriveService';
import {
  DLXDriveFolderAccessType,
  DLXDriveFolderAccessUserType,
  DLXDriveFolderAccessAccessGroupType,
  DLXDriveFolderAccessDepartmentsType,
} from '../../../../@types/dlx-drive/types';

// ----------------------------------------------------------------------

type DLXDriveManageAccessDialogProps = {
  folderId: number;
  folderName: string;
  isManageAccessDialogOpen: boolean;
  setIsManageAccessDialogOpen: (value: boolean) => void;
};

const DLXDriveManageAccessDialog: React.FC<DLXDriveManageAccessDialogProps> = ({
  folderId,
  folderName,
  isManageAccessDialogOpen,
  setIsManageAccessDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [accessGroups, setAccessGroups] = useState<DLXDriveFolderAccessAccessGroupType[]>([]);
  const [departments, setDepartments] = useState<DLXDriveFolderAccessDepartmentsType[]>([]);
  const [users, setUsers] = useState<DLXDriveFolderAccessUserType[]>([]);
  const [userList, setUserList] = useState<DLXDriveFolderAccessType[]>([]);
  const [value, setValue] = useState<DLXDriveFolderAccessType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const { enqueueSuccess } = useNotifications();

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="bottom-end" />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 420,
    },
  });

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  const isItemSelected = (item: DLXDriveFolderAccessType) => {
    if (item.type === DLXDriveAccessGroupType.AccessGroup) {
      return accessGroups.some((g) => g.id === item.id);
    }

    if (item.type === DLXDriveAccessGroupType.Department) {
      return departments.some((d) => d.id === item.id);
    }

    if (item.type === DLXDriveAccessGroupType.User) {
      return users.some((u) => u.id.toString() === item.id.toString());
    }
  };

  const getLabel = (option: DLXDriveFolderAccessType) => {
    if (option.type === DLXDriveAccessGroupType.AccessGroup) {
      return `${option.name}`;
    }

    if (option.type === DLXDriveAccessGroupType.Department) {
      return `${option.name}`;
    }

    if (option.type === DLXDriveAccessGroupType.User) {
      let label = '';

      if (option.name && option.name.trim().length > 0) {
        label = `${option.name}`;
      } else {
        label = `${option.email}`;
      }

      if (option.email) {
        label += ` (${option.email})`;
      }

      return label;
    }

    return '';
  };

  const handleClose = () => {
    setValue(null);
    setInputValue('');
    setIsManageAccessDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      await updateFolderAccessUserList(folderId, accessGroups, departments, users);

      enqueueSuccess(
        t('dlxDrive.notifications.folderPermissionsSuccessfullyUpdated', { itemName: folderName })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsManageAccessDialogOpen(false);
      addStopLoadingState();
    }
  };

  const handleDeleteItem = (id: number, itemType: DLXDriveAccessGroupType) => {
    if (itemType === DLXDriveAccessGroupType.AccessGroup) {
      const newAccessGroups = accessGroups.filter((g) => g.id !== id);
      setAccessGroups(newAccessGroups);
    }

    if (itemType === DLXDriveAccessGroupType.Department) {
      const newDepartments = departments.filter((d) => d.id !== id);
      setDepartments(newDepartments);
    }

    if (itemType === DLXDriveAccessGroupType.User) {
      const newUsers = users.filter((u) => u.id !== id);
      setUsers(newUsers);
    }
  };

  const handleAccessLevelChange = (
    id: number,
    item:
      | DLXDriveFolderAccessAccessGroupType
      | DLXDriveFolderAccessDepartmentsType
      | DLXDriveFolderAccessUserType,
    itemType: DLXDriveAccessGroupType,
    accessLevel: 0 | 1
  ) => {
    if (itemType === DLXDriveAccessGroupType.AccessGroup) {
      const index = accessGroups.findIndex((g) => g.id === id);

      if (index !== -1) {
        const newAccessGroups = [...accessGroups];
        newAccessGroups[index].accessLevel = accessLevel;
        setAccessGroups(newAccessGroups);
      }
    }

    if (itemType === DLXDriveAccessGroupType.Department) {
      const index = departments.findIndex((d) => d.id === id);

      if (index !== -1) {
        const newDepartments = [...departments];
        newDepartments[index].accessLevel = accessLevel;
        setDepartments(newDepartments);
      }
    }

    if (itemType === DLXDriveAccessGroupType.User) {
      const index = users.findIndex((u) => u.id === id);

      if (index !== -1) {
        const newUsers = [...users];
        newUsers[index].accessLevel = accessLevel;
        setUsers(newUsers);
      }
    }
  };

  const fetchFolderAccessLists = async () => {
    try {
      const folderAccessSelectedUsers = await getFolderAccess(folderId);
      const { accessGroups, departments, users } = folderAccessSelectedUsers;

      const folderAccessAllUsers = await getFolderAccessUserList(true);
      setUserList(folderAccessAllUsers);

      if (accessGroups.length > 0) {
        setAccessGroups(accessGroups);
      }

      if (departments.length > 0) {
        setDepartments(departments);
      }

      if (users.length > 0) {
        setUsers(users);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (folderId && isManageAccessDialogOpen) {
      fetchFolderAccessLists();
    }
  }, [isManageAccessDialogOpen]);

  useEffect(() => {
    // ---- Access Groups ----

    if (value?.type === DLXDriveAccessGroupType.AccessGroup) {
      const newAccessGroups = [...accessGroups];
      const newGroup: DLXDriveFolderAccessAccessGroupType = {
        id: value.id,
        name: value.name,
        accessLevel: 0,
      };

      newAccessGroups.push(newGroup);
      setAccessGroups(newAccessGroups);
      setValue(null);
    }

    // ---- Departments ----

    if (value?.type === DLXDriveAccessGroupType.Department) {
      const newDepartments = [...departments];
      const newDepartment: DLXDriveFolderAccessDepartmentsType = {
        id: value.id,
        name: value.name,
        accessLevel: 0,
      };

      newDepartments.push(newDepartment);
      setDepartments(newDepartments);
      setValue(null);
    }

    // ---- Users ----

    if (value?.type === DLXDriveAccessGroupType.User) {
      const newUsers = [...users];
      const newUser: DLXDriveFolderAccessUserType = {
        id: value.id,
        name: value.name,
        email: value.email,
        accessLevel: 0,
      };

      newUsers.push(newUser);
      setUsers(newUsers);
      setValue(null);
    }
  }, [value]);

  return (
    <Dialog open={isManageAccessDialogOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}
      >
        {t('dlxDrive.dialogs.manageAccessToFolder', { itemName: folderName })}

        <CustomTooltip
          title={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 1,
              }}
            >
              {/* ---- Read ----*/}
              <Typography variant="body1">
                <Trans
                  i18nKey="dlxDrive.dialogs.readRightsDescription.row1"
                  values={{ read: 'Read' }}
                  components={{ bold: <strong /> }}
                />
              </Typography>

              <Typography variant="body2">
                {t('dlxDrive.dialogs.readRightsDescription.row2')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.readRightsDescription.row3')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.readRightsDescription.row4')}
              </Typography>

              {/* ---- Write ----*/}
              <Typography variant="body1" sx={{ mt: 1 }}>
                <Trans
                  i18nKey="dlxDrive.dialogs.writeRightsDescription.row1"
                  values={{ write: 'Write' }}
                  components={{ bold: <strong /> }}
                />
              </Typography>

              <Typography variant="body2">
                {t('dlxDrive.dialogs.writeRightsDescription.row2')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.writeRightsDescription.row3')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.writeRightsDescription.row4')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.writeRightsDescription.row5')}
              </Typography>
              <Typography variant="body2">
                {t('dlxDrive.dialogs.writeRightsDescription.row6')}
              </Typography>
            </Box>
          }
          arrow
        >
          <HelpIcon />
        </CustomTooltip>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          value={value}
          onChange={(event: any, newValue: DLXDriveFolderAccessType | null) => {
            setValue(newValue);
          }}
          id="xmpkry5h"
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={userList
            .filter((u) => !isItemSelected(u))
            .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: DLXDriveFolderAccessType) => option.firstLetter}
          getOptionLabel={(option: DLXDriveFolderAccessType) => getLabel(option)}
          renderInput={(params) => (
            <TextField {...params} label={t('dlxDrive.dialogs.enterNameEmailDepartmentGroup')} />
          )}
          renderGroup={(params) => (
            <li key={Math.random()}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />

        <Scrollbar sx={{ height: '400px', mt: 3, display: 'flex', flexDirection: 'column' }}>
          {accessGroups.length > 0 && (
            <Box>
              <Divider>{t('accessGroups.manageAccessDialog.accessGroups')}</Divider>

              {accessGroups.map((group) => (
                <DLXDriveManageAccessItem
                  key={`${group}-group.id`}
                  group={group}
                  handleAccessLevelChange={handleAccessLevelChange}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </Box>
          )}

          {departments.length > 0 && (
            <Box>
              <Divider>{t('accessGroups.manageAccessDialog.departments')}</Divider>

              {departments.map((department) => (
                <DLXDriveManageAccessItem
                  key={`${department}-department.id`}
                  department={department}
                  handleAccessLevelChange={handleAccessLevelChange}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </Box>
          )}

          {users.length > 0 && (
            <Box>
              <Divider>{t('accessGroups.manageAccessDialog.users')}</Divider>

              {users.map((user) => (
                <DLXDriveManageAccessItem
                  key={`${user.name}-${user.email}`}
                  user={user}
                  handleAccessLevelChange={handleAccessLevelChange}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </Box>
          )}
        </Scrollbar>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton loading={isLoading} variant="contained" onClick={handleSave} id="yd0sown5">
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveManageAccessDialog;
