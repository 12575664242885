import { HOST_API, SIFT_API } from '../config';
import axios from '../utils/httpClients/axios';
import { CalendarEventTypesFilter } from '../@types/calendar/types';
import { setIsLoading, setEventTypesList, setEventTypesListTotal } from '../redux/slices/calendar';

const baseTypesUrl = `${HOST_API}CalendarType/types`;
const baseCalendarUrl = `${HOST_API}Calendar`;
const baseSiftUrl = `${SIFT_API.siftDomain}Holiday`;

export const getEventTypesList = async (filter: CalendarEventTypesFilter) => {
  setIsLoading(true);

  const response = await axios.post(`${baseTypesUrl}/list`, filter);
  const { items, total } = response.data;

  setEventTypesList(items);
  setEventTypesListTotal(total);
};

export const createEventType = async (model: any) => {
  setIsLoading(true);

  await axios.post(baseTypesUrl, model);
  await getEventTypesList({ offset: 0, limit: 10, searchQuery: '' });
};

export const updateEventType = async (model: any) => {
  setIsLoading(true);

  await axios.put(baseTypesUrl, model);
  await getEventTypesList({ offset: 0, limit: 10, searchQuery: '' });
};

export const deleteEventType = async (id: number) => {
  setIsLoading(true);

  await axios.delete(`${baseTypesUrl}/${id}`);
  await getEventTypesList({ offset: 0, limit: 10, searchQuery: '' });
};

// Calendar

export const getCalendarData = async (model: any) => {
  setIsLoading(true);

  const response = await axios.post(`${baseCalendarUrl}/events`, model);

  return response.data;
};

export const getCalendarMobileData = async (model: any) => {
  setIsLoading(true);

  const response = await axios.post(`${baseCalendarUrl}/events-mobile`, model);

  return response.data;
};

export const getDocumentCalendarData = async (documentId: number) => {
  setIsLoading(true);

  const response = await axios.get(`${baseCalendarUrl}/document/event?documentId=${documentId}`);

  return response.data;
};

export const getBankHoliday = async (
  startDate: string,
  stopDate: string,
  countryCodes: string[]
) => {
  const config = {
    headers: {
      'x-api-key': SIFT_API.siftApiKey,
      'Content-Type': 'application/json',
    },
  };

  const request = {
    startDate,
    stopDate,
    countryCodes,
  };

  const response = await axios.post(baseSiftUrl, request, config);

  return response.data.map((item: any) => item.holidays).flat();
};
