import { toLocal } from 'src/utils/dateTimeUtils';

import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

export class Form {
  formId: number | null;

  formTemplateId: number;

  name: string;

  edited: Date | null;

  formModuleType: DocumentModuleTypes;

  structure: string;

  doclogixCoreProfileId: number;

  doclogixCoreFileId: number;

  approvalInDoclogix: boolean;

  managerApproval: boolean;

  ceoApproval: boolean;

  defaultSigners: string[];

  parseJson(json: any): Form | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Form = new Form();

    model.formId = json.formId;
    model.formTemplateId = json.formTemplateId;
    model.name = json.name;
    model.structure = json.structure;
    model.edited = json.edited ? toLocal(json.edited) : null;
    model.formModuleType = json.formModuleType;
    model.doclogixCoreProfileId = json.doclogixCoreProfileId;
    model.doclogixCoreFileId = json.doclogixCoreFileId;
    model.approvalInDoclogix = json.approvalInDoclogix;
    model.managerApproval = json.managerApproval;
    model.ceoApproval = json.ceoApproval;
    model.defaultSigners = json.defaultSigners;

    return model;
  }
}
