import React from 'react';

import { Grid, Container } from '@mui/material';

import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import FormsList from '../../../sections/admin/templates/FormsList';

const Forms: React.FC = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('formTemplates.formTemplates')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <FormsList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Forms;
