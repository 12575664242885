import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Dialog,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import ColorPicker from 'src/components/color-picker/ColorPicker';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import useNotifications from '../../../../hooks/useNotifications';
import { setDriveListIdState } from '../../../../redux/slices/dlxDrive';
import { changeFolderColor } from '../../../../services/dlxDriveService';
import DLXDriveFolderIcon from '../../../../utils/dlx-drive/DLXDriveFolderIcon';
import {
  FOLDER_ICON_LARGE_SIZE,
  FOLDER_DEFAULT_ICON_COLOR,
} from '../../../../utils/constants/dlxDriveConstants';

// ----------------------------------------------------------------

type DLXDriveChangeFolderColorDialogProps = {
  folderId: number;
  folderName: string;
  folderColor: string;
  folderIcon: string;
  isChangeFolderColorDialogOpen: boolean;
  setIsChangeFolderColorDialogOpen: (value: boolean) => void;
};

const DLXDriveChangeFolderColorDialog: React.FC<DLXDriveChangeFolderColorDialogProps> = ({
  folderId,
  folderName,
  folderColor,
  folderIcon,
  isChangeFolderColorDialogOpen,
  setIsChangeFolderColorDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [newColor, setNewColor] = useState<string>('');
  const { enqueueSuccess } = useNotifications();
  const theme = useTheme();

  const predefinedColors = [
    '#9E86FF',
    '#83CFFF',
    '#60F1C8',
    '#FDF078',
    '#FFCA28',
    '#FF8F6D',
    '#FFA1F6',
    '#5C62FF',
  ];

  const PreviewBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '108px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[800],
  }));

  const lightModeBackgroundColor = {
    backgroundColor: theme.palette.grey[0],
  };

  const darkModeBackgroundColor = {
    backgroundColor: theme.palette.grey[800],
  };

  const handleClose = () => {
    setIsChangeFolderColorDialogOpen(false);
  };

  const handleColorChange = async () => {
    try {
      await changeFolderColor(folderId, newColor);

      enqueueSuccess('dlxDrive.notifications.iconColorUpdatedSuccessfully', { folderName });
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      setIsChangeFolderColorDialogOpen(false);
    }
  };

  useEffect(() => {
    if (folderColor.length > 0) {
      setNewColor(folderColor);
    } else {
      setNewColor(FOLDER_DEFAULT_ICON_COLOR);
    }
  }, [folderColor]);

  return (
    <Dialog open={isChangeFolderColorDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.changeColor', { folderName })}</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>{t('dlxDrive.dialogs.chooseColor')}</DialogContentText>

        <ColorPicker
          color={newColor}
          setColor={setNewColor}
          spacing={2}
          colors={predefinedColors}
        />

        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Divider>{t('layout.preview')}</Divider>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <PreviewBox style={lightModeBackgroundColor}>
                <DLXDriveFolderIcon
                  icon={folderIcon}
                  color={newColor}
                  fontSize={`${FOLDER_ICON_LARGE_SIZE}px`}
                />
              </PreviewBox>

              {/* ---- Will be activated when dark mode is ready ----*/}
              {/* <Typography variant="caption">{t('layout.lightMode')}</Typography> */}
            </Box>

            {/* ---- Will be activated when dark mode is ready ----*/}
            {/* <Box sx={{ flex: 1 }}> */}
            {/*  <PreviewBox style={darkModeBackgroundColor}> */}
            {/*    <DLXDriveFolderIcon */}
            {/*      icon={folderIcon} */}
            {/*      color={newColor} */}
            {/*      fontSize={`${FOLDER_ICON_LARGE_SIZE}px`} */}
            {/*    /> */}
            {/*  </PreviewBox> */}

            {/*  <Typography variant="caption">{t('layout.darkMode')}</Typography> */}
            {/* </Box> */}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={newColor.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleColorChange}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveChangeFolderColorDialog;
