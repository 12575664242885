import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers-pro';
import {
  Box,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import { shareItems } from '../../../services/dlxDriveService';
import useNotifications from '../../../hooks/useNotifications';
import isEmailAddressValid from '../../../utils/isEmailAddressValid';
import { addStopLoadingState } from '../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveShareDialogProps = {
  itemId: number;
  itemName: string;
  isFolder: boolean;
  isShareDialogOpen: boolean;
  setIsShareDialogOpen: (value: boolean) => void;
};

const DLXDriveSingleShareDialog: React.FC<DLXDriveShareDialogProps> = ({
  itemId,
  itemName,
  isFolder,
  isShareDialogOpen,
  setIsShareDialogOpen,
}) => {
  const { t } = useLocales();
  const { user } = useAuth();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { enqueueSuccess } = useNotifications();

  const handleClose = () => {
    setIsShareDialogOpen(false);
  };

  const handleShare = async () => {
    if (email && expirationDate) {
      const model = {
        email,
        sharedBy: user?.email ? user?.email : '',
        documentIds: isFolder ? [] : [itemId],
        folderIds: isFolder ? [itemId] : [],
        expirationDate,
      };

      try {
        await shareItems(model);

        enqueueSuccess('dlxDrive.notifications.bulkSharedSuccessfully');
      } catch (error) {
        console.error(error);
      } finally {
        setIsShareDialogOpen(false);
        addStopLoadingState();
      }
    }
  };

  return (
    <Dialog open={isShareDialogOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>
        {isFolder
          ? t('dlxDrive.dialogs.shareSingleFolder', { itemName })
          : t('dlxDrive.dialogs.shareSingleDocument', { itemName })}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('dlxDrive.dialogs.shareSingleInfoText', { item: isFolder ? 'folder' : 'document' })}
        </DialogContentText>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            mt: 3,
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={t('documents.sharing.inputs.emailAddress')}
            value={email}
            error={!!(email && !isEmailAddressValid(email))}
            helperText={
              email && !isEmailAddressValid(email)
                ? t('documents.sharing.errors.invalidEmailFormat')
                : ' '
            }
            onChange={(e) => setEmail(e.target.value)}
          />

          <DatePicker
            open={isOpen}
            disablePast
            sx={{ maxWidth: '200px' }}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true,
                label: t('dlxDrive.dialogs.expirationDate'),
                onClick: () => setIsOpen(true),
              },
            }}
            slots={{
              openPickerButton: () => null,
            }}
            defaultValue={expirationDate}
            onChange={(newDate) => {
              if (newDate) {
                try {
                  const date = new Date(newDate);
                  date.setHours(23);
                  date.setMinutes(59);
                  date.setSeconds(59);
                  date.setMilliseconds(999);

                  setExpirationDate(date.toISOString());
                } catch (error) {
                  console.error(error);
                  setExpirationDate(null);
                }
              } else {
                setExpirationDate(null);
              }

              setIsOpen(false);
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={(email && !isEmailAddressValid(email)) || !email || !expirationDate}
          loading={isLoading}
          variant="contained"
          onClick={handleShare}
          id="yd0sown5"
        >
          {`${t('shared.buttons.share')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveSingleShareDialog;
