import React, { useState, useEffect, SyntheticEvent } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../components/Scrollbar';
import useNotifications from '../../../../hooks/useNotifications';
import { DLXDriveFormTemplateListType } from '../../../../@types/dlx-drive/types';
import DLXDriveFormTemplatesAccordionItem from '../../settings/DLXDriveFormTemplatesAccordionItem';
import {
  updateFolder,
  getFormTemplates,
  getFolderFormTemplates,
} from '../../../../services/dlxDriveService';
import {
  setFillFormState,
  addStopLoadingState,
  setDriveListIdState,
} from '../../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveFormTemplatesDialogProps = {
  folderId: number;
  folderName: string;
  isFormTemplatesDialogOpen: boolean;
  setIsFormTemplatesDialogOpen: (value: boolean) => void;
};

const DLXDriveFormTemplatesDialog: React.FC<DLXDriveFormTemplatesDialogProps> = ({
  folderId,
  folderName,
  isFormTemplatesDialogOpen,
  setIsFormTemplatesDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [formTemplatesList, setFormTemplatesList] = useState<DLXDriveFormTemplateListType[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [checked, setChecked] = useState<number[]>([]);
  const { enqueueSuccess, enqueueError } = useNotifications();

  const fetchFormTemplatesList = async () => {
    try {
      const folderForms = await getFolderFormTemplates(folderId);

      setChecked(folderForms.map((form) => form.id));
    } catch (error) {
      console.error(error);
    } finally {
      addStopLoadingState();
    }
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = () => {
    fetchFormTemplatesList();
    setExpanded(false);
    setIsFormTemplatesDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      await updateFolder({ id: folderId, folderFormIds: checked });

      enqueueSuccess('documentTree.snackbar.folderUpdated');
      setDriveListIdState();
    } catch (error) {
      enqueueError(error.message);
    } finally {
      setFillFormState();
      handleClose();
    }
  };

  const fetchFormTemplates = async () => {
    if (isFormTemplatesDialogOpen) {
      try {
        const formTemplates = await getFormTemplates();

        const newFormTemplatesList: DLXDriveFormTemplateListType[] = [];

        if (formTemplates) {
          Object.keys(formTemplates).forEach((key) => {
            newFormTemplatesList.push({
              name: key,
              forms: formTemplates[key],
            });
          });
        }

        setFormTemplatesList(newFormTemplatesList);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (isFormTemplatesDialogOpen) {
      fetchFormTemplates();
      fetchFormTemplatesList();
    }
  }, [isFormTemplatesDialogOpen]);

  useEffect(() => {
    if (isFormTemplatesDialogOpen) {
      fetchFormTemplatesList();
    }
  }, [folderId]);

  return (
    <Dialog open={isFormTemplatesDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.formTemplates')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t('dlxDrive.dialogs.formTemplatesInfoText')}</DialogContentText>

        <Divider sx={{ my: 2 }} />

        <Scrollbar sx={{ p: 1, minHeight: '400px', maxHeight: '400px' }}>
          {formTemplatesList.length > 0 &&
            formTemplatesList.map((formTemplate, index) => (
              <DLXDriveFormTemplatesAccordionItem
                key={`${formTemplate.name}-${index}`}
                item={formTemplate}
                expanded={expanded}
                handleChange={handleChange}
                checked={checked}
                setChecked={setChecked}
                handleToggle={handleToggle}
              />
            ))}
        </Scrollbar>

        <Divider sx={{ mt: 2 }} />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton loading={isLoading} variant="contained" onClick={handleSave} id="yd0sown5">
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveFormTemplatesDialog;
