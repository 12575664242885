import { Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------

type DLXDriveInfoBarProps = {
  totalFolderItems: number;
};

const DLXDriveInfoBar: React.FC<DLXDriveInfoBarProps> = ({ totalFolderItems }) => {
  const { t } = useLocales();

  return (
    <Typography variant="body2">
      {t('dlxDrive.pagination.folderContainsItems', { totalFolderItems })}
    </Typography>
  );
};

export default DLXDriveInfoBar;
