import axios from 'src/utils/httpClients/axios';

import { Tag } from 'src/@types/tags/tag';
import { HOST_API, WORKFLOWS_API } from 'src/config';
import { TagsFilter } from 'src/@types/tags/tagsFilter';
import { TagCreate, TagUpdate } from 'src/@types/tags/types';
import { PagedResponse } from 'src/@types/shared/pagedResponse';

// ----------------------------------------------------------------------

const baseUrl = `${HOST_API  }tags`;
const workflowsUrl = WORKFLOWS_API.domain;
const workflowsApiKey = WORKFLOWS_API.apiKey;

async function getPagedTags(filter: TagsFilter) {
  const response = await axios.get<PagedResponse<Tag>>(baseUrl, { params: { ...filter } });
  return response.data;
}

async function createTag(tag: TagCreate) {
  await axios.post(baseUrl, { ...tag });
}

async function editTag(tag: TagUpdate) {
  await axios.put(`${baseUrl  }/${tag.id}`, { ...tag });
}

async function removeTag(id: number) {
  await axios.delete(`${baseUrl  }/${id}`);
}

// Worklows
async function getWorkflows() {
  try {
    const response = await fetch(`${workflowsUrl}elsa/api/workflow-definitions`, {
      headers: {
        'x-api-key': workflowsApiKey,
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    
  }
}

const tagService = {
  getPagedTags,
  createTag,
  editTag,
  removeTag,
  getWorkflows,
};

export default tagService;
