import axios from 'src/utils/httpClients/axios';

import { AUTH0_API } from 'src/config';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { JobTitlesFilter } from 'src/@types/jobTitles/jobTitlesFilter';
import { JobTitle, UpdateJobTitle } from 'src/@types/jobTitles/jobTitles';

const baseUrl = `${AUTH0_API.domain  }api/job-titles`;

async function getJobTitles(filter: JobTitlesFilter) {
  const response = await axios.get<PagedResponse<JobTitle>>(baseUrl, { params: { ...filter } });

  const data = {
    total: response.data.total,
    items: response.data.items,
  };

  return data;
}

async function createJobTitles(model: UpdateJobTitle) {
  await axios.post(baseUrl, model);
}

async function updateJobTitles(model: UpdateJobTitle, id: number) {
  await axios.put(`${baseUrl  }/${id}`, model);
}

async function deleteJobTitles(id: number) {
  await axios.delete(`${baseUrl  }/${id}`);
}

const aspJobTitlesService = {
  getJobTitles,
  createJobTitles,
  updateJobTitles,
  deleteJobTitles,
};

export default aspJobTitlesService;
