import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { CalendarState, CalendarEventType } from '../../@types/calendar/types';

// ----------------------------------------------------------------------

const initialState: CalendarState = {
  isLoading: false,
  eventTypesList: [],
  eventTypesListTotal: 0,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setEventTypesList(state, action) {
      state.eventTypesList = action.payload;
    },

    setEventTypesListTotal(state, action) {
      state.eventTypesListTotal = action.payload;
    },
  },
});

export const setIsLoading = (isLoading: boolean) => {
  dispatch(slice.actions.setIsLoading(isLoading));
};

export const setEventTypesList = (list: CalendarEventType[]) => {
  dispatch(slice.actions.setEventTypesList(list));

  setIsLoading(false);
};

export const setEventTypesListTotal = (total: number) => {
  dispatch(slice.actions.setEventTypesListTotal(total));
};

export default slice.reducer;
