export enum DocumentStatuses {
  New = 0,
  Completed = 1,
  WaitingForOthers = 2,
  WaitingForMe = 3,
  Rejected = 4,
  Voided = 5,
  WaitingForOthersApprove = 6,
  WaitingForMeApprove = 7,
  WaitingForRelatedDocuments = 8,
  WaitingForMeToAcknowledge = 9,
  WaitingForOthersToAcknowledge = 10,
}
