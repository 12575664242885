import React, { useRef, useState, useContext } from 'react';

import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

type UseModalShowReturnType = {
  open: boolean;
  setOpen: (value: boolean) => void;
  onClose: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
  };

  return {
    open,
    setOpen,
    onClose: handleOnClose,
  };
};

type ModalContextType = {
  showConfirmation: (
    title: string | JSX.Element,
    message: string | JSX.Element
  ) => Promise<boolean>;
  closeConfirmation: () => Promise<void>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = (
  props
) => {
  const { t } = useLocales();
  const { setOpen, open, onClose } = useModalShow();
  const [content, setContent] = useState<{
    title: string | JSX.Element;
    message: string | JSX.Element | React.Component;
  } | null>();
  const resolver = useRef<Function>();

  const handleShow = (
    title: string | JSX.Element,
    message: string | JSX.Element
  ): Promise<boolean> => {
    setContent({
      title,
      message,
    });
    setOpen(true);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const handleForceClose = (): Promise<void> => {
    setOpen(false);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
    closeConfirmation: handleForceClose,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onClose();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onClose();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle sx={{ mb: 2 }}>{content.title}</DialogTitle>
          <DialogContent>{`${content.message}`}</DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="outlined" color="inherit" id="mz5nnxqa">
              {t('shared.buttons.cancel')}
            </Button>
            <Button onClick={handleOk} variant="contained" id="yd0sown5">
              {t('shared.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useModalShow, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
