import SignaturePad from 'react-signature-pad-wrapper';
import React, { useRef, useState, useEffect, ChangeEvent, MutableRefObject } from 'react';

import {
  Box,
  Button,
  Dialog,
  useTheme,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { CroppedCanvas } from 'src/@types/documents/types';
import { setSignImage, setFinalSignSize } from 'src/redux/slices/signaturePadReqModel';

import INK_COLOR from 'src/sections/forms/elements/signature-utils/inkColor';
import cropSignatureCanvas from 'src/sections/forms/elements/signature-utils/cropSignatureCanvas';
import writeNameOnTheCanvas from 'src/sections/forms/elements/signature-utils/writeNameOnTheCanvas';

//-------------------------------------------------------
export const FONT_SIZE = 14;

type DocumentSignaturePadDialogProps = {
  isSignaturePadDialogOpen: boolean;
  setIsSignaturePadDialogOpen: (isSignaturePadDialogOpen: boolean) => void;
};

const DocumentSignaturePadDialog: React.FC<DocumentSignaturePadDialogProps> = ({
  isSignaturePadDialogOpen,
  setIsSignaturePadDialogOpen,
}) => {
  const { t } = useLocales();
  const [addedEventListener, setAddedEventListener] = useState(false);
  const [isEmptySignature, setIsEmptySignature] = useState(true);
  const canvas = useRef() as MutableRefObject<any>;
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>(undefined);
  const [surname, setSurname] = useState<string | undefined>(undefined);

  const handleClose = () => {
    setIsSignaturePadDialogOpen(false);
  };

  const handleClear = () => {
    canvas.current.clear();
    setIsEmptySignature(true);
  };

  const handleEndStroke = () => {
    setIsEmptySignature(canvas.current.isEmpty());
    // canvas.current.closePath();
  };

  const resizeSignatureCanvas = (signCanvas) => {
    const canvasResize = document.createElement('canvas');
    const ctx = canvasResize.getContext('2d');
    const MAX_SIZE = 150;

    if (ctx) {
      const aspectRatio = signCanvas.width / signCanvas.height;
      let drawWidth = signCanvas.width;
      let drawHeight = signCanvas.height;

      if (drawWidth > MAX_SIZE || drawHeight > MAX_SIZE) {
        if (aspectRatio > 1) {
          drawWidth = MAX_SIZE;
          drawHeight = drawWidth / aspectRatio;
        } else {
          drawHeight = MAX_SIZE;
          drawWidth = drawHeight * aspectRatio;
        }
      }

      canvasResize.width = Math.ceil(drawWidth);
      canvasResize.height = Math.ceil(drawHeight);

      // (left, top, width, height)
      ctx.clearRect(0, 0, canvasResize.width, canvasResize.height);
      // top -> FONT_SIZE instead 0
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(signCanvas, 0, 0, drawWidth, drawHeight);
    }
    return canvasResize;
  };

  const handleContinue = () => {
    const untrimmedCanvas = canvas.current.signaturePad.canvas as HTMLCanvasElement;
    const croppedSignature = cropSignatureCanvas(untrimmedCanvas);

    if (!croppedSignature.width || !croppedSignature.height) {
      console.log('Cropped  signature has no dimensions');
      return;
    }

    const croppedName = writeNameOnTheCanvas(`${name} ${surname}`, FONT_SIZE);

    const adjustedSignCanvasBySize = resizeSignatureCanvas(croppedSignature);

    if (croppedName) {
      const newCanvas: CroppedCanvas = {
        image: adjustedSignCanvasBySize.toDataURL(),
        width: adjustedSignCanvasBySize.width,
        height: adjustedSignCanvasBySize.height,
        textImage: croppedName.toDataURL(),
        textWidth: croppedName.width,
        textHeight: croppedName.height,
      };

      // add separately text as canvas
      setSignImage(
        newCanvas.image,
        newCanvas.width,
        newCanvas.height,
        newCanvas.textImage,
        newCanvas.textWidth,
        newCanvas.textHeight
      );
      setFinalSignSize(newCanvas.width, newCanvas.height);
    } else {
      const newCanvas: CroppedCanvas = {
        image: croppedSignature.toDataURL(),
        width: croppedSignature.width,
        height: croppedSignature.height,
        textImage: '',
        textHeight: 0,
        textWidth: 0,
      };

      setSignImage(
        newCanvas.image,
        newCanvas.width,
        newCanvas.height,
        newCanvas.textImage,
        newCanvas.textWidth,
        newCanvas.textHeight
      );
    }

    setIsSignaturePadDialogOpen(false);
  };

  useEffect(() => {
    if (name && !addedEventListener && canvas.current && canvas.current.signaturePad) {
      canvas.current.signaturePad.addEventListener('endStroke', handleEndStroke);

      setAddedEventListener(true);
    }
  }, [name]);

  return (
    <Dialog open={isSignaturePadDialogOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t('forms.addSignatureToSign')}</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {t('documents.writeDrawClickContinue')}
        </DialogContentText>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 3,
            }}
          >
            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.firstName')}
              value={name}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              autoComplete='off'
              id="9i2feut6"
            />

            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.lastName')}
              value={surname}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSurname(event.target.value);
              }}
              autoComplete='off'
              id="xxceh4gk"
            />
          </Box>

          <Box sx={{ border: `1px solid ${theme.palette.grey[300]}` }} id="dvabn4kt">
            <SignaturePad
              ref={canvas}
              options={{
                minWidth: 2,
                maxWidth: 4,
                penColor: INK_COLOR,
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            gap: 3,
          }}
        >
         

          <Button variant="outlined" onClick={handleClose} id="mz5nnxqa">
            {t('shared.buttons.cancel')}
          </Button>

          <Button disabled={isEmptySignature} variant="text" onClick={handleClear} id="3anq0rc3">
          {t('shared.buttons.clear')}
        </Button>
        </Box>

        <Button
            disabled={!name || !surname || isEmptySignature}
            variant="contained"
            onClick={handleContinue}
            id="b2pe340j"
          >
            {t('shared.buttons.continue')}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentSignaturePadDialog;
