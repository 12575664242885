import { ReactNode } from 'react';
import { IconifyIcon } from '@iconify/react';
import { SnackbarProvider } from 'notistack';

// @mui
import { useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';

//
import Iconify from './Iconify';
// theme
import { ColorSchema } from '../theme/palette';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
          
            '&.SnackbarItem-variantSuccess': {
              backgroundColor:  theme.palette.success.lighter,
              color: theme.palette.success.darker,
              border: `1px solid ${theme.palette.success.light}`
            },
            '&.SnackbarItem-variantError': {
              backgroundColor: theme.palette.error.lighter,
              color: theme.palette.error.darker,
              border: `1px solid ${theme.palette.error.light}`
            },
            '&.SnackbarItem-variantWarning': {
              backgroundColor: theme.palette.warning.lighter,
              color: theme.palette.warning.darker,
              border: `1px solid ${theme.palette.warning.light}`
            },
            '&.SnackbarItem-variantInfo': {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.info.darker,
               border: `1px solid ${theme.palette.info.light}`
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}

type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        iconVariant={{
          success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color='success' />,
          error: <SnackbarIcon icon="eva:info-fill" color='error' />,
          warning: <SnackbarIcon icon="eva:alert-triangle-fill" color='warning' />,
          info: <SnackbarIcon icon="eva:alert-circle-fill" color='info' />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component='span'
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
