import { FormElementError } from '../enums/FormElementError';
import { ErrorsListProps, ElementValidationProps } from '../types';

const validateIsNumber = ({ el, errorMessage, value }: ElementValidationProps) => {
  let newError: ErrorsListProps | null = null;
  let isNumber;

  if (value !== undefined) {
    isNumber = !Number.isNaN(parseInt(value, 10));
  }

  if (!isNumber) {
    newError = {
      elementID: el.id,
      elementName: el.label,
      errorCode: FormElementError.NotNumber,
      errorMessage,
    };

    return newError;
  }
};

export default validateIsNumber;
