import { ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { PATH_DASHBOARD } from 'src/routes/paths';

import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  if (isAuthenticated) {
    return <Navigate to={redirect || PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
