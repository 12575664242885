import { forwardRef } from 'react';

import { useTheme } from '@mui/material/styles';
import { AvatarProps, Avatar as MUIAvatar } from '@mui/material';

// ----------------------------------------------------------------------

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  color?: AvatarColor;
}

const Avatar = forwardRef<HTMLDivElement, Props>(({ children, sx, ...other }, ref) => {
  const theme = useTheme();

  return (
    <MUIAvatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        ...sx,
      }}
      {...other}
    >
      {children}
    </MUIAvatar>
  );
});

export default Avatar;
