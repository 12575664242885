import jwtDecode from 'jwt-decode';
import { sign, verify } from 'jsonwebtoken';

import { Token } from 'src/@types/auth/auth';
import { AuthUser } from 'src/@types/auth/user';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  // @ts-ignore
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (token: Token | null) => {
  if (token) {
    localStorage.setItem('accessToken', token.accessToken);
    localStorage.setItem('refreshToken', token.refreshToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
};

const getSession = (): Token => {
  const token = localStorage.getItem('accessToken');
  let user: AuthUser | null = null;
  if (token) {
    user = decodeToken(token);
  }

  return {
    accessToken: token,
    refreshToken: localStorage.getItem('refreshToken'),
    user,
  } as Token;
};

const decodeToken = (token: string): AuthUser => {
  // @ts-ignore
  const userInfo = jwtDecode<any>(token);
  const user = new AuthUser();
  user.sub = userInfo?.sub;
  user.email = userInfo?.email;
  user.firstName = userInfo?.given_name;
  user.lastName = userInfo?.family_name;
  user.locale = userInfo?.locale;
  user.isTenant = userInfo?.isTenant;
  user.roles =
    typeof userInfo?.role === 'string' ? [userInfo?.role] : !userInfo?.role ? [] : userInfo?.role;
  user.permissions =
    typeof userInfo?.permission === 'string'
      ? [+userInfo?.permission]
      : !userInfo?.permission
        ? []
        : userInfo?.permission.map((p: string) => +p);
  return user;
};

export { sign, verify, setSession, getSession, decodeToken, isValidToken };
