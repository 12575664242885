import SimpleBar from 'simplebar-react';
import { useRef, useEffect } from 'react';
import SimpleBarReact, { Props as ScrollbarProps } from 'simplebar-react';

import { Box, SxProps } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
    width: 4,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
  children: React.ReactNode;
  sx?: SxProps;
  scrollToBottomTrigger?: number;
}

export default function Scrollbar({ children, sx, scrollToBottomTrigger, ...other }: Props) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const scrollRef = useRef<SimpleBar>(null);

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  useEffect(() => {
    if (scrollToBottomTrigger) {
      scrollRef.current?.getScrollElement().scrollTo({ top: 9999, behavior: 'smooth' });
    }
  }, [scrollToBottomTrigger]);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} ref={scrollRef} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
