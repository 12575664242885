import { JSEncrypt } from 'jsencrypt';

import { HOST_API, TEMP_FILE_PUBLIC_KEY } from '../../config';

const encryptIDWithRsaPublicKey = (id: string) => {
  const tempFilesUrl = `${HOST_API}temp-files`;
  const publicKey = `-----BEGIN RSA PUBLIC KEY-----${TEMP_FILE_PUBLIC_KEY}-----END RSA PUBLIC KEY-----`;
  const encrypt = new JSEncrypt();

  encrypt.setPublicKey(publicKey);

  const toEncrypt = `${id} ${Date.now()}`;
  let encrypted = encrypt.encrypt(toEncrypt);

  if (typeof encrypted === 'string') {
    return `${tempFilesUrl}/${encrypted.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '~')}`;
  }

  return encrypted;
};

export default encryptIDWithRsaPublicKey;
