import merge from 'lodash/merge';
import { ReactNode } from 'react';

// @mui
import {
  Theme,
  useTheme,
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

// hooks
import componentsOverride from '../theme/overrides';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  const defaultTheme = useTheme();

  const theme = createTheme(defaultTheme as ThemeOptions);
  theme.components = merge(componentsOverride(theme));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
