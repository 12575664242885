import axios from 'src/utils/httpClients/axios';

import { AUTH0_API } from 'src/config';
import { Company, TenantAuth, CompanyUpdate, CompanySettings } from 'src/@types/company/types';

const baseUrl = `${AUTH0_API.domain}api/companies`;

async function getCompany() {
  const response = await axios.get<Company>(`${baseUrl}/own`);

  return response.data;
}

async function getCompanySettings() {
  const response = await axios.get<CompanySettings>(`${baseUrl}/settings`);

  return response.data;
}

export async function getTenantAuthSettings() {
  const response = await axios.get<TenantAuth>(`${baseUrl}/auth-settings`);

  return response.data;
}

async function updateCompany(request: CompanyUpdate) {
  await axios.put(baseUrl, request);
}

const aspCompanyService = {
  getCompany,
  getCompanySettings,
  getTenantAuthSettings,
  updateCompany,
};

export default aspCompanyService;
