import { User } from 'src/@types/users/user';

import { AddressBookUser } from '../../@types/users/addressBookUser';

// ----------------------------------------------------------------------

const getLettersAvatar = (user: AddressBookUser | User) => {
  let letters = '';

  if (user.firstName.length > 0) {
    letters += user.firstName[0].toUpperCase();
  }

  if (user.lastName.length > 0) {
    letters += user.lastName[0].toUpperCase();
  }

  return letters;
};

export default getLettersAvatar;
