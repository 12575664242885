// NEW FORM BUILDER COMPONENT

import { A4, A5 } from '../../../@types/forms/constants/FormBlankPageSize';
import { FormBlankPageFormat } from '../../../@types/forms/enums/FormBlankPageFormat';
import { FormBlankPageOrientation } from '../../../@types/forms/enums/FormBlankPageOrientation';

// ----------------------------------------------------------------------

export type PageFormatProps = {
  label: string;
  format: number;
  size: [number, number];
};
export const pageFormats: PageFormatProps[] = [
  // {
  //   label: 'A0 (841 x 1189 mm)',
  //   format: FormBlankPageFormat.A0,
  //   size: A0,
  // },
  // {
  //   label: 'A1 (594 x 841 mm)',
  //   format: FormBlankPageFormat.A1,
  //   size: A1,
  // },
  // {
  //   label: 'A2 (420 x 594 mm)',
  //   format: FormBlankPageFormat.A2,
  //   size: A2,
  // },
  // {
  //   label: 'A3 (297 x 420 mm)',
  //   format: FormBlankPageFormat.A3,
  //   size: A3,
  // },
  {
    label: 'A4 (210 x 297 mm)',
    format: FormBlankPageFormat.A4,
    size: A4,
  },
  {
    label: 'A5 (148 x 210 mm)',
    format: FormBlankPageFormat.A5,
    size: A5,
  },
  // {
  //   label: 'A6 (105 x 148 mm)',
  //   format: FormBlankPageFormat.A6,
  //   size: A6,
  // },
  // {
  //   label: 'A7 (74 x 105 mm)',
  //   format: FormBlankPageFormat.A7,
  //   size: A7,
  // },
  // {
  //   label: 'A8 (52 x 74 mm)',
  //   format: FormBlankPageFormat.A8,
  //   size: A8,
  // },
];

export type PageOrientationProps = {
  label: string;
  orientation: number;
};

export const pageOrientation: PageOrientationProps[] = [
  {
    label: 'Portrait',
    orientation: FormBlankPageOrientation.portrait,
  },
  {
    label: 'Landscape',
    orientation: FormBlankPageOrientation.landscape,
  },
];
