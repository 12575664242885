import qs from 'qs';
import { createSlice } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';

import { SessionControlCodeState } from 'src/@types/session';
import { EparakstCallbackRequest } from 'src/@types/auth/auth';

import { dispatch } from '../store';
import { setControlCode, clearControlCode } from './controlCode';
// utils
import identityClient from '../../utils/httpClients/identityAxios';

// ----------------------------------------------------------------------

const initialState: SessionControlCodeState = {
  sessionId: '',
  redirectLink: '',
  twoFASessionKey: '',
  readyToContinue: false,
  emails: [],
  firstName: '',
  lastName: '',
  error: '',
};

const slice = createSlice({
  name: 'sessionControlCode',
  initialState,
  reducers: {
    clearState(state) {
      state.sessionId = initialState.sessionId;
      state.error = initialState.error;
      state.readyToContinue = initialState.readyToContinue;
      state.twoFASessionKey = initialState.twoFASessionKey;
      state.emails = initialState.emails;
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
    },

    setControlCodeSession(state, action) {
      state.sessionId = action.payload.sessionId;
    },

    setEparakstsSession(state, action) {
      state.sessionId = action.payload.sessionId;
      state.redirectLink = action.payload.redirectUrl;
    },

    setTwoFASessionKey(state, action) {
      state.twoFASessionKey = action.payload;
    },

    setReadyToContinue(state, action) {
      state.readyToContinue = action.payload;
    },

    allowReadyToContinue(state, action) {
      state.readyToContinue = action.payload !== null;
      state.emails = action.payload.emails;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },

    hasError(state, action) {
      state.readyToContinue = false;
      state.error = action.payload;
    },

    clearError(state) {
      state.error = '';
    },
  },
});

// Reducer
export default slice.reducer;

let cancelSession = false;
let cancellationToken: CancelTokenSource;

export async function removeSession() {
  cancelSession = true;
  cancellationToken?.cancel();
}

export function clearSession() {
  dispatch(slice.actions.clearState());
  clearControlCode();
}

export function clearError() {
  dispatch(slice.actions.clearError());
}

export async function initSmartIdLoginSession(personalCode: string, countryCode: string) {
  if (!personalCode) {
    return;
  }

  const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
  const params = {
    personalCode,
    countryCode,
  };

  try {
    const response = await identityClient.post('/api/authentication/smartid', qs.stringify(params), config);

    dispatch(slice.actions.setControlCodeSession({ sessionId: response.data.id }));
    setControlCode(response.data.verificationCode);
    getSmartIdLoginSession(response.data.id);
  } catch (error: any) {
    throw error;
  }
}

export async function initMobileIdLoginSession(phoneNumber: string, nationalId: string) {
  if (!phoneNumber || !nationalId) {
    return;
  }

  const config = { headers: { 'Content-Type': 'application/json' } };
  const params = {
    phoneNumber,
    nationalId,
  };

  try {
    const response = await identityClient.post('/api/authentication/mobileid', params, config);

    dispatch(slice.actions.setControlCodeSession({ sessionId: response.data.id }));
    setControlCode(response.data.verificationCode);
    getMobileIdLoginSession(response.data.id);
  } catch (error: any) {
    throw error;
  }
}

export async function getSmartIdLoginSession(sessionId: string) {
  if (!sessionId) {
    return;
  }
  cancelSession = false;

  await checkStatus(`/api/authentication/smartId/${sessionId}`);
}

export function setReadyToContinue(readyToContinue: boolean) {
  dispatch(slice.actions.setReadyToContinue(readyToContinue));
}

export async function getMobileIdLoginSession(sessionId: string) {
  if (!sessionId) {
    return;
  }
  cancelSession = false;

  await checkStatus(`/api/authentication/mobileId/${sessionId}`);
}

export function setTwoFASessionKey(codeKey: string) {
  dispatch(slice.actions.setTwoFASessionKey(codeKey));
  clearControlCode();
}

async function checkStatus(url: string) {
  const iterationTimeoutSeconds = 3;

  if (cancelSession) {
    return;
  }

  try {
    cancellationToken = axios.CancelToken.source();
    const response = await identityClient.get(url, { cancelToken: cancellationToken.token });

    if (response.status !== 200 || response.data === null) {
      await setTimeout(async () => {
        await checkStatus(url);
      }, iterationTimeoutSeconds * 1000);
    } else {
      clearControlCode();
      if (cancelSession) {
        return;
      }
      dispatch(slice.actions.allowReadyToContinue(response.data));
    }
  } catch (error: any) {
    if (cancelSession) {
      return;
    }
    dispatch(slice.actions.hasError(error));
    clearControlCode();
  }
}

export async function initEparakstLoginSession() {
  try {
    const response = await identityClient.get('/api/authentication/eparaksts-mobile');
    dispatch(slice.actions.setEparakstsSession({ sessionId: response.data.id, redirectUrl: response.data.redirectUrl }));
  } catch (error: any) {
    throw error;
  }
}

export async function callbackEparakstLogin(request: EparakstCallbackRequest) {
  try {
    const response = await identityClient.post('/api/authentication/eparaksts-mobile/callback', request);
    dispatch(slice.actions.allowReadyToContinue(response.data));
  } catch (error: any) {
    throw error;
  }
}
