import React from 'react';

import ElementLabel from './ElementLabel';
import { ElementProps } from '../../../@types/forms/types';

type RegistrationNumberElementProps = {
  el: ElementProps;
  pageNumberScale: number;
};

const RegistrationNumberElement: React.FC<RegistrationNumberElementProps> = ({ el, pageNumberScale }) => (
  <ElementLabel el={el} label={el.prefix ? `${el.prefix}000` : el.label} pageNumberScale={pageNumberScale} />
);

export default RegistrationNumberElement;
