import { Roles } from './constants/roles';
import { Permissions } from './constants/permissions';

export class AuthUser {
  sub: string;

  email: string;

  firstName: string;

  lastName: string;

  locale: string;

  isTenant: boolean;

  roles: string[];

  permissions: Permissions[];

  constructor(json?: any) {
    if (json) {
      this.sub = json.sub;
      this.email = json.email;
      this.firstName = json.firstName;
      this.lastName = json.lastName;
      this.locale = json.locale;
      this.isTenant = json.isTenant;
      this.roles = json.roles;
      this.permissions = json.permissions;
    }
  }

  public get isSuperAdmin(): boolean {
    return this.roles.includes(Roles.SuperAdmin);
  }

  public get isAdmin(): boolean {
    return this.roles.includes(Roles.Admin);
  }

  public get isCeo(): boolean {
    return this.roles.includes(Roles.CEO);
  }

  public get isExternalUser(): boolean {
    return this.roles.includes(Roles.ExternalUser);
  }

  public get fullName(): string {
    if (!this.firstName && !this.lastName) {
      return '';
    }

    return `${this.firstName} ${this.lastName}`.trim();
  }

  public get timestampName(): string {
    return this.fullName ? this.fullName : this.email;
  }
}
