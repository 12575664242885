import React, { useState, useEffect } from 'react';

import { Box, IconButton, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';

import DLXDriveAccessGroupType from '../../../../@types/dlx-drive/enums/DLXDriveAccessGroupType';
import {
  DLXDriveFolderAccessUserType,
  DLXDriveFolderAccessAccessGroupType,
  DLXDriveFolderAccessDepartmentsType,
} from '../../../../@types/dlx-drive/types';

// ----------------------------------------------------------------------

type DLXDriveManageAccessItemProps = {
  group?: DLXDriveFolderAccessAccessGroupType;
  department?: DLXDriveFolderAccessDepartmentsType;
  user?: DLXDriveFolderAccessUserType;
  handleAccessLevelChange: (
    id: number,
    item:
      | DLXDriveFolderAccessAccessGroupType
      | DLXDriveFolderAccessDepartmentsType
      | DLXDriveFolderAccessUserType,
    itemType: DLXDriveAccessGroupType,
    accessLevel: 0 | 1
  ) => void;
  handleDeleteItem: (id: number, itemType: DLXDriveAccessGroupType) => void;
};

const DLXDriveManageAccessItem: React.FC<DLXDriveManageAccessItemProps> = ({
  group,
  department,
  user,
  handleAccessLevelChange,
  handleDeleteItem,
}) => {
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [accessLevel, setAccessLevel] = useState<string>('0');

  const addUserInfo = () => {
    if (user) {
      if (user.name) {
        let newUserName = user.name.trim();

        if (newUserName.length > 0) {
          setUserName(newUserName);
        } else {
          setUserName(user.email);
        }
      } else {
        setUserName(user.email);
      }
    }
  };

  const handleDeleteClick = () => {
    if (group) {
      handleDeleteItem(group.id, DLXDriveAccessGroupType.AccessGroup);
    }

    if (department) {
      handleDeleteItem(department.id, DLXDriveAccessGroupType.Department);
    }

    if (user) {
      handleDeleteItem(user.id, DLXDriveAccessGroupType.User);
    }
  };

  const handleChange = (newAccessLevel: string) => {
    if (group) {
      handleAccessLevelChange(
        group.id,
        group,
        DLXDriveAccessGroupType.AccessGroup,
        parseInt(newAccessLevel, 10) as 0 | 1
      );
    }

    if (department) {
      handleAccessLevelChange(
        department.id,
        department,
        DLXDriveAccessGroupType.Department,
        parseInt(newAccessLevel, 10) as 0 | 1
      );
    }

    if (user) {
      handleAccessLevelChange(
        user.id,
        user,
        DLXDriveAccessGroupType.User,
        parseInt(newAccessLevel, 10) as 0 | 1
      );
    }
  };

  useEffect(() => {
    if (group) {
      setAccessLevel(group.accessLevel.toString());
    }

    if (department) {
      setAccessLevel(department.accessLevel.toString());
    }

    if (user) {
      addUserInfo();
      setAccessLevel(user.accessLevel.toString());
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        my: 1,
        px: 1,
      }}
    >
      <Box>
        {group && <Typography variant="h6">{group.name}</Typography>}

        {department && <Typography variant="h6">{department.name}</Typography>}

        {user && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {userName !== undefined && (
              <Typography variant="h6" sx={{ mb: 0, pb: 0 }}>
                {userName}
              </Typography>
            )}

            <Typography>{user.email}</Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={accessLevel}
          exclusive
          id="2299gnyo"
          onChange={(event, value) => {
            if (value && value !== accessLevel) {
              setAccessLevel(value);

              handleChange(value);
            }
          }}
        >
          <ToggleButton size="small" value="0">
            Read
          </ToggleButton>

          <ToggleButton size="small" value="1">
            Write
          </ToggleButton>
        </ToggleButtonGroup>

        <Box>
          <IconButton id="f1u1v9sn" onClick={handleDeleteClick}>
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              style={{ width: '20px', height: '20px' }}
              alt="Delete"
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DLXDriveManageAccessItem;
