enum MassApprovalFailureReasons {
  NoProblem = 0,
  UserNotAdded = 1,
  UserAddedAsSigner = 2,
  UserAddedAsAcknowledger = 3,
  UserAlreadyApproved = 4,
  AnotherUserTurn = 5,
  DocumentIsBlocked = 6,
}

export default MassApprovalFailureReasons;
