export function toUtc(date: Date | string) {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
}

export function toLocal(date: Date | string) {
  const oldDate = new Date(date);
  const newDate = new Date(oldDate.getTime() + oldDate.getTimezoneOffset() * 60000);

  const offset = oldDate.getTimezoneOffset() / 60;
  const hours = oldDate.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}
