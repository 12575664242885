import { ReactNode } from 'react';

import useAppSettings from 'src/hooks/useAppSettings';

import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';

// ----------------------------------------------------------------------

type ModuleCheckProp = {
  module: ModuleTypes;
  children: ReactNode | string;
};

export default function ModuleCheck({ module, children }: ModuleCheckProp) {
  const modulesData = useAppSettings();

  if (modulesData.modules.includes(module)) {
    return <>{children}</>;
  }

  return null;
}
