import React, { useState, useEffect, ChangeEvent } from 'react';

import { LoadingButton } from '@mui/lab';
import { Dialog, TextField, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { useSelector } from '../../../../redux/store';
import useLocales from '../../../../hooks/useLocales';
import useNotifications from '../../../../hooks/useNotifications';
import { updateFolder } from '../../../../services/dlxDriveService';
import { setDriveListIdState } from '../../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveRenameFolderDialogProps = {
  isRenameFolderDialogOpen: boolean;
  setIsRenameFolderDialogOpen: (value: boolean) => void;
  folderId: number;
  folderName: string;
  folderPrefix: string | undefined;
};

const DLXDriveRenameFolderDialog: React.FC<DLXDriveRenameFolderDialogProps> = ({
  isRenameFolderDialogOpen,
  setIsRenameFolderDialogOpen,
  folderId,
  folderName,
  folderPrefix,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [newFolderPrefix, setNewFolderPrefix] = useState<string | undefined>(undefined);
  const { enqueueSuccess, enqueueError } = useNotifications();
  const maxLengthPrefix = 50;
  const maxLengthName = 100;

  const handleClose = () => {
    setIsRenameFolderDialogOpen(false);
  };

  const handleFolderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(event.target.value);
  };

  const handleFolderPrefixChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFolderPrefix(event.target.value);
  };

  const handleSave = async () => {
    try {
      await updateFolder({ id: folderId, name: newFolderName, prefix: newFolderPrefix });

      enqueueSuccess('dlxDrive.notifications.folderUpdated');
    } catch (error) {
      enqueueError(error.message);
    } finally {
      setDriveListIdState();
      handleClose();
    }
  };

  useEffect(() => {
    setNewFolderName(folderName);
    setNewFolderPrefix(folderPrefix);
  }, [folderName, folderPrefix]);

  return (
    <Dialog open={isRenameFolderDialogOpen} maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{folderName}</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          autoFocus
          required
          label={`${t('documentTree.modal.folderName')}`}
          fullWidth
          size="small"
          value={newFolderName}
          error={newFolderName.length === 0}
          inputProps={{ maxLength: maxLengthName }}
          helperText={newFolderName.length === 0 ? `${t('shared.errors.nameRequired')}` : newFolderName.length === maxLengthName ? `${t('dlxDrive.dialogs.maxFolderNameLengthReached', { maxLengthName })}`: ' '}
          onChange={handleFolderNameChange}
          id='xp747ed6'
        />

        <TextField
          label={`${t('documentTree.modal.prefix')}`}
          fullWidth
          size="small"
          value={newFolderPrefix}
          onChange={handleFolderPrefixChange}
          inputProps={{ maxLength: maxLengthPrefix }}
          helperText={newFolderPrefix && newFolderPrefix.length === maxLengthPrefix ? `${t('dlxDrive.dialogs.maxFolderPrefixLengthReached', { maxLengthPrefix })}` : ''}
          id='rpmxntpk'
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={newFolderName.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleSave}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveRenameFolderDialog;
