import { toLocal } from 'src/utils/dateTimeUtils';

import { FormTypes } from 'src/@types/shared/enums/formTypes';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

export class FormTemplate {
  id: number;

  name: string;

  structure: string;

  formModuleType: DocumentModuleTypes;

  formType: FormTypes | null;

  created: Date;

  doclogixCoreProfileId: string;

  doclogixCoreFileId: string;

  approvalInDoclogix: boolean;

  managerApproval: boolean;

  ceoApproval: boolean;

  parseJson(json: any): FormTemplate | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: FormTemplate = new FormTemplate();

    model.id = json.id;
    model.name = json.name;
    model.structure = json.structure;
    model.formModuleType = json.formModuleType;
    model.formType = json.formType;
    model.created = toLocal(json.created);
    model.doclogixCoreProfileId = json.doclogixCoreProfileId;
    model.doclogixCoreFileId = json.doclogixCoreFileId;
    model.approvalInDoclogix = json.approvalInDoclogix;
    model.managerApproval = json.managerApproval;
    model.ceoApproval = json.ceoApproval;

    return model;
  }
}
