import axios from 'src/utils/httpClients/axios';

import { HOST_API } from 'src/config';
import { PagingFilter } from 'src/@types/shared/pagingFilter';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { CustomList, CustomListItem, CustomListCreate } from 'src/@types/customLists/CustomList';

const baseUrl = `${HOST_API  }CustomList`;

export async function getCustomLists(filter: PagingFilter): Promise<PagedResponse<CustomList>> {
  const response = await axios.get(baseUrl, { params: { ...filter } });
  const { data } = response;

  return data;
}

export async function createCustomList(dto: CustomListCreate): Promise<number> {
  const response = await axios.put(baseUrl, dto);
  const { data } = response;

  return data;
}

export async function getCustomList(id: number): Promise<CustomList> {
  const response = await axios.get(`${baseUrl  }/${id}`);
  const { data } = response;

  return data;
}

export async function deleteCustomList(id: number): Promise<boolean> {
  const response = await axios.delete(`${baseUrl  }/${id}`);
  const { data } = response;

  return data;
}

export async function updateCustomList(dto: CustomList): Promise<number> {
  const response = await axios.post(baseUrl, dto);
  const { data } = response;

  return data;
}

export async function getFullList(): Promise<CustomListItem> {
  const response = await axios.get(`${baseUrl  }/list`);
  const { data } = response;

  return data;
}

const customListService = {
  getCustomLists,
  createCustomList,
  getCustomList,
  deleteCustomList,
  getFullList,
  updateCustomList,
};

export default customListService;
