import { createSlice } from '@reduxjs/toolkit';

import folderService from 'src/services/folderService';
import { FoldersFilter } from 'src/@types/folders/foldersFilter';
import { CreateFolder, initialStateProp } from 'src/@types/folders/types';

import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: initialStateProp = {
  isLoading: false,
  folders: null,
  error: null,
  selectedId: null,
  folder: null,
};

const slice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.folders = initialState.folders;
      state.error = initialState.error;
      state.selectedId = initialState.selectedId;
      state.folder = initialState.folder;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFoldersSuccess(state, action) {
      state.isLoading = false;
      state.folders = action.payload;
    },

    getFoldersError(state, action) {
      state.isLoading = false;
      state.folders = initialState.folders;
      state.error = action.payload;
    },

    folderSelected(state, action) {
      state.selectedId = action.payload;
    },

    getFolderSuccess(state, action) {
      state.isLoading = false;
      state.folder = action.payload;
      state.selectedId = action.payload.id;
    },
    getFolderError(state, action) {
      state.isLoading = false;
      state.folder = null;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function setSelectedFolderId(id: number | null) {
  dispatch(slice.actions.folderSelected(id));
}

export async function getOwnFolders(filter: FoldersFilter) {
  dispatch(slice.actions.startLoading());

  try {
    const data = await folderService.getPagedFolders(filter);

    dispatch(slice.actions.getFoldersSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getFoldersError(error));
  }
}

export async function removeFolder(id: number) {
  dispatch(slice.actions.startLoading());
  try {
    await folderService.removeFolder(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function removeBulkFolders(id: number[]) {
  dispatch(slice.actions.startLoading());

  try {
    await folderService.removeBulkFolders(id);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function removeDocumentFromFolder(folderId: number, documentId: number) {
  dispatch(slice.actions.startLoading());

  try {
    await folderService.removeDocumentFromFolder(folderId, documentId);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    throw error;
  }
}

export async function addNewFolder(model: CreateFolder) {
  try {
    dispatch(slice.actions.startLoading());
    await folderService.createFolder(model);
    dispatch(slice.actions.finishLoading());
  } catch (error) {
    throw error;
  }
}
