import { isString } from 'lodash';
import { useState, ReactNode, useEffect } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

import { alpha, Theme, styled } from '@mui/material/styles';
// @mui
import { Box, Paper, SxProps, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { CustomFile } from 'src/@types/fileView/types';

import { ProfileUpdate } from 'src/sections/profile/ProfileEditForm';

import Image from '../Image';
import Iconify from '../Iconify';
// utils
import { fData } from '../../utils/formatNumber';

// ----------------------------------------------------------------------
const accept = '.png, .jpg';

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

interface Props extends DropzoneOptions {
  error?: boolean;
  file: CustomFile | string | null;
  caption?: ReactNode;
  sx?: SxProps<Theme>;
  submitTrigger: number;
  handleSubmit: (values: CustomFile | null | string) => void;
  profile?: ProfileUpdate;
  setProfile?: (value: ProfileUpdate) => void;
}

export default function UploadAvatar({
  error,
  file,
  caption,
  sx,
  submitTrigger,
  handleSubmit,
  profile,
  setProfile,
  ...other
}: Props) {
  const { t } = useLocales();
  const [imgFile, setImgFile] = useState<any>();
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    accept,
    ...other,
    onDropAccepted(files, event) {
      const file: CustomFile = files[0];
      file.preview = URL.createObjectURL(files[0]);
      setImgFile(file);

      profile && setProfile && setProfile({ profile: profile.profile, image: file });
    },
  });

  useEffect(() => {
    setImgFile(file);
  }, []);

  useEffect(() => {
    if (submitTrigger) {
      handleSubmit(imgFile ?? '');
    }
  }, [submitTrigger]);

  const deleteImage = () => {
    setImgFile(setImgFile(null));
   profile && setProfile && setProfile({ profile: profile?.profile, image: null});
  };

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        my: 2,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <>
      <RootStyle sx={sx}>
        {imgFile && (
          <Iconify
            onClick={deleteImage}
            icon="ic:round-close"
            id='xpl0zn3w'
            sx={{
              width: 24,
              height: 24,
              mb: 1,
              marginLeft: '125px',
              position: 'absolute',
              '&:hover': { opacity: 0.72 },
            }}
          />
        )}
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
          }}
           id="3MK3fy"
        >
          <input {...getInputProps()} />
          {imgFile && (
            <Image
              alt="avatar"
              src={isString(imgFile) ? imgFile : imgFile.preview}
              sx={{ zIndex: 8 }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(imgFile && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
            }}
          >
            <Iconify icon="ic:round-add-a-photo" sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">
              {imgFile ? t('profile.updateAvatar') : t('profile.uploadAvatar')}
            </Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {caption}

      {fileRejections.length > 0 && <ShowRejectionItems />}
    </>
  );
}
