enum DLXDriveSortByItems {
  Name = 0,
  Modified = 1,
  Prefix = 2,
  Status = 3,
  Author = 4,
  Tags = 5,
}

export default DLXDriveSortByItems;
