import React from 'react';
import { useNavigate } from 'react-router';

import { Button } from '@mui/material';

import Iconify from '../../components/Iconify';
import useLocales from '../../hooks/useLocales';
import { PATH_ADMIN } from '../../routes/paths';

// ----------------------------------------------------------------------

const AdminPanelBackButton: React.FC = () => {
  const { t } = useLocales();
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(PATH_ADMIN.adminPanel)}
      startIcon={<Iconify icon="akar-icons:arrow-left" />}
      id="xhohvo9s"
    >
      {t('shared.buttons.back')}
    </Button>
  );
};

export default AdminPanelBackButton;
