import React from 'react';

import ElementLabel from './ElementLabel';
import { ElementProps } from '../../../@types/forms/types';

type StaticTextElementProps = {
  el: ElementProps;
  pageNumberScale: number;
  isMobileView?: boolean;
};

const StaticTextElement: React.FC<StaticTextElementProps> = ({ el, pageNumberScale, isMobileView }) => (
  <ElementLabel el={el} label={el.staticText} pageNumberScale={pageNumberScale}  isMobileView={isMobileView} isStatic />
);

export default StaticTextElement;
