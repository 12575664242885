export const CALENDAR_NAME_MIN_WIDTH = 240;

export const CALENDAR_CELL_WIDTH = 38;
export const CALENDAR_CELL_HEIGHT = 60;

export const CALENDAR_WORKDAY_CELL_BACKGROUND = '#ffffff';
export const CALENDAR_WEEKEND_CELL_BACKGROUND = '#F6F7F8';
export const CALENDAR_HOLIDAY_CELL_BACKGROUND = '#FFE7D9';

export const CALENDAR_CELL_BORDER_WIDTH = 1;
export const CALENDAR_CELL_BORDER_COLOR = '#DEE2E5';
export const CALENDAR_CELL_BORDER_STYLE = 'none none solid solid';
export const CALENDAR_EVENT_BORDER_RADIUS = '10px';

export const CALENDAR_BANK_HOLIDAY_COUNTRIES = ['lt', 'lv', 'ee'];
