// NEW FORM BUILDER COMPONENT

import React from 'react';

import { Container } from '@mui/material';

import { PATH_ADMIN } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';

import Page from 'src/components/Page';
import BackButton from 'src/components/BackButton';

import FormsCreateNewForm from 'src/sections/forms/create-form/FormsCreateNewForm';

// ----------------------------------------------------------------------

const CreateForm = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('formTemplates.createFormTemplate')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <BackButton paths={[PATH_ADMIN.forms]} />
        <FormsCreateNewForm titleLocale='formTemplates.createFormTemplate' />
      </Container>
    </Page>
  );
};

export default CreateForm;
