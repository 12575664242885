import React from 'react';
import { IDocument } from '@cyntler/react-doc-viewer';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme, IconButton, Typography } from '@mui/material';

type DocumentsViewerNavigationProps = {
  docs: IDocument[];
  currentDocumentIndex: number;
  setCurrentDocumentIndex: (index: number) => void;
};

const DocumentsViewerNavigation: React.FC<DocumentsViewerNavigationProps> = ({
  docs,
  currentDocumentIndex,
  setCurrentDocumentIndex,
}) => {
  const theme = useTheme();
  const previousDocument = () => {
    setCurrentDocumentIndex(currentDocumentIndex - 1);
  };

  const nextDocument = () => {
    setCurrentDocumentIndex(currentDocumentIndex + 1);
  };

  return (
    <>
      {docs.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 1,
          }}
        >
          <IconButton
            id="previous-document-button"
            disabled={currentDocumentIndex === 0}
            sx={{ display: docs.length > 1 ? 'block' : 'none' }}
            size="small"
            onClick={previousDocument}
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>

          {docs[currentDocumentIndex]?.fileName !== undefined && (
            <Typography sx={{ flex: 1, textAlign: 'center', wrap: 'noWrap', color: theme.palette.grey[800] }}>
              {docs[currentDocumentIndex].fileName}
            </Typography>
          )}

          <IconButton
            id="next-document-button"
            disabled={currentDocumentIndex >= docs.length - 1}
            sx={{ display: docs.length > 1 ? 'block' : 'none' }}
            onClick={nextDocument}
            size="small"
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default DocumentsViewerNavigation;
