import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import PushPinIcon from '@mui/icons-material/PushPin';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Tooltip, ListItem, IconButton, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import useNotifications from '../../hooks/useNotifications';
import { pinToNavigation } from '../../services/dlxDriveService';
import { setNavigationState } from '../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type NavigationItemProps = {
  item: any;
  isPinned?: boolean;
  isDefault?: boolean;
};

const NavigationItem: React.FC<NavigationItemProps> = ({ item, isPinned, isDefault }) => {
  const { t } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSuccess } = useNotifications();
  const { pinnedFolderIds } = useSelector((state) => state.dlxDrive);

  const isActive = (path: string) => {
    if (!isDefault) {
      return pathname.includes(path);
    }

    if (isDefault && !pinnedFolderIds.includes(parseInt(pathname.split('/')[2], 10))) {
      return pathname.includes(path);
    }

    return false;
  };

  const COLOR_PASSIVE = theme.palette.text.secondary;
  const COLOR_ACTIVE = theme.palette.primary.main;

  const unPinItem = async () => {
    const id = item.path.split('/')[2];

    try {
      await pinToNavigation(id);

      enqueueSuccess(
        t('dlxDrive.notifications.folderSuccessfullyUnPinned', {
          itemName: item.title,
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setNavigationState();
    }
  };

  return (
    <ListItem
      sx={{ '& .MuiListItemSecondaryAction-root': { top: '17px', right: '2px' } }}
      secondaryAction={
        isPinned && (
          <Tooltip title={t('dlxDrive.folderSettings.clickToUnpin')} placement="top">
            <IconButton
              sx={{ top: 0, left: 0 }}
              aria-label="delete"
              size="small"
              onClick={unPinItem}
            >
              <PushPinIcon fontSize="small" sx={{ rotate: '45deg', opacity: '0.5' }} />
            </IconButton>
          </Tooltip>
        )
      }
      disablePadding
    >
      <Box
        sx={{
          borderRadius: 1,
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          mb: 1,
          backgroundColor: isActive(item.path) ? alpha(COLOR_ACTIVE, 0.1) : null,
          cursor: 'pointer',
        }}
        onClick={() => navigate(item.path)}
      >
        <Box sx={{ color: isActive(item.path) ? COLOR_ACTIVE : COLOR_PASSIVE }}>{item.icon}</Box>

        <Typography
          variant="body2"
          sx={{
            color: isActive(item.path) ? COLOR_ACTIVE : COLOR_PASSIVE,
            textAlign: 'center',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {t(`${item.title}`)}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default NavigationItem;
