export class Role {
  id: string;

  name: string;

  public get localeName(): string {
    return `permissions.roleNames.${this.name}`;
  }

  parseJson(json: any): Role | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Role = new Role();

    model.id = json.id;
    model.name = json.name;

    return model;
  }
}
