import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
} from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import useNotifications from '../../../../hooks/useNotifications';
import { getFolderList, moveDocuments } from '../../../../services/dlxDriveService';
import { addStopLoadingState, setDriveListIdState } from '../../../../redux/slices/dlxDrive';
import {
  DLXDriveFolderListItem,
  DLXDriveMoveDocumentsModelType,
} from '../../../../@types/dlx-drive/types';

// ----------------------------------------------------------------

type DLXDriveMoveDocumentDialogProps = {
  documentIds: number[];
  documentName: string;
  isMoveDocumentDialogOpen: boolean;
  setIsMoveDocumentDialogOpen: (value: boolean) => void;
  increaseDocumentReloadState?: () => void;
};

const DLXDriveMoveDocumentDialog: React.FC<DLXDriveMoveDocumentDialogProps> = ({
  documentIds,
  documentName,
  isMoveDocumentDialogOpen,
  setIsMoveDocumentDialogOpen,
  increaseDocumentReloadState,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [moveToFolder, setMoveToFolder] = useState<DLXDriveFolderListItem>({
    firstLetter: '',
    id: -1,
    name: '',
  });
  const [folderList, setFolderList] = useState<DLXDriveFolderListItem[]>([]);
  const { enqueueSuccess } = useNotifications();

  const fetchFolderList = async () => {
    const list = await getFolderList(true);

    setFolderList(list);
  };

  const clearMoveToFolder = () => {
    setMoveToFolder({
      firstLetter: '',
      id: -1,
      name: '',
    });
  };

  const handleClose = () => {
    clearMoveToFolder();
    setIsMoveDocumentDialogOpen(false);
  };

  const handleChange = (newFolder: DLXDriveFolderListItem | null) => {
    if (newFolder) {
      setMoveToFolder(newFolder);
    }
  };

  const handleSave = async () => {
    const model: DLXDriveMoveDocumentsModelType = {
      idsToMove: documentIds,
      destinationFolder: moveToFolder.id,
    };

    try {
      await moveDocuments(model);

      enqueueSuccess('dlxDrive.notifications.fileMoved');
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      if (increaseDocumentReloadState) {
        increaseDocumentReloadState();
      }

      addStopLoadingState();
      handleClose();
    }
  };

  useEffect(() => {
    if (isMoveDocumentDialogOpen) {
      fetchFolderList();
    }
  }, [isMoveDocumentDialogOpen]);

  return (
    <Dialog open={isMoveDocumentDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.moveDocument', { documentName })}</DialogTitle>

      <DialogContent>
        <Autocomplete
          size='small'
          value={moveToFolder.id !== -1 ? moveToFolder : null}
          options={folderList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: DLXDriveFolderListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.name} (id: ${option.id})`}
          onChange={(event: any, newFolder: DLXDriveFolderListItem | null) => {
            newFolder ? handleChange(newFolder) : clearMoveToFolder();
          }}
          renderInput={(params) => (
            <TextField key={params.id} {...params} label={t('dlxDrive.dialogs.folderList')} />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={moveToFolder.id === -1}
          loading={isLoading}
          variant="contained"
          onClick={handleSave}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveMoveDocumentDialog;
