import { Grid, Container } from '@mui/material';

import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import EventTypesList from '../../../sections/calendar/event-types/EventTypesList';

const EventTypes = () => {
  const { t } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={t('calendar.events.calendarEvents')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container>
          <Grid item xs={12}>
            <EventTypesList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default EventTypes;
