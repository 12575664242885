import { createSlice } from '@reduxjs/toolkit';

// Types
import { JobTitlesState } from 'src/@types/jobTitles/types';
import { UpdateJobTitle } from 'src/@types/jobTitles/jobTitles';
import { JobTitlesFilter } from 'src/@types/jobTitles/jobTitlesFilter';
// Services
import aspJobTitlesService from 'src/services/asp-identity/aspJobTitlesService';

// utils
import { dispatch } from '../store';

const initialState: JobTitlesState = {
  isLoading: false,
  error: null,
  jobTitles: null,
  selectedId: null,
};

const slice = createSlice({
  name: 'jobTitles',
  initialState,
  reducers: {
    clearState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.jobTitles = initialState.jobTitles;
      state.selectedId = initialState.selectedId;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getJobTitlesSuccess(state, action) {
      state.isLoading = false;
      state.jobTitles = action.payload;
    },

    getJobTitlesError(state, action) {
      state.isLoading = false;
      state.jobTitles = initialState.jobTitles;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export async function getJobTitles(filter: JobTitlesFilter) {
  dispatch(slice.actions.startLoading());
  try {
    const data = await aspJobTitlesService.getJobTitles(filter);

    dispatch(slice.actions.getJobTitlesSuccess(data));
  } catch (error) {
    dispatch(slice.actions.getJobTitlesError(error));
  }
}

export async function createJobTitle(model: UpdateJobTitle) {
  try {
    await aspJobTitlesService.createJobTitles(model);
  } catch (error) {
    throw error;
  }
}

export async function updateJobTitle(model: UpdateJobTitle, id: number) {
  try {
    await aspJobTitlesService.updateJobTitles(model, id);
  } catch (error) {
    throw error;
  }
}

export async function deleteJobTitle(id: number) {
  try {
    await aspJobTitlesService.deleteJobTitles(id);
  } catch (error) {
    throw error;
  }
}
