export class Permission {
  id: number;

  name: string;

  public get localeName(): string {
    return `permissions.permissionNames.${this.name}`;
  }

  parseJson(json: any): Permission | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Permission = new Permission();

    model.id = json.id;
    model.name = json.name;

    return model;
  }
}
