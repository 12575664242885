export enum FolderActions {
  Read = 1,
  Delete = 2,
  FolderMove = 3,
  FolderEdit = 4,
  FolderCreate = 5,
  ManageFormTemplates = 6,
  ManageAccess = 7,
  FileMove = 8,
  FileDelete = 9,
  FolderDownload = 10,
  FileUpload = 11,
  FormCreate = 12,
  ReadAll = 13,
  FileShare = 14,
  FolderShare = 15,
  FileDownload = 16,
}
