import axios from '../utils/httpClients/axios';
import { HOST_API, AUTH0_API } from '../config';
import { addStartLoadingState } from '../redux/slices/dlxDrive';
import {
  AccessGroupsMemberListItem,
  AccessGroupsDepartmentListItem,
} from '../@types/access-groups/types';

// ----------------------------------------------------------------

const baseIdentityUrl = `${AUTH0_API.domain}api`;
const baseSigningcoreUrl = `${HOST_API}AccessGroup`;

export const getDepartmentsList = async (isGrouped?: boolean, offset?: number, limit?: number) => {
  addStartLoadingState();

  const response = await axios.get(
    `${baseIdentityUrl}/departments?offset=${offset || 0}&limit=${limit || Number.MAX_SAFE_INTEGER}`
  );

  const list = response.data.items;

  if (list && list.length > 0 && isGrouped) {
    return list.map((option: Omit<AccessGroupsDepartmentListItem, 'firstLetter'>) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
  }

  return list;
};

export const getMembersList = async (isGrouped?: boolean, offset?: number, limit?: number) => {
  addStartLoadingState();

  const response = await axios.get(
    `${baseIdentityUrl}/users/items?offset=${offset || 0}&limit=${limit || Number.MAX_SAFE_INTEGER}`
  );

  const list = response.data.items;

  const getFirstLetter = (option: Omit<AccessGroupsMemberListItem, 'firstLetter'>) => {
    if (option.lastName) {
      return option.lastName[0].toUpperCase();
    }

    if (option.firstName) {
      return option.firstName[0].toUpperCase();
    }

    if (option.email) {
      return option.email[0].toUpperCase();
    }

    return option.id[0].toUpperCase();
  };

  if (list && list.length > 0 && isGrouped) {
    return list.map((option: Omit<AccessGroupsMemberListItem, 'firstLetter'>) => {
      const firstLetter = getFirstLetter(option);
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
  }

  return list;
};

export const createAccessGroup = async (model: any) => {
  const response = await axios.put(`${baseSigningcoreUrl}`, { ...model });

  return response.data;
};

export const editAccessGroup = async (model: any) => {
  const response = await axios.post(`${baseSigningcoreUrl}`, { ...model });

  return response.data;
};

export const deleteAccessGroup = async (id: number) => {
  const response = await axios.delete(`${baseSigningcoreUrl}/${id}`);

  return response.data;
};

export const getAccessGroup = async () => {
  const response = await axios.get(`${baseSigningcoreUrl}`);

  return response.data;
};
