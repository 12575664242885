import React, { useState, useEffect, ChangeEvent } from 'react';

import { Box, useTheme, TextField, SelectChangeEvent } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { setErrorList } from '../../../redux/slices/formBuilder';
import validateIsEmail from '../../../@types/forms/utils/validateIsEmail';
import validateIsNumber from '../../../@types/forms/utils/validateIsNumber';
import validateIsRequired from '../../../@types/forms/utils/validateIsRequired';
import validateNumberOfCharacters from '../../../@types/forms/utils/validateNumberOfCharacters';
import { FormElementValidationType } from '../../../@types/forms/enums/FormElementValidationType';
import { ElementProps, ErrorsListProps, ElementValidationProps } from '../../../@types/forms/types';

//-----------------------------------------------------

type InputFieldElementProps = {
  isChild?: boolean;
  el: ElementProps;
  scaledDimensions: {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    fontSize: number;
  };
  elementContentValue?: string[] | undefined;
  handleTextAlign: (alignEnum: number) => string;
  handleInputFieldChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    el: ElementProps
  ) => void;
  pageNumberScale: number;
};

const InputFieldElement: React.FC<InputFieldElementProps> = ({
  isChild,
  el,
  scaledDimensions,
  elementContentValue,
  handleTextAlign,
  handleInputFieldChange,
  pageNumberScale,
}) => {
  const { t } = useLocales();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [elementErrorsList, setElementErrorsList] = useState<ErrorsListProps[]>([]);
  const theme = useTheme();

  useEffect(() => {
    const data: ElementValidationProps = { el, errorMessage: '', value, elementErrorsList };
    const newErrorList: ErrorsListProps[] = [];

    if (el.required) {
      const newError = validateIsRequired({
        ...data,
        errorMessage: t('forms.validationErrorMessage.fieldRequired'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (el?.validations?.value === FormElementValidationType.OnlyNumbers) {
      const newError = validateIsNumber({
        ...data,
        errorMessage: t('forms.validationErrorMessage.notNumber'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (
      el?.validations?.value === FormElementValidationType.Email &&
      value !== undefined &&
      value !== ''
    ) {
      const newError = validateIsEmail({
        ...data,
        errorMessage: t('forms.validationErrorMessage.invalidEmailFormat'),
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    if (
      el?.validations?.value === FormElementValidationType.NumberOfCharacters &&
      el?.numberOfCharacters
    ) {
      const min = el?.numberOfCharacters[0];
      const max = el?.numberOfCharacters[1];
      let errorMessage = '';

      if (min === max) {
        errorMessage = `${t('forms.validationErrorMessage.InvalidNumberOfCharacters')} ${t(
          'forms.validationErrorMessage.InvalidNumberOfCharactersStrictly',
          {
            min,
          }
        )}`;
      } else {
        errorMessage = `${t('forms.validationErrorMessage.InvalidNumberOfCharacters')} ${t(
          'forms.validationErrorMessage.InvalidNumberOfCharactersMinMax',
          {
            min,
            max,
          }
        )}`;
      }

      const newError = validateNumberOfCharacters({
        ...data,
        errorMessage,
      });

      if (newError) {
        newErrorList.push(newError);
      }
    }

    setElementErrorsList(newErrorList);
  }, [value]);

  useEffect(() => {
    if (elementErrorsList) {
      setErrorList({ elementID: el.id, errors: elementErrorsList });
    }
  }, [elementErrorsList]);

  useEffect(() => {
    if (elementContentValue) {
      setValue(elementContentValue[0]);
    }
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.info.lighter,
        borderRadius: 1,
        width: '100%',
      }}
    >
      <TextField
        disabled={isChild}
        value={value}
        fullWidth
        multiline
        error={elementErrorsList.length > 0}
        helperText={elementErrorsList.length > 0 ? elementErrorsList[0].errorMessage : null}
        size="small"
        inputProps={{
          maxLength:
            el.validations.value !== 0 && el.numberOfCharacters ? el.numberOfCharacters[1] : 1000,
          sx: {
            color: el.color,
            fontSize: `${el.fontSize * pageNumberScale}px`,
            fontFamily: `${el.font}`,
            textAlign: handleTextAlign(el.textAlignment),
            fontWeight: el.textStyle.includes('Bold') ? 'Bold' : 'Regular',
            fontStyle: el.textStyle.includes('Italic') ? 'italic' : 'normal',
            textDecoration: el.textDecoration === 1 ? 'underline' : '',
          },
        }}
        InputLabelProps={{ shrink: !!value }}
        id={el.id}
        label={el.label}
        variant="standard"
        onChange={(event) => {
          handleInputFieldChange && handleInputFieldChange(event, el);
          setValue(event.target.value);
        }}
        sx={{
          width: '100%',
          maxWidth: '100%',
        }}
      />
    </Box>
  );
};

export default InputFieldElement;
