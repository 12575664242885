import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Box, Drawer, Divider } from '@mui/material';

import LogoFooter from './LogoFooter';
import Logo from '../../../components/Logo';
import useAuth from '../../../hooks/useAuth';
import Scrollbar from '../../../components/Scrollbar';
import { Profile } from '../../../@types/users/profile';
import useMenuDrawer from '../../../hooks/useMenuDrawer';
import { getCompany } from '../../../redux/slices/company';
import Navigation from '../../../components/navigation/Navigation';
import aspUserService from '../../../services/asp-identity/aspUserService';
import { DASHBOARD_NAVBAR_WIDTH, DASHBOARD_HEADER_DESKTOP } from '../../../config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function DashboardNavbar() {
  const { pathname } = useLocation();
  const [languageMenuOpen] = useState(false);
  const [user, setUser] = useState<Profile>();
  const { user: adminUser } = useAuth();

  const menuDrawer = useMenuDrawer();

  useEffect(() => {
    menuDrawer.onClose();
  }, [pathname]);

  useEffect(() => {
    menuDrawer.onToggleMenu(languageMenuOpen);
  }, [languageMenuOpen]);

  const getProfileData = async () => {
    const data = await aspUserService.getProfile();
    setUser(data);
  };

  useEffect(() => {
    if (adminUser?.isAdmin || adminUser?.isSuperAdmin) {
      getCompany();
    }

    getProfileData();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        overflowX: 'hidden',
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {!menuDrawer.isLargeScreen && (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}
            onClick={() => menuDrawer.onToggleMenu(false)}
          >
            <Logo disabledLink />
          </Box>
          <Divider orientation="horizontal" sx={{ mx: 1, mb: 2 }} />
        </>
      )}

      <Navigation />
      {/* <NavSection /> */}

      <Box sx={{ flexGrow: 1 }} />

      <LogoFooter />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: DASHBOARD_NAVBAR_WIDTH,
          xs: menuDrawer.isOpen ? DASHBOARD_NAVBAR_WIDTH : 0,
        },
        position: 'relative',
        ...(menuDrawer.isOpen && {
          position: 'absolute',
        }),
      }}
    >
      {menuDrawer.isLargeScreen && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              mt: `${DASHBOARD_HEADER_DESKTOP}px`,
              width: DASHBOARD_NAVBAR_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {!menuDrawer.isLargeScreen && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 0,
              display: 'hidden',
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              overflowX: 'hidden',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(menuDrawer.isOpen && {
                width: DASHBOARD_NAVBAR_WIDTH,
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
