import { Location, useLocation } from 'react-router-dom';
import { useState, ReactNode, useEffect, createContext } from 'react';
// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const PrevLocationContext = createContext<Location | undefined>(undefined);

function PrevLocationProvider({ children }: Props) {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<Location>();
  const [currentLocation, setCurrentLocation] = useState<Location>();

  useEffect(() => {
    if (currentLocation?.pathname !== location.pathname || currentLocation?.search !== location.search) {
      setPrevLocation(currentLocation);
      setCurrentLocation(location);
    }
  }, [location]);

  return <PrevLocationContext.Provider value={prevLocation}>{children}</PrevLocationContext.Provider>;
}

export { PrevLocationContext, PrevLocationProvider };
