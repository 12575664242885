import React from 'react';

import { Box, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';

import CalendarDesktopDayCell from './CalendarDesktopDayCell';
import CalendarDesktopUserCell from './CalendarDesktopUserCell';
import CalendarDesktopEmptyCell from './CalendarDesktopEmptyCell';
import CalendarDesktopEventCell from './CalendarDesktopEventCell';
import { CalendarDayProps, CalendarDepartmentProps } from '../../@types/calendar/types';
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_NAME_MIN_WIDTH,
  CALENDAR_CELL_BORDER_COLOR,
  CALENDAR_CELL_BORDER_WIDTH,
} from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopLayoutProps = {
  days: CalendarDayProps[];
  departments: CalendarDepartmentProps[];
};

const CalendarDesktopLayout: React.FC<CalendarDesktopLayoutProps> = ({ days, departments }) => (
  <>
    {departments.map((department) => (
      <Box key={`department-key-${department.departmentId}`} sx={{ mb: 2 }}>
        <Box
          sx={{
            width: 'calc(100% - 2px)',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
              height: `${CALENDAR_CELL_HEIGHT / 2}px`,
              borderBottom: `${CALENDAR_CELL_BORDER_WIDTH}px solid ${CALENDAR_CELL_BORDER_COLOR}`,
            }}
          >
            <Box
              sx={{
                width: '100%',
                minWidth: `${CALENDAR_NAME_MIN_WIDTH}px`,
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <GroupsIcon sx={{ opacity: 0.8 }} />

              <Typography variant="body2" noWrap>
                {department.name}
              </Typography>
            </Box>
          </Box>

          {days.map((day) => (
            <CalendarDesktopDayCell
              key={`day-cell-key-${department.departmentId}-${day.dateId}`}
              day={day}
            />
          ))}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {department.users.map((user) => (
            <Box key={`user-key-${user.userId}`} sx={{ width: '100%' }}>
              <Box
                sx={{
                  width: 'calc(100% - 2px)',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    height: `${CALENDAR_CELL_HEIGHT}px`,
                    borderBottom: `${CALENDAR_CELL_BORDER_WIDTH}px solid ${CALENDAR_CELL_BORDER_COLOR}`,
                  }}
                >
                  <CalendarDesktopUserCell user={user} />
                </Box>

                {days.map((day, index) => {
                  if (!user.events.hasOwnProperty(day.dateId)) {
                    return (
                      <CalendarDesktopEmptyCell
                        key={`empty-day-key-${day.date}-${index}`}
                        day={day}
                      />
                    );
                  }

                  return (
                    <CalendarDesktopEventCell
                      key={`event-day-key-${day.date}-${index}`}
                      day={day}
                      user={user}
                    />
                  );
                })}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </>
);

export default CalendarDesktopLayout;
