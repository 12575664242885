import { useNavigate } from 'react-router';
import React, { useRef, useState, useEffect } from 'react';

import { Box, Card, MenuItem, useTheme, Typography } from '@mui/material';

import { useSelector } from '../../../redux/store';
import useLocales from '../../../hooks/useLocales';
import Scrollbar from '../../../components/Scrollbar';
import useResponsive from '../../../hooks/useResponsive';
import MenuPopover from '../../../components/MenuPopover';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { FillFormType } from '../../../@types/dlx-drive/types';
import { PATH_HR, PATH_ACCOUNTING } from '../../../routes/paths';
import { addStopLoadingState } from '../../../redux/slices/dlxDrive';
import { getFolderFormTemplates } from '../../../services/dlxDriveService';

// --------------------------------------------------------

type DLXDriveDocumentActionsFillFormProps = {
  folderId: number;
};

const DLXDriveDocumentActionsFillForm: React.FC<DLXDriveDocumentActionsFillFormProps> = ({
  folderId,
}) => {
  const { t } = useLocales();
  const [open, setOpen] = useState<boolean>(false);
  const [forms, setForms] = useState<FillFormType[]>([]);
  const theme = useTheme();
  const { reloadFillForm, breadcrumbs } = useSelector((state) => state.dlxDrive);
  const navigate = useNavigate();
  const isMobile = useResponsive('only', 'xs');
  const fillFormRef = useRef(null);

  const fetchFormTemplatesList = async () => {
    try {
      const folderForms = await getFolderFormTemplates(folderId);
      setForms(folderForms);
    } catch (error) {
      console.error(error);
    } finally {
      addStopLoadingState();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormClick = (formId: number, formType: number) => {
    let route = '';

    if (formType === 0) {
      route = PATH_HR.createDocumentFormIdFolderId(formId, folderId);
    } else {
      route = PATH_ACCOUNTING.createDocumentFormIdFolderId(formId, folderId);
    }

    navigate(route);
  };

  useEffect(() => {
    if (folderId) {
      fetchFormTemplatesList();
    }
  }, [reloadFillForm, breadcrumbs]);

  return (
    <>
      {forms.length > 0 && (
        <Card
          ref={fillFormRef}
          onClick={handleOpen}
          sx={{
            width: isMobile ? '100%' : '360px',
            px: isMobile ? 1.5 : 3,
            py: isMobile ? 1.5 : 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'}>
              {t('dlxDrive.buttons.fillForm')}
            </Typography>

            {!isMobile && (
              <Typography variant="body2" color={theme.palette.text.secondary}>
                {t('documents.selectFormAndFill')}
              </Typography>
            )}
          </Box>

          <Box>
            <SvgIconStyle
              src="/icons/dlx-drive/fill_form.svg"
              sx={{
                width: { xs: 34, sm: 54 },
                height: { xs: 34, sm: 54 },
                display: 'inherit',
                opacity: '0.2',
              }}
            />
          </Box>
        </Card>
      )}

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={fillFormRef.current}
        sx={{ width: '360px', ml: '-1px' }}
      >
        <Scrollbar sx={{ maxHeight: '248px' }}>
          {forms.map((form) => (
            <MenuItem
              key={form.id}
              sx={{ py: 1, px: 2, borderRadius: 1, m: 1 }}
              onClick={() => handleFormClick(form.id, form.type)}
              id={`XBZYa4_${form.id}`}
            >
              <Typography noWrap>{form.name}</Typography>
            </MenuItem>
          ))}
        </Scrollbar>
      </MenuPopover>
    </>
  );
};

export default DLXDriveDocumentActionsFillForm;
