import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
  Box,
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import useNotifications from '../../../hooks/useNotifications';
import { shareItems } from '../../../services/dlxDriveService';
import isEmailAddressValid from '../../../utils/isEmailAddressValid';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../../utils/constants/dlxDriveConstants';
import {
  removeItemState,
  addStopLoadingState,
  clearCheckedItemsState,
} from '../../../redux/slices/dlxDrive';

type DLXDriveBulkShareDialogProps = {
  isBulkShareDialogOpen: boolean;
  setIsBulkShareDialogOpen: (value: boolean) => void;
};

// ----------------------------------------------------------------

const DLXDriveBulkShareDialog: React.FC<DLXDriveBulkShareDialogProps> = ({
  isBulkShareDialogOpen,
  setIsBulkShareDialogOpen,
}) => {
  const { t } = useLocales();
  const { user } = useAuth();
  const { isLoading, checkedItems } = useSelector((state) => state.dlxDrive);
  const [email, setEmail] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { enqueueSuccess } = useNotifications();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: t('dlxDrive.dataGrid.columnId'),
    },
    {
      field: 'name',
      headerName: t('dlxDrive.dataGrid.columnName'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('dlxDrive.dataGrid.columnType'),
    },
    {
      field: 'remove',
      headerName: t('dlxDrive.dataGrid.columnRemove'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const { id, name, type } = params.row;

          const itemToRemove = {
            id,
            name,
            isFolder: type === t('dlxDrive.types.folder'),
            actions: [],
          };

          removeItemState(itemToRemove);
        };

        return (
          <IconButton onClick={onClick} id="tTJmXX">
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              width={FOLDER_ACTIONS_MENU_ICON_SIZE}
              height={FOLDER_ACTIONS_MENU_ICON_SIZE}
              alt={t('delete')}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = checkedItems.map((item) => ({
    id: item.id,
    name: item.name,
    type: item.isFolder
      ? t('dlxDrive.dataGrid.columnTypeFolder')
      : t('dlxDrive.dataGrid.columnTypeDocument'),
  }));

  const handleClose = () => {
    setIsBulkShareDialogOpen(false);
  };

  const handleShare = async () => {
    if (email && expirationDate) {
      try {
        const model = {
          email,
          sharedBy: user ? user.email : '',
          displayText: user ? user.email : '',
          documentIds: checkedItems.filter((item) => !item.isFolder).map((item) => item.id),
          folderIds: checkedItems.filter((item) => item.isFolder).map((item) => item.id),
          expirationDate,
        };

        await shareItems(model);

        setEmail('');
        setExpirationDate(null);
        clearCheckedItemsState();

        enqueueSuccess('dlxDrive.notifications.bulkSharedSuccessfully');
      } catch (error) {
        console.error(error);
      } finally {
        addStopLoadingState();
        setIsBulkShareDialogOpen(false);
      }
    }
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setIsBulkShareDialogOpen(false);
    }
  }, [checkedItems]);

  return (
    <Dialog open={isBulkShareDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.bulkDocumentSharing')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t('dlxDrive.dialogs.bulkDocumentSharingInfoText')}</DialogContentText>

        <Box
          id='tugnjhn5'
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
            mt: 3,
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={t('documents.sharing.inputs.emailAddress')}
            value={email}
            error={!!(email && !isEmailAddressValid(email))}
            helperText={
              email && !isEmailAddressValid(email)
                ? t('documents.sharing.errors.invalidEmailFormat')
                : ' '
            }
            onChange={(e) => setEmail(e.target.value)}
            id="sfghg5g"
          />

          <DatePicker
            open={isOpen}
            disablePast
            sx={{ maxWidth: '200px' }}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true,
                label: t('dlxDrive.dialogs.expirationDate'),
                onClick: () => setIsOpen(true),
              },
            }}
            slots={{
              openPickerButton: () => null,
            }}
            defaultValue={expirationDate}
            onChange={(newDate) => {
              if (newDate) {
                try {
                  const date = new Date(newDate);
                  date.setHours(23);
                  date.setMinutes(59);
                  date.setSeconds(59);
                  date.setMilliseconds(999);

                  setExpirationDate(date.toISOString());
                } catch (error) {
                  console.error(error);
                  setExpirationDate(null);
                }
              } else {
                setExpirationDate(null);
              }

              setIsOpen(false);
            }}
          />
        </Box>

        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={50}
          sx={{ width: '100%' }}
          disableColumnFilter
          disableColumnMenu
          disableMultipleColumnsSorting
          disableChildrenSorting
          localeText={{ footerTotalRows: t('dlxDrive.dataGrid.footerTotalRows') }}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={(email && !isEmailAddressValid(email)) || !email || !expirationDate}
          loading={isLoading}
          variant="contained"
          onClick={handleShare}
          id="yd0sown5"
        >
          {`${t('shared.buttons.share')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveBulkShareDialog;
