import { m } from 'framer-motion';

import { Box, SxProps } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';

//
import ProgressBar from './ProgressBar';
import { useSelector } from '../redux/store';
import { getFileStringType } from '../utils/fileUtils';
// config
import { DASHBOARD_NAVBAR_WIDTH, DASHBOARD_HEADER_DESKTOP } from '../config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

type Props = {
  isDashboard?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ isDashboard, ...other }: Props) {
  const { settings } = useSelector((state) => state.company);

  const src = () => {
    if (settings?.logoImage) {
      const logoType = getFileStringType(settings.logoImage) ?? 'image/jpg';
      return `data:${logoType};base64,${settings.logoImage}`;
    }

    return '/logo/logo_default.png';
  };

  return (
    <>
      <ProgressBar />

      <RootStyle
        sx={{
          ...(isDashboard && {
            width: { lg: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)` },
            height: { lg: `calc(100% - ${DASHBOARD_HEADER_DESKTOP}px)` },
          }),
        }}
        {...other}
      >
        <m.div
          initial={{ rotateY: 0 }}
          animate={{ rotateY: 360 }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              maxHeight: '64px',
              maxWidth: '64px',
            }}
            src={src()}
            alt="Logo"
          />
        </m.div>

        <Box
          component={m.div}
          animate={{
            scale: [1.2, 1, 1, 1.2, 1.2],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
          sx={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) =>
              `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
          }}
        />

        <Box
          component={m.div}
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          }}
          transition={{
            ease: 'linear',
            duration: 3.2,
            repeat: Infinity,
          }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) =>
              `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
          }}
        />
      </RootStyle>
    </>
  );
}
