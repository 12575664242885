import { ReactNode } from 'react';
import { Navigate } from 'react-router';

import { getSession } from 'src/utils/auth/jwt';

import { Permissions } from 'src/@types/auth/constants/permissions';

// ----------------------------------------------------------------------

type PermissionGuardProp = {
  permission: Permissions;
  children: ReactNode | string;
};

export default function PermissionGuard({ permission, children }: PermissionGuardProp) {
  const {user} = getSession();

  if (!user?.permissions.includes(permission)) {
    return <Navigate to='/404' replace />;
  }

  return <>{children}</>;
}
