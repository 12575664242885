import { toLocal } from 'src/utils/dateTimeUtils';

export class Template {
  id: number;

  name: string;

  created: Date;

  parseJson(json: any): Template | null {
    if (json === null || json === undefined) {
      return null;
    }

    const model: Template = new Template();

    model.id = json.id;
    model.name = json.name;
    model.created = toLocal(json.created);

    return model;
  }
}
