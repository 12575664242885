import { FormElementType } from '../../@types/forms/enums/FormElementType';

const getElementIcon = (type: number) => {
  let elementIcon = '';

  switch (type) {
    case FormElementType.StaticText:
      elementIcon = 'form_builder_static_text_icon.svg';
      break;

    case FormElementType.InputField:
    case FormElementType.MultiLineInput:
      elementIcon = 'form_builder_input_field_icon.svg';
      break;

    case FormElementType.Dropdown:
      elementIcon = 'form_builder_dropdown_icon.svg';
      break;

    case FormElementType.Checkbox:
      elementIcon = 'form_builder_checkbox_icon.svg';
      break;

    case FormElementType.RadioButton:
      elementIcon = 'form_builder_radio_button_icon.svg';
      break;

    case FormElementType.Date:
      elementIcon = 'form_builder_date_picker_icon.svg';
      break;

    case FormElementType.DateRange:
      elementIcon = 'form_builder_date_range_icon.svg';
      break;

    case FormElementType.Image:
      elementIcon = 'form_builder_image_icon.svg';
      break;

    case FormElementType.CustomList:
      elementIcon = 'form_builder_custom_list_icon.svg';
      break;

    case FormElementType.AddressBook:
      elementIcon = 'form_builder_address_book_icon.svg';
      break;
    case FormElementType.Hyperlink:
      elementIcon = 'form_builder_hyperlink_icon.svg';
      break;

    case FormElementType.Signature:
      elementIcon = 'form_builder_signature_icon.svg';
      break;

    case FormElementType.User:
      elementIcon = 'form_builder_user_icon.svg';
      break;

    case FormElementType.RegistrationNumber:
      elementIcon = 'form_builder_registration_number.svg';
      break;

    default:
      elementIcon = 'form_builder_checkbox_icon.svg';
  }

  return elementIcon;
};

export default getElementIcon;
