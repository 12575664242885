export enum Countries {
  Lithuania = 'lt',
  Latvia = 'lv',
  Estonia = 'ee',
}

export enum CountriesUpperCase {
  Lithuania = 'LT',
  Latvia = 'LV',
  Estonia = 'EE',
}
