import { lazy, Suspense, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import { PATH_HR, PATH_STANDARD, PATH_DASHBOARD, PATH_ACCOUNTING } from 'src/routes/paths';

import useAppSettings from 'src/hooks/useAppSettings';

import RoleGuard from 'src/guards/RoleGuard';
import AuthLayout from 'src/layouts/AuthLayout';
import ModuleGuard from 'src/guards/ModuleGuard';
import List from 'src/pages/admin/customLists/List';
import Dashboard from 'src/pages/dashboard/Dashboard';
import { Roles } from 'src/@types/auth/constants/roles';
import PermissionGuard from 'src/guards/PermissionGuard';
import { ModuleTypes } from 'src/@types/module/enums/moduleTypes';
import { DocumentsTypeProvider } from 'src/contexts/DocumentsTypeContext';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';
import { Permissions as PermissionTypes } from 'src/@types/auth/constants/permissions';

import Departments from 'src/sections/admin/departments/Departments';

import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import Forms from '../pages/admin/forms/Forms';
import Calendar from '../pages/calendar/Calendar';
import DashboardLayout from '../layouts/dashboard';
import DLXDrive from '../pages/dlx-drive/DLXDrive';
import EditForm from '../pages/admin/forms/EditForm';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import CreateForm from '../pages/admin/forms/CreatForm';
import EventTypes from '../pages/admin/calendar/EventTypes';
import AccessGroups from '../pages/admin/dlxDrive/AccessGroups';
import EparakstsMobileCallback from '../sections/documents/EparakstsMobileCallback';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/documents')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const DefaultPage = () => {
  const moduleData = useAppSettings();

  if (moduleData.modules.includes(ModuleTypes.Standard)) {
    return <Navigate to={PATH_STANDARD.root} replace />;
  }

  if (moduleData.modules.includes(ModuleTypes.Accounting)) {
    return <Navigate to={PATH_ACCOUNTING.root} replace />;
  }

  if (moduleData.modules.includes(ModuleTypes.HR)) {
    return <Navigate to={PATH_HR.root} replace />;
  }

  return <Navigate to={PATH_DASHBOARD.root} replace />;
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.EmailLogin}>
              <Register />
            </ModuleGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.EmailLogin}>
              <ResetPassword />
            </ModuleGuard>
          ),
        },
        {
          path: 'password-recovery',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.EmailLogin}>
              <PasswordRecovery />
            </ModuleGuard>
          ),
        },
        {
          path: 'confirm-email',
          element: <ConfirmEmail />,
        },
        {
          path: 'eparaksts',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.EparakstLogin}>
              <EparakstsRedirect />
            </ModuleGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.Dashboard}>
            <DashboardLayout />
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [{ element: <Dashboard />, index: true }],
    },

    // HR Routes
    {
      path: 'hr',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.HR}>
            <PermissionGuard permission={PermissionTypes.UseHRModule}>
              <DocumentsTypeProvider documentType={DocumentModuleTypes.HR}>
                <DashboardLayout />
              </DocumentsTypeProvider>
            </PermissionGuard>
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/hr/documents" replace />, index: true },
        {
          path: 'documents',
          children: [
            { element: <Documents />, index: true },
            {
              path: ':id',
              children: [
                { element: <DocumentDetails />, index: true },
                {
                  path: 'edit',
                  element: <FilledFormEdit />,
                },
              ],
            },
          ],
        },
        {
          path: 'create-document',
          children: [
            {
              element: (
                <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                  <HRFormFill />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: ':formBuilderId',
              children: [
                {
                  element: (
                    <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                      <HRFormFill />
                    </PermissionGuard>
                  ),
                  index: true,
                },
                {
                  path: ':folderId',
                  children: [
                    {
                      element: (
                        <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                          <HRFormFill />
                        </PermissionGuard>
                      ),
                      index: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    // Accounting Routes
    {
      path: 'accounting',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.Accounting}>
            <PermissionGuard permission={PermissionTypes.UseAccountingModule}>
              <DocumentsTypeProvider documentType={DocumentModuleTypes.Accounting}>
                <DashboardLayout />
              </DocumentsTypeProvider>
            </PermissionGuard>
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/accounting/documents" replace />, index: true },
        {
          path: 'documents',
          children: [
            { element: <Documents />, index: true },
            {
              path: ':id',
              children: [
                { element: <DocumentDetails />, index: true },
                {
                  path: 'edit',
                  element: <FilledFormEdit />,
                },
              ],
            },
          ],
        },
        {
          path: 'create-document',
          children: [
            {
              element: (
                <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                  <AccountancyFormFill />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: ':formBuilderId',
              children: [
                {
                  element: (
                    <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                      <AccountancyFormFill />
                    </PermissionGuard>
                  ),
                  index: true,
                },
                {
                  path: ':folderId',
                  children: [
                    {
                      element: (
                        <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                          <AccountancyFormFill />
                        </PermissionGuard>
                      ),
                      index: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    //  Document tree
    {
      path: 'document-tree',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.FoldersModule}>
            <DashboardLayout />
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [
        // { element: <DocumentTree />, index: true }
        {
          path: '/document-tree',
          element: <DocumentTree />,
        },
        {
          path: '/document-tree/:id',
          element: <DocumentTree />,
        },
      ],
    },

    //  DLX Drive
    {
      path: 'folders',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/folders',
          element: <DLXDrive />,
        },
        {
          path: '/folders/:id',
          element: <DLXDrive />,
        },
        {
          path: '/folders/:id/:page',
          element: <DLXDrive />,
        },
      ],
    },

    // Calendar
    {
      path: 'calendar',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.Calendar}>
            <DashboardLayout />
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '/calendar',
          element: <Calendar />,
        },
      ],
    },

    // Standard Routes
    {
      path: 'standard',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.Standard}>
            <DocumentsTypeProvider documentType={DocumentModuleTypes.Standard}>
              <DashboardLayout />
            </DocumentsTypeProvider>
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/standard/documents" replace />, index: true },
        {
          path: 'documents',
          children: [
            { element: <Documents />, index: true },
            {
              path: ':id',
              children: [
                { element: <DocumentDetails />, index: true },
                {
                  path: 'edit',
                  element: <DocumentEdit />,
                },
                {
                  path: 'edit-form',
                  element: <DocumentEditForm />,
                },
                {
                  path: 'eparaksts-mobile/callback',
                  element: <EparakstsMobileCallback />,
                },
              ],
            },
          ],
        },
        {
          path: 'compose-document',
          children: [
            {
              element: (
                <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                  <DocumentUpload />
                </PermissionGuard>
              ),
              index: true,
            },
            {
              path: ':id',
              children: [
                {
                  element: (
                    <PermissionGuard permission={PermissionTypes.ComposeDocument}>
                      <DocumentUpload />
                    </PermissionGuard>
                  ),
                  index: true,
                },
              ],
            },
          ],
        },
      ],
    },

    // Profile Routes
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <Profile />, index: true }],
    },

    // Address book
    {
      path: 'addressBook',
      element: (
        <AuthGuard>
          <ModuleGuard moduleKey={ModuleTypes.AddressBook}>
            <PermissionGuard permission={PermissionTypes.UseAddressBook}>
              <DashboardLayout />
            </PermissionGuard>
          </ModuleGuard>
        </AuthGuard>
      ),
      children: [{ element: <AddressBook />, index: true }],
    },

    // Admin Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <RoleGuard accessibleRoles={[Roles.Admin]}>
            <DashboardLayout />
          </RoleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin-panel" replace />, index: true },
        {
          path: 'admin-panel',
          element: <AdminPanel />,
        },
        {
          path: 'users',
          element: <Users />,
        },
        {
          path: 'permissions',
          element: <Permissions />,
        },
        {
          path: 'company',
          element: <Company />,
        },
        {
          path: 'access-groups',
          element: <AccessGroups />,
        },
        {
          path: 'event-types',
          element: <EventTypes />,
        },
        {
          path: 'departments',
          element: <Departments />,
        },

        // Form Routes (Admin part)
        {
          path: 'forms',
          children: [{ element: <Forms />, index: true }],
        },
        {
          path: 'forms/create',
          children: [{ element: <CreateForm />, index: true }],
        },
        {
          path: 'forms/edit',
          children: [
            {
              path: ':id',
              element: <EditForm />,
            },
          ],
        },
        {
          path: 'lists',
          children: [{ element: <List />, index: true }],
        },
        // Old React Form Builder Routes
        {
          path: 'templates',
          children: [
            { element: <Templates />, index: true },
            {
              path: 'forms',
              children: [
                { element: <Navigate to="/admin/templates" replace />, index: true },
                {
                  path: ':id',
                  element: <FormEdit />,
                },
              ],
            },
          ],
        },
        {
          path: 'tags',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.Tags}>
              <Tags />
            </ModuleGuard>
          ),
        },
        {
          path: 'job-titles',
          element: (
            <ModuleGuard moduleKey={ModuleTypes.CustomJobTitles}>
              <JobTitles />
            </ModuleGuard>
          ),
        },
      ],
    },

    // Super Admin Routes
    {
      path: 'super-admin',
      element: (
        <AuthGuard>
          <RoleGuard accessibleRoles={[Roles.SuperAdmin]}>
            <DashboardLayout />
          </RoleGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/super-admin/tenants" replace />, index: true },
        {
          path: 'tenants',
          children: [
            { element: <Tenants />, index: true },
            {
              path: 'create',
              element: <TenantCreate />,
            },
            {
              path: ':id',
              children: [
                {
                  path: 'edit',
                  index: true,
                  element: <TenantEdit />,
                },
              ],
            },
          ],
        },
        {
          path: 'form-templates',
          children: [
            { element: <FormTemplates />, index: true },
            {
              path: 'create',
              element: <FormTemplateCreate />,
            },
            {
              path: ':id',
              element: <FormTemplateEdit />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [{ element: <DefaultPage />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const PasswordRecovery = Loadable(lazy(() => import('../pages/auth/PasswordRecovery')));
const ConfirmEmail = Loadable(lazy(() => import('../pages/auth/ConfirmEmail')));
const EparakstsRedirect = Loadable(lazy(() => import('../pages/auth/EparakstsRedirect')));
// Documents
const Documents = Loadable(lazy(() => import('../pages/documents/Documents')));
const DocumentUpload = Loadable(lazy(() => import('../pages/documents/DocumentUpload')));
const DocumentCreate = Loadable(lazy(() => import('../pages/documents/DocumentCreate')));
const DocumentDetails = Loadable(lazy(() => import('../pages/documents/DocumentDetails')));
const DocumentEdit = Loadable(lazy(() => import('../pages/forms/FilledFormEdit')));
const DocumentEditForm = Loadable(lazy(() => import('../pages/forms/FilledFormEdit')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Admin
const AdminPanel = Loadable(lazy(() => import('../sections/admin/AdminPanel')));
const Users = Loadable(lazy(() => import('../pages/admin/Users')));
const Permissions = Loadable(lazy(() => import('../pages/admin/Permissions')));
const Company = Loadable(lazy(() => import('../pages/admin/Company')));
const Templates = Loadable(lazy(() => import('../pages/admin/templates/Templates')));
const TemplateUpload = Loadable(lazy(() => import('../pages/admin/templates/TemplateUpload')));
const TemplateDetails = Loadable(lazy(() => import('../pages/admin/templates/TemplateDetails')));
const Tags = Loadable(lazy(() => import('../pages/admin/tags/Tags')));
const FormEdit = Loadable(lazy(() => import('../pages/admin/templates/forms/FormEdit')));
const JobTitles = Loadable(lazy(() => import('../pages/admin/jobTitles/JobTitles')));
// Profile
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));
// Tenants
const Tenants = Loadable(lazy(() => import('../pages/super-admin/tenants/Tenants')));
const TenantCreate = Loadable(lazy(() => import('../pages/super-admin/tenants/TenantCreate')));
const TenantEdit = Loadable(lazy(() => import('../pages/super-admin/tenants/TenantEdit')));
// Form Templates
const FormTemplates = Loadable(
  lazy(() => import('../pages/super-admin/form-templates/FormTemplates'))
);
const FormTemplateCreate = Loadable(
  lazy(() => import('../pages/super-admin/form-templates/FormTemplateCreate'))
);
const FormTemplateEdit = Loadable(
  lazy(() => import('../pages/super-admin/form-templates/FormTemplateEdit'))
);
// Forms
const HRFormFill = Loadable(lazy(() => import('../pages/forms/HRFormFill')));
const AccountancyFormFill = Loadable(lazy(() => import('../pages/forms/AccountancyFormFill')));
const FilledFormEdit = Loadable(lazy(() => import('../pages/forms/FilledFormEdit')));
// Address book
const AddressBook = Loadable(lazy(() => import('../pages/addressBook/AddressBook')));
// Document tree
const DocumentTree = Loadable(lazy(() => import('../pages/document-tree/DocumentTree')));
