import React, { useState } from 'react';

import { Button } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import DocumentSignaturePadDialog from 'src/sections/documents/signing/DocumentSignaturePadDialog';

// -----------------------------------------------------------------------

const DocumentSignaturePad: React.FC = () => {
  //  const isMobile = useResponsive('down', 'sm');
  const { t } = useLocales();
  const [isSignaturePadDialogOpen, setIsSignaturePadDialogOpen] = useState(false);

  return (
    <>
      <Button
        fullWidth
        variant="text"
        onClick={() => setIsSignaturePadDialogOpen(true)}
        id="jm81xk8p"
      >
        {t('shared.buttons.addSignatureToSign')}
      </Button>

      {isSignaturePadDialogOpen && (
        <DocumentSignaturePadDialog
          isSignaturePadDialogOpen={isSignaturePadDialogOpen}
          setIsSignaturePadDialogOpen={setIsSignaturePadDialogOpen}
        />
      )}
    </>
  );
};

export default DocumentSignaturePad;
