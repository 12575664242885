import { useTranslation } from 'react-i18next';
import { et, lt, lv, ru, enIE } from 'date-fns/locale';

import { enUS, etEE, ruRU, Localization } from '@mui/material/locale';

import { getSession, isValidToken } from 'src/utils/auth/jwt';

import ltLT from 'src/locales/mui-system-locales/lt';
import lvLV from 'src/locales/mui-system-locales/lv';
import aspUserService from 'src/services/asp-identity/aspUserService';

// ----------------------------------------------------------------------

export type CalendarLocaleType = {
  code: string;
  label: string;
};

export type LanguageType = {
  label: string;
  value: string;
  systemValue: Localization;
  dateFns: Locale;
};

const LANGS: LanguageType[] = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    dateFns: enIE,
  },
  {
    label: 'Lietuviškai',
    value: 'lt',
    systemValue: ltLT,
    dateFns: lt,
  },
  {
    label: 'Latviešu',
    value: 'lv',
    systemValue: lvLV,
    dateFns: lv,
  },
  {
    label: 'Eesti',
    value: 'ee',
    systemValue: etEE,
    dateFns: et,
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    dateFns: ru,
  },
];

export default function useLocales() {
  const { i18n, t } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const CALENDAR_LOCALES: readonly CalendarLocaleType[] = [
    { code: 'EE', label: t('shared.countries.ee') },
    { code: 'LT', label: t('shared.countries.lt') },
    { code: 'LV', label: t('shared.countries.lv') },
  ];

  const handleChangeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);

    const { accessToken } = getSession();

    if (accessToken && isValidToken(accessToken)) {
      aspUserService.setLocale(locale);
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    t,
    currentLang,
    allLang: LANGS,
    calendarLocales: CALENDAR_LOCALES,
    i18n,
  };
}
