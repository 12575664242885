import React from 'react';

import { Box } from '@mui/material';

import { useSelector } from '../../redux/store';
import useResponsive from '../../hooks/useResponsive';
import { FolderActions } from '../../@types/dlx-drive/enums/FolderActions';
import DLXDriveDocumentActionsFillForm from './settings/DLXDriveDocumentActionsFillForm';
import DLXDriveDocumentActionsUploadDocument from './settings/DLXDriveDocumentActionsUploadDocument';

// --------------------------------------------------------

type DLXDriveDocumentActionsProps = {
  folderId: number;

};

const DLXDriveDocumentActions: React.FC<DLXDriveDocumentActionsProps> = ({ folderId }) => {
  const { selectedFolder } = useSelector((state) => state.dlxDrive);
  const isMobile = useResponsive('only', 'xs');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'stretch',
        gap: isMobile ? 1 : 2,
        mb: isMobile ? 0 : 4,
      }}
    >
      {selectedFolder.actions.includes(FolderActions.FileUpload) && (
        <DLXDriveDocumentActionsUploadDocument folderId={folderId} />
      )}

      {selectedFolder.actions.includes(FolderActions.FormCreate) && (
        <DLXDriveDocumentActionsFillForm folderId={folderId} />
      )}
    </Box>
  );
};

export default DLXDriveDocumentActions;
