import { PagingFilter } from 'src/@types/shared/pagingFilter';

export class SortFilter extends PagingFilter {
  sortingColumn: number | null;

  sortDescending: boolean | null;

  constructor() {
    super();
  }
}
