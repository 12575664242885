import 'dayjs/locale/en';
import 'dayjs/locale/lt';
import 'dayjs/locale/lv';
import 'dayjs/locale/ru';
import 'dayjs/locale/et';

import { LicenseInfo } from '@mui/x-license-pro';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import Router from './routes';
import ThemeProvider from './theme';
import useLocales from './hooks/useLocales';
import RtlLayout from './components/RtlLayout';
import GlobalStyles from './theme/globalStyles';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './contexts/JWTContext';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import { AppSettingsProvider } from './contexts/AppSettingsContext';
import { PrevLocationProvider } from './contexts/PrevLocationContext';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ConfirmationModalContextProvider from './contexts/ConfirmationModalContext'; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function App() {
  const { currentLang } = useLocales();

  LicenseInfo.setLicenseKey(
    'b266b524e58b1e7b09070011d11bf0ceTz05NjA1NSxFPTE3NTc0ODkwMTcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={currentLang.value === 'ee' ? 'et' : currentLang.value}
      dateFormats={{ keyboardDate: 'YYYY-MM-DD' }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <ThemeColorPresets>
            <ThemeLocalization>
              <RtlLayout>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <ConfirmationModalContextProvider>
                      <AuthProvider>
                        <AppSettingsProvider>
                          <PrevLocationProvider>
                            <GlobalStyles />
                            <ProgressBarStyle />
                            <ChartStyle />
                            <ScrollToTop />
                            <Router />
                          </PrevLocationProvider>
                        </AppSettingsProvider>
                      </AuthProvider>
                    </ConfirmationModalContextProvider>
                  </MotionLazyContainer>
                </NotistackProvider>
              </RtlLayout>
            </ThemeLocalization>
          </ThemeColorPresets>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}
