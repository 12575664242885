import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import useAppSettings from 'src/hooks/useAppSettings';

import Logo from '../components/Logo';
import LanguagePopover from './dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(1, 3, 0),
}));

// ----------------------------------------------------------------------

export default function AuthLayout() {
  const moduleData = useAppSettings();

  return (
    <>
      {!moduleData.isCompany && (
        <HeaderStyle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Logo sx={{ maxWidth: '150px' }} />
            <LanguagePopover />
          </Box>
        </HeaderStyle>
      )}
      <Outlet />
    </>
  );
}
