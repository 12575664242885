import React, { useState } from 'react';
import fileDownload from 'js-file-download';

import { Containers } from 'src/@types/fileView/enums/fileExtensions';

import useLocales from '../../../hooks/useLocales';
import MenuPopover from '../../../components/MenuPopover';
import useNotifications from '../../../hooks/useNotifications';
import { bulkDownload } from '../../../services/dlxDriveService';
import { addStopLoadingState } from '../../../redux/slices/dlxDrive';
import DLXDriveFolderActionsMenuItem from './DLXDriveFolderActionsMenuItem';
import DLXDriveSingleShareDialog from '../dialogs/DLXDriveSingleShareDialog';
import { FolderActions } from '../../../@types/dlx-drive/enums/FolderActions';
import DLXDriveSingleDeleteDialog from '../dialogs/DLXDriveSingleDeleteDialog';
import { DLXDriveBulkDownloadModelType } from '../../../@types/dlx-drive/types';
import { DocumentStatuses } from '../../../@types/documents/enums/documentStatuses';
import DLXDriveMoveDocumentDialog from '../dialogs/documents/DLXDriveMoveDocumentDialog';
import DLXDriveSingleApproveDialog from '../dialogs/documents/DLXDriveSingleApproveDialog';

// ----------------------------------------------------------------

type DLXDriveDocumentActionsMenuProps = {
  isMenuPopoverOpen: boolean;
  setIsMenuPopoverOpen: (value: boolean) => void;
  anchorEl: React.RefObject<HTMLElement>;
  documentId: number;
  documentName: string;
  documentStatus: DocumentStatuses;
  actions: FolderActions[];
};

const DLXDriveDocumentActionsMenu: React.FC<DLXDriveDocumentActionsMenuProps> = ({
  isMenuPopoverOpen,
  setIsMenuPopoverOpen,
  anchorEl,
  documentId,
  documentName,
  documentStatus,
  actions,
}) => {
  const { t } = useLocales();
  const [isMoveDocumentDialogOpen, setIsMoveDocumentDialogOpen] = useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isSingleApproveDialogOpen, setIsSingleApproveDialogOpen] = useState<boolean>(false);
  const { enqueueSuccess, enqueueError } = useNotifications();

  const handleClose = () => {
    setIsMenuPopoverOpen(false);
  };

  const handleDocumentMoveClick = () => {
    setIsMoveDocumentDialogOpen(true);
    handleClose();
  };

  const handleShareClick = () => {
    setIsShareDialogOpen(true);
    handleClose();
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
    handleClose();
  };

  const handleApproveClick = () => {
    setIsSingleApproveDialogOpen(true);
    handleClose();
  };

  const handleFolderDownloadClick = async () => {
    let model: DLXDriveBulkDownloadModelType = {};

    model.documentIds = [documentId];
    model.folderIds = [];

    const isContainer = Containers.some((item) => documentName.includes(item));

    try {
      const file = await bulkDownload(model);

      let {fileName} = file;

      if (isContainer) {
        fileName = documentName;
      }

      fileDownload(file.file, fileName, file.mime);
      enqueueSuccess('dlxDrive.notifications.downloadSuccess');
    } catch (error) {
      enqueueError('dlxDrive.errors.downloadError');
    } finally {
      addStopLoadingState();
    }
  };

  return (
    <>
      <MenuPopover
        open={isMenuPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl.current}
        sx={{
          ml: '-1px',
        }}
      >
        <DLXDriveFolderActionsMenuItem
          disabled={!actions.includes(FolderActions.FileDownload)}
          src="/icons/dlx-drive/actions/download.svg"
          name={t('dlxDrive.folderSettings.download')}
          onClick={handleFolderDownloadClick}
          id="w5YNy1"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={!actions.includes(FolderActions.FileShare)}
          src="/icons/dlx-drive/actions/share.svg"
          name={t('dlxDrive.folderSettings.share')}
          onClick={handleShareClick}
          id="5VHgit"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={documentStatus !== DocumentStatuses.WaitingForMeApprove}
          src="/icons/dlx-drive/actions/approve.svg"
          name={t('dlxDrive.folderSettings.approve')}
          onClick={handleApproveClick}
          id="aK4QQv"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={!actions.includes(FolderActions.FileMove)}
          src="/icons/dlx-drive/actions/move.svg"
          name={t('dlxDrive.folderSettings.moveTo')}
          onClick={handleDocumentMoveClick}
          id="4hTLp7"
        />

        <DLXDriveFolderActionsMenuItem
          disabled={!actions.includes(FolderActions.FileDelete)}
          src="/icons/dlx-drive/actions/delete.svg"
          name={t('dlxDrive.folderSettings.delete')}
          onClick={handleDeleteClick}
          id="MK3P8b"
        />
      </MenuPopover>

      <DLXDriveMoveDocumentDialog
        documentIds={[documentId]}
        documentName={documentName}
        isMoveDocumentDialogOpen={isMoveDocumentDialogOpen}
        setIsMoveDocumentDialogOpen={setIsMoveDocumentDialogOpen}
      />

      <DLXDriveSingleShareDialog
        itemId={documentId}
        itemName={documentName}
        isFolder={false}
        isShareDialogOpen={isShareDialogOpen}
        setIsShareDialogOpen={setIsShareDialogOpen}
      />

      <DLXDriveSingleDeleteDialog
        itemId={documentId}
        itemName={documentName}
        isFolder={false}
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
      />

      <DLXDriveSingleApproveDialog
        itemId={documentId}
        itemName={documentName}
        isSingleApproveDialogOpen={isSingleApproveDialogOpen}
        setIsSingleApproveDialogOpen={setIsSingleApproveDialogOpen}
      />
    </>
  );
};

export default DLXDriveDocumentActionsMenu;
