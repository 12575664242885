import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import { LegendItemProps } from '../../@types/calendar/types';
import useLocales, { LanguageType } from '../../hooks/useLocales';

// ----------------------------------------------------------------

export type CalendarDesktopLegendProps = {
  legend: LegendItemProps[];
  isMobile: boolean | undefined;
  wrapperWidth: number;
};

const CalendarLegendItem = (
  item: LegendItemProps,
  isMobile: boolean | undefined,
  currentLang: LanguageType,
  textWidth: string
) => (
  <Box
    key={`calendar-legend-item-${item.id}`}
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      gap: 1,
    }}
  >
    <Box
      sx={{
        width: isMobile ? '14px' : '20px',
        height: isMobile ? '14px' : '20px',
        borderRadius: '20px',
        backgroundColor: `${item.colorCode}`,
      }}
    />

    <Typography sx={{ maxWidth: textWidth }} variant={isMobile ? 'caption' : 'subtitle1'} noWrap>
      {JSON.parse(item.name)[currentLang.value]}
    </Typography>
  </Box>
);

const CalendarLegend: React.FC<CalendarDesktopLegendProps> = ({
  legend,
  isMobile,
  wrapperWidth,
}) => {
  const { currentLang } = useLocales();
  const [textWidth, setTextWidth] = useState<string>('310px');

  const WIDTH_OF_OTHER_ELEMENTS = 24;

  useEffect(() => {
    setTextWidth(`${wrapperWidth - WIDTH_OF_OTHER_ELEMENTS}px`);
  }, [wrapperWidth]);

  return (
    <Box sx={{ width: '100%', mb: 1 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: isMobile ? 1 : 4,
        }}
      >
        {legend.map((item) => CalendarLegendItem(item, isMobile, currentLang, textWidth))}
      </Box>
    </Box>
  );
};

export default CalendarLegend;
