import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Table = (theme: Theme) => {
  const isLight = theme.palette.mode === 'light';

  const backgroundColor = isLight ? theme.palette.grey[0] : theme.palette.grey[800];

  return {
    MuiTable: {
      styleOverrides: {
        root: {
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          borderCollapse: 'separate',
          borderSpacing: '0px 4px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,

            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '&.activeHover:hover': {
            '&>td': {
              backgroundColor: isLight
                ? `${theme.palette.secondary.lighter}`
                : `${theme.palette.secondary.darker}`,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor,
          borderBottom: 'none',
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          },
        },
        head: {
          color: isLight ? theme.palette.grey[900] : theme.palette.grey[0],
          backgroundColor: isLight ? theme.palette.grey[300] : theme.palette.grey[600],
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: -4,
        },
      },
    },
  };
};

export default Table;
