import React from 'react';

import { Box, Skeleton } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';

type DocumentFileViewerSkeletonProps = {
  wrapperSize: { width: number; height: number };
};

const DocumentFileViewerSkeleton: React.FC<DocumentFileViewerSkeletonProps> = ({ wrapperSize }) => {
  const isMobile = useResponsive('down', 'sm');
  const multiple = isMobile ? 5 : 10;

  const lowHeight = 1 * multiple;
  const middleHeight = 2 * multiple;
  const highHeight = 6 * multiple;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant="rounded" width="70%" height={middleHeight} />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
        {Array.from(new Array(3)).map((_, index) => (
          <Skeleton key={`skeleton-1-${index}`} variant="rounded" width="40%" height={lowHeight} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 4 }}>
        <Skeleton variant="rounded" width="50%" height={middleHeight} />

        {Array.from(new Array(2)).map((_, index) => (
          <Skeleton key={`skeleton-2-${index}`} variant="rounded" width="30%" height={lowHeight} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 4 }}>
        {Array.from(new Array(15)).map((_, index) => (
          <Skeleton key={`skeleton-3-${index}`} variant="rounded" width="100%" height={lowHeight} />
        ))}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 4, mt: 8 }}>
        <Skeleton variant="rounded" width="40%" height={lowHeight} />
        <Skeleton variant="circular" width={highHeight} height={highHeight} />
      </Box>
    </Box>
  );
};

export default DocumentFileViewerSkeleton;
