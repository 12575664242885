import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { addNotification } from 'src/redux/slices/systemNotifications';
import { SystemNotification } from 'src/@types/system-notifications/types';

import useAuth from './useAuth';
import Iconify from '../components/Iconify';
import { IconButtonAnimate } from '../components/animate';

//-------------------------------------------------------------

const useNotifications = () => {
  const { user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useLocales();
  const theme = useTheme();

  const createNotification = (type: number, key: any, options?: any) => {
    if (!user) {
      return;
    }

    addNotification({ localeObject: { key, options }, type, dateTime: new Date() } as SystemNotification);
  };

  const enqueueSuccess = (key: any, options?: any) => {
    createNotification(1, key, options);
    enqueueSnackbar(t(key, options), {
      variant: 'success',
      action: (key) => (
        <IconButtonAnimate size='small' onClick={() => closeSnackbar(key)}>
          <Iconify icon="eva:close-fill"  sx={{color: theme.palette.success.darker}} />
        </IconButtonAnimate>
      ),
    });
  };

  const enqueueError = (key: any, options?: any) => {
    createNotification(2, key, options);
    enqueueSnackbar(t(key, options), {
      variant: 'error',
      action: (key) => (
        <IconButtonAnimate size='small' onClick={() => closeSnackbar(key)}>
          <Iconify icon="eva:close-fill" sx={{color: theme.palette.error.darker}} />
        </IconButtonAnimate>
      ),
    });
  };

  return { enqueueSuccess, enqueueError };
};

export default useNotifications;
