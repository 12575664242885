import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import useLocales from '../../hooks/useLocales';
import { CalendarUserProps } from '../../@types/calendar/types';
import CalendarMobileLayoutItem from './CalendarMobileLayoutItem';

// ----------------------------------------------------------------

type CalendarMobileLayoutProps = {
  calendarMobileData: CalendarUserProps[];
};

export type CalendarMobileItemProps = {
  fullName: string;
  eventName: string;
  color: string;
  endDate: string;
  isApproved: boolean;
};

const CalendarMobileLayout: React.FC<CalendarMobileLayoutProps> = ({ calendarMobileData }) => {
  const { t, currentLang } = useLocales();
  const [mobileEvents, setMobileEvents] = useState<CalendarMobileItemProps[]>([]);

  const handleMobileCalendarEvents = () => {
    const mobileCalendarEvents: CalendarMobileItemProps[] = [];

    calendarMobileData.map((user, i) => {
      let item: CalendarMobileItemProps = {
        fullName: user.fullName,
        eventName: '',
        color: '',
        endDate: '',
        isApproved: false,
      };

      Object.keys(user.events).map((key) => {
        const x = user.events[key];

        Object.keys(x).map((key) => {
          const event = x[key];

          item = {
            ...item,
            eventName: JSON.parse(event.eventTypeName)[currentLang.value],
            color: event.eventTypeColor,
            endDate: event.endDate,
            isApproved: event.approved,
          };

          mobileCalendarEvents.push(item);
        });
      });
    });

    setMobileEvents(mobileCalendarEvents);
  };

  useEffect(() => {
    if (calendarMobileData.length > 0) {
      handleMobileCalendarEvents();
    } else {
      setMobileEvents([]);
    }
  }, [calendarMobileData]);

  return (
    <>
      {mobileEvents.length > 0 && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', px: 0.5, gap: 1 }}>
          {mobileEvents.map((event, i) => (
            <CalendarMobileLayoutItem key={`calendar-mobile-layout-item-${i}`} item={event} />
          ))}
        </Box>
      )}

      {mobileEvents.length === 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <CalendarMonthIcon sx={{ fontSize: 40, opacity: 0.5 }} />

          <Typography variant="subtitle1">{t('calendar.noEventFound')}</Typography>
        </Box>
      )}
    </>
  );
};

export default CalendarMobileLayout;
