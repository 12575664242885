export class PagingFilter {
  offset?: number;

  limit?: number;

  constructor(offset: number | undefined = undefined, limit: number | undefined = undefined) {
    this.offset = offset;
    this.limit = limit;
  }
}
