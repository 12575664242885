import React, { useState, useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Checkbox, TextField, Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import { urlParamsProps } from '../../@types/calendar/types';
import { addStopLoadingState } from '../../redux/slices/dlxDrive';
import { getDepartmentsList } from '../../services/accessGroupsService';
import { AccessGroupsDepartmentListItem } from '../../@types/access-groups/types';

// ----------------------------------------------------------------

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type CalendarDepartmentsProps = {
  urlParams: urlParamsProps;
  setSearchParams: (params: urlParamsProps) => void;
};

const CalendarDepartments: React.FC<CalendarDepartmentsProps> = ({
  urlParams,
  setSearchParams,
}) => {
  const { t } = useLocales();
  const [departmentList, setDepartmentList] = useState<AccessGroupsDepartmentListItem[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<AccessGroupsDepartmentListItem[]>(
    []
  );
  const [departmentsQuery, setDepartmentsQuery] = useState<string>('');
  const isMobile = useResponsive('down', 'sm');

  const fetchDepartmentList = async () => {
    try {
      const departmentList = await getDepartmentsList(true);

      setDepartmentList(departmentList);
    } catch (error) {
      console.error(error);
    } finally {
      addStopLoadingState();
    }
  };

  useEffect(() => {
    if (selectedDepartments.length === 0) {
      setDepartmentsQuery('');
    } else {
      setDepartmentsQuery(selectedDepartments.map((department) => department.id).join('-'));
    }
  }, [selectedDepartments]);

  useEffect(() => {
    const newURLParams = { ...urlParams };

    if (departmentsQuery === '') {
      delete newURLParams.departments;
    } else {
      newURLParams.departments = departmentsQuery;
    }

    setSearchParams({ ...newURLParams });
  }, [departmentsQuery]);

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  useEffect(() => {
    const { departments } = urlParams;

    if (departments && departmentList.length > 0) {
      const selectedDepartmentsIds = departments?.split('-').map((id) => parseInt(id, 10));

      const newSelectedDepartments = departmentList.filter((department) =>
        selectedDepartmentsIds.includes(department.id)
      );

      setSelectedDepartments(newSelectedDepartments);
    }
  }, [urlParams, departmentList]);

  return (
    <Autocomplete
      id="calendar-departments"
      size="small"
      sx={{
        width: isMobile ? '100%' : '40%',
        maxWidth: isMobile ? 'none' : '400px',
      }}
      multiple
      fullWidth
      limitTags={1}
      disableCloseOnSelect
      value={selectedDepartments}
      options={departmentList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option: AccessGroupsDepartmentListItem) => option.firstLetter}
      getOptionLabel={(option) => `${option.name}`}
      onChange={(event: any, newValue: AccessGroupsDepartmentListItem[] | null) => {
        newValue ? setSelectedDepartments(newValue) : setSelectedDepartments([]);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps }}
          label={t('calendar.departments')}
        />
      )}
    />
  );
};

export default CalendarDepartments;
