import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
} from '@mui/material';

import useLocales from '../../../../hooks/useLocales';
import { useSelector } from '../../../../redux/store';
import useNotifications from '../../../../hooks/useNotifications';
import { DLXDriveFolderListItem } from '../../../../@types/dlx-drive/types';
import { moveFolder, getFolderList } from '../../../../services/dlxDriveService';
import { addStopLoadingState, setDriveListIdState } from '../../../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

type DLXDriveMoveFolderDialogProps = {
  folderId: number;
  folderName: string;
  isMoveFolderDialogOpen: boolean;
  setIsMoveFolderDialogOpen: (value: boolean) => void;
};

const DLXDriveMoveFolderDialog: React.FC<DLXDriveMoveFolderDialogProps> = ({
  folderId,
  folderName,
  isMoveFolderDialogOpen,
  setIsMoveFolderDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const [moveToFolder, setMoveToFolder] = useState<DLXDriveFolderListItem>({
    firstLetter: '',
    id: -1,
    name: '',
  });
  const [folderList, setFolderList] = useState<DLXDriveFolderListItem[]>([]);
  const { enqueueSuccess } = useNotifications();

  const fetchFolderList = async () => {
    const url: string = `ids=${folderId}`;

    const folderList = await getFolderList(true, url.length > 0 ? url : undefined);

    setFolderList(folderList);
    addStopLoadingState();
  };

  const clearMoveToFolder = () => {
    setMoveToFolder({
      firstLetter: '',
      id: -1,
      name: '',
    });
  };

  const handleClose = () => {
    clearMoveToFolder();
    setIsMoveFolderDialogOpen(false);
  };

  const handleChange = (newFolder: DLXDriveFolderListItem | null) => {
    if (newFolder) {
      setMoveToFolder(newFolder);
    }
  };

  const handleSave = async () => {
    try {
      await moveFolder({ idsToMove: [folderId], destinationFolder: moveToFolder.id });

      enqueueSuccess('dlxDrive.notifications.folderMoved');
      setDriveListIdState();
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (isMoveFolderDialogOpen) {
      fetchFolderList();
    }
  }, [isMoveFolderDialogOpen]);

  return (
    <Dialog open={isMoveFolderDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.moveFolder', { folderName })}</DialogTitle>

      <DialogContent>
        <Autocomplete
          value={moveToFolder.id !== -1 ? moveToFolder : null}
          options={folderList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: DLXDriveFolderListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.name} (id: ${option.id})`}
          onChange={(event: any, newFolder: DLXDriveFolderListItem | null) => {
            newFolder ? handleChange(newFolder) : clearMoveToFolder();
          }}
          renderInput={(params) => (
            <TextField key={params.id} {...params} label={t('dlxDrive.dialogs.folderList')} />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={moveToFolder.id === -1}
          loading={isLoading}
          variant="contained"
          onClick={handleSave}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveMoveFolderDialog;
