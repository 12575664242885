import axios from 'src/utils/httpClients/axios';

import { SIFT_API, AUTH0_API } from 'src/config';
import { FileStatisticType, SignatureAuthType } from 'src/@types/analytics/types';

// ----------------------------------------------------------------------

type RangeDate = {
  startDate: string;
  endDate: string;
};

const baseUrl = `${SIFT_API.siftDomain}Action/user/list/`;
const identityUrl = `${AUTH0_API.domain}api/companies/tenantActions`;

const head = {
  headers: {
    'x-api-key': SIFT_API.siftApiKey,
  },
};

const getSignAuthCount = (res: any, fileName: string): number => {
  const num = res.filter((item: any) => item.action === fileName).length;
  return num;
};

const signAuthData = (resData: any): SignatureAuthType => ({
  signatureSmartId: getSignAuthCount(resData, 'smartid_signature'),
  signatureMobileId: getSignAuthCount(resData, 'mobileid_signature'),
  authenticationSmartId: getSignAuthCount(resData, 'smartid_authentication'),
  authenticationMobileId: getSignAuthCount(resData, 'mobileid_authentification'),
});

const getDocTypeCount = (res: any, fileName: string): number => {
  const num = res.filter((item: any) => item.signedDocumentType === fileName).length;
  return num;
};

const filesStatistic = (resData): FileStatisticType => ({
  pdf: getDocTypeCount(resData, 'pdf'),
  adoc: getDocTypeCount(resData, 'adoc'),
  asice: getDocTypeCount(resData, 'asice'),
  edoc: getDocTypeCount(resData, 'edoc'),
  bdoc: getDocTypeCount(resData, 'bdoc'),
});

async function getTenantStatistics(rangeDate: RangeDate) {
  const settings = {
    params: {
      ...rangeDate,
    },
    headers: {
      'Accept-Language': 'en',
    },
  };

  const response = await axios.get(identityUrl, settings);
  const signAuthDataToShow = signAuthData(response.data);
  const filesStatisticsToShow = filesStatistic(response.data);

  const dataToShow = {
    ...signAuthDataToShow,
    ...filesStatisticsToShow,
  };

  return dataToShow;
}

async function getSignatureAuthFilesCounts(email: string | undefined, rangeDate: RangeDate) {
  const settings = {
    params: {
      ...rangeDate,
    },
    ...head,
  };

  const response = await axios.get(`${baseUrl}${email}`, settings);
  const signAuthDataToShow = signAuthData(response.data);
  const filesStatisticsToShow = filesStatistic(response.data);

  const dataToShow = {
    ...signAuthDataToShow,
    ...filesStatisticsToShow,
  };

  return dataToShow;
}

const dashboardB2BAnalyticsService = {
  getSignatureAuthFilesCounts,
  getTenantStatistics,
};

export default dashboardB2BAnalyticsService;
