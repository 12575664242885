export const DOC_VIEWER_DOCUMENT_EXTENSIONS = [
  'bmp',
  'csv',
  'odt',
  'doc',
  'docx',
  'gif',
  'htm',
  'html',
  'jpg',
  'jpeg',
  'png',
  'ppt',
  'pptx',
  'tiff',
  'txt',
  'xls',
  'xlsx',
  'mp4',
  'webp',
];

export const DOC_VIEWER_FAKE_LOADING_TIMEOUT = 1000;

export const SKELETON_ANIMATION = 'wave';
