import React, { useState, useEffect } from 'react';

import { Box, Avatar, Typography } from '@mui/material';

import { User } from '../../@types/users/user';
import { CalendarUserProps } from '../../@types/calendar/types';
import getLettersAvatar from '../../utils/avatars/getLettersAvatar';
import { CALENDAR_NAME_MIN_WIDTH } from '../../@types/calendar/constants/cell';

// ----------------------------------------------------------------

type CalendarDesktopUserCellProps = {
  user: CalendarUserProps;
};

const CalendarDesktopUserCell: React.FC<CalendarDesktopUserCellProps> = ({ user }) => {
  const [userAvatar, setUserAvatar] = useState<string>('');

  const { fullName, jobTitle, image } = user;

  const getAvatar = async () => {
    const newUser = {
      firstName: user.fullName.split(' ')[0],
      lastName: user.fullName.split(' ')[1],
      image: user.image,
    } as unknown as User;

    let newAvatar = '';

    if (!user.image) {
      newAvatar = getLettersAvatar(newUser);
    }

    if (user.image) {
      newAvatar = `${user.image}`;
    }

    setUserAvatar(newAvatar);
  };

  useEffect(() => {
    getAvatar();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: `${CALENDAR_NAME_MIN_WIDTH}px`,
        height: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box>
        {!user.image && (
          <Avatar sx={{ width: '30px', height: '30px' }}>
            <Typography variant="subtitle2">{userAvatar}</Typography>
          </Avatar>
        )}

        {user.image && (
          <Avatar
            sx={{ width: '30px', height: '30px' }}
            src={userAvatar}
            alt={`${user.fullName}`}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{fullName}</Typography>

        <Typography variant="caption">{jobTitle}</Typography>
      </Box>
    </Box>
  );
};

export default CalendarDesktopUserCell;
